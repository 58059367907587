// https://github.com/evanlouie/fibonacci-performance/blob/master/fib.js#L28
export function fibonacci(n: number, prev?: number, curr?: number): number {
  if (prev == null) {
    prev = 1;
  }
  if (curr == null) {
    curr = 1;
  }
  if (n === 1) {
    return prev;
  }
  if (n === 2) {
    return curr;
  }

  return fibonacci(n - 1, curr, prev + curr);
}

export const goldenRatio = 1.618;
export const goldenRatioInverse = 1 / goldenRatio;
export const millisecondsInAMonth = 2678400000;
