/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { useMutation } from '@apollo/client';
import { SET_USER_SUBSCRIPTION_AUTORENEW_MUTATION } from 'src/queries/membership';
import { UserSubscription } from 'src/types';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { toast } from 'react-toastify';

export function useSetAutorenew(
  autorenew: Boolean,
  userSubscription?: UserSubscription
): (_: any, options?: any) => Promise<void> {
  const ensureUser = useEnsureUser();
  const [setUserSubscriptionAutornewMutation] = useMutation(
    SET_USER_SUBSCRIPTION_AUTORENEW_MUTATION
  );
  const handleGraphQLErrors = useGraphQLErrors();

  return async (_: any, options?: any): Promise<void> => {
    try {
      if (!setUserSubscriptionAutornewMutation || !userSubscription) return;

      await ensureUser();

      await setUserSubscriptionAutornewMutation({
        variables: {
          autorenew: autorenew,
          userSubscriptionId: userSubscription.id,
        },
      });
    } catch (e) {
      handleGraphQLErrors(e as Error, (message) => {
        toast(message, { type: 'error' });
      });
    } finally {
      if (options && options.setSubmitting) options.setSubmitting(false);
    }
  };
}
