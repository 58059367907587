import React, { ReactElement } from 'react';
import ModerationRequestListView from 'src/components/moderation-request-list/moderation-request-list-view';
import { PaginationQueryVariables, PaginationArgs } from 'src/types';
import { PAGINATED_MODERATION_REQUEST_QUERY } from 'src/queries';
import GraphqlResult from 'src/components/graphql-result';
import { useOffsetBasedPagination } from 'src/hooks';
import {
  generateMediaItemsMetadataPreview,
  generateCommentChildrenMetadata,
  generateCommentsMetadata,
} from 'src/utils/pagination';

const variables: PaginationQueryVariables = {
  commentsMetadata: generateCommentsMetadata(),
  commentChildrenMetadata: generateCommentChildrenMetadata(),
  mediaItemsMetadata: generateMediaItemsMetadataPreview(),
  metadata: {
    limit: 10,
    offset: 0,
  },
  type: 'ModerationRequest',
};

interface InterfaceProps {
  className?: string;
  paginationArgs?: PaginationArgs;
}

const ModerationRequestListQuery: React.FC<InterfaceProps> = ({
  className,
  paginationArgs = {},
}) => {
  const response = useOffsetBasedPagination(
    PAGINATED_MODERATION_REQUEST_QUERY,
    {
      ...variables,
      paginationArgs,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  return (
    <GraphqlResult response={response}>
      {(data): ReactElement => {
        return <ModerationRequestListView className={className} {...data} />;
      }}
    </GraphqlResult>
  );
};

export default ModerationRequestListQuery;
