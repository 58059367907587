import React from 'react';
import { Profile } from 'src/types';
import ReportForm from 'src/components/report-form';

interface InterfaceProps {
  profile: Profile;
  onReject: () => void;
  onResolve: () => void;
}

const ReportProfileFormView: React.FC<InterfaceProps> = ({
  profile,
  onReject,
  onResolve,
}) => {
  return (
    <ReportForm
      initialValues={{ profileId: profile.id, comment: '' }}
      onReject={onReject}
      onResolve={onResolve}
    />
  );
};

export default ReportProfileFormView;
