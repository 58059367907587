import React, { ReactElement } from 'react';
import {
  ModerationRequest,
  ModerationRequestResponseInput,
  User,
  ModerationRequestResponseFieldRevisionInput,
  ModerationRequestResponseUserActionInput,
} from 'src/types';
import Moment from 'react-moment';
import moment from 'moment';
import styled from 'styled-components';
import { GlobalStyleVariables } from 'src/styles/global';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import {
  ButtonSet,
  DeemphasizedButton,
  StyledButton,
  HeadingTwo,
} from 'src/styles';
import { Formik, Form, FieldArray } from 'formik';
import { RESPOND_TO_MODERATION_REQUEST_MUTATION } from 'src/queries';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  SimpleTextarea,
  SimpleCheckbox,
  SimpleDate,
  SimpleInput,
} from 'src/components/simple-form';
import {
  generateMediaItemsMetadataPreview,
  generateCommentsMetadata,
  generateCommentChildrenMetadata,
} from 'src/utils/pagination';
import ModerationRequestItemResponses from 'src/components/moderation-request-item/moderation-request-item-responses';
import UsernameView from 'src/components/username';
import { shallowEquals } from 'src/utils/comparision';

interface ContainerProps {
  isResolved: boolean;
}

const Container = styled.div.attrs((props: ContainerProps) => ({
  isResolved: props.isResolved,
}))`
  display: flex;
  flex-direction: column;
  background-color: rgba(
    ${(props): string => props.theme.lowMutedColor},
    ${Math.pow(goldenRatioInverse, 5)}
  );
  padding: 1rem;
  transition: background-color ${GlobalStyleVariables.baseDuration}ms;

  ${(props): string | undefined => {
    if (props.isResolved) {
      return `
        opacity: ${goldenRatioInverse};
      `;
    }
  }}
`;

const TimeContainer = styled.div`
  margin-top: 1rem;
  color: rgba(${(props): string => props.theme.highMutedColor}, 1);
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  text-align: right;
`;

const StyledForm = styled(Form)`
  margin: ${fibonacci(3)}rem 0;
`;

const DisciplinaryActions = styled.div`
  margin-bottom: ${fibonacci(5)}rem;
`;

const StyledFieldArray = styled(FieldArray)`
  margin-top: ${fibonacci(3)}rem;
`;

const StyledHeadingTwo = styled(HeadingTwo)`
  margin-bottom: ${fibonacci(1) * goldenRatioInverse}rem;
`;

const StyledUsername = styled(UsernameView)`
  display: block;
  margin-bottom: ${fibonacci(1) * goldenRatioInverse}rem;
`;

export interface ModerationRequestResponseFieldInterface {
  field: string;
  original: string;
  type: 'input' | 'textarea';
}

interface InterfaceProps {
  fields?: ModerationRequestResponseFieldInterface[];
  isDisciplinary?: boolean;
  moderationRequest: ModerationRequest;
  users?: User[];
}

const ModerationRequestItemWrapper: React.FC<InterfaceProps> = ({
  children,
  fields,
  isDisciplinary,
  moderationRequest,
  users,
}) => {
  const { t } = useTranslation('general');
  const millisecondsInAMonth = 2678400000;
  const [respondToModerationRequestMutation] = useMutation(
    RESPOND_TO_MODERATION_REQUEST_MUTATION
  );

  const priorResponses = (
    moderationRequest.moderationRequestResponses?.sort((a, b) =>
      a.insertedAt.toLocaleString().localeCompare(b.insertedAt.toLocaleString())
    ) || []
  ).reduce(
    (aggregate, response) => {
      if (response.moderationRequestResponseFieldRevisions) {
        response.moderationRequestResponseFieldRevisions.forEach(
          (fieldRevision) => {
            aggregate.moderationRequestResponseFieldRevisions[
              fieldRevision.field
            ] = fieldRevision;
          }
        );
      }
      if (response.moderationRequestResponseUserActions) {
        response.moderationRequestResponseUserActions.forEach((userAction) => {
          aggregate.moderationRequestResponseUserActions[userAction.user.id] = {
            ...userAction,
            userId: userAction.user.id,
          };
        });
      }
      return aggregate;
    },
    {
      moderationRequestResponseFieldRevisions: {} as {
        [key: string]: ModerationRequestResponseFieldRevisionInput;
      },
      moderationRequestResponseUserActions: {} as {
        [key: string]: ModerationRequestResponseUserActionInput;
      },
    }
  );

  const sanitizeResponse = (
    moderationRequestResponse: ModerationRequestResponseInput
  ): ModerationRequestResponseInput => {
    if (moderationRequestResponse.moderationRequestResponseFieldRevisions) {
      moderationRequestResponse.moderationRequestResponseFieldRevisions =
        moderationRequestResponse.moderationRequestResponseFieldRevisions
          .filter((fieldRevision) => {
            const prior =
              priorResponses.moderationRequestResponseFieldRevisions[
                fieldRevision.field
              ];

            return (
              (fieldRevision.revision !== fieldRevision.original &&
                !fieldRevision.id) ||
              (prior && !shallowEquals(fieldRevision, prior))
            );
          })
          .map((fieldRevision) => {
            return {
              field: fieldRevision.field,
              revision: fieldRevision.revision,
            };
          });
    }

    if (moderationRequestResponse.moderationRequestResponseUserActions) {
      moderationRequestResponse.moderationRequestResponseUserActions =
        moderationRequestResponse.moderationRequestResponseUserActions
          .filter((userAction) => {
            const prior =
              priorResponses.moderationRequestResponseUserActions[
                userAction.userId
              ];
            return (
              ((userAction.cannotPostAnything ||
                userAction.cannotPostComments ||
                userAction.cannotPostContent ||
                userAction.cannotModerate) &&
                !userAction.id) ||
              (prior && !shallowEquals(userAction, prior))
            );
          })
          .map((userAction) => {
            return {
              activeUntil: userAction.activeUntil
                ? moment(userAction.activeUntil).format(
                    'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
                  )
                : undefined,
              cannotPostAnything: userAction.cannotPostAnything,
              cannotPostComments: userAction.cannotPostComments,
              cannotPostContent: userAction.cannotPostContent,
              cannotModerate: userAction.cannotModerate,
              userId: userAction.userId,
            };
          });
    }

    return moderationRequestResponse;
  };

  const handleResponse = (
    hasConfirmed: boolean,
    moderationRequestResponse: ModerationRequestResponseInput
  ): void => {
    moderationRequestResponse = sanitizeResponse(
      Object.assign({}, moderationRequestResponse)
    );
    respondToModerationRequestMutation({
      variables: {
        commentsMetadata: generateCommentsMetadata(),
        commentChildrenMetadata: generateCommentChildrenMetadata(),
        mediaItemsMetadata: generateMediaItemsMetadataPreview(),
        moderationRequestResponse: {
          ...moderationRequestResponse,
          hasConfirmed,
        },
      },
    });
  };

  const onReject = (values: ModerationRequestResponseInput): void => {
    handleResponse(false, values);
  };

  const onSubmit = (
    values: ModerationRequestResponseInput,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { setFieldValue, setSubmitting }: any
  ): void => {
    try {
      handleResponse(true, values);
    } finally {
      setSubmitting(false);
      setFieldValue('comment', '');
    }
  };

  const moderationRequestResponseFieldRevisions = fields
    ? fields.map((field) => {
        const prior =
          priorResponses.moderationRequestResponseFieldRevisions[field.field];
        return prior
          ? Object.assign({}, prior)
          : {
              field: field.field,
              original: field.original,
              revision: field.original,
              type: field.type,
            };
      })
    : undefined;

  const moderationRequestResponseUserActions = users
    ? users.map((user) => {
        const prior =
          priorResponses.moderationRequestResponseUserActions[user.id];

        return prior
          ? Object.assign({}, prior)
          : {
              cannotPostAnything: false,
              cannotPostComments: false,
              cannotPostContent: false,
              cannotModerate: false,
              userId: user.id,
              user,
            };
      })
    : undefined;

  return (
    <Container isResolved={moderationRequest.isResolved}>
      {children}
      <Formik
        initialValues={{
          moderationRequestId: moderationRequest.id,
          hasConfirmed: false,
          shouldRemove: false,
          moderationRequestResponseFieldRevisions,
          moderationRequestResponseUserActions,
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }): ReactElement => (
          <StyledForm>
            <ModerationRequestItemResponses
              isDisciplinary={isDisciplinary}
              moderationRequest={moderationRequest}
            />
            {isDisciplinary && (
              <DisciplinaryActions>
                <StyledFieldArray name="moderationRequestResponseUserActions">
                  {(): ReactElement => {
                    return (
                      <>
                        <StyledHeadingTwo>
                          {t('moderationResponseUserActions')}
                        </StyledHeadingTwo>
                        {values.moderationRequestResponseUserActions &&
                          values.moderationRequestResponseUserActions.map(
                            (userAction, index) => {
                              return (
                                <div key={userAction.userId}>
                                  {userAction.user && (
                                    <StyledUsername user={userAction.user} />
                                  )}
                                  <SimpleCheckbox
                                    checked={userAction.cannotPostAnything}
                                    label={t(
                                      'moderationResponseCannotPostAnything'
                                    )}
                                    name={`moderationRequestResponseUserActions.${index}.cannotPostAnything`}
                                  />
                                  <SimpleCheckbox
                                    checked={userAction.cannotPostComments}
                                    label={t(
                                      'moderationResponseCannotPostComments'
                                    )}
                                    name={`moderationRequestResponseUserActions.${index}.cannotPostComments`}
                                  />
                                  <SimpleCheckbox
                                    checked={userAction.cannotPostContent}
                                    label={t(
                                      'moderationResponseCannotPostContent'
                                    )}
                                    name={`moderationRequestResponseUserActions.${index}.cannotPostContent`}
                                  />
                                  <SimpleCheckbox
                                    checked={userAction.cannotModerate}
                                    label={t(
                                      'moderationResponseCannotModerate'
                                    )}
                                    name={`moderationRequestResponseUserActions.${index}.cannotModerate`}
                                  />
                                  {(userAction.cannotPostAnything ||
                                    userAction.cannotPostComments ||
                                    userAction.cannotPostContent ||
                                    userAction.cannotModerate) && (
                                    <SimpleDate
                                      label={t(
                                        'moderationResponseRestrictUntil'
                                      )}
                                      minDate={new Date()}
                                      name={`moderationRequestResponseUserActions.${index}.activeUntil`}
                                      showMonthDropdown
                                      showYearDropdown
                                    />
                                  )}
                                </div>
                              );
                            }
                          )}
                      </>
                    );
                  }}
                </StyledFieldArray>
                <StyledFieldArray name="moderationRequestResponseFieldRevisions">
                  {(): ReactElement => {
                    return (
                      <>
                        <StyledHeadingTwo>
                          {t('moderationResponseFieldRevisions')}
                        </StyledHeadingTwo>
                        {values.moderationRequestResponseFieldRevisions &&
                          values.moderationRequestResponseFieldRevisions.map(
                            (fieldRevision, index) => {
                              return (
                                <div key={fieldRevision.field}>
                                  {fieldRevision.type === 'textarea' ? (
                                    <SimpleTextarea
                                      label={fieldRevision.field}
                                      name={`moderationRequestResponseFieldRevisions.${index}.revision`}
                                    />
                                  ) : (
                                    <SimpleInput
                                      label={fieldRevision.field}
                                      name={`moderationRequestResponseFieldRevisions.${index}.revision`}
                                    />
                                  )}
                                </div>
                              );
                            }
                          )}
                      </>
                    );
                  }}
                </StyledFieldArray>
              </DisciplinaryActions>
            )}
            <SimpleTextarea
              label={t('moderationResponseComment')}
              name="comment"
              placeholder={t('moderationResponseCommentDetail')}
            />
            <ButtonSet>
              <DeemphasizedButton
                type="button"
                onClick={(): void => onReject(values)}
              >
                {isDisciplinary
                  ? t('moderationResponseDismiss')
                  : t('moderationResponseReject')}
              </DeemphasizedButton>
              <StyledButton type="submit" disabled={isSubmitting}>
                {isDisciplinary
                  ? t('moderationResponseTakeAction')
                  : t('moderationResponseAccept')}
              </StyledButton>
            </ButtonSet>
          </StyledForm>
        )}
      </Formik>
      <TimeContainer>
        <Moment
          date={moderationRequest.sortedAt}
          fromNowDuring={millisecondsInAMonth}
          format="MM/DD/YY"
        />
      </TimeContainer>
    </Container>
  );
};

export default ModerationRequestItemWrapper;
