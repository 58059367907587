import React from 'react';
import { Notification } from 'src/types';
import NotificationItemPostInteraction from 'src/components/notification-item/types/post-interaction';
import NotificationItemWrapper from 'src/components/notification-item/notification-item-wrapper';
import { useNavigate } from 'react-router';

interface InterfaceProps {
  notification: Notification;
  onClick: () => void;
}

const NotificationItemPostCommentLeft: React.FC<InterfaceProps> = ({
  notification,
  onClick,
}) => {
  const navigate = useNavigate();

  function augmentedClick(): void {
    if (notification.post) {
      navigate({
        pathname: `/posts/${notification.post.id}/comments`,
        search: `?commentId=${notification.comment.id}`,
      });
    }
    onClick();
  }

  return (
    <NotificationItemWrapper
      notification={notification}
      onClick={augmentedClick}
    >
      <NotificationItemPostInteraction
        notification={notification}
        type="CommentLeft"
      />
    </NotificationItemWrapper>
  );
};

export default NotificationItemPostCommentLeft;
