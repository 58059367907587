import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { SecondaryNavBar } from 'src/components/nav-bar';
import { User } from 'src/types';

interface InterfaceProps {
  user: User;
}

const UserFollowingRouteSelector: React.FC<InterfaceProps> = ({ user }) => {
  const { t } = useTranslation('general');

  return (
    <>
      {user.curatedListFollowingCount > 0 ? (
        <SecondaryNavBar>
          {[
            {
              to: '',
              end: true,
              preventScrollReset: true,
              scrollToNavOnClick: true,
              content: t('followingMembersWithCount', {
                count: user.userFollowingCount,
              }),
            },
            {
              to: 'lists',
              preventScrollReset: true,
              scrollToNavOnClick: true,
              content: t('followingListsWithCount', {
                count: user.curatedListFollowingCount,
              }),
            },
          ]}
        </SecondaryNavBar>
      ) : null}
      <Outlet context={{ user }} />
    </>
  );
};

export default UserFollowingRouteSelector;
