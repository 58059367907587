/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { client } from 'src/components/app/apollo-wrapper';
import { Comment, MediaItem } from 'src/types';
import {
  MEDIA_COLLECTION_WITH_PAGINATED_MEDIA_ITEMS_FRAGMENT,
  COMMENT_AND_USER_WITH_CHILDREN_FRAGMENT,
  ALBUM_AND_CREDITS_WITH_COMMENTS_FRAGMENT,
} from 'src/queries';
import {
  generateCommentsMetadata,
  generateCommentChildrenMetadata,
  generateMediaItemsMetadata,
} from 'src/utils/pagination';

type args = {
  [argName: string]: any;
};

export function userByUsername(user: any, { args }: args): object {
  return (
    user || {
      id: `User:${args.username}`,
      typename: 'User',
    }
  );
}

export function offsetBasedPaginatedEntries(
  entries: any,
  { args, toReference }: any
): object | undefined {
  try {
    if (args.type === 'MediaItem') {
      const mediaCollection = client.readFragment({
        id: toReference({
          __typename: 'MediaCollection',
          id: args.paginationArgs.mediaCollectionId,
        }).id,
        variables: {
          mediaItemsMetadata: args.metadata,
        },
        fragment: MEDIA_COLLECTION_WITH_PAGINATED_MEDIA_ITEMS_FRAGMENT,
        fragmentName: 'mediaCollectionWithPaginatedMediaItems',
      });

      if (mediaCollection.mediaItems) {
        return mediaCollection.mediaItems.map((mediaItem: MediaItem) => {
          return toReference({
            __typename: 'MediaItem',
            id: mediaItem.id,
          });
        });
      }
    } else if (args.type === 'Comment' && args.paginationArgs.parentId) {
      const parent = client.readFragment({
        id: toReference({
          __typename: 'Comment',
          id: args.paginationArgs.parentId,
        }).id,
        variables: {
          commentChildrenMetadata: args.metadata,
        },
        fragment: COMMENT_AND_USER_WITH_CHILDREN_FRAGMENT,
        fragmentName: 'commentAndUserWithChildren',
      });

      if (parent.children) {
        return parent.children.map((comment: Comment) => {
          return toReference({
            __typename: 'Comment',
            id: comment.id,
          });
        });
      }
    } else if (args.type === 'Comment' && args.paginationArgs.postId) {
      const post = client.readFragment({
        id: toReference({
          __typename: 'Post',
          id: args.paginationArgs.postId,
        }).id,
        variables: {
          commentsMetadata: generateCommentsMetadata(),
          commentChildrenMetadata: generateCommentChildrenMetadata(),
          mediaItemsMetadata: generateMediaItemsMetadata(),
        },
        fragment: ALBUM_AND_CREDITS_WITH_COMMENTS_FRAGMENT,
        fragmentName: 'postAndCreditsWithComments',
      });
      if (post.comments) {
        return post.comments?.map((comment: Comment) => {
          return toReference({
            __typename: 'Comment',
            id: comment.id,
          });
        });
      }
    }
  } catch (e) {
    // Invariant Violations happen whenever the readFragment finds no results
    // which is an expected part of this flow
    if ((e as Error).name !== 'Invariant Violation') console.log(e);
  }

  return entries;
}
