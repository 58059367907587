import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin-bottom: ${fibonacci(6)}rem;
  padding: ${fibonacci(3)}rem;
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  z-index: 1;
  overflow: hidden;

  img,
  video {
    max-height: 50svh;
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    margin-bottom: 0 !important;
    border: 0;
    padding: ${fibonacci(3)}rem 0;
    width: 100vw;
    z-index: initial;

    img,
    video {
      max-height: 75svh;
      width: 100vw;
    }
  }
`;

interface InterfaceProps {
  preamble: ReactElement;
  content: ReactElement;
}

const FeedItemContainer: React.FC<InterfaceProps> = ({ content, preamble }) => {
  return (
    <Container>
      {preamble}
      {content}
    </Container>
  );
};

export default FeedItemContainer;
