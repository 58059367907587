import { createGlobalStyle } from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';

const baseFontSize = fibonacci(3);

export const GlobalStyleVariables = {
  baseFontSize,
  fontSizeTwoDown: baseFontSize * 0.694,
  fontSizeOneDown: baseFontSize * 0.833,
  fontSizeOneUp: baseFontSize * 1.2,
  fontSizeTwoUp: baseFontSize * 1.44,
  fontSizeThreeUp: baseFontSize * 1.728,
  fontSizeFourUp: baseFontSize * 2.074,
  baseDuration: 250,
  headerHeight: fibonacci(5),
  mainContainerMargins: 1.5,
  phoneMainContainerMargins: fibonacci(1) * goldenRatioInverse,
  phoneMediaQuery: 'only screen and (max-device-width: 812px)',
  mediaQueryOneUp: 'only screen and (min-width: 1500px)',
  mediaQueryTwoUp: 'only screen and (min-width: 1800px)',
  mediaQueryThreeUp: 'only screen and (min-width: 2100px)',
};

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    min-height: 100dvh;
  }

  html {
    font-size: 62.5%;
  }

  body {
    background-color: rgba(${(props): string =>
      props.theme.backgroundColor}, 1);
    color: rgba(${(props): string => props.theme.primaryColor}, 1);
    font-family: 'Comfortaa', cursive;
    font-size: ${GlobalStyleVariables.baseFontSize}rem;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  * {
    box-sizing: border-box;
  }

  h1 {
    font-size: ${GlobalStyleVariables.fontSizeThreeUp}rem;
  }

  h2 {
    font-size: ${GlobalStyleVariables.fontSizeTwoUp}rem;
  }

  h3 {
    font-size: ${GlobalStyleVariables.fontSizeOneUp}rem;
  }

  h4 {
    font-size: ${GlobalStyleVariables.baseFontSize}rem;
  }

  h5 {
    font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  }

  h6 {
    font-size: ${GlobalStyleVariables.fontSizeTwoDown}rem;
  }

  a {
    color: rgba(${(props): string => props.theme.linkColor}, 1);
    text-decoration: none;

    &:focus, &:hover {
      outline: 0;
      text-decoration: underline;
    }
  }

  input {
    font-size: ${GlobalStyleVariables.baseFontSize}rem;
  }

  ul {
    list-style-type: square;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity ${GlobalStyleVariables.baseDuration}ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .Toastify__toast {
    padding: ${fibonacci(3)}rem ${fibonacci(3)}rem;
    margin-bottom: ${fibonacci(3)}rem;
    border-radius: 0;
    min-height: initial;
    max-height: initial;
    border: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
      rgba(${(props): string => props.theme.primaryColor}, 1);
    color: rgba(${(props): string => props.theme.primaryColor}, 1);
      
    .Toastify__close-button {
      color: rgba(${(props): string => props.theme.primaryColor}, 1);
    }

    &.Toastify__toast--default {
      background: rgba(${(props): string => props.theme.backgroundColor}, 1);
    }

    &.Toastify__toast--success {
      border-color: rgba(${(props): string =>
        props.theme.backgroundColor}, ${goldenRatioInverse});
      color: rgba(${(props): string =>
        props.theme.backgroundColor}, ${goldenRatioInverse});
      background: rgba(${(props): string => props.theme.successColor}, 1);
      
      .Toastify__close-button {
        color: rgba(${(props): string =>
          props.theme.backgroundColor}, ${goldenRatioInverse});
      }
    }

    &.Toastify__toast--error {
      background: rgba(${(props): string => props.theme.errorColor}, 1);
    }
  }

  .UserMenuModalOverlay, .PanedModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
  }

  .MediaEditorModal {
    display: flex;
    max-height: 100dvh;
    max-width: 100dvw;
    padding: 1rem;
    outline: none;
    background-color: rgba(${(props): string =>
      props.theme.backgroundColor}, 1);
    color: rgba(${(props): string => props.theme.primaryColor}, 1);
  }

  .ConfirmationModal {
    max-width: ${goldenRatioInverse * 100}%;
    padding: ${fibonacci(4)}rem;
    outline: none;
    border: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
      rgba(${(props): string => props.theme.primaryColor}, 1);
    background-color: rgba(${(props): string =>
      props.theme.backgroundColor}, 1);
    color: rgba(${(props): string => props.theme.primaryColor}, 1);
  }

  .PanedModal {
    overflow: auto;
    height: 98%;
    width: 98%;
    max-height: ${fibonacci(11) * goldenRatioInverse}rem;
    max-width: ${fibonacci(11)}rem;
    outline: none;
    border: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
      rgba(${(props): string => props.theme.primaryColor}, 1);
    background-color: rgba(${(props): string =>
      props.theme.backgroundColor}, 1);
    color: rgba(${(props): string => props.theme.primaryColor}, 1);
  }

  .PanedModalOverlay, .ConfirmationModalOverlay, .MediaEditorModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 100dvw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .UserMenuModal {
    position: absolute;
    top: ${GlobalStyleVariables.headerHeight}rem;
    right: ${fibonacci(3)}rem;
    max-width: 100%;
    background-color: rgba(${(props): string =>
      props.theme.backgroundColor}, 1);
    border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
      rgba(${(props): string => props.theme.primaryColor}, 1);
    border-top: 0;
    outline: 0;

    @media ${GlobalStyleVariables.phoneMediaQuery} {
      right: 0;
    }
  }
  
  .UserMenuModalOverlay {
    z-index: 999;

    &.ReactModal__Overlay {
      opacity: 1;
      transform: translateY(-20%);
      transition: transform ${GlobalStyleVariables.baseDuration}ms ease-in-out;
    }

    &.ReactModal__Overlay--after-open{
      opacity: 1;
      transform: translateX(0);
    }

    &.ReactModal__Overlay--before-close{
      opacity: 1;
      transform: translateY(-20%);
    }
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker-popper__restyled {
    &[data-placement^="bottom"] {
      margin-top: 1rem;

      .react-datepicker__triangle, .react-datepicker__triangle::before {
        border-bottom-color: rgba(${(props): string =>
          props.theme.primaryColor}, ${goldenRatioInverse});
      }

      .react-datepicker__triangle {
        border-width: 1rem;
        margin-top: -1rem;
      }

      .react-datepicker__triangle::before {
        border-width: 1rem;
        left: -1rem;
      }
    }

    &[data-placement^="top"] {
      margin-bottom: 1rem;

      .react-datepicker__triangle, .react-datepicker__triangle::before {
        border-top-color: rgba(${(props): string =>
          props.theme.primaryColor}, ${goldenRatioInverse});
      }

      .react-datepicker__triangle {
        border-width: 1rem;
        margin-bottom: -1rem;
      }

      .react-datepicker__triangle::before {
        border-width: 1rem;
        left: -1rem;
      }
    }

    &[data-placement^="left"] {
      margin-right: 1rem;

      .react-datepicker__triangle, .react-datepicker__triangle::before {
        border-left-color: rgba(${(props): string =>
          props.theme.primaryColor}, ${goldenRatioInverse});
      }

      .react-datepicker__triangle {
        border-width: 1rem;
        margin-right: -1rem;
      }

      .react-datepicker__triangle::before {
        border-width: 1rem;
        top: -1rem;
      }
    }

    &[data-placement^="right"] {
      margin-left: 1rem;

      .react-datepicker__triangle, .react-datepicker__triangle::before {
        border-right-color: rgba(${(props): string =>
          props.theme.primaryColor}, ${goldenRatioInverse});
      }

      .react-datepicker__triangle {
        border-width: 1rem;
        margin-left: -1rem;
      }

      .react-datepicker__triangle::before {
        border-width: 1rem;
        top: -1rem;
      }
    }
  }

  .react-datepicker-input__restyled {
    width: 100%;
    padding: ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem ${fibonacci(
  1
)}rem;
    font-size: ${GlobalStyleVariables.baseFontSize}rem;
    color: rgba(${(props): string => props.theme.primaryColor}, 1);
    background-color: transparent;
    border: 0;
    border-bottom: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
      rgba(${(props): string =>
        props.theme.primaryColor}, ${goldenRatioInverse});
    transition: border-color ${GlobalStyleVariables.baseDuration}ms;

    &:focus {
      border-style: solid;
      outline: 0;
    }
  }

  .react-datepicker-calendar__restyled {
    color: rgba(${(props): string => props.theme.primaryColor}, 1);
    background-color: rgba(${(props): string =>
      props.theme.backgroundColor}, 1);
    border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
      rgba(${(props): string => props.theme.primaryColor}, 1);
    border-radius: 0;

    .react-datepicker__header {
      background-color: rgba(${(props): string =>
        props.theme.lowMutedColor}, 0.2);
      border-bottom: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
        rgba(${(props): string => props.theme.highMutedColor}, 1);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-top: 1rem;
  
      .react-datepicker__current-month {
        font-size: ${GlobalStyleVariables.baseFontSize}rem;
        color: rgba(${(props): string => props.theme.primaryColor}, 1);
      }
  
      .react-datepicker__day-name {
        width: ${fibonacci(4)}rem;
        line-height: ${fibonacci(3)}rem;
        padding: ${fibonacci(1) * (1 - goldenRatioInverse)}rem;
        font-size: ${GlobalStyleVariables.fontSizeTwoDown}rem;
        color: rgba(${(props): string => props.theme.highMutedColor}, 1);
      }

      .react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container {
        margin: 0 1rem;
      }

      .react-datepicker__month-read-view, .react-datepicker__year-read-view {
        border: 0;
        border-radius: 0;
      }

      .react-datepicker__month-read-view--selected-month, .react-datepicker__year-read-view--selected-year {
        font-size: ${GlobalStyleVariables.fontSizeTwoDown}rem;
        margin-right: ${fibonacci(1) * Math.pow(goldenRatioInverse, 10)}rem;
      }

      .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
        border: 0;
      }

      .react-datepicker__month-dropdown, .react-datepicker__year-dropdown {
        border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
          rgba(${(props): string =>
            props.theme.primaryColor}, ${goldenRatioInverse});
        border-radius: 0;
        background-color: rgba(${(props): string =>
          props.theme.backgroundColor}, 1);
      }

      .react-datepicker__month-option, .react-datepicker__year-option {
        line-height: ${fibonacci(3)}rem;
        font-size: ${GlobalStyleVariables.fontSizeTwoDown}rem;

        &:hover, &:focus {
          background-color: rgba(${(props): string =>
            props.theme.primaryColor}, 1);
          color: rgba(${(props): string => props.theme.backgroundColor}, 1);
        }

        &:first-of-type {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        &:last-of-type {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .react-datepicker__navigation--years-upcoming {
        top: -${fibonacci(1) * goldenRatioInverse}rem;
        border-bottom-color: rgba(${(props): string =>
          props.theme.highMutedColor}, 1);
      }

      .react-datepicker__navigation--years-previous {
        top: ${fibonacci(1) * goldenRatioInverse}rem;
        border-top-color: rgba(${(props): string =>
          props.theme.highMutedColor}, 1);
      }
    }

    .react-datepicker__navigation {
      top: 1rem;
      height: 1rem;
      width: 1rem;
      border-width: 1rem;
    }

    .react-datepicker__navigation--previous {
      left: 1rem;
      border-right-color: rgba(${(props): string =>
        props.theme.primaryColor}, 1);
    }
  
    .react-datepicker__navigation--next {
      right: 1rem;
      border-left-color: rgba(${(props): string =>
        props.theme.primaryColor}, 1);
    }

    .react-datepicker__day {
      width: ${fibonacci(4)}rem;
      line-height: ${fibonacci(3)}rem;
      padding: ${fibonacci(1) * (1 - goldenRatioInverse)}rem;
      font-size: ${GlobalStyleVariables.fontSizeTwoDown}rem;
      color: rgba(${(props): string => props.theme.primaryColor}, 1);

      &.react-datepicker__day--outside-month {
        color: rgba(${(props): string =>
          props.theme.primaryColor}, ${goldenRatioInverse});
      }

      &:hover, &:focus {
        border-radius: 0;
        background-color: rgba(${(props): string =>
          props.theme.lowMutedColor}, ${goldenRatioInverse});
      }

      &.react-datepicker__day--selected {
        border-radius: 0;
        background-color: rgba(${(props): string =>
          props.theme.primaryColor}, 1);
        color: rgba(${(props): string => props.theme.backgroundColor}, 1);
      }

      &.react-datepicker__day--disabled {
        color: rgba(${(props): string => props.theme.primaryColor}, 1);
        background-color: transparent;
      }
    }

    .react-datepicker__time-container {
      width: ${fibonacci(7)}rem;
      border-left: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
      rgba(${(props): string => props.theme.highMutedColor}, 1);

      .react-datepicker-time__header {
        font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
        color: rgba(${(props): string => props.theme.primaryColor}, 1);
      }

      .react-datepicker__time {
        background-color: rgba(${(props): string =>
          props.theme.backgroundColor}, ${goldenRatioInverse});
      }

      .react-datepicker__time-box {
        width: 100%;
      }

      .react-datepicker__time-list-item { 
        height: initial;
        padding: 1rem 0;
        font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;

        &:hover, &:focus, &.react-datepicker__time-list-item--selected {
          background-color: rgba(${(props): string =>
            props.theme.primaryColor}, 1) !important;
          color: rgba(${(props): string =>
            props.theme.backgroundColor}, 1) !important;
        }
      }
    }
  }

  .__react_component_tooltip {
    padding: 1rem ${fibonacci(3)}rem;
    font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  
    &.type-dark {
      border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
        rgba(${(props): string => props.theme.primaryColor}, 1);
      color: rgba(${(props): string => props.theme.primaryColor}, 1);
      background-color: rgba(${(props): string =>
        props.theme.backgroundColor}, 1);

      &.place-top {
        :after {
          border-width: 1rem;
          bottom: -${
            fibonacci(1) + fibonacci(1) * Math.pow(goldenRatioInverse, 4)
          }rem;
          margin-left: -1rem;
          border-top-color: rgba(${(props): string =>
            props.theme.primaryColor}, 1);
          border-bottom-width: 0;
        }
      }

      &.place-bottom {
        :after {
          border-width: 1rem;
          top: -${
            fibonacci(1) + fibonacci(1) * Math.pow(goldenRatioInverse, 4)
          }rem;
          margin-left: -1rem;
          border-bottom-color: rgba(${(props): string =>
            props.theme.primaryColor}, 1);
          border-top-width: 0;
        }
      }

      &.place-right {
        :after {
          border-width: 1rem;
          left: -1rem;
          margin-top: -1rem;
          border-right-color: rgba(${(props): string =>
            props.theme.primaryColor}, 1);
          border-left-width: 0;
        }
      }

      &.place-left {
        :after {
          border-width: 1rem;
          right: -1rem;
          margin-top: -1rem;
          border-left-color: rgba(${(props): string =>
            props.theme.primaryColor}, 1);
          border-right-width: 0;
        }
      }
    }
  }
`;
