import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { HeadingTwo, Paragraph } from 'src/styles';

const About = styled.div`
  padding: ${fibonacci(3)}rem ${fibonacci(3)}rem ${fibonacci(5)}rem;
`;

const StyledHeadingTwo = styled(HeadingTwo)`
  margin-bottom: ${fibonacci(3)}rem;
`;

const AddToCuratedListAbout: React.FC = () => {
  const { t } = useTranslation('general');

  return (
    <About>
      <StyledHeadingTwo>{t('curatedLists')}</StyledHeadingTwo>
      <Paragraph>{t('curatedListsAbout')}</Paragraph>
      <Paragraph>{t('curatedListsAbout2')}</Paragraph>
      <Paragraph>{t('curatedListsAbout3')}</Paragraph>
    </About>
  );
};

export default AddToCuratedListAbout;
