import React, { Fragment, useCallback, useEffect } from 'react';

type axis = 'x' | 'y';

interface InterfaceProps {
  axis?: axis;
  enabled?: boolean;
  scrollElementRef?: HTMLDivElement | null;
  onApproachingInfinity: () => Promise<void>;
}

function determineEventElement(
  isUsingWindow: boolean,
  scrollElement: Element
): Window | Element {
  return isUsingWindow ? window : scrollElement;
}

function determineThreshold(
  axis: axis,
  isUsingWindow: boolean,
  scrollElement: Element
): number {
  switch (axis) {
    case 'x':
      return isUsingWindow ? window.screen.width : scrollElement.clientWidth;
    case 'y':
      return isUsingWindow ? window.screen.height : scrollElement.clientHeight;
  }
}

function calculateScrollPosition(axis: axis, scrollElement: Element): number {
  const boundingRect = scrollElement.getBoundingClientRect();
  switch (axis) {
    case 'x':
      return (
        scrollElement.scrollWidth + boundingRect.left - scrollElement.scrollLeft
      );
    case 'y':
      return (
        scrollElement.scrollHeight + boundingRect.top - scrollElement.scrollTop
      );
  }
}

function checkInfinity(
  scrollElement: Element,
  scrollElementRef?: HTMLDivElement | null,
  axis: axis = 'y'
): boolean {
  const isUsingWindow = !scrollElementRef;
  const threshold = determineThreshold(axis, isUsingWindow, scrollElement);
  const scrollPosition = calculateScrollPosition(axis, scrollElement);

  return scrollPosition < threshold * 2;
}

const InfiniteScrollView: React.FC<InterfaceProps> = ({
  axis,
  children,
  enabled = true,
  scrollElementRef,
  onApproachingInfinity,
}) => {
  const determineScrollElement = useCallback((): Element => {
    if (scrollElementRef) {
      return scrollElementRef as Element;
    } else {
      return document.body || document.documentElement;
    }
  }, [scrollElementRef]);

  const atInfinityGoBeyond = useCallback(() => {
    // scrollElementRef is undefined when using the window, but null when using unrendered refs
    if (scrollElementRef !== null) {
      const scrollElement = determineScrollElement();
      if (enabled && checkInfinity(scrollElement, scrollElementRef, axis)) {
        onApproachingInfinity();
      }
    }
  }, [
    determineScrollElement,
    scrollElementRef,
    axis,
    enabled,
    onApproachingInfinity,
  ]);

  useEffect(() => {
    if (scrollElementRef === null) return;

    const scrollElement = determineScrollElement();
    const eventElement = determineEventElement(
      scrollElementRef === undefined,
      scrollElement
    );

    atInfinityGoBeyond();

    eventElement.addEventListener('scroll', atInfinityGoBeyond);
    window.addEventListener('resize', atInfinityGoBeyond);

    return (): void => {
      eventElement.removeEventListener('scroll', atInfinityGoBeyond);
      window.removeEventListener('resize', atInfinityGoBeyond);
    };
  }, [determineScrollElement, atInfinityGoBeyond, scrollElementRef]);

  return <Fragment>{children}</Fragment>;
};

export default InfiniteScrollView;
