import React from 'react';
import PostList from 'src/components/post-list';
import useUser from '../../use-user';

const UserListPurchasesRouteView: React.FC = () => {
  const { user } = useUser();

  return (
    <PostList
      useCarousel={true}
      paginationArgs={{
        purchaserId: user.id,
      }}
    />
  );
};

export default UserListPurchasesRouteView;
