import gql from 'graphql-tag';
import { FEED_ITEM_FRAGMENT } from 'src/queries/fragments__complex';
import {
  ESSENTIAL_USER_FRAGMENT,
  PROFILE_FRAGMENT,
  SUBSCRIPTION_DISCOUNT_FRAGMENT,
  USER_SUBSCRIPTION_FRAGMENT,
} from 'src/queries/fragments__simple';

export const COLLECT_ALBUM_MUTATION = gql`
  mutation LikePost($postId: String) {
    likePost(postId: $postId) {
      post {
        id
        likeCount
      }
      user {
        ...essentialUser
        likeCount
        likedPosts {
          id
        }
      }
    }
  }
  ${ESSENTIAL_USER_FRAGMENT}
`;

export const MANAGE_SUBSCRIPTION_PACKAGE_MUTATION = gql`
  mutation ManageSubscriptionPackages(
    $subscriptionPackagesInput: SubscriptionPackagesInput
  ) {
    manageSubscriptionPackages(
      subscriptionPackagesInput: $subscriptionPackagesInput
    ) {
      subscriptionPrice
      subscriptionDiscounts {
        ...subscriptionDiscount
      }
    }
  }
  ${SUBSCRIPTION_DISCOUNT_FRAGMENT}
`;

export const PURCHASE_USER_SUBSCRIPTION_MUTATION = gql`
  mutation PurchaseUserSubscription(
    $purchaseUserSubscriptionInput: PurchaseUserSubscriptionInput
  ) {
    purchaseUserSubscription(
      purchaseUserSubscriptionInput: $purchaseUserSubscriptionInput
    ) {
      userSubscription {
        ...userSubscription
      }
      follower {
        ...essentialUser
        userFollowingCount
        subscribedCount
      }
      followed {
        ...essentialUser
        userFollowerCount
        subscriberCount
      }
    }
  }
  ${USER_SUBSCRIPTION_FRAGMENT}
  ${ESSENTIAL_USER_FRAGMENT}
`;

export const SET_USER_SUBSCRIPTION_AUTORENEW_MUTATION = gql`
  mutation SetUserSubscriptionAutorenew(
    $userSubscriptionId: String
    $autorenew: Boolean
  ) {
    setUserSubscriptionAutorenew(
      userSubscriptionId: $userSubscriptionId
      autorenew: $autorenew
    ) {
      ...userSubscription
    }
  }
  ${USER_SUBSCRIPTION_FRAGMENT}
`;

export const MANAGE_SUBSCRIPTION_PROMOTION_MUTATION = gql`
  mutation ManageSubscriptionPromotion(
    $subscriptionPromotionInput: SubscriptionPromotionInput
  ) {
    manageSubscriptionPromotion(
      subscriptionPromotionInput: $subscriptionPromotionInput
    ) {
      subscriptionPrice
      subscriptionDiscount {
        ...subscriptionDiscount
      }
    }
  }
  ${SUBSCRIPTION_DISCOUNT_FRAGMENT}
`;

export const DECOLLECT_ALBUM_MUTATION = gql`
  mutation DelikePost($postId: String) {
    unlikePost(postId: $postId) {
      post {
        id
        likeCount
      }
      user {
        ...essentialUser
        likeCount
        likedPosts {
          id
        }
      }
    }
  }
  ${ESSENTIAL_USER_FRAGMENT}
`;

export const FOLLOW_CURATED_LIST_MUTATION = gql`
  mutation FollowCuratedList($followedId: String) {
    followCuratedList(followedId: $followedId) {
      followerCuratedListFollowingCount
      followedUserFollowerCount
    }
  }
`;

export const FOLLOW_USER_MUTATION = gql`
  mutation FollowUser($followedId: String) {
    followUser(followedId: $followedId) {
      follower {
        ...essentialUser
        userFollowingCount
      }
      followed {
        ...essentialUser
        userFollowerCount
      }
    }
  }
  ${ESSENTIAL_USER_FRAGMENT}
`;

export const PAGINATED_FEED_ITEM_QUERY = gql`
  query PaginatedPosts(
    $mediaItemsMetadata: PaginationMetadataInput
    $metadata: PaginationMetadataInput
    $paginationArgs: PaginationArgsInput
    $type: String
  ) {
    offsetBasedPaginatedEntries(
      type: $type
      metadata: $metadata
      paginationArgs: $paginationArgs
    ) {
      __typename
      ... on FeedItem {
        ...feedItem
      }
    }
  }
  ${FEED_ITEM_FRAGMENT}
`;

export const PURCHASE_ALBUM_MUTATION = gql`
  mutation PurchasePost($postId: String) {
    purchasePost(postId: $postId) {
      post {
        id
        likeCount
        purchaseCount
      }
      user {
        ...essentialUser
        likeCount
        purchaseCount
        likedPosts {
          id
        }
        purchasedPosts {
          id
        }
      }
    }
  }
  ${ESSENTIAL_USER_FRAGMENT}
`;

export const SUBSCRIPTION_DISCOUNTS_QUERY = gql`
  query SubscriptionDiscounts($isActive: Boolean, $type: Int, $userId: String) {
    subscriptionDiscounts(isActive: $isActive, type: $type, userId: $userId) {
      ...subscriptionDiscount
    }
  }
  ${SUBSCRIPTION_DISCOUNT_FRAGMENT}
`;

export const UNFOLLOW_CURATED_LIST_MUTATION = gql`
  mutation UnfollowCuratedList($followedId: String) {
    unfollowCuratedList(followedId: $followedId) {
      followerCuratedListFollowingCount
      followedUserFollowerCount
    }
  }
`;

export const UNFOLLOW_USER_MUTATION = gql`
  mutation UnfollowUser($followedId: String) {
    unfollowUser(followedId: $followedId) {
      follower {
        ...essentialUser
        userFollowingCount
      }
      followed {
        ...essentialUser
        userFollowerCount
      }
    }
  }
  ${ESSENTIAL_USER_FRAGMENT}
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfile(
    $id: String
    $avatar: ImageInput
    $description: String
    $displayName: String
    $pronouns: String
    $website: String
  ) {
    updateProfile(
      id: $id
      avatar: $avatar
      description: $description
      displayName: $displayName
      pronouns: $pronouns
      website: $website
    ) {
      id
      username
      displayName
      pronouns
      avatar {
        id
        extension
        uploadUrl
      }
      profile {
        ...profile
      }
    }
  }
  ${PROFILE_FRAGMENT}
`;
