import React, { createRef } from 'react';
import { GlobalStyleVariables } from 'src/styles';
import ReactModal from 'react-modal';
import ImageEditor from 'src/components/image-editor';
import { useTranslation } from 'react-i18next';
import { FileUpload, FileUploadModifications } from 'src/types';
import styled from 'styled-components';
import { renderModifiedImage } from 'src/utils/uploads';

const Video = styled.video`
  max-height: 100%;
  max-width: 100%;
`;

interface InterfaceProps {
  closeModal: () => void;
  file?: FileUpload;
  forceRatio?: number;
  isOpen: boolean;
  save: (file: FileUpload) => void;
}

const MediaEditorModalView: React.FC<InterfaceProps> = ({
  closeModal,
  file,
  forceRatio,
  isOpen,
  save,
}) => {
  const { t } = useTranslation('general');
  const ref = createRef<HTMLDivElement>();

  const saveFile = (modifications: FileUploadModifications): void => {
    if (file) {
      file.modifications = modifications;
      if (file.type === 'image' && ref.current) {
        const canvas = document.createElement('canvas');
        ref.current.appendChild(canvas);
        const image = new Image();
        image.onload = (): void => {
          canvas.height = image.naturalHeight;
          canvas.width = image.naturalWidth;
          renderModifiedImage(file, canvas, image);
          canvas.toBlob(
            (blob): void => {
              if (blob) {
                const modifiedFile = blob as File;
                Object.defineProperty(modifiedFile, 'name', {
                  writable: true,
                  value: file.file.name,
                });
                file.modifiedFile = modifiedFile;
                save(file);
              }
            },
            file.type,
            1.0
          );
          canvas.remove();
        };
        image.src = file.result;
      }
    }

    closeModal();
  };

  return (
    <ReactModal
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      isOpen={isOpen}
      contentLabel={t('mediaEditorModal')}
      onRequestClose={closeModal}
      closeTimeoutMS={GlobalStyleVariables.baseDuration}
      className="MediaEditorModal"
      overlayClassName="MediaEditorModalOverlay"
    >
      {file && file.type === 'image' && (
        <ImageEditor
          file={file}
          cancel={closeModal}
          save={saveFile}
          forceRatio={forceRatio}
        />
      )}
      {file && file.type === 'video' && (
        <Video src={file.result} autoPlay loop />
      )}
      <div ref={ref} />
    </ReactModal>
  );
};

export default MediaEditorModalView;
