import React from 'react';
import { Notification } from 'src/types';
import Moment from 'react-moment';
import styled from 'styled-components';
import { GlobalStyleVariables } from 'src/styles/global';
import { goldenRatioInverse, millisecondsInAMonth } from 'src/utils/math';
import { PrimaryColorVanillaLink } from 'src/styles';

interface ContainerProps {
  isRead: boolean;
}

const Container = styled(PrimaryColorVanillaLink).attrs(
  (props: ContainerProps) => ({
    isRead: props.isRead,
  })
)`
  display: flex;
  flex-direction: column;
  background-color: rgba(
    ${(props): string => props.theme.lowMutedColor},
    ${Math.pow(goldenRatioInverse, 5)}
  );
  padding: 1rem;
  transition: background-color ${GlobalStyleVariables.baseDuration}ms;
  overflow-x: hidden;
  text-overflow: ellipsis;

  ${(props): string | undefined => {
    if (!props.isRead) {
      return `
      border-right: 1rem solid rgba(${props.theme.primaryColor}, 1);
      padding-right: 1rem;
      `;
    }
  }}

  &:hover, &:focus {
    text-decoration: none;
    background-color: rgba(
      ${(props): string => props.theme.lowMutedColor},
      ${Math.pow(goldenRatioInverse, 4)}
    );
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  }
`;

const TimeContainer = styled.div`
  margin-top: 1rem;
  color: rgba(${(props): string => props.theme.highMutedColor}, 1);
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  text-align: right;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    font-size: ${GlobalStyleVariables.fontSizeTwoDown}rem;
  }
`;

interface InterfaceProps {
  notification: Notification;
  onClick?: () => void;
}

const NotificationItemWrapper: React.FC<InterfaceProps> = ({
  children,
  notification,
  onClick,
}) => {
  return (
    <Container onClick={onClick} isRead={notification.isRead} tabIndex={1}>
      {children}
      <TimeContainer>
        <Moment
          date={notification.sortedAt}
          fromNowDuring={millisecondsInAMonth}
          format="MM/DD/YY"
        />
      </TimeContainer>
    </Container>
  );
};

export default NotificationItemWrapper;
