import React from 'react';
import { StyledLabel, StyledLabelName, StyledFieldError } from 'src/styles';
import DateField from 'src/components/date-field';

interface InterfaceProps {
  autoFocus?: boolean;
  disabled?: boolean;
  label: string;
  minDate?: Date;
  maxDate?: Date;
  name: string;
  showMonthDropdown?: boolean;
  showTimeSelect?: boolean;
  showYearDropdown?: boolean;
}

const DateView: React.FC<InterfaceProps> = ({
  autoFocus,
  disabled,
  label,
  minDate,
  maxDate,
  name,
  showMonthDropdown,
  showTimeSelect,
  showYearDropdown,
}) => {
  return (
    <StyledLabel disabled={disabled}>
      <StyledLabelName as="div">{label}</StyledLabelName>
      <StyledFieldError name={name} component="div" />
      <DateField
        autoFocus={autoFocus}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        showMonthDropdown={showMonthDropdown}
        showTimeSelect={showTimeSelect}
        showYearDropdown={showYearDropdown}
      />
    </StyledLabel>
  );
};

export default DateView;
