import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';

export const DefaultLinkMixin = css`
  cursor: pointer;
`;

export const PrimaryColorLinkMixin = css`
  color: rgba(${(props): string => props.theme.primaryColor}, 1);
  ${DefaultLinkMixin}
`;

export const MutedColorLinkMixin = css`
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
  ${DefaultLinkMixin}
`;

export const PrimaryColorLink = styled(Link)`
  ${PrimaryColorLinkMixin}
`;

export const PrimaryColorVanillaLink = styled.a`
  ${PrimaryColorLinkMixin}
`;

export const MutedLink = styled(PrimaryColorLink)`
  ${MutedColorLinkMixin}
`;

export const DefaultLink = styled.a`
  ${DefaultLinkMixin}
`;

export const InvertingSVGLinkMixin = css`
  padding: ${fibonacci(1) * goldenRatioInverse}rem;

  &:focus {
    background-color: rgba(${(props): string => props.theme.primaryColor}, 1);
    svg {
      color: rgba(${(props): string => props.theme.backgroundColor}, 1);
    }
  }
`;

export const InvertingSVGLink = styled(PrimaryColorLink)`
  ${InvertingSVGLinkMixin}
`;

export const InvertingSVGVanillaLink = styled(PrimaryColorVanillaLink)`
  ${InvertingSVGLinkMixin}
`;
