import styled from 'styled-components';

export const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const UnstyledListItem = styled.li`
  /* https://gomakethings.com/creating-unstyled-lists/#fixing-semantics */
  :before {
    content: '\\a0';
    position: absolute;
    pointer-events: none;
  }
`;
