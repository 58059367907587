/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import MediaItemListView from 'src/components/media-item-list/media-item-list-view';
import { PAGINATED_MEDIA_ITEMS_QUERY } from 'src/queries';
import { PaginationQueryVariables, PaginationArgs } from 'src/types';
import { useOffsetBasedPagination } from 'src/hooks';
import GraphqlResult from 'src/components/graphql-result';
import { generateMediaItemsMetadata } from 'src/utils/pagination';

const variables: PaginationQueryVariables = {
  paginationArgs: {},
  metadata: generateMediaItemsMetadata(),
  type: 'MediaItem',
};

interface InterfaceProps {
  canClickToPlay?: boolean;
  currentIndex: number;
  paginationArgs?: PaginationArgs;
  noContentText?: string;
  noContentClickCb?: (_: any, { setSubmitting }: any) => Promise<void>;
  scrollElementRef?: HTMLDivElement | null;
  shouldMute?: boolean;
  shouldPlayOnView?: boolean;
  setCarouselLength?: (carouselLength: number) => void;
}

const MediaItemListQuery: React.FC<InterfaceProps> = ({
  canClickToPlay,
  currentIndex,
  paginationArgs = {},
  noContentText,
  noContentClickCb,
  scrollElementRef,
  shouldMute,
  shouldPlayOnView,
  setCarouselLength,
}) => {
  const response = useOffsetBasedPagination(PAGINATED_MEDIA_ITEMS_QUERY, {
    ...variables,
    paginationArgs,
  });

  return (
    <GraphqlResult response={response}>
      {(data): ReactElement => {
        return (
          <MediaItemListView
            canClickToPlay={canClickToPlay}
            currentIndex={currentIndex}
            noContentText={noContentText}
            noContentClickCb={noContentClickCb}
            scrollElementRef={scrollElementRef}
            setCarouselLength={setCarouselLength}
            shouldMute={shouldMute}
            shouldPlayOnView={shouldPlayOnView}
            {...data}
          />
        );
      }}
    </GraphqlResult>
  );
};

export default MediaItemListQuery;
