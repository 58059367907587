import React, { ReactElement } from 'react';
import PostListView from 'src/components/post-list/post-list-view';
import { PaginationArgs, PaginationMetadataInput } from 'src/types';
import { PAGINATED_ALBUM_QUERY } from 'src/queries';
import GraphqlResult from 'src/components/graphql-result';
import { useOffsetBasedPagination } from 'src/hooks';
import {
  generateGridPaginationMetadata,
  generateMediaItemsMetadataNoPreview,
  generateMediaItemsMetadataPreview,
  generatePaginationMetadata,
} from 'src/utils/pagination';

interface InterfaceProps {
  className?: string;
  metadata?: PaginationMetadataInput;
  paginationArgs?: PaginationArgs;
  scrollElementRef?: HTMLDivElement | null;
  shouldPlayOnView?: boolean;
  shouldMute?: boolean;
  useCarousel?: boolean;
  useGridLayout?: boolean;
  setCarouselLength?: (carouselLength: number) => void;
}

const PostListQuery: React.FC<InterfaceProps> = ({
  className,
  metadata = generatePaginationMetadata(),
  paginationArgs = {},
  scrollElementRef,
  shouldMute,
  shouldPlayOnView = true,
  useCarousel,
  useGridLayout,
  setCarouselLength,
}) => {
  const response = useOffsetBasedPagination(PAGINATED_ALBUM_QUERY, {
    mediaItemsMetadata: useCarousel
      ? generateMediaItemsMetadataNoPreview()
      : generateMediaItemsMetadataPreview(),
    metadata: useGridLayout ? generateGridPaginationMetadata() : metadata,
    paginationArgs,
    type: 'Post',
  });

  return (
    <GraphqlResult response={response}>
      {(data): ReactElement => {
        return (
          <PostListView
            className={className}
            scrollElementRef={scrollElementRef}
            shouldMute={shouldMute}
            shouldPlayOnView={shouldPlayOnView}
            setCarouselLength={setCarouselLength}
            useCarousel={useCarousel}
            useGridLayout={useGridLayout}
            {...data}
          />
        );
      }}
    </GraphqlResult>
  );
};

export default PostListQuery;
