import React from 'react';
import { Image } from 'src/types/schema';
import styled from 'styled-components';
import { goldenRatioInverse } from 'src/utils/math';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MediaProps {
  classSize?: number;
}

const ImageElement = styled.img.attrs((props: MediaProps) => ({
  classSize: props.classSize || 1,
}))`
  display: block;
  height: 100%;
  width: 100%;
  border-radius: ${Math.pow(goldenRatioInverse, 3) * 100}%;
`;

const StyledFontAwesomeIcon = styled.div.attrs((props: MediaProps) => ({
  classSize: props.classSize || 1,
}))`
  svg {
    height: ${(props): number => props.classSize}rem;
    width: initial !important;
    border-radius: ${Math.pow(goldenRatioInverse, 3) * 100}%;
  }
`;

interface InterfaceProps {
  className?: string;
  avatar?: Image;
  size?: number;
}

const AvatarView: React.FC<InterfaceProps> = ({ className, avatar, size }) => {
  if (avatar) {
    return (
      <ImageElement className={className} src={avatar.src} classSize={size} />
    );
  } else {
    return (
      <StyledFontAwesomeIcon className={className} classSize={size}>
        <FontAwesomeIcon icon="bug" />
      </StyledFontAwesomeIcon>
    );
  }
};

export default AvatarView;
