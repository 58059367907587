import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  MinorMajorButtonSet,
  StyledButton,
  StyledError,
  DeemphasizedButton,
} from 'src/styles';
import { useAuth } from 'src/hooks/use-auth';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import {
  SubscriptionDiscountType,
  SubscriptionDiscountValidSubscriberType,
  SubscriptionPromotionInput,
} from 'src/types';
import {
  SimpleCheckbox,
  SimpleDeconstructedSelect,
  SimpleInput,
} from 'src/components/simple-form';
import LoadingSpinner from '../loading-spinner';
import { useManageSubscriptionPromotion } from 'src/hooks/use-manage-subscription-promotion';
import { useQuery } from '@apollo/client';
import { SUBSCRIPTION_DISCOUNTS_QUERY } from 'src/queries';
import GraphqlResult from '../graphql-result';

const StyledForm = styled(Form)`
  padding: ${fibonacci(3)}rem;
`;

const StyledButtonSet = styled(MinorMajorButtonSet)`
  margin-top: ${fibonacci(3)}rem;
`;

const ValidSubscriberTypeRow = styled.div`
  margin-bottom: ${fibonacci(3)}rem;
`;

const PackageRow = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  display: block;
  margin-bottom: ${goldenRatioInverse}rem;
`;

const Total = styled.div`
  padding: 1rem;
  margin-bottom: 2rem;
`;

interface InterfaceProps {
  onReject: () => void;
  onResolve: () => void;
}

const SubscriptionPackageFormView: React.FC<InterfaceProps> = ({
  onReject,
  onResolve,
}) => {
  const { t } = useTranslation('general');
  const handleGraphQLErrors = useGraphQLErrors();
  const [globalError, setGlobalError] = useState<string | null>(null);
  const { user } = useAuth();
  const manageSubscriptionPromotion = useManageSubscriptionPromotion();

  const response = useQuery(SUBSCRIPTION_DISCOUNTS_QUERY, {
    variables: {
      type: SubscriptionDiscountType.initialSubscription,
      isActive: true,
      userId: user?.id,
    },
  });

  if (!user) {
    return <LoadingSpinner />;
  } else {
    return (
      <GraphqlResult response={response}>
        {(data): ReactElement => {
          const promotion = data ? data.subscriptionDiscounts[0] : null;
          const promotionSchema = yup.object().shape({
            subscriptionPrice: yup
              .number()
              .required(t('errorRequired'))
              .min(5, t('errorAtLeastDollarAmount', { amount: 5 }))
              .max(69, t('errorAtMostDollarAmount', { amount: 69 })),
            promotion: yup.object().shape({
              duration: yup
                .number()
                .integer(t('errorInteger'))
                .required(t('errorRequired'))
                .min(1, t('errorAtLeastAmount', { amount: 1 }))
                .max(31, t('errorAtMostAmount', { amount: 31 })),
              percentage: yup
                .number()
                .integer(t('errorInteger'))
                .required(t('errorRequired'))
                .min(0, t('errorAtLeastAmount', { amount: 0 }))
                .max(100, t('errorAtMostAmount', { amount: 100 })),
            }),
          });

          const onSubmit = async (
            values: SubscriptionPromotionInput,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { setSubmitting }: any
          ): Promise<void> => {
            try {
              if (!manageSubscriptionPromotion) return;

              setGlobalError(null);

              await manageSubscriptionPromotion(values);

              onResolve();
            } catch (e) {
              handleGraphQLErrors(e as Error, (message: string) => {
                setGlobalError(message);
              });
            } finally {
              setSubmitting(false);
            }
          };

          return (
            <Formik
              initialValues={{
                subscriptionPrice: (user.subscriptionPrice
                  ? parseFloat(user.subscriptionPrice.toString())
                  : 5
                ).toFixed(2) as never,
                promotionEnabled: !!promotion,
                promotion: promotion
                  ? {
                      duration: promotion.duration,
                      type: promotion.type,
                      percentage: promotion.percentage,
                      validSubscriberTypes: promotion.validSubscriberTypes,
                    }
                  : {
                      duration: 31,
                      percentage: 100,
                      type: SubscriptionDiscountType.initialSubscription,
                      validSubscriberTypes:
                        SubscriptionDiscountValidSubscriberType.firstTime,
                    },
              }}
              validationSchema={promotionSchema}
              onSubmit={onSubmit}
              validateOnBlur={false}
            >
              {({ isSubmitting, values }): ReactElement => (
                <StyledForm data-testid="subscription-package-form">
                  <SimpleInput
                    data-testid="subscription-package-form__subscription-price"
                    autoComplete="transaction-amount"
                    autoFocus={true}
                    label={t('subscriptionPrice')}
                    min={5}
                    max={69}
                    name="subscriptionPrice"
                    step="0.01"
                    type="number"
                  />
                  <SimpleCheckbox
                    checked={values.promotionEnabled}
                    label={t('subscriptionPromotionEnabled')}
                    name={`promotionEnabled`}
                  />
                  {values.promotionEnabled && (
                    <>
                      <ValidSubscriberTypeRow>
                        <Label>
                          {t('subscriptionPromotionValidSubscriberTypes')}
                        </Label>
                        <SimpleDeconstructedSelect
                          name="promotion.validSubscriberTypes"
                          options={[
                            {
                              label: t(
                                'subscriptionPromotionValidSubscriberTypeFirstTime'
                              ),
                              value:
                                SubscriptionDiscountValidSubscriberType.firstTime,
                            },
                            {
                              label: t(
                                'subscriptionPromotionValidSubscriberTypeReturning'
                              ),
                              value:
                                SubscriptionDiscountValidSubscriberType.returning,
                            },
                            {
                              label: t(
                                'subscriptionPromotionValidSubscriberTypeEither'
                              ),
                              value:
                                SubscriptionDiscountValidSubscriberType.either,
                            },
                          ]}
                        />
                      </ValidSubscriberTypeRow>
                      <PackageRow>
                        <SimpleInput
                          data-testid="subscription-promotion-form__duration"
                          label={t('subscriptionPromotionDuration')}
                          min={1}
                          max={31}
                          name="promotion.duration"
                          type="number"
                        />
                        <SimpleInput
                          data-testid="subscription-promotion-form__percentage"
                          label={t('subscriptionPromotionPercentage')}
                          min={0}
                          max={100}
                          name="promotion.percentage"
                          type="number"
                        />
                        <Total>
                          {t('price', {
                            amount:
                              (values.promotion.percentage / 100) *
                              values.subscriptionPrice *
                              ((values.promotion.duration ?? 31) / 31),
                          })}
                        </Total>
                      </PackageRow>
                    </>
                  )}
                  {globalError && <StyledError>{globalError}</StyledError>}
                  <StyledButtonSet>
                    <DeemphasizedButton
                      data-testid="subscription-package-form__cancel"
                      type="button"
                      onClick={onReject}
                    >
                      {t('cancel')}
                    </DeemphasizedButton>
                    <StyledButton
                      data-testid="subscription-package-form__submit"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {t('submit')}
                    </StyledButton>
                  </StyledButtonSet>
                </StyledForm>
              )}
            </Formik>
          );
        }}
      </GraphqlResult>
    );
  }
};

export default SubscriptionPackageFormView;
