import React, { ReactElement, useEffect } from 'react';
import { PaginatableType, User } from 'src/types';
import InfiniteScroll from 'src/components/infinite-scroll/index';
import NoContent from 'src/components/no-content';
import UserCard from 'src/components/user-card';
import {
  SquareCardGridLayout,
  CardVerticalLayout,
  LineItemLayout,
} from 'src/styles';
import UserLineItem from '../user-line-item';

interface InterfaceProps {
  className?: string;
  entries: PaginatableType[];
  hideIfEmpty?: boolean;
  onLoadMore: () => Promise<void>;
  scrollElementRef?: HTMLDivElement | null;
  useGridLayout?: boolean;
  useLineItemLayout?: boolean;
  setCarouselLength?: (carouselLength: number) => void;
}

const UserListView: React.FC<InterfaceProps> = ({
  className,
  entries,
  hideIfEmpty,
  onLoadMore,
  scrollElementRef,
  useGridLayout,
  useLineItemLayout,
  setCarouselLength,
}) => {
  useEffect(() => {
    if (setCarouselLength && !!entries) {
      setCarouselLength(entries.length);
    }
  }, [entries.length]);

  if (!entries || entries.length === 0) {
    if (hideIfEmpty) {
      return <></>;
    } else {
      return <NoContent></NoContent>;
    }
  }

  // TODO: aggregate these with a graphdb query;
  // you know, once we're using graphdb :)
  const usedUserIds = [] as string[];
  entries = entries.filter((user) => {
    user = user as User;
    const canDisplay = usedUserIds.indexOf(user.id) === -1;
    usedUserIds.push(user.id);
    return canDisplay;
  });

  function renderEntries(): ReactElement[] {
    return entries.map((user) => {
      user = user as User;

      return useLineItemLayout ? (
        <UserLineItem key={user.id} user={user} />
      ) : (
        <UserCard key={user.id} user={user} />
      );
    });
  }

  return (
    <InfiniteScroll
      onApproachingInfinity={onLoadMore}
      axis={scrollElementRef ? 'x' : 'y'}
      scrollElementRef={scrollElementRef}
    >
      {scrollElementRef ? (
        renderEntries()
      ) : useGridLayout ? (
        <SquareCardGridLayout className={className}>
          {renderEntries()}
        </SquareCardGridLayout>
      ) : useLineItemLayout ? (
        <LineItemLayout className={className}>{renderEntries()}</LineItemLayout>
      ) : (
        <CardVerticalLayout className={className}>
          {renderEntries()}
        </CardVerticalLayout>
      )}
    </InfiniteScroll>
  );
};

export default UserListView;
