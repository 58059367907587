/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { StyledButton } from 'src/styles';
import { Post } from 'src/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAuth } from 'src/hooks/use-auth';
import { DeemphasizedButton, ButtonSet } from 'src/styles/components/button';
import { ACCEPT_CREDIT_MUTATION, DECLINE_CREDIT_MUTATION } from 'src/queries';
import { useMutation } from '@apollo/client';

const StyledButtonSet = styled(ButtonSet)`
  margin-top: 1rem;
`;

interface InterfaceProps {
  post: Post;
}

const PostSummaryCreditAcceptanceInterface: React.FC<InterfaceProps> = ({
  post,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const credit = post.credits.find(
    (credit) => user && credit.user.id === user.id
  );
  const creditNeedsApproval = user && credit && !credit.isVerified;

  const [acceptCreditMutation] = useMutation(ACCEPT_CREDIT_MUTATION);
  const [declineCreditMutation] = useMutation(DECLINE_CREDIT_MUTATION, {
    update() {
      post.credits = post.credits.filter((c) => !credit || c.id !== credit.id);
    },
  });

  if (creditNeedsApproval && credit) {
    const acceptCredit = (): void => {
      acceptCreditMutation({
        variables: {
          creditId: credit.id,
        },
      });
    };
    const declineCredit = (): void => {
      declineCreditMutation({
        variables: {
          creditId: credit.id,
        },
      });
    };

    return (
      <StyledButtonSet>
        <DeemphasizedButton onClick={declineCredit}>
          {t('creditsDecline')}
        </DeemphasizedButton>
        <StyledButton onClick={acceptCredit}>{t('creditsAccept')}</StyledButton>
      </StyledButtonSet>
    );
  } else {
    return <></>;
  }
};

export default PostSummaryCreditAcceptanceInterface;
