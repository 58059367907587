import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const NoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${fibonacci(9)}rem;
  width: 100%;
`;

const NoContentIcon = styled.div`
  margin-top: ${fibonacci(4)}rem;
  font-size: ${fibonacci(7)}rem;
`;

interface InterfaceProps {
  className?: string;
  icon?: IconProp;
}

const NoContentView: React.FC<InterfaceProps> = ({
  children,
  className,
  icon,
}) => {
  const { t } = useTranslation('general');

  if (!icon) icon = 'ghost' as IconProp;

  return (
    <NoContent className={className}>
      {children ? children : t('noContent')}
      <NoContentIcon>
        <FontAwesomeIcon icon={icon} />
      </NoContentIcon>
    </NoContent>
  );
};

export default NoContentView;
