import React from 'react';
import PostList from 'src/components/post-list';
import NoContent from 'src/components/no-content';
import { useTranslation } from 'react-i18next';
import useUser from '../use-user';

const UserUploadsRouteView: React.FC = () => {
  const { t } = useTranslation('general');
  const { user } = useUser();

  if (user?.postCount === 0) {
    return <NoContent>{t('noUploads')}</NoContent>;
  } else {
    return (
      <PostList
        paginationArgs={{
          userId: user.id,
        }}
        useCarousel={true}
      />
    );
  }
};

export default UserUploadsRouteView;
