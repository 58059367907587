import React from 'react';
import { Outlet } from 'react-router-dom';
import SearchRouteNav from 'src/routes/search/search-route-nav';
import { MainContainer } from 'src/styles';

const SearchRouteView: React.FC = () => {
  return (
    <MainContainer>
      <SearchRouteNav />

      <Outlet />
    </MainContainer>
  );
};

export default SearchRouteView;
