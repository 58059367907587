import { User } from 'src/types';
import { useAuth } from 'src/hooks/use-auth';
import { useAccountModals } from 'src/hooks/use-account-modals';

export function useEnsureUser(): () => Promise<User> {
  const { user } = useAuth();
  const { openModal } = useAccountModals();

  return (): Promise<User> => {
    return new Promise((resolve, reject): void => {
      if (user) resolve(user);
      else {
        if (!openModal) reject();
        else {
          openModal('login').then(
            (user: User) => {
              if (user) {
                resolve(user);
              } else {
                reject();
              }
            },
            () => reject()
          );
        }
      }
    });
  };
}
