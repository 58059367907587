import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import {
  DeemphasizedButton,
  StyledButton,
  HeadingTwo,
  SemiDeemphasizedButton,
  Ellipsis,
} from 'src/styles';
import { useAuth } from 'src/hooks/use-auth';
import { CuratedList, Post } from 'src/types';
import { useAddToCuratedList } from 'src/hooks/use-add-to-curated-list';
import { useRemoveFromCuratedList } from 'src/hooks/use-remove-from-curated-list';
import { CuratedPostListing } from 'src/types/schema';

const Container = styled.div`
  padding: ${fibonacci(3)}rem;
`;

const StyledHeadingTwo = styled(HeadingTwo)`
  margin-bottom: ${fibonacci(3)}rem;
`;

const SuperStyledButton = styled(StyledButton)`
  margin-top: ${fibonacci(3)}rem;
  width: 100%;
`;

const SuperDeemphasizedButton = styled(SemiDeemphasizedButton)`
  margin-top: ${fibonacci(3)}rem;
  width: 100%;

  &:hover {
    text-decoration: line-through;
  }
`;

const CancelButton = styled(DeemphasizedButton)`
  margin-top: ${fibonacci(4)}rem;
  width: 100%;
`;

function getListing(
  curatedList: CuratedList,
  post: Post
): CuratedPostListing | undefined {
  return curatedList.curatedPostListings.find((curatedPostListing) => {
    return curatedPostListing.post.id === post.id;
  });
}

interface InterfaceProps {
  post: Post;
  hasListed: boolean;
  onReject: () => void;
  onResolve: () => void;
}

const AddToCuratedListExisting: React.FC<InterfaceProps> = ({
  post,
  hasListed,
  onReject,
  onResolve,
}) => {
  const { t } = useTranslation('general');
  const { user } = useAuth();
  const addToCuratedList = useAddToCuratedList(post);
  const removeFromCuratedList = useRemoveFromCuratedList(post);
  const memberships =
    user?.curatorialMemberships
      .slice(0)
      .reverse()
      .sort((a, b) => {
        const aListing = getListing(a.curatedList, post);
        const bListing = getListing(b.curatedList, post);

        return bListing ? 1 : aListing ? -1 : 0;
      }) || [];

  async function add(curatedList: CuratedList): Promise<void> {
    await addToCuratedList(curatedList);
    if (!hasListed) onResolve();
  }

  async function remove(curatedPostListing: CuratedPostListing): Promise<void> {
    await removeFromCuratedList(curatedPostListing);
    if (!hasListed) onResolve();
  }

  return (
    <Container>
      <StyledHeadingTwo>{t('curatedListsAddToExistingList')}</StyledHeadingTwo>
      {memberships.map(({ curatedList }) => {
        const curatedPostListing = getListing(curatedList, post);

        if (curatedPostListing) {
          return (
            <SuperDeemphasizedButton
              key={curatedList.id}
              onClick={(): Promise<void> => remove(curatedPostListing)}
            >
              <Ellipsis>
                {t('curatedListsListed', { name: curatedList.name })}
              </Ellipsis>
            </SuperDeemphasizedButton>
          );
        } else {
          return (
            <SuperStyledButton
              key={curatedList.id}
              onClick={(): Promise<void> => add(curatedList)}
            >
              <Ellipsis>{curatedList.name}</Ellipsis>
            </SuperStyledButton>
          );
        }
      })}
      <CancelButton as={SuperStyledButton} onClick={onReject}>
        {hasListed ? t('done') : t('cancel')}
      </CancelButton>
    </Container>
  );
};

export default AddToCuratedListExisting;
