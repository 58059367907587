import React, { Fragment, MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { GlobalStyleVariables } from 'src/styles/global';
import { ResizeObserver } from 'resize-observer';

interface NavButtonInterfaceProps {
  isHidden: boolean;
  isLeft: boolean;
}

const NavButton = styled.button.attrs((props: NavButtonInterfaceProps) => ({
  isHidden: props.isHidden,
  isLeft: props.isLeft,
}))`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${fibonacci(6)}rem;
  padding: 1rem;
  border: 0;
  opacity: ${(props): string | number =>
    props.isHidden ? '0.05 !important' : goldenRatioInverse};
  font-size: 7rem;
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  color: rgba(${(props): string => props.theme.primaryColor}, 1);
  cursor: pointer;
  transition: opacity ${GlobalStyleVariables.baseDuration}ms;
  z-index: 3;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: 0;
  }

  svg {
    pointer-events: none;
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    ${(props): string => (props.isLeft ? 'left: 0;' : 'right: 0;')}
    margin: auto;
    width: ${fibonacci(5)}rem;
    opacity: 0 !important;
  }
`;

const holdTimeout = (
  scroller: HTMLElement,
  onClick: (scroller: HTMLElement) => void,
  setHoldRef: (holdRef: number) => void
): void => {
  onClick(scroller);

  setHoldRef(
    window.setTimeout(
      holdTimeout.bind(null, scroller, onClick, setHoldRef),
      100
    )
  );
};

const startHold = (
  scroller: HTMLElement,
  onClick: (scroller: HTMLElement) => void,
  setHoldRef: (holdRef: number) => void
): void => {
  requestAnimationFrame(() => {
    scroller.focus();
  });

  setHoldRef(
    window.setTimeout(
      holdTimeout.bind(null, scroller, onClick, setHoldRef),
      300
    )
  );
};

const endHold = (holdRef: number | undefined): void => {
  clearTimeout(holdRef);
};

const dragTimeout = (
  scroller: HTMLElement,
  onClick: (scroller: HTMLElement, isSlow?: boolean) => void,
  setDragRef: (dragRef: number) => void
): void => {
  onClick(scroller, true);

  setDragRef(
    window.setTimeout(dragTimeout.bind(null, scroller, onClick, setDragRef), 1)
  );
};

const dragEnter = (
  scroller: HTMLElement,
  onClick: (scroller: HTMLElement, isSlow?: boolean) => void,
  setDragRef: (dragRef: number) => void
): void => {
  setDragRef(
    window.setTimeout(dragTimeout.bind(null, scroller, onClick, setDragRef), 1)
  );
};

const dragExit = (dragRef: number | undefined): void => {
  clearTimeout(dragRef);
};

const dragSpeed = 3;

function scrollRight(scroller: HTMLElement, isSlow?: boolean): void {
  if (isSlow) {
    scroller.scrollLeft += dragSpeed;
  } else {
    const intersection = scroller.scrollLeft;
    const element = [...(scroller.children as unknown as HTMLElement[])].find(
      (element): boolean => {
        return element.offsetLeft + element.offsetWidth > intersection;
      }
    );
    if (element) {
      scroller.scrollLeft = element.offsetLeft + element.offsetWidth + 2;
    }
  }
}

function scrollLeft(scroller: HTMLElement, isSlow?: boolean): void {
  if (isSlow) {
    scroller.scrollLeft -= dragSpeed;
  } else {
    const intersection = scroller.scrollLeft;
    const element = [...(scroller.children as unknown as HTMLElement[])]
      .reverse()
      .find((element): boolean => {
        return element.offsetLeft < intersection;
      });
    if (element) {
      scroller.scrollLeft = element.offsetLeft;
    }
  }
}

type Direction = 'left' | 'right';

interface InterfaceProps {
  scrollElementRef?: HTMLElement | null;
  direction: Direction;
}

const CarouselNavButton: React.FC<InterfaceProps> = ({
  scrollElementRef,
  direction,
}) => {
  const [isHidden, setIsHidden] = useState(true);
  useEffect(() => {
    if (scrollElementRef) {
      const hideOrRevealButton = (): void => {
        if (scrollElementRef) {
          if (direction === 'left' && scrollElementRef.scrollLeft === 0) {
            return setIsHidden(true);
          } else if (
            direction === 'right' &&
            scrollElementRef.scrollLeft + scrollElementRef.clientWidth >=
              scrollElementRef.scrollWidth - 1
          ) {
            return setIsHidden(true);
          }
          setIsHidden(false);
        }
      };

      hideOrRevealButton();

      const resizeObserver = new ResizeObserver(hideOrRevealButton);
      resizeObserver.observe(scrollElementRef);
      scrollElementRef.addEventListener('scroll', hideOrRevealButton);

      return (): void => {
        resizeObserver.unobserve(scrollElementRef);
        scrollElementRef.removeEventListener('scroll', hideOrRevealButton);
      };
    }
  }, [scrollElementRef]);

  const [holdRef, setHoldRef] = useState<number | undefined>();
  const [dragRef, setDragRef] = useState<number | undefined>();

  if (scrollElementRef) {
    const isLeft = direction === 'left';
    const onClick = isLeft ? scrollLeft : scrollRight;
    const icon = `chevron-${direction}` as IconProp;

    return (
      <NavButton
        tabIndex={-1}
        onMouseDown={(e: MouseEvent): void => {
          e.preventDefault();
          startHold(scrollElementRef, onClick, setHoldRef);
        }}
        onMouseUp={(e: MouseEvent): void => {
          e.preventDefault();
          endHold(holdRef);
        }}
        onMouseOut={(): void => endHold(holdRef)}
        onDragEnter={(): void =>
          dragEnter(scrollElementRef, onClick, setDragRef)
        }
        onDragLeave={(): void => dragExit(dragRef)}
        onClick={(e: MouseEvent): void => {
          e.preventDefault();
          onClick(scrollElementRef);
        }}
        isLeft={isLeft}
        isHidden={isHidden}
        type="button"
      >
        <FontAwesomeIcon icon={icon} />
      </NavButton>
    );
  }

  return <Fragment></Fragment>;
};

export default CarouselNavButton;
