import { useMutation } from '@apollo/client';
import { REMOVE_FROM_CURATED_LIST_MUTATION } from 'src/queries';
import { CuratedPostListing, Post } from 'src/types';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { updateCurrentUser, updatePaginatedPosts } from 'src/utils/data';
import { postTitle } from 'src/utils/formatting';

export function useRemoveFromCuratedList(
  post: Post
): (curatedPostListing: CuratedPostListing) => Promise<void> {
  const { t } = useTranslation('general');
  const [removeFromCuratedListMutation] = useMutation(
    REMOVE_FROM_CURATED_LIST_MUTATION,
    {
      update(cache, { data: { removeFromCuratedList } }) {
        updateCurrentUser(cache, () => {
          return removeFromCuratedList.user;
        });

        updatePaginatedPosts(
          cache,
          {
            curatedListId: removeFromCuratedList.curatedList.id,
          },
          (posts) => {
            return posts.filter((post) => {
              return post.id !== removeFromCuratedList.post.id;
            });
          }
        );
      },
    }
  );
  const handleGraphQLErrors = useGraphQLErrors();

  return async (curatedPostListing: CuratedPostListing): Promise<void> => {
    try {
      await removeFromCuratedListMutation({
        variables: {
          curatedPostListingId: curatedPostListing.id,
        },
      });

      toast(
        t('curatedListsRemoved', {
          title: postTitle(post),
        })
      );
    } catch (e) {
      handleGraphQLErrors(e as Error, (message) => {
        toast(message, { type: 'error' });
      });
    }
  };
}
