import styled, { css } from 'styled-components';
import { Field, ErrorMessage } from 'formik';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles/global';

interface StyledLabelProps {
  disabled?: boolean;
}

export const StyledLabel = styled.label.attrs((props: StyledLabelProps) => ({
  disabled: props.disabled || false,
}))`
  display: block;
  margin-bottom: ${fibonacci(3)}rem;
  background-color: rgba(${(props): string => props.theme.lowMutedColor}, 0.1);
  opacity: ${(props): number => (props.disabled ? goldenRatioInverse : 1)};
`;

export const StyledLabelName = styled.span`
  padding: ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem 1rem;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  opacity: ${goldenRatioInverse};
`;

export const StyledInputMixin = css`
  width: 100%;
  padding: ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem 1rem;
  border: 0;
  border-bottom: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
    rgba(${(props): string => props.theme.primaryColor}, ${goldenRatioInverse});
  color: rgba(${(props): string => props.theme.primaryColor}, 1);
  background: transparent;
  transition: border-color ${GlobalStyleVariables.baseDuration}ms;

  &:focus {
    outline: 0;
    border-bottom-style: solid;
    border-color: rgba(${(props): string => props.theme.primaryColor}, 1);
  }

  ::placeholder {
    color: rgba(
      ${(props): string => props.theme.lowMutedColor},
      ${goldenRatioInverse}
    );
  }

  &[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

export const StyledInput = styled(Field)`
  ${StyledInputMixin}
`;

export const VanillaStyledInput = styled.input`
  ${StyledInputMixin}
`;

const scrollbarWidth = 12;

export const StyledRangeMixin = css`
  -webkit-appearance: none;
  margin: ${fibonacci(3)}rem 0;
  width: 100%;
  background: transparent;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1rem;
    cursor: pointer;
    animate: ${GlobalStyleVariables.baseDuration}ms;
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
    transition: outline ${GlobalStyleVariables.baseDuration}ms;
  }
  &::-webkit-slider-thumb {
    outline: ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem solid
      rgba(${(props): string => props.theme.backgroundColor}, 1);
    height: ${fibonacci(4)}rem;
    width: ${fibonacci(1) + fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem;
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -${fibonacci(4) / 2.9}rem;
    transition: outline ${GlobalStyleVariables.baseDuration}ms;
  }
  &:focus::-webkit-slider-runnable-track {
    outline: ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem dashed
      rgba(${(props): string => props.theme.primaryColor}, 1);
  }
  &:focus::-webkit-slider-thumb {
    outline-style: dashed;
  }
  &::-moz-range-track {
    width: 100%;
    height: 1rem;
    cursor: pointer;
    animate: ${GlobalStyleVariables.baseDuration}ms;
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
    transition: outline ${GlobalStyleVariables.baseDuration}ms;
  }
  &::-moz-range-thumb {
    outline: ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem solid
      rgba(${(props): string => props.theme.backgroundColor}, 1);
    height: ${fibonacci(4)}rem;
    width: ${fibonacci(1) + fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem;
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
    cursor: pointer;
    margin-top: -${fibonacci(4) / 2.9}rem;
    border-radius: 0;
    border: 0;
    transition: outline ${GlobalStyleVariables.baseDuration}ms;
  }
  &:focus::-moz-range-track {
    outline: ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem dashed
      rgba(${(props): string => props.theme.primaryColor}, 1);
  }
  &:focus::-moz-range-thumb {
    outline-style: dashed;
  }
  &::-ms-track {
    width: 100%;
    height: 1rem;
    cursor: pointer;
    animate: ${GlobalStyleVariables.baseDuration}ms;
    background: transparent;
    border-color: transparent;
    border: 0;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
  }
  &::-ms-fill-upper {
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
  }
  &::-ms-thumb {
    outline: ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem solid
      rgba(${(props): string => props.theme.backgroundColor}, 1);
    height: ${fibonacci(4)}rem;
    width: ${fibonacci(1) + fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem;
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
    cursor: pointer;
    margin-top: -${fibonacci(4) / 2.9}rem;
  }
  &:focus::-ms-fill-lower {
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
  }
  &:focus::-ms-fill-upper {
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
  }
`;

export const StyledRange = styled(Field)`
  ${StyledRangeMixin}
`;

export const StyledRangeNativeInput = styled.input`
  ${StyledRangeMixin}
`;

export const StyledTextArea = styled(StyledInput)`
  height: ${fibonacci(7)}rem;
  border-bottom: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
    rgba(${(props): string => props.theme.primaryColor}, ${goldenRatioInverse});
  resize: none;
  font-size: ${GlobalStyleVariables.baseFontSize}rem;

  &:focus {
    border-style: solid;
  }

  ::-webkit-scrollbar {
    height: ${scrollbarWidth}px;
    width: ${scrollbarWidth}px;
    border-radius: ${scrollbarWidth / 2}px;
    background-color: rgba(${(props): string => props.theme.highMutedColor}, 1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${scrollbarWidth / 2}px;
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
  }
`;

export const StyledCheckbox = styled(Field)`
  height: ${fibonacci(3)}rem;
  width: ${fibonacci(3)}rem;
  appearance: none;
  border: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  outline: none;
  transition-duration: ${GlobalStyleVariables.baseDuration}ms;
  background-color: transparent;
  cursor: pointer;

  &:active {
    border-style: solid;
  }

  &:checked {
    border-styled: solid;
    background-color: rgba(${(props): string => props.theme.primaryColor}, 1);

    &:active {
      border-style: dashed;
    }
  }
`;

export const StyledErrorMixin = css`
  padding: ${fibonacci(1) * goldenRatioInverse}rem 1rem;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  color: rgba(${(props): string => props.theme.errorColor}, 1);
`;

export const StyledFieldError = styled(ErrorMessage)`
  ${StyledErrorMixin}
`;

export const StyledError = styled.div`
  ${StyledErrorMixin}
`;
