import React from 'react';
import { Notification } from 'src/types';
import { useTranslation } from 'react-i18next';
import NotificationItemWrapper from 'src/components/notification-item/notification-item-wrapper';
import { useNavigate } from 'react-router';

interface InterfaceProps {
  notification: Notification;
  onClick: () => void;
}

const NotificationItemNewModerationRequest: React.FC<InterfaceProps> = ({
  notification,
  onClick,
}) => {
  const { t } = useTranslation('general');
  const navigate = useNavigate();

  function augmentedClick(): void {
    navigate({
      pathname: '/moderation',
    });
    onClick();
  }

  return (
    <NotificationItemWrapper
      notification={notification}
      onClick={augmentedClick}
    >
      {t('notificationsNewModerationRequest')}
    </NotificationItemWrapper>
  );
};

export default NotificationItemNewModerationRequest;
