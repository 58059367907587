import React from 'react';
import { MainContainer, HeadingOne, Paragraph, StyledButton } from 'src/styles';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { useAuth } from 'src/hooks/use-auth';

const StyledHeadingOne = styled(HeadingOne)`
  margin: ${fibonacci(3)}rem 0;
`;

const RestyledButton = styled(StyledButton)`
  margin: ${fibonacci(5)}rem 0;
  padding: 1rem ${fibonacci(3)}rem;
`;

const CreatorRegistrationSubmitted: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const navigate = useNavigate();

  function done(): void {
    navigate({
      pathname: user ? `/${user.username}` : '/',
    });
  }

  return (
    <MainContainer>
      <StyledHeadingOne>
        {t('creatorApplicationsCompletionTitle')}
      </StyledHeadingOne>
      <Paragraph>{t('creatorApplicationsCompletionDescription')}</Paragraph>
      <RestyledButton onClick={done}>
        {t('creatorApplicationsCompletionToProfile')}
      </RestyledButton>
    </MainContainer>
  );
};

export default CreatorRegistrationSubmitted;
