import React, { ReactElement } from 'react';
import { PaginationResult } from 'src/types';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'src/components/loading-spinner';
import NoContent from 'src/components/no-content';
import { QueryResult } from '@apollo/client';

type ResultType = PaginationResult | QueryResult;

interface InterfaceProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (data: any) => ReactElement;
  response: ResultType;
}

const GraqhlResultView: React.FC<InterfaceProps> = ({ children, response }) => {
  const { t } = useTranslation('general');
  const { error, loading, data } = response;

  if (loading) {
    return <LoadingSpinner />;
  } else if (data) {
    return children(data);
  } else {
    if (error) console.log(error);
    return <NoContent icon="book-dead">{t('error')}</NoContent>;
  }
};

export default GraqhlResultView;
