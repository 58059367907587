import styled, { css } from 'styled-components';
import { goldenRatioInverse, fibonacci } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const buttonMixin = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: ${fibonacci(1) * goldenRatioInverse}rem 1rem;
  border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  font-size: ${GlobalStyleVariables.baseFontSize}rem;
  text-decoration: none;
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  color: rgba(${(props): string => props.theme.primaryColor}, 1);
  cursor: pointer;
  transition: color ${GlobalStyleVariables.baseDuration}ms,
    border-color ${GlobalStyleVariables.baseDuration}ms;

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: underline;
  }

  &:disabled {
    color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
    border-color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
    cursor: default;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

export const invertingButtonMixin = css`
  ${buttonMixin}

  transition: color ${GlobalStyleVariables.baseDuration}ms,
    background-color ${GlobalStyleVariables.baseDuration}ms;

  &.active {
    outline: none;
    background-color: rgba(${(props): string => props.theme.primaryColor}, 1);
    color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  }
`;

export const invertingHoverButtonMixin = css`
  ${invertingButtonMixin}

  &:hover,
  &:focus {
    background-color: rgba(${(props): string => props.theme.primaryColor}, 1);
    color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  }
`;

export const StyledButton = styled.button`
  ${buttonMixin}
`;

export const InvertedButton = styled(StyledButton)`
  background-color: rgba(${(props): string => props.theme.primaryColor}, 1);
  color: rgba(${(props): string => props.theme.backgroundColor}, 1);
`;

export const InvertingHoverButton = styled.button`
  ${invertingHoverButtonMixin}
`;

export const SemiDeemphasizedButton = styled(StyledButton)`
  border-style: dashed;
`;

export const DeemphasizedButton = styled(StyledButton)`
  border-style: dotted;
`;

export const BorderlessButton = styled(StyledButton)`
  border: 0;
`;

export const FontAwesomeButton = styled(FontAwesomeIcon)`
  color: rgba(${(props): string => props.theme.primaryColor}, 1);
  font-size: ${GlobalStyleVariables.fontSizeTwoUp}rem;
  transition: border-color ${GlobalStyleVariables.baseDuration}ms;
`;

export const ButtonSet = styled.div`
  display: flex;

  > {
    :not(:first-child) {
      border-left-width: ${(fibonacci(1) * Math.pow(goldenRatioInverse, 2)) /
      2}rem;
    }

    :not(:last-child) {
      border-right-width: ${(fibonacci(1) * Math.pow(goldenRatioInverse, 2)) /
      2}rem;
    }
  }
`;

export const DashDotDotButtonSet = styled(ButtonSet)`
  > {
    :first-child {
      flex-grow: 999999;
    }

    :not(:first-child) {
      flex-shrink: 0;
    }
  }
`;

export const MinorMajorButtonSet = styled.div`
  display: flex;

  > {
    :first-child {
      width: 33%;
    }

    :last-child {
      width: 67%;
    }
  }
`;
