/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { useMutation } from '@apollo/client';
import { CuratedList } from 'src/types';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DEFEATURE_CURATED_LIST_MUTATION } from 'src/queries';

export function useDefeatureCuratedList(
  curatedList: CuratedList
): (_: any, options?: any) => Promise<void> {
  const { t } = useTranslation('general');
  const ensureUser = useEnsureUser();
  const [defeatureCuratedListMutation] = useMutation(
    DEFEATURE_CURATED_LIST_MUTATION
  );
  const handleGraphQLErrors = useGraphQLErrors();

  return async (_: any, options?: any): Promise<void> => {
    try {
      if (!defeatureCuratedListMutation) return;

      await ensureUser();

      await defeatureCuratedListMutation({
        variables: {
          curatedListId: curatedList.id,
        },
      });

      toast(t('curatedListsDefeatured', { name: curatedList.name }));
    } catch (e) {
      handleGraphQLErrors(e as Error, (message) => {
        toast(message, { type: 'error' });
      });
    } finally {
      if (options && options.setSubmitting) options.setSubmitting(false);
    }
  };
}
