import React, { ReactElement, useEffect } from 'react';
import { PaginatableType, CuratedList } from 'src/types';
import InfiniteScroll from 'src/components/infinite-scroll';
import NoContent from 'src/components/no-content';
import CuratedListCard from 'src/components/curated-list-card';
import { SquareCardGridLayout, CardVerticalLayout } from 'src/styles';

interface InterfaceProps {
  className?: string;
  entries: PaginatableType[];
  hideIfEmpty: boolean;
  onLoadMore: () => Promise<void>;
  scrollElementRef?: HTMLDivElement | null;
  useGridLayout?: boolean;
  setCarouselLength?: (carouselLength: number) => void;
}

const CuratedListListView: React.FC<InterfaceProps> = ({
  className,
  entries,
  hideIfEmpty,
  onLoadMore,
  scrollElementRef,
  useGridLayout,
  setCarouselLength,
}) => {
  useEffect(() => {
    if (setCarouselLength && !!entries) {
      setCarouselLength(entries.length);
    }
  }, [entries.length]);

  if (!entries || entries.length === 0) {
    if (hideIfEmpty) {
      return <></>;
    } else {
      return <NoContent></NoContent>;
    }
  }

  // TODO: aggregate these with a graphdb query;
  // you know, once we're using graphdb :)
  const usedCuratedListIds = [] as string[];
  entries = entries.filter((curatedList) => {
    curatedList = curatedList as CuratedList;
    const canDisplay = usedCuratedListIds.indexOf(curatedList.id) === -1;
    usedCuratedListIds.push(curatedList.id);
    return canDisplay;
  });

  function renderEntries(): ReactElement[] {
    return entries.map((curatedList) => {
      curatedList = curatedList as CuratedList;

      return <CuratedListCard key={curatedList.id} curatedList={curatedList} />;
    });
  }

  return (
    <InfiniteScroll
      onApproachingInfinity={onLoadMore}
      axis={scrollElementRef ? 'x' : 'y'}
      scrollElementRef={scrollElementRef}
    >
      {scrollElementRef ? (
        renderEntries()
      ) : useGridLayout ? (
        <SquareCardGridLayout className={className}>
          {renderEntries()}
        </SquareCardGridLayout>
      ) : (
        <CardVerticalLayout className={className}>
          {renderEntries()}
        </CardVerticalLayout>
      )}
    </InfiniteScroll>
  );
};

export default CuratedListListView;
