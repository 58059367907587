/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  DragEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { useDoubleTap } from 'use-double-tap';
import { FileFilter, FileUpload } from 'src/types';
import FileUploaderImage from 'src/components/file-uploader/file-uploader-image';
import FileUploaderVideo from 'src/components/file-uploader/file-uploader-video';
import { PrimaryColorVanillaLink } from 'src/styles/components/link';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragIndexContainer } from 'src/components/file-uploader/queue/file-uploader-queue-carousel';
import Keyhole from 'src/components/icons/keyhole';
import { StyledRangeNativeInput } from 'src/styles';

interface ContainerProps {
  isDragging: boolean;
}

const Container = styled.div.attrs((props: ContainerProps) => ({
  isDragging: props.isDragging || false,
}))`
  position: relative;
  height: 100%;
  cursor: pointer;
  opacity: ${(props): number => (props.isDragging ? 0 : 1)};
  z-index: 2;
`;

interface ButtonProps {
  isSelected: boolean;
}

const Button = styled(PrimaryColorVanillaLink).attrs((props: ButtonProps) => ({
  isSelected: props.isSelected || false,
}))`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${fibonacci(4)}rem;
  width: ${fibonacci(4)}rem;
  border-radius: 50%;
  color: rgba(
    ${(props): string =>
      props.isSelected
        ? props.theme.backgroundColor
        : props.theme.primaryColor},
    1
  );
  background-color: rgba(
    ${(props): string =>
      props.isSelected
        ? props.theme.primaryColor
        : props.theme.backgroundColor},
    ${(props): string | number => (props.isSelected ? 1 : goldenRatioInverse)}
  );
`;

const RemoveButton = styled(Button)`
  top: 1rem;
  right: 1rem;
`;

const EditButton = styled(Button)`
  bottom: 1rem;
  right: 1rem;
`;

const FilterRow = styled.div`
  position: absolute;
  right: 1rem;
  display: flex;
  flex-direction: column;

  a {
    position: relative;
    height: ${fibonacci(4)}rem;
    width: ${fibonacci(4)}rem;
    margin-top: 1rem;
    padding: ${goldenRatioInverse}rem;
    fill: rgba(${(props): string => props.theme.primaryColor}, 1);
  }
`;

const FilterRangeContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  label {
    margin-bottom: 0;

    input {
      margin: 1rem 0;
    }
  }
`;

export interface DragItem {
  index: number;
  type: string;
}

interface InterfaceProps {
  addFilesToQueue: (files: FileList, index?: number) => Promise<void>;
  dragIndexContainer: DragIndexContainer;
  file: FileUpload;
  index: number;
  mode: string;
  moveItem: (hoverIndex: number) => void;
  onDrag: (index: number) => void;
  removeFile: (index: number) => void;
  saveFilteredImage: (index: number, file: FileUpload) => void;
  selectFile: (file: FileUpload) => void;
}

const FileUploaderQueueItem: React.FC<InterfaceProps> = ({
  addFilesToQueue,
  dragIndexContainer,
  file,
  index,
  mode,
  moveItem,
  onDrag,
  removeFile,
  saveFilteredImage,
  selectFile,
}) => {
  // take from:
  // https://react-dnd.github.io/react-dnd/examples/sortable/simple
  const ref = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const dragStart = (event: DragEvent): void => {
    event.dataTransfer.dropEffect = 'move';
    onDrag(index);
    requestAnimationFrame(() => {
      setIsDragging(true);
    });
  };

  const dragOver = (event: DragEvent): void => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';

    const { dragIndex } = dragIndexContainer;

    const isDraggingFile = event.dataTransfer.types.some((type) => {
      return type === 'Files';
    });

    if (!ref.current || dragIndex === undefined || isDraggingFile) return;

    const hoverIndex = index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) return;

    // Determine rectangle on screen
    const hoverBoundingRect = ref.current.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

    // Get pixels to the top
    const hoverClientX = event.clientX - hoverBoundingRect.left;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;
    moveItem(index);
  };

  const dragEnd = (event: DragEvent): void => {
    event.dataTransfer.dropEffect = 'move';
    onDrag(undefined as unknown as number);
    setIsDragging(false);
  };

  const drop = useCallback(
    (event: DragEvent): void => {
      event.preventDefault();
      addFilesToQueue(event.dataTransfer.files, index + 1);
    },
    [addFilesToQueue]
  );

  const updateFilterIntensity = (event: ChangeEvent<HTMLInputElement>) => {
    file.filterIntensity = parseInt(event.target?.value);
    saveFilteredImage(index, file);
  };

  useEffect(() => {
    if (!file.filteredModifiedFile) {
      saveFilteredImage(index, file);
    }
  }, [file.filteredModifiedFile]);

  return (
    <Container
      ref={ref}
      {...useDoubleTap(
        (): boolean | void => mode === 'upload' && selectFile(file)
      )}
      onDragStart={dragStart}
      onDragOver={dragOver}
      onDragEnd={dragEnd}
      onDrop={drop}
      draggable={mode === 'upload'}
      isDragging={isDragging}
    >
      {file.type === 'image' && <FileUploaderImage file={file} mode={mode} />}
      {file.type === 'video' && <FileUploaderVideo file={file} mode={mode} />}
      {mode === 'upload' && (
        <>
          <RemoveButton onClick={(): void => removeFile(index)}>
            <FontAwesomeIcon icon="times" />
          </RemoveButton>
          <EditButton onClick={(): void => selectFile(file)}>
            <FontAwesomeIcon icon="pen" />
          </EditButton>
        </>
      )}
      {mode === 'filter' && (
        <>
          <FilterRow>
            <Button
              isSelected={file.previewType === FileFilter.none}
              onClick={() => {
                file.previewable = true;
                file.previewType = FileFilter.none;
                saveFilteredImage(index, file);
              }}
            >
              <FontAwesomeIcon icon={['far', 'square']} />
            </Button>
            <Button
              isSelected={file.previewType === FileFilter.keyhole}
              onClick={() => {
                file.previewable = false;
                file.previewType = FileFilter.keyhole;
                if (!file.filterIntensity) file.filterIntensity = 50;
                saveFilteredImage(index, file);
              }}
            >
              <Keyhole />
            </Button>
            <Button
              isSelected={file.previewType === FileFilter.blur}
              onClick={() => {
                file.previewable = false;
                file.previewType = FileFilter.blur;
                if (!file.filterIntensity) file.filterIntensity = 50;
                saveFilteredImage(index, file);
              }}
            >
              <FontAwesomeIcon icon="tint" />
            </Button>
            <Button
              isSelected={file.previewType === FileFilter.locked}
              onClick={() => {
                file.previewable = false;
                file.previewType = FileFilter.locked;
                saveFilteredImage(index, file);
              }}
            >
              <FontAwesomeIcon icon="lock" />
            </Button>
          </FilterRow>
          {(file.previewType === FileFilter.keyhole ||
            file.previewType === FileFilter.blur) && (
            <FilterRangeContainer>
              <StyledRangeNativeInput
                min={1}
                max={100}
                step={1}
                type="range"
                value={file.filterIntensity}
                onChange={updateFilterIntensity}
              />
            </FilterRangeContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default FileUploaderQueueItem;
