import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { SecondaryNavBar } from 'src/components/nav-bar';
import useUser from '../use-user';

const UserLikeRouteView: React.FC = () => {
  const { t } = useTranslation('general');
  const { user } = useUser();
  const options = [];

  if (user.curatedListCount > 0) {
    options.push({
      to: `lists`,
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('listsWithCount', {
        count: user.curatedListCount,
      }),
    });
  }

  if (user.purchaseCount > 0) {
    options.push({
      to: `purchases`,
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('purchasesWithCount', {
        count: user.purchaseCount,
      }),
    });
  }

  options.push({
    to: ``,
    end: true,
    preventScrollReset: true,
    scrollToNavOnClick: true,
    content: t('postsLikedWithCount', {
      count: user.likeCount,
    }),
  });

  return (
    <>
      {options.length > 1 && <SecondaryNavBar>{options}</SecondaryNavBar>}
      <Outlet context={{ user }} />
    </>
  );
};

export default UserLikeRouteView;
