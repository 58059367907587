import React, {
  useState,
  ReactElement,
  createRef,
  useEffect,
  useCallback,
} from 'react';
import { InvertingHoverButton } from 'src/styles';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles/global';

interface DropdownProps {
  isClosing: boolean;
  isOpening: boolean;
}

const Button = styled(InvertingHoverButton)`
  position: relative;
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 110%;
  right: -${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem;
  overflow: hidden;
  z-index: 10;
`;

const Dropdown = styled.div.attrs((props: DropdownProps) => ({
  isOpening: props.isOpening,
}))`
  border: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  border-top: 1px solid rgba(${(props): string => props.theme.primaryColor}, 1);
  color: rgba(${(props): string => props.theme.primaryColor}, 1);
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  transform: translateY(
    ${(props): string => (props.isOpening ? '-100%' : '0')}
  );
  transition: transform ${GlobalStyleVariables.baseDuration}ms;
`;

interface InterfaceProps {
  button: string | ReactElement;
  className?: string;
  menu: (closeDropdown: () => void) => ReactElement;
}

const DropdownButton: React.FC<InterfaceProps> = ({
  button,
  className,
  menu,
}) => {
  const ref = createRef<HTMLButtonElement>();
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [dropdownIsOpening, setDropdownIsOpening] = useState(false);

  const openDropdown = useCallback((): void => {
    if (dropdownIsOpen) return;

    setDropdownIsOpen(true);
    setDropdownIsOpening(true);

    requestAnimationFrame(() => {
      setDropdownIsOpening(false);
    });
  }, [dropdownIsOpen]);

  const closeDropdown = useCallback((): void => {
    setDropdownIsOpen(false);
    setDropdownIsOpening(false);
  }, []);

  const onKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        event.preventDefault();
        closeDropdown();
      }
    },
    [closeDropdown]
  );

  useEffect(() => {
    if (onKeyDown) {
      document.addEventListener('keydown', onKeyDown);

      return (): void => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [onKeyDown]);

  return (
    <Button
      type="button"
      className={className}
      onBlur={closeDropdown}
      onClick={openDropdown}
      ref={ref}
    >
      {button}
      {dropdownIsOpen && (
        <DropdownContainer>
          <Dropdown isOpening={dropdownIsOpening}>
            {menu(closeDropdown)}
          </Dropdown>
        </DropdownContainer>
      )}
    </Button>
  );
};

export default DropdownButton;
