import React from 'react';
import {
  StyledLabel,
  StyledLabelName,
  StyledFieldError,
  StyledTextArea,
} from 'src/styles';

interface InterfaceProps {
  autoFocus?: boolean;
  disabled?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
}

const TextareaView: React.FC<InterfaceProps> = ({
  autoFocus,
  disabled,
  label,
  name,
  placeholder,
}) => {
  return (
    <StyledLabel disabled={disabled}>
      {label && <StyledLabelName>{label}</StyledLabelName>}
      <StyledFieldError name={name} component="div" />
      <StyledTextArea
        autoFocus={autoFocus}
        component="textarea"
        disabled={disabled}
        name={name}
        placeholder={placeholder}
      />
    </StyledLabel>
  );
};

export default TextareaView;
