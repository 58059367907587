import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import FileUploaderButton from 'src/components/file-uploader/file-uploader-button';
import FileUploaderSingltonItem from 'src/components/file-uploader/singleton/file-uploader-singleton-item';
import { convertToFileUpload } from 'src/components/file-uploader/utils';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import MediaEditorModal from 'src/components/media-editor-modal';
import { FileUpload, SimpleFile } from 'src/types';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalStyleVariables } from 'src/styles';

const Container = styled.div``;

const InputContainer = styled.div`
  position: relative;
`;

interface StyledUploadButtonProps {
  isHidden?: false;
}

const StyledUploadButton = styled(FileUploaderButton).attrs(
  (props: StyledUploadButtonProps) => ({
    isHidden: props.isHidden || false,
  })
)`
  height: 100%;
  width: 100%;

  ${(props): string | undefined => {
    if (props.isHidden) {
      return `
        display: none;
      `;
    }
  }}
`;

const StyledFileUploaderSingltonItem = styled(FileUploaderSingltonItem)`
  height: 100%;
  width: 100%;
  z-index: 2;
`;

interface ProcessingScreenProps {
  isProcessing: boolean;
}

const ProcessingScreen = styled.div.attrs((props: ProcessingScreenProps) => ({
  isProcessing: props.isProcessing || false,
}))`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: ${fibonacci(8)}rem;
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  transition: opacity ${GlobalStyleVariables.baseDuration}ms;

  ${(props): string | undefined => {
    if (props.isProcessing) {
      return `
        opacity: ${goldenRatioInverse};
        pointer-events: initial;
      `;
    }
  }}
`;

interface InterfaceProps {
  className?: string;
  accept?: string;
  autoOpenModal?: boolean;
  buttonContent?: ReactElement;
  file?: FileUpload;
  forceRatio?: number;
  originalFile?: SimpleFile;
  remove: () => void;
  replace: (file: FileUpload) => void;
}

const FileUploaderSingltonView: React.FC<InterfaceProps> = ({
  className,
  accept,
  autoOpenModal,
  buttonContent,
  file,
  forceRatio,
  originalFile,
  remove,
  replace,
}) => {
  const [nonClosureState] = useState({ mustForceRatio: false });
  const [editorIsOpen, setEditorIsOpen] = useState(false);
  const [isProcessingFiles, setIsProcessingFiles] = useState(false);
  const confirm = useConfirmationModal();
  const { t } = useTranslation('general');

  const addFiles = async (newFiles: FileList): Promise<void> => {
    setIsProcessingFiles(true);
    const fileUploads = await Promise.all(
      Array.from(newFiles).map((file) => {
        return convertToFileUpload(file);
      })
    );

    if (fileUploads.length === 1) {
      replace(fileUploads[0]);
      if (autoOpenModal || forceRatio) openEditor();

      if (forceRatio) nonClosureState.mustForceRatio = true;
    }

    setIsProcessingFiles(false);
  };

  const removeFile = async (): Promise<void> => {
    if (await confirm(t('fileUploaderRemoveConfirm'))) {
      remove();
    }
  };

  const updateFiles = (file: FileUpload): void => {
    replace(file);
    nonClosureState.mustForceRatio = false;
  };

  const openEditor = (): void => {
    setEditorIsOpen(true);
  };

  const closeEditor = (): void => {
    if (nonClosureState.mustForceRatio) {
      remove();
    }
    setEditorIsOpen(false);
  };

  return (
    <Container className={className}>
      <InputContainer>
        <StyledUploadButton
          accept={accept}
          addFiles={addFiles}
          content={buttonContent}
          isHidden={(file && !file.delete) || originalFile}
        />
        <StyledFileUploaderSingltonItem
          file={file}
          openEditor={openEditor}
          originalFile={originalFile}
          removeFile={removeFile}
        />
      </InputContainer>
      <MediaEditorModal
        closeModal={closeEditor}
        save={updateFiles}
        isOpen={editorIsOpen}
        file={file}
        forceRatio={forceRatio}
      />
      <ProcessingScreen isProcessing={isProcessingFiles}>
        <FontAwesomeIcon icon="spinner" pulse />
      </ProcessingScreen>
    </Container>
  );
};

export default FileUploaderSingltonView;
