import React from 'react';
import UserList from 'src/components/user-list/index';

interface InterfaceProps {
  purchasedPostId: string;
}

const PostBuyersListView: React.FC<InterfaceProps> = ({ purchasedPostId }) => {
  return (
    <UserList
      paginationArgs={{
        purchasedPostId,
      }}
      useLineItemLayout={true}
    />
  );
};

export default PostBuyersListView;
