import React from 'react';
import { useAuth } from 'src/hooks/use-auth';
import CreatorRegistrationSubmitted from './creator-registration-submitted';
import CreatorRegistrationApproved from './creator-registration-approved';
import CreatorRegistrationForm from './creator-registration-form';

const CreatorRegistrationRouteView: React.FC = () => {
  const { user } = useAuth();

  if (user?.permissions?.canPostContent) {
    return <CreatorRegistrationApproved />;
  } else if (user?.hasSubmittedCreatorApplication) {
    return <CreatorRegistrationSubmitted />;
  } else {
    return <CreatorRegistrationForm />;
  }
};

export default CreatorRegistrationRouteView;
