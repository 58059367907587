/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { useMutation } from '@apollo/client';
import { DECOLLECT_ALBUM_MUTATION } from 'src/queries';
import { Post, User } from 'src/types';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { updateCurrentUser, updatePaginatedPosts } from 'src/utils/data';
import { postTitle } from 'src/utils/formatting';

export function useDelikePost(
  post: Post
): (_: any, options?: any) => Promise<void> {
  const { t } = useTranslation('general');
  const ensureUser = useEnsureUser();
  const [unlikePostMutation] = useMutation(DECOLLECT_ALBUM_MUTATION, {
    update(cache, { data: { unlikePost } }) {
      updateCurrentUser(cache, (currentUser: User) => {
        return {
          ...currentUser,
          ...unlikePost.user,
        };
      });

      updatePaginatedPosts(
        cache,
        {
          likerId: unlikePost.user.id,
        },
        (posts) => {
          return posts.filter((post) => {
            return post.id !== unlikePost.post.id;
          });
        }
      );
    },
  });
  const handleGraphQLErrors = useGraphQLErrors();

  return async (_: any, options?: any): Promise<void> => {
    try {
      if (!unlikePostMutation) return;

      await ensureUser();

      await unlikePostMutation({
        variables: {
          postId: post.id,
        },
      });

      toast(
        t('likeRemoved', {
          title: postTitle(post),
        })
      );
    } catch (e) {
      handleGraphQLErrors(e as Error, (message) => {
        toast(message, { type: 'error' });
      });
    } finally {
      if (options && options.setSubmitting) options.setSubmitting(false);
    }
  };
}
