import gql from 'graphql-tag';
import {
  ALBUM_AND_CREDITS_FRAGMENT,
  COMMENT_AND_USER_FRAGMENT,
  COMMENT_AND_USER_WITH_CHILDREN_FRAGMENT,
  ALBUM_AND_CREDITS_WITH_COMMENTS_FRAGMENT,
} from 'src/queries/fragments__complex';
import {
  CREDIT_FRAGMENT,
  ALBUM_FRAGMENT,
  COMMENT_FRAGMENT,
} from 'src/queries/fragments__simple';

export const PAGINATED_ALBUM_QUERY = gql`
  query PaginatedPosts(
    $mediaItemsMetadata: PaginationMetadataInput
    $metadata: PaginationMetadataInput
    $paginationArgs: PaginationArgsInput
    $type: String
  ) {
    offsetBasedPaginatedEntries(
      type: $type
      metadata: $metadata
      paginationArgs: $paginationArgs
    ) {
      __typename
      ... on Post {
        ...postAndCredits
      }
    }
  }
  ${ALBUM_AND_CREDITS_FRAGMENT}
`;

export const PAGINATED_COMMENTS_WITH_CHILDREN_QUERY = gql`
  query PaginatedComments(
    $commentChildrenMetadata: PaginationMetadataInput
    $metadata: PaginationMetadataInput
    $paginationArgs: PaginationArgsInput
    $type: String
  ) {
    offsetBasedPaginatedEntries(
      type: $type
      metadata: $metadata
      paginationArgs: $paginationArgs
    ) {
      __typename
      ... on Comment {
        ...commentAndUserWithChildren
      }
    }
  }
  ${COMMENT_AND_USER_WITH_CHILDREN_FRAGMENT}
`;

export const PAGINATED_COMMENTS_QUERY = gql`
  query PaginatedComments(
    $metadata: PaginationMetadataInput
    $paginationArgs: PaginationArgsInput
    $type: String
  ) {
    offsetBasedPaginatedEntries(
      type: $type
      metadata: $metadata
      paginationArgs: $paginationArgs
    ) {
      __typename
      ... on Comment {
        ...commentAndUser
      }
    }
  }
  ${COMMENT_AND_USER_FRAGMENT}
`;

export const ALBUM_BY_ID_QUERY = gql`
  query PostById(
    $postId: String
    $mediaItemsMetadata: PaginationMetadataInput
    $commentsMetadata: PaginationMetadataInput
    $commentChildrenMetadata: PaginationMetadataInput
  ) {
    post(postId: $postId) {
      ...postAndCreditsWithComments
    }
  }
  ${ALBUM_AND_CREDITS_WITH_COMMENTS_FRAGMENT}
`;

export const CREATE_ALBUM_MUTATION = gql`
  mutation CreatePost($post: PostInput) {
    createPost(post: $post) {
      id
      mediaCollection {
        id
        mediaItems {
          id
          order
          image {
            id
            extension
            filterIntensity
            previewType
            uploadUrl
            previewUploadUrl
          }
          video {
            id
            extension
            filterIntensity
            previewType
            uploadUrl
            previewUploadUrl
          }
        }
      }
    }
  }
`;

export const DELETE_ALBUM_MUTATION = gql`
  mutation DeletePost($postId: ID) {
    deletePost(postId: $postId) {
      id
    }
  }
`;

export const UPDATE_ALBUM_MUTATION = gql`
  mutation UpdatePost($post: PostUpdateInput) {
    updatePost(post: $post) {
      id
      description
      price
      title
      mediaCollection {
        id
        mediaItems {
          id
          order
          image {
            id
            extension
            filterIntensity
            previewType
            uploadUrl
            previewUploadUrl
          }
          video {
            id
            extension
            filterIntensity
            previewType
            uploadUrl
            previewUploadUrl
          }
        }
      }
    }
  }
`;

export const CREATE_ARTIST_APPLICATION_MUTATION = gql`
  mutation CreateCreatorApplication(
    $creatorApplication: CreatorApplicationInput
  ) {
    createCreatorApplication(creatorApplication: $creatorApplication) {
      id
    }
  }
`;

export const CREATE_COMMENT_MUTATION = gql`
  mutation CreateComment(
    $comment: CommentInput
    $commentChildrenMetadata: PaginationMetadataInput = { limit: 0, offset: 0 }
  ) {
    createComment(comment: $comment) {
      post {
        ...post
      }
      comment {
        ...commentAndUserWithChildren
      }
      parent {
        ...comment
      }
    }
  }
  ${ALBUM_FRAGMENT}
  ${COMMENT_FRAGMENT}
  ${COMMENT_AND_USER_WITH_CHILDREN_FRAGMENT}
`;

export const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteComment($commentId: ID) {
    deleteComment(commentId: $commentId) {
      post {
        ...post
      }
      comment {
        ...commentAndUser
      }
      parent {
        ...comment
      }
    }
  }
  ${ALBUM_FRAGMENT}
  ${COMMENT_FRAGMENT}
  ${COMMENT_AND_USER_FRAGMENT}
`;

export const UPDATE_COMMENT_MUTATION = gql`
  mutation UpdateComment($comment: CommentUpdateInput) {
    updateComment(comment: $comment) {
      comment {
        ...comment
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const ACCEPT_CREDIT_MUTATION = gql`
  mutation AcceptCredit($creditId: String) {
    acceptCredit(creditId: $creditId) {
      ...credit
    }
  }
  ${CREDIT_FRAGMENT}
`;

export const DECLINE_CREDIT_MUTATION = gql`
  mutation DeclineCredit($creditId: String) {
    declineCredit(creditId: $creditId) {
      ...credit
    }
  }
  ${CREDIT_FRAGMENT}
`;
