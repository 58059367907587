import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { Formik, Form } from 'formik';
import { useUnfollowUser } from 'src/hooks/use-unfollow-user';
import { useFollowUser } from 'src/hooks/use-follow-user';
import { DeemphasizedButton, Ellipsis, StyledButton } from 'src/styles';
import { User } from 'src/types';
import { useAuth } from 'src/hooks/use-auth';
import { checkIfUserFollowsUser } from 'src/utils/computes';

const Container = styled.div`
  padding: ${fibonacci(3)}rem;
`;

const About = styled.div`
  padding-bottom: ${fibonacci(3)}rem;
`;

const StyledForm = styled(Form)`
  flex-grow: 1;
  flex-shrink: 0;
`;

const RestyledButton = styled(StyledButton)`
  width: 100%;
`;

const RestyledDeemphasizedButton = styled(DeemphasizedButton)`
  width: 100%;
`;

interface InterfaceProps {
  followed: User;
  onResolve: (subscribed: boolean) => void;
}

const SubscriptionFormFollow: React.FC<InterfaceProps> = ({
  followed,
  onResolve,
}) => {
  const { user: authUser } = useAuth();
  const { t } = useTranslation('general');
  const isFollowing = checkIfUserFollowsUser(authUser, followed);

  const onUnfollow = useUnfollowUser(followed);
  const onFollow = useFollowUser(followed);

  const unfollow = () => {
    onUnfollow();
    onResolve(false);
  };

  const follow = () => {
    onFollow();
    onResolve(false);
  };

  return (
    <Container>
      {isFollowing ? (
        <Formik initialValues={{}} onSubmit={unfollow}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <About>
                <Ellipsis>
                  {t('subscribeUnfollow', { user: followed.username })}
                </Ellipsis>
              </About>
              <RestyledDeemphasizedButton type="submit" disabled={isSubmitting}>
                {t('followUnfollow')}
              </RestyledDeemphasizedButton>
            </StyledForm>
          )}
        </Formik>
      ) : (
        <Formik initialValues={{}} onSubmit={follow}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <About>{t('subscribeFollow')}</About>
              <RestyledButton type="submit" disabled={isSubmitting}>
                {t('followFollow')}
              </RestyledButton>
            </StyledForm>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default SubscriptionFormFollow;
