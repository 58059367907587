import React, { Fragment } from 'react';
import { useAuth } from 'src/hooks/use-auth';
import { useAccountModals } from 'src/hooks/use-account-modals';
import HeaderUserMenu from 'src/components/header/header-user-menu';
import HeaderLoginButtons from 'src/components/header/header-login-buttons';
import {
  FontAwesomeButton,
  GlobalStyleVariables,
  InvertingHoverButton,
  InvertingSVGLink,
} from 'src/styles';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import HeaderNotificationsMenu from 'src/components/header/header-notifications-menu';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ControlPanel = styled.div`
  display: flex;

  > * {
    margin-right: ${fibonacci(3)}rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    > * {
      margin-right: ${fibonacci(1) * goldenRatioInverse}rem;
    }
  }
`;

const StyledInvertingHoverButton = styled(InvertingHoverButton)`
  border: 0;
`;

interface InterfaceProps {
  notificationsMenuIsOpen: boolean;
  userMenuIsOpen: boolean;
  setNotificationsMenuIsOpen: (state: boolean) => void;
  setUserMenuIsOpen: (state: boolean) => void;
}

const HeaderLoginMenuSwitch: React.FC<InterfaceProps> = ({
  notificationsMenuIsOpen,
  userMenuIsOpen,
  setNotificationsMenuIsOpen,
  setUserMenuIsOpen,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const navigate = useNavigate();
  const { openModal } = useAccountModals();

  const openLoginModal = (): void => {
    if (openModal) openModal('login');
  };

  const openRegisterModal = (): void => {
    if (openModal) openModal('registration');
  };

  const toRegister = (): void => {
    navigate('/creator_registration');
  };

  return (
    <Fragment>
      {user ? (
        <ControlPanel>
          {user.permissions.canPostContent ? (
            <InvertingSVGLink to="/publish">
              <FontAwesomeButton icon="camera" />
            </InvertingSVGLink>
          ) : (
            <StyledInvertingHoverButton onClick={toRegister}>
              {t('create')}
            </StyledInvertingHoverButton>
          )}
          <HeaderNotificationsMenu
            menuIsOpen={notificationsMenuIsOpen}
            setMenuIsOpen={setNotificationsMenuIsOpen}
            user={user}
          />
          <HeaderUserMenu
            menuIsOpen={userMenuIsOpen}
            setMenuIsOpen={setUserMenuIsOpen}
          />
        </ControlPanel>
      ) : (
        <HeaderLoginButtons
          toggleLoginModal={openLoginModal}
          toggleRegisterModal={openRegisterModal}
        />
      )}
    </Fragment>
  );
};

export default HeaderLoginMenuSwitch;
