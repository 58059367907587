import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { Formik, Form } from 'formik';
import {
  MinorMajorButtonSet,
  DeemphasizedButton,
  StyledButton,
} from 'src/styles';
import { SimpleTextarea } from 'src/components/simple-form';
import { ModerationRequestReportInput } from 'src/types';
import { useMutation } from '@apollo/client';
import { SUBMIT_MODERATION_REQUEST_REPORT_MUTATION } from 'src/queries';
import * as yup from 'yup';

const StyledForm = styled(Form)`
  padding: ${fibonacci(3)}rem;
`;

const StyledButtonSet = styled(MinorMajorButtonSet)`
  margin-top: ${fibonacci(3)}rem;
`;

interface InterfaceProps {
  initialValues: ModerationRequestReportInput;
  onReject: () => void;
  onResolve: () => void;
}

const ReportFormView: React.FC<InterfaceProps> = ({
  initialValues,
  onReject,
  onResolve,
}) => {
  const { t } = useTranslation('general');
  const [submitModerationRequestReport] = useMutation(
    SUBMIT_MODERATION_REQUEST_REPORT_MUTATION
  );
  const reportSchema = yup.object().shape({
    comment: yup
      .string()
      .required()
      .max(666, t('errorTooLong', { length: 666 })),
  });

  const onSubmit = async (
    values: ModerationRequestReportInput,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { setSubmitting }: any
  ): Promise<void> => {
    try {
      await submitModerationRequestReport({
        variables: { moderationRequestReport: values },
      });
      onResolve();
    } catch (e) {
      console.log('error', e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={reportSchema}
    >
      {({ isSubmitting }): ReactElement => (
        <StyledForm>
          <SimpleTextarea
            label={t('postsModerationReportComment')}
            name="comment"
            placeholder={t('postsModerationReportCommentDetail')}
          />
          <StyledButtonSet>
            <DeemphasizedButton type="button" onClick={onReject}>
              {t('cancel')}
            </DeemphasizedButton>
            <StyledButton type="submit" disabled={isSubmitting}>
              {t('submit')}
            </StyledButton>
          </StyledButtonSet>
        </StyledForm>
      )}
    </Formik>
  );
};

export default ReportFormView;
