import React, { ReactElement } from 'react';
import * as yup from 'yup';
import {
  StyledButton,
  DeemphasizedButton,
  MinorMajorButtonSet,
  GlobalStyleVariables,
} from 'src/styles';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Post, Comment, CommentInput } from 'src/types';
import { SimpleTextarea } from 'src/components/simple-form';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { useMutation } from '@apollo/client';
import { useAuth } from 'src/hooks/use-auth';
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { CREATE_COMMENT_MUTATION } from 'src/queries/cms';
import { updatePaginatedComments } from 'src/utils/data';

const CommentForm = styled(Form)`
  width: 100%;
  margin-bottom: ${fibonacci(3)}rem;
  background-color: rgba(${(props): string => props.theme.lowMutedColor}, 0.1);

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    margin-bottom: 0;
  }
`;

const StyledButtonSet = styled(MinorMajorButtonSet)`
  margin-top: ${fibonacci(3)}rem;
`;

interface InterfaceProps {
  post: Post;
  onExit?: () => void;
  parent?: Comment;
}

const CommentFormView: React.FC<InterfaceProps> = ({
  post,
  onExit,
  parent,
}) => {
  const { t } = useTranslation('general');
  const { user } = useAuth();
  const ensureUser = useEnsureUser();
  const [createComment] = useMutation(CREATE_COMMENT_MUTATION, {
    update(cache, { data: { createComment } }) {
      const paginationArgs = parent
        ? { parentId: parent.id }
        : { postId: post.id };
      updatePaginatedComments(cache, paginationArgs, (comments) => {
        return [createComment.comment, ...comments];
      });
    },
  });

  const commentSchema = yup.object().shape({
    text: yup.string().max(666, t('errorTooLong', { length: 666 })),
  });

  const onSubmit = async (
    comment: CommentInput,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { setFieldValue, setSubmitting }: any
  ): Promise<void> => {
    try {
      await ensureUser();
      await createComment({
        variables: {
          comment,
        },
      });
      setFieldValue('text', '');
      if (onExit) onExit();
    } catch (e) {
      console.log('error', e);
    } finally {
      setSubmitting(false);
    }
  };

  if (user?.restrictions.cannotPostComments) {
    return <></>;
  } else {
    return (
      <Formik
        initialValues={{
          postId: post.id,
          parentId: parent ? parent.id : undefined,
          text: '',
        }}
        onSubmit={onSubmit}
        validationSchema={commentSchema}
      >
        {({ isSubmitting }): ReactElement => (
          <CommentForm>
            <SimpleTextarea
              name="text"
              placeholder={
                parent ? t('commentsReplyDetail') : t('commentsCommentDetail')
              }
            />
            <StyledButtonSet>
              {onExit && (
                <DeemphasizedButton
                  type="button"
                  disabled={isSubmitting}
                  onClick={onExit}
                >
                  {t('cancel')}
                </DeemphasizedButton>
              )}
              <StyledButton type="submit" disabled={isSubmitting}>
                {t('submit')}
              </StyledButton>
            </StyledButtonSet>
          </CommentForm>
        )}
      </Formik>
    );
  }
};

export default CommentFormView;
