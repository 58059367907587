import React, { useState } from 'react';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { GlobalStyleVariables, InvertingSVGLink } from 'src/styles';
import HeaderLoginMenuSwitch from 'src/components/header/header-login-menu-switch';
import HeaderSearchBar from 'src/components/header/header-search-bar';
import HoneycombLogo from 'src/components/honeycomb-logo';
import { timeout } from 'src/utils/async';

const Container = styled.header`
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  height: ${GlobalStyleVariables.headerHeight}rem;
  width: 100%;
  padding: 0 ${GlobalStyleVariables.mainContainerMargins}vw;
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  border-bottom: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);

  > *:last-child {
    margin-left: auto;
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    padding: 0 ${GlobalStyleVariables.phoneMainContainerMargins}rem;
  }
`;

const LogoContainer = styled(InvertingSVGLink)`
  align-self: flex-end;
  padding: 0.2rem 0.2rem 0;
`;

const Logo = styled(HoneycombLogo)`
  height: 3.8rem;
`;

const HeaderView: React.FC = () => {
  const [notificationsMenuIsOpen, setNotificationsMenuIsOpen] = useState(false);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

  const manageNotificationsMenu = async (state: boolean) => {
    await timeout(50);
    setNotificationsMenuIsOpen(state);
  };

  const manageUserMenu = async (state: boolean) => {
    await timeout(50);
    setUserMenuIsOpen(state);
  };

  const closeAllMenus = () => {
    setNotificationsMenuIsOpen(false);
    setUserMenuIsOpen(false);
  };

  return (
    <Container onClick={closeAllMenus}>
      <LogoContainer to="/">
        <Logo />
      </LogoContainer>
      <HeaderSearchBar />
      <HeaderLoginMenuSwitch
        notificationsMenuIsOpen={notificationsMenuIsOpen}
        userMenuIsOpen={userMenuIsOpen}
        setNotificationsMenuIsOpen={manageNotificationsMenu}
        setUserMenuIsOpen={manageUserMenu}
      />
    </Container>
  );
};

export default HeaderView;
