import React from 'react';
import { Notification } from 'src/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles/global';

const Comment = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgba(
    ${(props): string => props.theme.lowMutedColor},
    ${Math.pow(goldenRatioInverse, 5)}
  );
`;

const Moderator = styled.div`
  margin-bottom: ${fibonacci(1) * goldenRatioInverse}rem;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
`;

interface InterfaceProps {
  notification: Notification;
}

const NotificationItemModeratorComment: React.FC<InterfaceProps> = ({
  notification,
}) => {
  const { t } = useTranslation('general');

  return notification.moderationRequestResponse.comment ? (
    <Comment>
      <Moderator>{t('notificationsModeratorComment')}</Moderator>
      {notification.moderationRequestResponse.comment}
    </Comment>
  ) : (
    <></>
  );
};

export default NotificationItemModeratorComment;
