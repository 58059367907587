import React, { Children } from 'react';
import NoContent from 'src/components/no-content';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { UnstyledList, UnstyledListItem } from 'src/styles';

const List = styled(UnstyledList)`
  margin: ${fibonacci(5)}rem 0;
`;

const Item = styled(UnstyledListItem)`
  margin-bottom: ${fibonacci(5)}rem;
`;

interface InterfaceProps {
  className?: string;
}

const ListViewContainer: React.FC<InterfaceProps> = ({
  children,
  className,
}) => {
  if (Children.count(children) === 0) {
    return <NoContent />;
  }

  return (
    <List className={className}>
      {Children.map(children, (child, index) => {
        return <Item key={index}>{child}</Item>;
      })}
    </List>
  );
};

export default ListViewContainer;
