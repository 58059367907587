import { default as NavBar } from 'src/components/nav-bar/nav-bar-view';
import { GlobalStyleVariables } from 'src/styles';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import styled from 'styled-components';

export default NavBar;

export const SecondaryNavBar = styled(NavBar)`
  top: ${GlobalStyleVariables.headerHeight * 2}rem;
  height: initial;
  z-index: 11;
  margin-bottom: ${fibonacci(3)}rem;
  border-bottom: dotted ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);

  a {
    height: ${fibonacci(4)}rem;
    border: 0;
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    a {
      font-size: ${GlobalStyleVariables.fontSizeTwoDown}rem;
    }
  }
`;
