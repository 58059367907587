import React, { ReactElement } from 'react';
import CommentListView from 'src/components/comment-list/comment-list-view';
import {
  PaginationArgs,
  PaginationMetadataInput,
  Post,
  Comment,
} from 'src/types';
import {
  PAGINATED_COMMENTS_QUERY,
  PAGINATED_COMMENTS_WITH_CHILDREN_QUERY,
} from 'src/queries';
import GraphqlResult from 'src/components/graphql-result';
import { useOffsetBasedPagination } from 'src/hooks';
import {
  generateCommentsMetadata,
  generateCommentChildrenMetadata,
} from 'src/utils/pagination';

interface InterfaceProps {
  activeCommentId?: string;
  activeParentId?: string;
  post: Post;
  className?: string;
  metadata?: PaginationMetadataInput;
  paginationArgs?: PaginationArgs;
  parent?: Comment;
  openReply?: () => void;
  scrollElementRef?: React.RefObject<HTMLElement>;
}

const CommentListQuery: React.FC<InterfaceProps> = ({
  activeCommentId,
  activeParentId,
  post,
  className,
  metadata = generateCommentsMetadata(),
  paginationArgs = {},
  parent,
  openReply,
}) => {
  const response = useOffsetBasedPagination(
    parent ? PAGINATED_COMMENTS_QUERY : PAGINATED_COMMENTS_WITH_CHILDREN_QUERY,
    {
      commentChildrenMetadata: generateCommentChildrenMetadata(),
      metadata,
      paginationArgs,
      type: 'Comment',
    }
  );

  return (
    <GraphqlResult response={response}>
      {(data): ReactElement => (
        <CommentListView
          activeCommentId={activeCommentId}
          activeParentId={activeParentId}
          post={post}
          className={className}
          parent={parent}
          openReply={openReply}
          {...data}
        />
      )}
    </GraphqlResult>
  );
};

export default CommentListQuery;
