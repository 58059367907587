import React from 'react';
import UserList from 'src/components/user-list/index';

interface InterfaceProps {
  likedPostId: string;
}

const PostLikersListView: React.FC<InterfaceProps> = ({ likedPostId }) => {
  return (
    <UserList
      paginationArgs={{
        likedPostId,
      }}
      useLineItemLayout={true}
    />
  );
};

export default PostLikersListView;
