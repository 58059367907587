import { FileFilter, FileUpload } from 'src/types';

const getImageOrientation = function (file: File): Promise<number> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      const view = new DataView(reader.result as ArrayBuffer);
      if (view.getUint16(0, false) !== 0xffd8) {
        return resolve(-2);
      }
      const length = view.byteLength;
      let offset = 2;
      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) return resolve(-1);
        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return resolve(-1);
          }

          const little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return resolve(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return resolve(-1);
    };
    reader.readAsArrayBuffer(file);
  });
};

const getRotation = function (orientation: number): number {
  if (orientation === 3 || orientation === 4) return 180;
  else if (orientation === 5 || orientation === 6) return 270;
  else if (orientation === 7 || orientation === 8) return 90;
  else return 0;
};

const rotateImage = function (
  file: File,
  orientation: number,
  rotation: number
): Promise<File> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      const image = new Image();
      if (typeof reader.result === 'string') {
        image.src = reader.result;

        image.onload = (): void => {
          const canvas = document.createElement('canvas');
          canvas.height =
            [0, 180].indexOf(rotation) > -1 ? image.height : image.width;
          canvas.width =
            [0, 180].indexOf(rotation) > -1 ? image.width : image.height;

          const ctx = canvas.getContext('2d');

          if (ctx) {
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((rotation * Math.PI) / 180);
            if ([2, 4, 5, 7].indexOf(orientation) > -1) ctx.scale(-1, 1);
            ctx.drawImage(image, -image.width / 2, -image.height / 2);
            canvas.toBlob(
              (blob): void => {
                if (blob) resolve(blob as File);
              },
              file.type,
              1.0
            );
          }
        };
      }
    };
  });
};

const getRotatedImage = async function (file: File): Promise<File> {
  return new Promise((resolve): void => {
    getImageOrientation(file).then((orientation) => {
      if (Math.abs(orientation) === 1) {
        resolve(file);
      } else {
        const rotation = getRotation(orientation);
        rotateImage(file, orientation, rotation).then((rotatedFile) => {
          Object.defineProperty(rotatedFile, 'name', {
            writable: true,
            value: file.name,
          });
          resolve(rotatedFile);
        });
      }
    });
  });
};

export const convertToFileUpload = async (file: File): Promise<FileUpload> => {
  return new Promise((resolve) => {
    if (file.type.match('image.*')) {
      getRotatedImage(file).then((rotatedFile) => {
        const reader = new FileReader();
        reader.readAsDataURL(rotatedFile);
        reader.onload = (): void => {
          if (reader.result && typeof reader.result === 'string') {
            resolve({
              filterIntensity: 50,
              previewType: FileFilter.keyhole,
              modifications: {},
              modifiedFile: rotatedFile,
              previewable: false,
              result: reader.result,
              type: 'image',
              file: rotatedFile,
            });
          }
        };
      });
    } else {
      const reader = new FileReader();
      reader.onloadend = (): void => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const fileType = file.type;
        const blob = new Blob([arrayBuffer], { type: fileType });
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = (): void => {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
          resolve({
            modifications: {
              duration,
            },
            modifiedFile: file,
            previewable: false,
            result: URL.createObjectURL(blob),
            type: 'video',
            file,
          });
        };

        video.src = URL.createObjectURL(file);
      };
      reader.readAsArrayBuffer(file);
    }
  });
};
