import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  MinorMajorButtonSet,
  DeemphasizedButton,
  StyledButton,
  HeadingTwo,
} from 'src/styles';
import { useCreateCuratedList } from 'src/hooks/use-create-curated-list';
import { Post } from 'src/types';
import { useAddToCuratedList } from 'src/hooks/use-add-to-curated-list';
import { SimpleInput } from 'src/components/simple-form';

const StyledForm = styled(Form)`
  padding: ${fibonacci(3)}rem;
`;

const StyledHeadingTwo = styled(HeadingTwo)`
  margin-bottom: ${fibonacci(3)}rem;
`;

const StyledButtonSet = styled(MinorMajorButtonSet)`
  margin-top: ${fibonacci(3)}rem;
`;

interface FormValues {
  name?: string;
}

interface InterfaceProps {
  post: Post;
  hasListed: boolean;
  onReject: () => void;
  onResolve: () => void;
}

const AddToCuratedListNew: React.FC<InterfaceProps> = ({
  post,
  hasListed,
  onReject,
  onResolve,
}) => {
  const { t } = useTranslation('general');
  const createCuratedList = useCreateCuratedList();
  const addToCuratedList = useAddToCuratedList(post);

  const CuratedListSchema = yup.object().shape({
    name: yup.string().max(69, t('errorTooLong', { length: 69 })),
  });

  const onSubmit = async (
    values: FormValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { resetForm, setSubmitting }: any
  ): Promise<void> => {
    try {
      const curatedList = await createCuratedList(values.name || '');
      await addToCuratedList(curatedList);

      if (!hasListed) onResolve();
      else resetForm();
    } catch (e) {
      console.log('error', e as Error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ name: '' }}
      onSubmit={onSubmit}
      validationSchema={CuratedListSchema}
    >
      {({ isSubmitting }): ReactElement => (
        <StyledForm>
          <StyledHeadingTwo>{t('curatedListsAddToNewList')}</StyledHeadingTwo>
          <SimpleInput
            autoComplete="off"
            label={t('curatedListsName')}
            name="name"
          />
          <StyledButtonSet>
            <DeemphasizedButton type="button" onClick={onReject}>
              {t('cancel')}
            </DeemphasizedButton>
            <StyledButton type="submit" disabled={isSubmitting}>
              {hasListed ? t('add') : t('submit')}
            </StyledButton>
          </StyledButtonSet>
        </StyledForm>
      )}
    </Formik>
  );
};

export default AddToCuratedListNew;
