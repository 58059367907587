import React from 'react';
import styled from 'styled-components';
import { User } from 'src/types';
import { Avatar } from 'src/components/avatar';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { useTranslation } from 'react-i18next';
import { GlobalStyleVariables } from 'src/styles/global';
import { PrimaryColorLink } from 'src/styles';

const MediaItemContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const MediaItemInfoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
`;

const MediaItemInfo = styled.div`
  margin-top: ${fibonacci(1) * goldenRatioInverse}rem;
  padding: ${fibonacci(1) * goldenRatioInverse}rem 1rem;
  background: rgba(${(props): string => props.theme.primaryColor}, 1);
  color: rgba(${(props): string => props.theme.backgroundColor}, 1);
`;

interface InterfaceProps {
  user: User;
}

const UserCardMediaItem: React.FC<InterfaceProps> = ({ user }) => {
  const { t } = useTranslation('general');

  return (
    <PrimaryColorLink to={`/${user.username}`}>
      <MediaItemContainer>
        <Avatar avatar={user.avatar} size={(1 - goldenRatioInverse) * 100} />
        <MediaItemInfoContainer>
          {user.postCount > 0 && (
            <MediaItemInfo>
              {t('uploadsWithCount', { count: user.postCount })}
            </MediaItemInfo>
          )}

          {user.userFollowerCount > 0 && (
            <MediaItemInfo>
              {t('followersWithCount', {
                count: user.userFollowerCount,
              })}
            </MediaItemInfo>
          )}

          {user.purchaseCount > 0 && (
            <MediaItemInfo>
              {t('purchasesWithCount', { count: user.purchaseCount })}
            </MediaItemInfo>
          )}
        </MediaItemInfoContainer>
      </MediaItemContainer>
    </PrimaryColorLink>
  );
};

export default UserCardMediaItem;
