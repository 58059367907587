import React, { ReactElement } from 'react';
import * as yup from 'yup';
import {
  StyledButton,
  MinorMajorButtonSet,
  DeemphasizedButton,
} from 'src/styles';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Comment, CommentUpdateInput } from 'src/types';
import { SimpleTextarea } from 'src/components/simple-form';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { useMutation } from '@apollo/client';
import { UPDATE_COMMENT_MUTATION } from 'src/queries/cms';

const CommentForm = styled(Form)`
  width: 100%;
  margin-bottom: ${fibonacci(3)}rem;
  background-color: rgba(${(props): string => props.theme.lowMutedColor}, 0.1);
`;

const StyledButtonSet = styled(MinorMajorButtonSet)`
  margin-top: ${fibonacci(3)}rem;
`;

interface InterfaceProps {
  toggleIsEditing: () => void;
  comment: Comment;
}

const CommentEditFormView: React.FC<InterfaceProps> = ({
  toggleIsEditing,
  comment,
}) => {
  const { t } = useTranslation('general');
  const [updateComment] = useMutation(UPDATE_COMMENT_MUTATION);

  const commentSchema = yup.object().shape({
    text: yup
      .string()
      .required(t('errorRequired'))
      .max(666, t('errorTooLong', { length: 666 })),
  });

  const onSubmit = async (
    comment: CommentUpdateInput,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { setSubmitting }: any
  ): Promise<void> => {
    try {
      await updateComment({
        variables: {
          comment,
        },
      });
      toggleIsEditing();
    } catch (e) {
      console.log('error', e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        id: comment.id,
        text: comment.text,
      }}
      onSubmit={onSubmit}
      validationSchema={commentSchema}
    >
      {({ isSubmitting }): ReactElement => (
        <CommentForm>
          <SimpleTextarea name="text" placeholder={t('commentsUpdateDetail')} />

          <StyledButtonSet>
            <DeemphasizedButton type="button" onClick={toggleIsEditing}>
              {t('cancel')}
            </DeemphasizedButton>
            <StyledButton type="submit" disabled={isSubmitting}>
              {t('update')}
            </StyledButton>
          </StyledButtonSet>
        </CommentForm>
      )}
    </Formik>
  );
};

export default CommentEditFormView;
