import gql from 'graphql-tag';
import {
  USER_MEMBER_AND_ARTIST_FRAGMENT,
  USER_WITH_AVATAR,
  CURRENT_USER_FRAGMENT,
} from 'src/queries/fragments__complex';

export const PAGINATED_USER_QUERY = gql`
  query PaginatedUsers(
    $metadata: PaginationMetadataInput
    $paginationArgs: PaginationArgsInput
    $type: String
  ) {
    offsetBasedPaginatedEntries(
      type: $type
      metadata: $metadata
      paginationArgs: $paginationArgs
    ) {
      __typename
      ... on User {
        ...userMemberAndCreator
      }
    }
  }
  ${USER_MEMBER_AND_ARTIST_FRAGMENT}
`;

export const USER_SUGGESTIONS_QUERY = gql`
  query UserSuggestions($query: String, $metadata: PaginationMetadataInput) {
    userSuggestions(query: $query, metadata: $metadata) {
      ...userWithAvatar
    }
  }
  ${USER_WITH_AVATAR}
`;

export const USER_BY_USERNAME_QUERY = gql`
  query UserByUsername($username: String) {
    userByUsername(username: $username) {
      ...userMemberAndCreator
      curatedPostCount
      curatedListCount
    }
  }
  ${USER_MEMBER_AND_ARTIST_FRAGMENT}
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      ...currentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export const LOGIN_MUTATION = gql`
  mutation Login($identity: String, $password: String) {
    login(identity: $identity, password: $password) {
      token
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($email: String, $password: String, $username: String) {
    createUser(email: $email, password: $password, username: $username) {
      token
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;
