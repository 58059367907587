import React from 'react';
import { ModerationRequest } from 'src/types';
import { useTranslation } from 'react-i18next';
import ModerationRequestItemWrapper from 'src/components/moderation-request-item/moderation-request-item-wrapper';
import { HeadingTwo } from 'src/styles';
import UserSummary from 'src/components/user-summary';
import ModerationRequestItemReports from 'src/components/moderation-request-item/moderation-request-item-reports';

interface InterfaceProps {
  moderationRequest: ModerationRequest;
}

const ModerationRequestItemUserModerationRequest: React.FC<InterfaceProps> = ({
  moderationRequest,
}) => {
  const { t } = useTranslation('general');
  const user = moderationRequest.profile.user;

  return (
    <ModerationRequestItemWrapper
      moderationRequest={moderationRequest}
      isDisciplinary={true}
      users={[user]}
      fields={[
        {
          field: 'website',
          original: user.profile.website || '',
          type: 'input',
        },
        {
          field: 'pronouns',
          original: user.pronouns || '',
          type: 'input',
        },
        {
          field: 'description',
          original: user.profile.description || '',
          type: 'textarea',
        },
      ]}
    >
      <HeadingTwo>{t('moderationUserModerationRequest')}</HeadingTwo>
      <ModerationRequestItemReports moderationRequest={moderationRequest} />
      <UserSummary user={user} />
    </ModerationRequestItemWrapper>
  );
};

export default ModerationRequestItemUserModerationRequest;
