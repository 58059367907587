import React from 'react';
import styled from 'styled-components';
import { User } from 'src/types';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { PrimaryColorLink } from 'src/styles';
import { GlobalStyleVariables } from 'src/styles/global';
import { Avatar } from '../avatar';
import UserLineItemFollowButton from './user-line-follow-button';
import { useTranslation } from 'react-i18next';

const Container = styled.li`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

const Link = styled(PrimaryColorLink)`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  margin-right: 1rem;
`;

const StyledAvatar = styled(Avatar)`
  height: ${fibonacci(6)}rem;
  width: ${fibonacci(6)}rem;
  margin-left: 1rem;
`;

const InfoContainer = styled.div`
  flex-grow: 1;
  margin-left: 1rem;
`;

const Username = styled.div`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DisplayName = styled.div`
  margin-top: ${goldenRatioInverse}rem;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  opacity: ${goldenRatioInverse};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AdditionalInfo = styled.div`
  margin-top: 1rem;
  font-size: ${GlobalStyleVariables.fontSizeTwoDown}rem;
  opacity: ${goldenRatioInverse};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface InterfaceProps {
  user: User;
}

const UserLineItemView: React.FC<InterfaceProps> = ({ user }) => {
  const { t } = useTranslation('general');

  return (
    <Container>
      <Link to={`/${user.username}`}>
        <StyledAvatar avatar={user.avatar} size={8} />
        <InfoContainer>
          <Username>@{user.username}</Username>
          {user.displayName && <DisplayName>{user.displayName}</DisplayName>}
          <AdditionalInfo>
            {t('followersWithCount', {
              count: user.userFollowerCount,
            })}
          </AdditionalInfo>
        </InfoContainer>
      </Link>
      <UserLineItemFollowButton user={user} />
    </Container>
  );
};

export default UserLineItemView;
