import React from 'react';
import { MainContainer } from 'src/styles/components/layout';
import PublishRouteRouter from 'src/routes/publish/publish-route-router';
import BanScreen from 'src/components/ban-screen';
import { useAuth } from 'src/hooks/use-auth';

const PublishRouteView: React.FC = () => {
  const { user } = useAuth();
  return (
    <MainContainer>
      <BanScreen user={user}>
        <PublishRouteRouter />
      </BanScreen>
    </MainContainer>
  );
};

export default PublishRouteView;
