import React, { ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { StyledButton, ButtonSet, DeemphasizedButton } from 'src/styles';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { CreatorApplicationInput, Citizenship } from 'src/types';
import { useAuth } from 'src/hooks/use-auth';
import LoadingSpinner from 'src/components/loading-spinner';
import {
  SimpleInput,
  SimpleDate,
  SimpleSelect,
} from 'src/components/simple-form';
import { generateGenericOptionItemsFromEnum } from 'src/utils/generators';
import { useBlocker } from 'react-router-dom';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';

interface InterfaceProps {
  cancel: () => void;
  submit: (variables: CreatorApplicationInput) => Promise<void>;
}

const CreatorApplicationFormView: React.FC<InterfaceProps> = ({
  cancel,
  submit,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const [isDirty, setIsDirty] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return currentLocation.pathname !== nextLocation.pathname && isDirty;
  });
  const confirm = useConfirmationModal();
  const cancelWrapper = async () => {
    if (!isDirty || (await confirm(t('formCancel')))) {
      cancel();
    }
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm(t('formCancel'), blocker);
    }
  }, [blocker.state]);

  const citizenshipOptions = generateGenericOptionItemsFromEnum(t, Citizenship);
  citizenshipOptions.push(citizenshipOptions.splice(0, 1)[0]);

  if (!user) {
    return <LoadingSpinner />;
  } else {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    eighteenYearsAgo.setDate(eighteenYearsAgo.getDate() + 1);
    const exactlyEightYearsAgo = new Date();
    exactlyEightYearsAgo.setFullYear(exactlyEightYearsAgo.getFullYear() - 18);

    const creatorApplicationSchema = yup.object().shape({
      birthday: yup.date().max(eighteenYearsAgo),
      legalName: yup
        .string()
        .required()
        .max(666, t('errorTooLong', { length: 666 })),
    });

    const onSubmit = async (
      variables: CreatorApplicationInput,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { setSubmitting }: any
    ): Promise<void> => {
      try {
        await submit(variables);
      } catch (e) {
        console.log('error', e);
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <Formik
        initialValues={{
          birthday: exactlyEightYearsAgo,
          citizenship: 1,
          legalName: '',
        }}
        onSubmit={onSubmit}
        validationSchema={creatorApplicationSchema}
      >
        {({ dirty, isSubmitting }): ReactElement => {
          setIsDirty(dirty);

          return (
            <Form>
              <SimpleInput
                label={t('creatorApplicationsLegalName')}
                name="legalName"
              />
              <SimpleDate
                label={t('creatorApplicationsBirthday')}
                maxDate={eighteenYearsAgo}
                name="birthday"
                showMonthDropdown
                showYearDropdown
              />
              <SimpleSelect
                label={t('creatorApplicationsCitizenship')}
                name="citizenship"
                options={citizenshipOptions}
              />
              <ButtonSet>
                <DeemphasizedButton type="button" onClick={cancelWrapper}>
                  {t('cancel')}
                </DeemphasizedButton>
                <StyledButton type="submit" disabled={isSubmitting}>
                  {t('submit')}
                </StyledButton>
              </ButtonSet>
            </Form>
          );
        }}
      </Formik>
    );
  }
};

export default CreatorApplicationFormView;
