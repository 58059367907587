import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  MinorMajorButtonSet,
  StyledButton,
  StyledError,
  DeemphasizedButton,
} from 'src/styles';
import { useAuth } from 'src/hooks/use-auth';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { User } from 'src/types';
import { SimpleInput } from 'src/components/simple-form';

const StyledForm = styled(Form)`
  padding: ${fibonacci(3)}rem;
`;

const StyledButtonSet = styled(MinorMajorButtonSet)`
  margin-top: ${fibonacci(3)}rem;
`;

interface LoginValues {
  identity: string;
  password: string;
}

interface InterfaceProps {
  onReject: () => void;
  onResolve: (user: User) => void;
}

const LoginFormView: React.FC<InterfaceProps> = ({ onReject, onResolve }) => {
  const { t } = useTranslation('general');
  const handleGraphQLErrors = useGraphQLErrors();
  const [globalError, setGlobalError] = useState<string | null>(null);
  const { login } = useAuth();

  const userSchema = yup.object().shape({
    identity: yup
      .string()
      .required(t('errorRequired'))
      .max(1000, t('errorTooLong', { length: 1000 })),
    password: yup.string().required(t('errorRequired')),
  });

  const onSubmit = async (
    values: LoginValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { setSubmitting }: any
  ): Promise<void> => {
    try {
      if (!login) return;

      setGlobalError(null);

      const user = await login(values);

      onResolve(user);
    } catch (e) {
      handleGraphQLErrors(e as Error, (message: string) => {
        setGlobalError(message);
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ identity: '', password: '' }}
      validationSchema={userSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }): ReactElement => (
        <StyledForm data-testid="login-form">
          <SimpleInput
            data-testid="login-form__identity"
            autoComplete="username"
            autoFocus={true}
            label={t('usernameOrEmailAddress')}
            name="identity"
          />
          <SimpleInput
            data-testid="login-form__password"
            autoComplete="new-password"
            label={t('password')}
            name="password"
            type="password"
          />
          {globalError && <StyledError>{globalError}</StyledError>}
          <StyledButtonSet>
            <DeemphasizedButton
              data-testid="login-form__cancel"
              type="button"
              onClick={onReject}
            >
              {t('cancel')}
            </DeemphasizedButton>
            <StyledButton
              data-testid="login-form__submit"
              type="submit"
              disabled={isSubmitting}
            >
              {t('submit')}
            </StyledButton>
          </StyledButtonSet>
        </StyledForm>
      )}
    </Formik>
  );
};

export default LoginFormView;
