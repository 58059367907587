import React from 'react';
import { ModerationRequest } from 'src/types';
import { useTranslation } from 'react-i18next';
import ModerationRequestItemWrapper from 'src/components/moderation-request-item/moderation-request-item-wrapper';
import { HeadingTwo } from 'src/styles';
import CuratedListSummary from 'src/components/curated-list-summary';
import ModerationRequestItemReports from 'src/components/moderation-request-item/moderation-request-item-reports';

interface InterfaceProps {
  moderationRequest: ModerationRequest;
}

const ModerationRequestItemCuratedListModerationRequest: React.FC<InterfaceProps> =
  ({ moderationRequest }) => {
    const { t } = useTranslation('general');
    const curatedList = moderationRequest.curatedList;

    return (
      <ModerationRequestItemWrapper
        moderationRequest={moderationRequest}
        isDisciplinary={true}
        users={curatedList.curatorialMemberships.map(
          (membership) => membership.user
        )}
        fields={[
          {
            field: 'name',
            original: curatedList.name || '',
            type: 'input',
          },
          {
            field: 'description',
            original: curatedList.description || '',
            type: 'textarea',
          },
        ]}
      >
        <HeadingTwo>{t('moderationCuratedListModerationRequest')}</HeadingTwo>
        <ModerationRequestItemReports moderationRequest={moderationRequest} />
        <CuratedListSummary curatedList={curatedList} />
      </ModerationRequestItemWrapper>
    );
  };

export default ModerationRequestItemCuratedListModerationRequest;
