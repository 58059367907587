import { User, Post, PostAccessType } from 'src/types';

export function checkIfUserFollowsUser(
  follower: User | undefined,
  followed: User
): boolean {
  if (!follower) return false;

  return follower.followedUsers.some(({ id }) => id === followed.id);
}

export function checkIfUserIsPostCreator(
  user: User | undefined,
  post: Post
): boolean {
  if (!user) return false;

  return (
    user.credits?.some((c) => {
      return c.post.id === post.id;
    }) ||
    post.credits?.some((c) => {
      return c.user.id == user.id;
    })
  );
}

export function checkIfUserHasLikedPost(
  user: User | undefined,
  post: Post
): boolean {
  if (!user) return false;

  return user.likedPosts.some(({ id }) => id === post.id);
}

export function checkIfUserHasPurchasedPost(
  user: User | undefined,
  post: Post
): boolean {
  if (!user) return false;

  return user.purchasedPosts.some(({ id }) => id === post.id);
}

export function checkIfUserHasSubscribedToAnPostCreator(
  user: User | undefined,
  post: Post
): boolean {
  if (!user) return false;

  return user.activeUserSubscriptions.some((us) => {
    return post.credits.some((c) => c.user.id === us.subscribed.id);
  });
}

export function checkIfUserHasListedPost(
  user: User | undefined,
  post: Post
): boolean {
  if (!user) return false;

  return user?.curatorialMemberships?.some(
    ({ curatedList: { curatedPostListings } }) =>
      curatedPostListings.some(({ post: { id } }) => id === post.id)
  );
}

export function checkIfUserCanListPost(
  user: User | undefined,
  post: Post
): boolean {
  return (
    checkIfUserHasLikedPost(user, post) ||
    checkIfUserHasPurchasedPost(user, post)
  );
}

export function checkIfUserMustPayForPost(
  user: User | undefined,
  post: Post
): boolean {
  return post.accessType === PostAccessType['post-access-type-pay'];
}

export function checkIfUserMustSubscribeForPost(
  user: User | undefined,
  post: Post
): boolean {
  return post.accessType === PostAccessType['post-access-type-subscription'];
}
