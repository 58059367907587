/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Post } from 'src/types';
import { goldenRatioInverse } from 'src/utils/math';
import { StyledButton } from 'src/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { useAuth } from 'src/hooks/use-auth';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useAccountModals } from 'src/hooks/use-account-modals';
import { checkIfUserHasListedPost } from 'src/utils/computes';

const StyledForm = styled(Form)`
  display: flex;
`;

const BorderlessButton = styled(StyledButton)`
  border: 0;
  background: transparent;

  &:disabled {
    color: rgba(
      ${(props): string => props.theme.highMutedColor},
      ${goldenRatioInverse}
    );
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
`;

interface InterfaceProps {
  post: Post;
}

const PostCardListeButton: React.FC<InterfaceProps> = ({ post }) => {
  const { t } = useTranslation('general');
  const { user } = useAuth();
  const { openModal } = useAccountModals();
  const hasListed = checkIfUserHasListedPost(user, post);

  async function addToCuratedList(
    _: any,
    { setSubmitting }: any
  ): Promise<void> {
    try {
      if (!openModal) return;

      openModal('addToCuratedList', { post, hasListed });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Formik initialValues={{}} onSubmit={addToCuratedList}>
        {({ isSubmitting }): ReactElement => (
          <StyledForm>
            <BorderlessButton
              type="submit"
              disabled={isSubmitting}
              data-for={`post_list_${post.id}`}
              data-tip
            >
              <PriceContainer>
                <FontAwesomeIcon icon="list" />
              </PriceContainer>
            </BorderlessButton>
          </StyledForm>
        )}
      </Formik>
      <ReactTooltip
        id={`post_list_${post.id}`}
        effect="solid"
        place="bottom"
        getContent={(): string => {
          return hasListed
            ? t('curatedListsManageListings')
            : t('curatedListsAddTo');
        }}
      />
    </>
  );
};

export default PostCardListeButton;
