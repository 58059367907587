import React, { ReactElement } from 'react';
import { USER_BY_USERNAME_QUERY } from 'src/queries';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import GraphqlResult from 'src/components/graphql-result';
import { MainContainer, GlobalStyleVariables } from 'src/styles';
import UserRouteNav from 'src/routes/user/user-route-nav';
import UserRouteContent from 'src/routes/user/user-route-content';
import StickyBorder from 'src/components/sticky-border';
import UserRouteRouter from 'src/routes/user/user-route-router';
import { generateMediaItemsMetadata } from 'src/utils/pagination';

const UserRouteView: React.FC = () => {
  const { username } = useParams<{ username?: string }>();
  const response = useQuery(USER_BY_USERNAME_QUERY, {
    variables: {
      mediaItemsMetadata: generateMediaItemsMetadata(),
      username: username,
    },
  });

  return (
    <MainContainer>
      <GraphqlResult response={response}>
        {({ userByUsername }): ReactElement => (
          <>
            <UserRouteContent user={userByUsername} />

            <UserRouteNav user={userByUsername} />

            <StickyBorder top={GlobalStyleVariables.headerHeight * 2} />

            <UserRouteRouter user={userByUsername} />
          </>
        )}
      </GraphqlResult>
    </MainContainer>
  );
};

export default UserRouteView;
