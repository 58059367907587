import { TFunction } from 'react-i18next';
import { GenericOptionItem } from 'src/types';

export function generateGenericOptionItemsFromEnum(
  t: TFunction<'general'>,
  enumerable: {
    [s: number]: string;
  }
): GenericOptionItem[] {
  return Array(Object.keys(enumerable).length / 2)
    .fill(null)
    .map((_, i) => {
      return {
        label: t(enumerable[i]),
        value: i,
      };
    });
}
