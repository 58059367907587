import React, { ReactElement } from 'react';
import { PaginationQueryVariables, PaginationArgs } from 'src/types';
import { PAGINATED_NOTIFICATIONS_QUERY } from 'src/queries';
import GraphqlResult from 'src/components/graphql-result';
import { useOffsetBasedPagination } from 'src/hooks';
import NotificationListQueryInner from './notification-list-query-inner';

const variables: PaginationQueryVariables = {
  metadata: {
    limit: 10,
    offset: 0,
  },
  type: 'Notification',
};

interface InterfaceProps {
  className?: string;
  onClick?: () => void;
  paginationArgs?: PaginationArgs;
}

const NotificationListQuery: React.FC<InterfaceProps> = ({
  className,
  onClick,
  paginationArgs = {},
}) => {
  const response = useOffsetBasedPagination(
    PAGINATED_NOTIFICATIONS_QUERY,
    {
      ...variables,
      paginationArgs,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  return (
    <GraphqlResult response={response}>
      {(data): ReactElement => {
        return (
          <NotificationListQueryInner
            className={className}
            data={data}
            onClick={onClick}
          />
        );
      }}
    </GraphqlResult>
  );
};

export default NotificationListQuery;
