/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { fibonacci } from 'src/utils/math';
import {
  BorderlessButton,
  InvertedButton,
  MinorMajorButtonSet,
  GlobalStyleVariables,
} from 'src/styles';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { Blocker } from 'react-router-dom';

const Container = styled.div`
  overflow: hidden;
`;

const StyledButtonSet = styled(MinorMajorButtonSet)`
  margin-top: ${fibonacci(3)}rem;
  min-width: ${fibonacci(8)}rem;
`;

interface ModalResolution {
  resolve: (accepted: boolean) => void;
}

interface ModalAttrs {
  blocker?: Blocker;
  text: string;
  isAlert?: boolean;
}

interface ContextProps {
  closeModal: () => void;
  modalAttrs: ModalAttrs;
  modalIsOpen: boolean;
  modalResolution?: ModalResolution;
  openModal: (
    text: string,
    blocker?: Blocker,
    isAlert?: boolean
  ) => Promise<boolean>;
}

const ConfirmationModalContext = createContext<Partial<ContextProps>>({});

export const useConfirmationModal = (): ((
  text: string,
  blocker?: Blocker,
  isAlert?: boolean
) => Promise<boolean>) => {
  const context = useContext(ConfirmationModalContext);

  return (
    text: string,
    blocker?: Blocker,
    isAlert?: boolean
  ): Promise<boolean> => {
    return context.openModal
      ? context.openModal(text, blocker, isAlert)
      : new Promise((resolve): void => resolve(false));
  };
};

function useConfirmationModalProvider(): ContextProps {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalAttrs, setModalAttrs] = useState<ModalAttrs>({
    text: '',
    blocker: undefined,
    isAlert: false,
  });
  const [modalResolution, setModalResolution] = useState<
    ModalResolution | undefined
  >();
  const [modalPromise, setModalPromise] = useState<Promise<any> | undefined>();

  const closeModal = (): void => {
    setModalIsOpen(false);
    setModalPromise(undefined);
    setModalResolution(undefined);
    setModalAttrs({ text: '', blocker: undefined, isAlert: false });
  };

  const openModal = (
    text: string,
    blocker: Blocker | undefined,
    isAlert = false
  ): Promise<any> => {
    if (modalPromise) return modalPromise;

    const promise = new Promise((resolve): void => {
      setModalResolution({ resolve });
      setModalIsOpen(true);
      setModalAttrs({ text, blocker, isAlert });
    });
    setModalPromise(promise);
    return promise;
  };

  return {
    closeModal,
    modalAttrs,
    modalIsOpen,
    modalResolution,
    openModal,
  };
}

export const ConfirmationModalProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('general');
  const confirmationModal = useConfirmationModalProvider();
  const onReject = (): void => {
    if (confirmationModal) {
      const reset = confirmationModal.modalAttrs.blocker?.reset;
      if (reset) {
        reset();
      }
      if (confirmationModal.modalResolution) {
        confirmationModal.modalResolution.resolve(false);
      }
      confirmationModal.closeModal();
    }
  };
  const onResolve = (): void => {
    if (confirmationModal) {
      const proceed = confirmationModal.modalAttrs.blocker?.proceed;
      if (proceed) {
        proceed();
      }
      if (confirmationModal.modalResolution) {
        confirmationModal.modalResolution.resolve(true);
      }
      confirmationModal.closeModal();
    }
  };

  return (
    <ConfirmationModalContext.Provider value={confirmationModal}>
      <ReactModal
        ariaHideApp={process.env.NODE_ENV !== 'test'}
        isOpen={confirmationModal.modalIsOpen}
        contentLabel={t('confirm')}
        onRequestClose={onReject}
        closeTimeoutMS={GlobalStyleVariables.baseDuration}
        className="ConfirmationModal"
        overlayClassName="ConfirmationModalOverlay"
      >
        <Container>
          <>{confirmationModal.modalAttrs.text}</>
          <StyledButtonSet>
            <BorderlessButton onClick={onReject}>{t('no')}</BorderlessButton>
            <InvertedButton onClick={onResolve}>{t('yes')}</InvertedButton>
          </StyledButtonSet>
        </Container>
      </ReactModal>
      {children}
    </ConfirmationModalContext.Provider>
  );
};
