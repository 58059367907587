import { goldenRatioInverse } from 'src/utils/math';

interface ImageCoordintes {
  width: number;
  height: number;
  x: number;
  y: number;
}

const checkIfSideways = (rotation: number): boolean => {
  return rotation === 90 || rotation === 270;
};

export const canvasPadding = (window.innerHeight / 100) * goldenRatioInverse;

export const getImageCoordinates = (
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  rotation: number
): ImageCoordintes => {
  // taken from:
  // https://sadique.io/blog/2013/10/03/fitting-an-image-in-to-a-canvas-object/
  const isSideways = checkIfSideways(rotation);
  const paddedCanvasWidth = canvas.width - canvasPadding * 2;
  const paddedCanvasHeight = canvas.height - canvasPadding * 2;
  const imageAspectRatio = isSideways
    ? image.height / image.width
    : image.width / image.height;
  const canvasAspectRatio = paddedCanvasWidth / paddedCanvasHeight;
  let height, width;

  if (imageAspectRatio < canvasAspectRatio) {
    height = paddedCanvasHeight;
    width = image.width * (height / image.height);
  } else if (imageAspectRatio > canvasAspectRatio) {
    width = paddedCanvasWidth;
    height = image.height * (width / image.width);
  } else {
    height = paddedCanvasHeight;
    width = paddedCanvasWidth;
  }

  if (isSideways) {
    if (height < width) {
      width = height;
      height = height * (image.height / image.width);
    } else {
      height = width;
      width = width * (image.width / image.height);
    }
  }

  return { width, height, x: -width / 2, y: -height / 2 };
};

const drawCropLine = (
  context: CanvasRenderingContext2D,
  points: number[][]
): void => {
  context.beginPath();
  context.moveTo(points[0][0], points[0][1]);
  points.slice(1).forEach((point) => context.lineTo(point[0], point[1]));
  context.stroke();
};

const drawHorizontalCropLine = (
  context: CanvasRenderingContext2D,
  cropLineCoordinates: ImageCoordintes,
  total: number,
  iteration: number
): void => {
  drawCropLine(context, [
    [
      cropLineCoordinates.x,
      cropLineCoordinates.y + (cropLineCoordinates.height / total) * iteration,
    ],
    [
      cropLineCoordinates.x + cropLineCoordinates.width,
      cropLineCoordinates.y + (cropLineCoordinates.height / total) * iteration,
    ],
  ]);
};

const drawVerticalCropLine = (
  context: CanvasRenderingContext2D,
  cropLineCoordinates: ImageCoordintes,
  total: number,
  iteration: number
): void => {
  drawCropLine(context, [
    [
      cropLineCoordinates.x + (cropLineCoordinates.width / total) * iteration,
      cropLineCoordinates.y,
    ],
    [
      cropLineCoordinates.x + (cropLineCoordinates.width / total) * iteration,
      cropLineCoordinates.y + cropLineCoordinates.height,
    ],
  ]);
};

const getHandleLength = (canvas: HTMLCanvasElement): number => {
  return canvas.clientHeight / 12;
};

const getCropLineCoordinates = (
  cropCoordinates: number[],
  imageCoordinates: ImageCoordintes
): ImageCoordintes => {
  const startX =
    imageCoordinates.x + cropCoordinates[0] * imageCoordinates.width;
  const startY =
    imageCoordinates.y + cropCoordinates[1] * imageCoordinates.height;

  return {
    x: startX,
    y: startY,
    width:
      imageCoordinates.x + cropCoordinates[2] * imageCoordinates.width - startX,
    height:
      imageCoordinates.y +
      cropCoordinates[3] * imageCoordinates.height -
      startY,
  };
};

const drawCropHandles = (
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  cropLineCoordinates: ImageCoordintes
): void => {
  context.lineWidth = canvasPadding;
  context.strokeStyle = `rgba(225, 225, 225, 1)`;
  const handleLength = getHandleLength(canvas);
  const halfWidth = context.lineWidth / 2;
  const nw = cropLineCoordinates.width < 0 ? -1 : 1;
  const nh = cropLineCoordinates.height < 0 ? -1 : 1;
  const lengthByWidthPos =
    (Math.min(handleLength, Math.abs(cropLineCoordinates.width)) + halfWidth) *
    nw;
  const lengthByHeightPos =
    (Math.min(handleLength, Math.abs(cropLineCoordinates.height)) + halfWidth) *
    nh;
  const lengthByWidthNeg =
    (Math.min(handleLength, Math.abs(cropLineCoordinates.width)) - halfWidth) *
    nw;
  const lengthByHeightNeg =
    (Math.min(handleLength, Math.abs(cropLineCoordinates.height)) - halfWidth) *
    nh;
  const widthByWidth = halfWidth * nw;
  const widthByHeight = halfWidth * nh;

  drawCropLine(context, [
    [
      cropLineCoordinates.x + lengthByWidthNeg,
      cropLineCoordinates.y - widthByHeight,
    ],
    [
      cropLineCoordinates.x - widthByWidth,
      cropLineCoordinates.y - widthByHeight,
    ],
    [
      cropLineCoordinates.x - widthByWidth,
      cropLineCoordinates.y + lengthByHeightNeg,
    ],
  ]);

  drawCropLine(context, [
    [
      cropLineCoordinates.width + cropLineCoordinates.x - lengthByWidthPos,
      cropLineCoordinates.y - widthByHeight,
    ],
    [
      cropLineCoordinates.width + cropLineCoordinates.x + widthByWidth,
      cropLineCoordinates.y - widthByHeight,
    ],
    [
      cropLineCoordinates.width + cropLineCoordinates.x + widthByWidth,
      cropLineCoordinates.y + lengthByHeightNeg,
    ],
  ]);

  drawCropLine(context, [
    [
      cropLineCoordinates.x - widthByWidth,
      cropLineCoordinates.height + cropLineCoordinates.y - lengthByHeightPos,
    ],
    [
      cropLineCoordinates.x - widthByWidth,
      cropLineCoordinates.height + cropLineCoordinates.y + widthByHeight,
    ],
    [
      cropLineCoordinates.x + lengthByWidthNeg,
      cropLineCoordinates.height + cropLineCoordinates.y + widthByHeight,
    ],
  ]);

  drawCropLine(context, [
    [
      cropLineCoordinates.width + cropLineCoordinates.x - lengthByWidthPos,
      cropLineCoordinates.height + cropLineCoordinates.y + widthByHeight,
    ],
    [
      cropLineCoordinates.width + cropLineCoordinates.x + widthByWidth,
      cropLineCoordinates.height + cropLineCoordinates.y + widthByHeight,
    ],
    [
      cropLineCoordinates.width + cropLineCoordinates.x + widthByWidth,
      cropLineCoordinates.height + cropLineCoordinates.y - lengthByHeightPos,
    ],
  ]);
};

export const drawGrid = (
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  cropCoordinates: number[],
  imageCoordinates: ImageCoordintes
): void => {
  const cropLineCoordinates = getCropLineCoordinates(
    cropCoordinates,
    imageCoordinates
  );

  context.lineWidth =
    (window.innerHeight / 100) * Math.pow(goldenRatioInverse, 3);
  context.strokeStyle = `rgba(225, 225, 225, ${Math.pow(
    goldenRatioInverse,
    4
  )})`;

  context.strokeRect(
    cropLineCoordinates.x,
    cropLineCoordinates.y,
    cropLineCoordinates.width,
    cropLineCoordinates.height
  );

  const gridLength = 3;

  [...Array(gridLength - 1)].forEach((_, index) => {
    drawHorizontalCropLine(context, cropLineCoordinates, gridLength, index + 1);
    drawVerticalCropLine(context, cropLineCoordinates, gridLength, index + 1);
  });

  drawCropHandles(canvas, context, cropLineCoordinates);
};

const isPointInPath = (
  point: number[],
  path: number[],
  context: CanvasRenderingContext2D
): boolean => {
  const handle = new Path2D();
  handle.rect(path[0], path[1], path[2], path[3]);
  context.fillStyle = 'transparent';
  context.fill(handle);
  return context.isPointInPath(handle, point[0], point[1]);
};

const getOffsetCoordinates = (
  coordinates: number[],
  canvas: HTMLCanvasElement,
  imageCoordinates: ImageCoordintes
): number[] => {
  return [
    coordinates[0] - (canvas.width - imageCoordinates.width) / 2,
    coordinates[1] - (canvas.height - imageCoordinates.height) / 2,
  ];
};

const setCropCoordinatesWithConstraints = (
  setCropCoordinates: (cropCoordinates: number[]) => void,
  cropCoordinates: number[],
  newCropCoordinates: number[],
  allowRatioChange?: boolean
): void => {
  let startX = Math.min(1, Math.max(0, newCropCoordinates[0]));
  let startY = Math.min(1, Math.max(0, newCropCoordinates[1]));
  let endX = Math.min(1, Math.max(0, newCropCoordinates[2]));
  let endY = Math.min(1, Math.max(0, newCropCoordinates[3]));

  if (startX > endX) {
    startX = cropCoordinates[0];
    endX = cropCoordinates[2];
  }

  if (startY > endY) {
    startY = cropCoordinates[1];
    endY = cropCoordinates[3];
  }

  if (!allowRatioChange) {
    if (
      newCropCoordinates[0] < 0 &&
      newCropCoordinates[2] !== cropCoordinates[2]
    ) {
      const diff = cropCoordinates[0];
      endX = Math.min(1, cropCoordinates[2] + diff);
    }

    if (
      newCropCoordinates[1] < 0 &&
      newCropCoordinates[3] !== cropCoordinates[3]
    ) {
      const diff = cropCoordinates[1];
      endY = Math.min(1, cropCoordinates[3] + diff);
    }

    if (
      newCropCoordinates[2] > 1 &&
      newCropCoordinates[0] !== cropCoordinates[0]
    ) {
      const diff = 1 - cropCoordinates[2];
      startX = Math.max(0, cropCoordinates[0] - diff);
    }

    if (
      newCropCoordinates[3] > 1 &&
      newCropCoordinates[1] !== cropCoordinates[1]
    ) {
      const diff = 1 - cropCoordinates[3];
      startY = Math.max(0, cropCoordinates[1] - diff);
    }
  }

  setCropCoordinates([startX, startY, endX, endY]);
};

const getDiffX = (
  point: number,
  startPoint: number,
  isHorizontallyFlipped: boolean
): number => {
  return isHorizontallyFlipped ? startPoint - point : point - startPoint;
};

const getDiffY = (point: number, startPoint: number): number => {
  return point - startPoint;
};

const getFlippedDiffX = (
  point: number,
  startPoint: number,
  isHorizontallyFlipped: boolean
): number => {
  return isHorizontallyFlipped ? point - startPoint : startPoint - point;
};

const getFlippedDiffY = (point: number, startPoint: number): number => {
  return startPoint - point;
};

const getRotatedDiffs = (
  points: number[],
  startPoints: number[],
  rotation: number,
  isHorizontallyFlipped: boolean
): number[] => {
  if (rotation === 90) {
    if (isHorizontallyFlipped) {
      return [
        getFlippedDiffY(points[1], startPoints[1]),
        getDiffX(points[0], startPoints[0], isHorizontallyFlipped),
      ];
    } else {
      return [
        getDiffY(points[1], startPoints[1]),
        getFlippedDiffX(points[0], startPoints[0], isHorizontallyFlipped),
      ];
    }
  } else if (rotation === 180) {
    return [
      getFlippedDiffX(points[0], startPoints[0], isHorizontallyFlipped),
      getFlippedDiffY(points[1], startPoints[1]),
    ];
  } else if (rotation === 270) {
    if (isHorizontallyFlipped) {
      return [
        getDiffY(points[1], startPoints[1]),
        getFlippedDiffX(points[0], startPoints[0], isHorizontallyFlipped),
      ];
    } else {
      return [
        getFlippedDiffY(points[1], startPoints[1]),
        getDiffX(points[0], startPoints[0], isHorizontallyFlipped),
      ];
    }
  } else {
    return [
      getDiffX(points[0], startPoints[0], isHorizontallyFlipped),
      getDiffY(points[1], startPoints[1]),
    ];
  }
};

const getIdealPlacement = (idealPlacement: number[]): number[] => {
  if (idealPlacement[0] < 0) {
    const diff = Math.abs(idealPlacement[0]);
    return [0, idealPlacement[1] + diff];
  } else if (idealPlacement[1] > 1) {
    const diff = idealPlacement[1] - 1;
    return [idealPlacement[0] - diff, 1];
  } else {
    return idealPlacement;
  }
};

const getYPlacement = (
  cropCoordinates: number[],
  newHeightRatio: number
): number[] => {
  const oldHeightRatio = cropCoordinates[3] - cropCoordinates[1];
  const halfRatioDiff = (newHeightRatio - oldHeightRatio) / 2;

  return getIdealPlacement([
    cropCoordinates[1] - halfRatioDiff,
    cropCoordinates[3] + halfRatioDiff,
  ]);
};

const getXPlacement = (
  cropCoordinates: number[],
  newWidthRatio: number
): number[] => {
  const oldWidthRatio = cropCoordinates[2] - cropCoordinates[0];
  const halfRatioDiff = (newWidthRatio - oldWidthRatio) / 2;

  return getIdealPlacement([
    cropCoordinates[0] - halfRatioDiff,
    cropCoordinates[2] + halfRatioDiff,
  ]);
};

const getCoordinatesXShape = (
  cropCoordinates: number[],
  ratio: number
): number[] => {
  const yPlacement = getYPlacement(cropCoordinates, ratio);

  return [cropCoordinates[0], yPlacement[0], cropCoordinates[2], yPlacement[1]];
};

const getCoordinatesYShape = (
  cropCoordinates: number[],
  ratio: number
): number[] => {
  const xPlacement = getXPlacement(cropCoordinates, ratio);

  return [xPlacement[0], cropCoordinates[1], xPlacement[1], cropCoordinates[3]];
};

export const getOriginalShapreCoordinates = (
  cropCoordinates: number[]
): number[] => {
  const widthRatio = cropCoordinates[2] - cropCoordinates[0];

  return getCoordinatesXShape(cropCoordinates, widthRatio);
};

export const getSquareCoordinates = (
  cropCoordinates: number[],
  imageCoordinates: ImageCoordintes
): number[] => {
  const widthRatio = cropCoordinates[2] - cropCoordinates[0];
  const heightRatio = cropCoordinates[3] - cropCoordinates[1];
  const derivedYRatio =
    widthRatio / (imageCoordinates.height / imageCoordinates.width);
  const derivedXRatio =
    heightRatio / (imageCoordinates.width / imageCoordinates.height);
  const yPlacement = getYPlacement(cropCoordinates, derivedYRatio);

  if (yPlacement[0] < 0 || yPlacement[1] > 1) {
    return getCoordinatesYShape(cropCoordinates, derivedXRatio);
  } else {
    return getCoordinatesXShape(cropCoordinates, derivedYRatio);
  }
};

const canvasTouchMove = (
  corner: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'none',
  canvas: HTMLCanvasElement,
  rotation: number,
  isHorizontallyFlipped: boolean,
  imageCoordinates: ImageCoordintes,
  cropCoordinates: number[],
  setCropCoordinates: (cropCoordinates: number[]) => void,
  startCropCoordinates: number[],
  startCoordinates: number[],
  event: TouchEvent,
  forceRatio?: number
): void => {
  const points = getOffsetCoordinates(
    [event.targetTouches[0].clientX, event.targetTouches[0].clientY],
    canvas,
    imageCoordinates
  );

  canvasMove(
    corner,
    rotation,
    isHorizontallyFlipped,
    imageCoordinates,
    cropCoordinates,
    setCropCoordinates,
    startCropCoordinates,
    startCoordinates,
    points,
    forceRatio
  );
};

const canvasMouseMove = (
  corner: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'none',
  canvas: HTMLCanvasElement,
  rotation: number,
  isHorizontallyFlipped: boolean,
  imageCoordinates: ImageCoordintes,
  cropCoordinates: number[],
  setCropCoordinates: (cropCoordinates: number[]) => void,
  startCropCoordinates: number[],
  startCoordinates: number[],
  event: MouseEvent,
  forceRatio?: number
): void => {
  const points = getOffsetCoordinates(
    [event.x, event.y],
    canvas,
    imageCoordinates
  );

  canvasMove(
    corner,
    rotation,
    isHorizontallyFlipped,
    imageCoordinates,
    cropCoordinates,
    setCropCoordinates,
    startCropCoordinates,
    startCoordinates,
    points,
    forceRatio
  );
};

const canvasMove = (
  corner: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'none',
  rotation: number,
  isHorizontallyFlipped: boolean,
  imageCoordinates: ImageCoordintes,
  cropCoordinates: number[],
  setCropCoordinates: (cropCoordinates: number[]) => void,
  startCropCoordinates: number[],
  startCoordinates: number[],
  points: number[],
  forceRatio?: number
): void => {
  const [xDiff, yDiff] = getRotatedDiffs(
    points,
    startCoordinates,
    rotation,
    isHorizontallyFlipped
  );

  if (corner === 'none') {
    setCropCoordinatesWithConstraints(setCropCoordinates, cropCoordinates, [
      xDiff / imageCoordinates.width + startCropCoordinates[0],
      yDiff / imageCoordinates.height + startCropCoordinates[1],
      xDiff / imageCoordinates.width + startCropCoordinates[2],
      yDiff / imageCoordinates.height + startCropCoordinates[3],
    ]);
  } else if (forceRatio === 1) {
    const xRatio = xDiff / imageCoordinates.height;
    const yRatio = yDiff / imageCoordinates.width;
    const ratio =
      corner === 'topRight' || corner === 'bottomLeft'
        ? (xRatio - yRatio) / 2
        : (xRatio + yRatio) / 2;
    const startX = Math.max(
      0,
      startCropCoordinates[0] +
        (corner === 'topLeft' || corner === 'bottomLeft' ? ratio : -ratio)
    );
    const startY = Math.max(
      0,
      startCropCoordinates[1] +
        (corner === 'topLeft' || corner === 'bottomLeft' ? ratio : -ratio)
    );
    const endX = Math.min(
      1,
      startCropCoordinates[2] +
        (corner === 'topRight' || corner === 'bottomRight' ? ratio : -ratio)
    );
    const endY = Math.min(
      1,
      startCropCoordinates[3] +
        (corner === 'topRight' || corner === 'bottomRight' ? ratio : -ratio)
    );
    const squareCoordinates = getSquareCoordinates(
      [startX, startY, endX, endY],
      imageCoordinates
    );

    setCropCoordinatesWithConstraints(
      setCropCoordinates,
      cropCoordinates,
      squareCoordinates
    );
  } else if (corner === 'topLeft') {
    setCropCoordinatesWithConstraints(setCropCoordinates, cropCoordinates, [
      xDiff / imageCoordinates.width + startCropCoordinates[0],
      yDiff / imageCoordinates.height + startCropCoordinates[1],
      cropCoordinates[2],
      cropCoordinates[3],
    ]);
  } else if (corner === 'topRight') {
    setCropCoordinatesWithConstraints(setCropCoordinates, cropCoordinates, [
      cropCoordinates[0],
      yDiff / imageCoordinates.height + startCropCoordinates[1],
      xDiff / imageCoordinates.width + startCropCoordinates[2],
      cropCoordinates[3],
    ]);
  } else if (corner === 'bottomLeft') {
    setCropCoordinatesWithConstraints(setCropCoordinates, cropCoordinates, [
      xDiff / imageCoordinates.width + startCropCoordinates[0],
      cropCoordinates[1],
      cropCoordinates[2],
      yDiff / imageCoordinates.height + startCropCoordinates[3],
    ]);
  } else if (corner === 'bottomRight') {
    setCropCoordinatesWithConstraints(setCropCoordinates, cropCoordinates, [
      cropCoordinates[0],
      cropCoordinates[1],
      xDiff / imageCoordinates.width + startCropCoordinates[2],
      yDiff / imageCoordinates.height + startCropCoordinates[3],
    ]);
  }
};

const bindMouseMove = (
  corner: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'none',
  canvas: HTMLCanvasElement,
  rotation: number,
  isHorizontallyFlipped: boolean,
  imageCoordinates: ImageCoordintes,
  cropCoordinates: number[],
  setCropCoordinates: (cropCoordinates: number[]) => void,
  clientX: number,
  clientY: number,
  forceRatio?: number
): void => {
  const startCropCoordinates = [...cropCoordinates];
  const boundCanvasMouseMove = (moveEvent: MouseEvent): void => {
    canvasMouseMove(
      corner,
      canvas,
      rotation,
      isHorizontallyFlipped,
      imageCoordinates,
      cropCoordinates,
      setCropCoordinates,
      startCropCoordinates,
      getOffsetCoordinates([clientX, clientY], canvas, imageCoordinates),
      moveEvent,
      forceRatio
    );
  };
  const boundRemove = (): void => {
    canvas.removeEventListener('mousemove', boundCanvasMouseMove);
    canvas.removeEventListener('mouseup', boundRemove);
    canvas.removeEventListener('mouseout', boundRemove);
  };

  canvas.addEventListener('mousemove', boundCanvasMouseMove);
  canvas.addEventListener('mouseup', boundRemove);
  canvas.addEventListener('mouseout', boundRemove);
};

const bindTouchMove = (
  corner: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'none',
  canvas: HTMLCanvasElement,
  rotation: number,
  isHorizontallyFlipped: boolean,
  imageCoordinates: ImageCoordintes,
  cropCoordinates: number[],
  setCropCoordinates: (cropCoordinates: number[]) => void,
  clientX: number,
  clientY: number,
  forceRatio?: number
): void => {
  const startCropCoordinates = [...cropCoordinates];
  const boundCanvasTouchMove = (moveEvent: TouchEvent): void => {
    canvasTouchMove(
      corner,
      canvas,
      rotation,
      isHorizontallyFlipped,
      imageCoordinates,
      cropCoordinates,
      setCropCoordinates,
      startCropCoordinates,
      getOffsetCoordinates([clientX, clientY], canvas, imageCoordinates),
      moveEvent,
      forceRatio
    );
  };
  const boundRemove = (): void => {
    canvas.removeEventListener('touchmove', boundCanvasTouchMove);
    canvas.removeEventListener('touchcancel', boundRemove);
    canvas.removeEventListener('touchend', boundRemove);
  };

  canvas.addEventListener('touchmove', boundCanvasTouchMove);
  canvas.addEventListener('touchcancel', boundRemove);
  canvas.addEventListener('touchend', boundRemove);
};

export const canvasStartEvent = (
  container: HTMLDivElement,
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  image: HTMLImageElement,
  rotation: number,
  isHorizontallyFlipped: boolean,
  cropCoordinates: number[],
  setCropCoordinates: (cropCoordinates: number[]) => void,
  clientX: number,
  clientY: number,
  bindMove: (
    corner: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'none',
    canvas: HTMLCanvasElement,
    rotation: number,
    isHorizontallyFlipped: boolean,
    imageCoordinates: ImageCoordintes,
    cropCoordinates: number[],
    setCropCoordinates: (cropCoordinates: number[]) => void,
    clientX: number,
    clientY: number,
    forceRatio?: number
  ) => void,
  forceRatio?: number
): void => {
  const handleLength = getHandleLength(canvas);
  const doubleHandle = handleLength * 2;
  const quadruapleHandle = handleLength * 4;
  const bodyBoundingRect = container.getBoundingClientRect();
  const canvasBoundingRect = canvas.getBoundingClientRect();
  const canvasOffsetTop = canvasBoundingRect.top - bodyBoundingRect.top;
  const canvasOffsetLeft = canvasBoundingRect.left - bodyBoundingRect.left;
  const point = [clientX - canvasOffsetLeft, clientY - canvasOffsetTop];
  const imageCoordinates = getImageCoordinates(canvas, image, rotation);
  const isSideways = checkIfSideways(rotation);
  const xOffset = (isSideways ? canvas.height : canvas.width) / 2;
  const yOffset = (isSideways ? canvas.width : canvas.height) / 2;
  const cropLineCoordinates = getCropLineCoordinates(cropCoordinates, {
    ...imageCoordinates,
    x: imageCoordinates.x + xOffset,
    y: imageCoordinates.y + yOffset,
  });

  if (
    isPointInPath(
      point,
      [
        cropLineCoordinates.x - doubleHandle - xOffset,
        cropLineCoordinates.y - doubleHandle - yOffset,
        quadruapleHandle,
        quadruapleHandle,
      ],
      context
    )
  ) {
    bindMove(
      'topLeft',
      canvas,
      rotation,
      isHorizontallyFlipped,
      imageCoordinates,
      cropCoordinates,
      setCropCoordinates,
      clientX,
      clientY,
      forceRatio
    );
  } else if (
    isPointInPath(
      point,
      [
        cropLineCoordinates.x +
          cropLineCoordinates.width -
          doubleHandle -
          xOffset,
        cropLineCoordinates.y - doubleHandle - yOffset,
        quadruapleHandle,
        quadruapleHandle,
      ],
      context
    )
  ) {
    bindMove(
      'topRight',
      canvas,
      rotation,
      isHorizontallyFlipped,
      imageCoordinates,
      cropCoordinates,
      setCropCoordinates,
      clientX,
      clientY,
      forceRatio
    );
  } else if (
    isPointInPath(
      point,
      [
        cropLineCoordinates.x - doubleHandle - xOffset,
        cropLineCoordinates.y +
          cropLineCoordinates.height -
          doubleHandle -
          yOffset,
        quadruapleHandle,
        quadruapleHandle,
      ],
      context
    )
  ) {
    bindMove(
      'bottomLeft',
      canvas,
      rotation,
      isHorizontallyFlipped,
      imageCoordinates,
      cropCoordinates,
      setCropCoordinates,
      clientX,
      clientY,
      forceRatio
    );
  } else if (
    isPointInPath(
      point,
      [
        cropLineCoordinates.x +
          cropLineCoordinates.width -
          doubleHandle -
          xOffset,
        cropLineCoordinates.y +
          cropLineCoordinates.height -
          doubleHandle -
          yOffset,
        quadruapleHandle,
        quadruapleHandle,
      ],
      context
    )
  ) {
    bindMove(
      'bottomRight',
      canvas,
      rotation,
      isHorizontallyFlipped,
      imageCoordinates,
      cropCoordinates,
      setCropCoordinates,
      clientX,
      clientY,
      forceRatio
    );
  } else {
    bindMove(
      'none',
      canvas,
      rotation,
      isHorizontallyFlipped,
      imageCoordinates,
      cropCoordinates,
      setCropCoordinates,
      clientX,
      clientY
    );
  }
};

export const canvasClick = (
  container: HTMLDivElement,
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  image: HTMLImageElement,
  rotation: number,
  isHorizontallyFlipped: boolean,
  cropCoordinates: number[],
  setCropCoordinates: (cropCoordinates: number[]) => void,
  event: MouseEvent,
  forceRatio?: number
): void => {
  canvasStartEvent(
    container,
    canvas,
    context,
    image,
    rotation,
    isHorizontallyFlipped,
    cropCoordinates,
    setCropCoordinates,
    event.clientX,
    event.clientY,
    bindMouseMove,
    forceRatio
  );
};

export const canvasTouch = (
  container: HTMLDivElement,
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  image: HTMLImageElement,
  rotation: number,
  isHorizontallyFlipped: boolean,
  cropCoordinates: number[],
  setCropCoordinates: (cropCoordinates: number[]) => void,
  event: TouchEvent,
  forceRatio?: number
): void => {
  if (event.touches.length > 1) return;

  event.preventDefault();

  canvasStartEvent(
    container,
    canvas,
    context,
    image,
    rotation,
    isHorizontallyFlipped,
    cropCoordinates,
    setCropCoordinates,
    event.targetTouches[0].clientX,
    event.targetTouches[0].clientY,
    bindTouchMove,
    forceRatio
  );
};

export const canvasWheel = (
  cropCoordinates: number[],
  setCropCoordinates: (cropCoordinates: number[]) => void,
  event: WheelEvent,
  forceRatio?: number
): void => {
  event.preventDefault();

  const step = 0.05;
  const xStep = (cropCoordinates[2] - cropCoordinates[0]) * step;
  const yStep = (cropCoordinates[3] - cropCoordinates[1]) * step;

  let position =
    event.deltaY > 0
      ? [
          cropCoordinates[0] - xStep,
          cropCoordinates[1] - yStep,
          cropCoordinates[2] + xStep,
          cropCoordinates[3] + yStep,
        ]
      : event.deltaY < -1
      ? [
          cropCoordinates[0] + xStep,
          cropCoordinates[1] + yStep,
          cropCoordinates[2] - xStep,
          cropCoordinates[3] - yStep,
        ]
      : cropCoordinates;

  if (forceRatio === 1 && position.some((p) => p < 0 || p > 1)) {
    position = cropCoordinates;
  }

  setCropCoordinatesWithConstraints(
    setCropCoordinates,
    cropCoordinates,
    position,
    forceRatio !== 1
  );
};
