import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'src/components/select';
import { GlobalStyleVariables } from 'src/styles';
import { goldenRatioInverse } from 'src/utils/math';

const StyledSelect = styled(Select)`
  position: sticky;
  top: ${GlobalStyleVariables.headerHeight * 2}rem;
  width: 100%;
  z-index: 9;
  flex-grow: 0;

  > div {
    left: -${1 * Math.pow(goldenRatioInverse, 2)}rem;
  }
`;

interface InterfaceProps {
  isPost?: boolean;
}

interface SelectType {
  label: string;
  value: string;
}

const SearchSortView: React.FC<InterfaceProps> = ({ isPost }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('general');
  const queryState = (location.state || {}) as { [key: string]: string };

  function generateSelectType(type: string): SelectType {
    return { label: t(type), value: type };
  }

  const baseOrderTypes = ['orderPopular', 'orderTrending', 'orderNew'];
  const types = isPost
    ? [...baseOrderTypes, 'orderFeatured', 'orderAffordable']
    : baseOrderTypes;
  const options = types.map(generateSelectType);

  function generateStateChangeFunction(): (value: string) => void {
    return function onStateChange(value): void {
      queryState.orderType = value;

      navigate(`${location.pathname}${location.search}`, {
        state: queryState,
        replace: true,
      });
    };
  }

  return (
    <StyledSelect
      options={options}
      value={queryState.orderType || options[0].value}
      onChange={generateStateChangeFunction()}
    />
  );
};

export default SearchSortView;
