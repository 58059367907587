import { useEffect, useState, useRef } from 'react';
import { isMobile } from 'src/utils/detection';

export function useOnScreen(element: HTMLElement | null): boolean {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setIsOnScreen(entry.isIntersecting);
      },
      {
        threshold: isMobile() ? [0, 1] : [1],
      }
    );
  }, []);

  useEffect(() => {
    if (element) {
      observerRef.current?.observe(element);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [element, observerRef.current]);

  return isOnScreen;
}
