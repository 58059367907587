import React from 'react';
import { Field } from 'formik';
import { DeconstructedSelect } from 'src/components/deconstructed-select';
import { GenericOptionItem } from 'src/types';

interface InterfaceProps {
  canDeselect?: boolean;
  className?: string;
  disabled?: boolean;
  name: string;
  options: GenericOptionItem[];
  onSelect?: (option?: GenericOptionItem) => void;
}

const SimpleDeconstructedSelectView: React.FC<InterfaceProps> = ({
  canDeselect,
  className,
  disabled,
  options,
  name,
  onSelect,
}) => {
  return (
    <Field
      canDeselect={canDeselect}
      className={className}
      component={DeconstructedSelect}
      disabled={disabled}
      optionName={name}
      options={options}
      onSelect={onSelect}
    />
  );
};

export default SimpleDeconstructedSelectView;
