/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { MediaItem } from 'src/types';
import MediaItemElement from 'src/components/media-item-element';

interface InterfaceProps {
  canClickToPlay?: boolean;
  isVisible: boolean;
  mediaItem: MediaItem;
  noContentText?: string;
  shouldMute?: boolean;
  shouldPlayOnView?: boolean;
  noContentClickCb?: (_: any, { setSubmitting }: any) => Promise<void>;
}

const MediaItemCardView: React.FC<InterfaceProps> = ({
  canClickToPlay,
  isVisible,
  mediaItem,
  noContentText,
  shouldMute,
  shouldPlayOnView,
  noContentClickCb,
}) => {
  return (
    <div>
      <MediaItemElement
        canClickToPlay={canClickToPlay}
        mediaItem={mediaItem}
        isVisible={isVisible}
        noContentText={noContentText}
        noContentClickCb={noContentClickCb}
        shouldMute={shouldMute}
        shouldPlayOnView={shouldPlayOnView}
      />
    </div>
  );
};

export default MediaItemCardView;
