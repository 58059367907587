import React, { ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Post, PostAccessType, PostEditInput } from 'src/types';
import { useAuth } from 'src/hooks/use-auth';
import LoadingSpinner from 'src/components/loading-spinner';
import PostEditInnerForm from './post-edit-inner-form';
import { convertPostToPostEditInput } from 'src/utils/converters';
import { toast } from 'react-toastify';

interface InterfaceProps {
  className?: string;
  post: Post;
  cancel: () => void;
  update: (variables: PostEditInput) => Promise<void>;
}

const PostForm: React.FC<InterfaceProps> = ({
  className,
  post,
  cancel,
  update,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const [postInput, setPostInput] = useState<PostEditInput>();

  useEffect(() => {
    const convert = async () => {
      try {
        setPostInput(await convertPostToPostEditInput(post));
      } catch {
        toast(t('error'));
        cancel();
      }
    };

    convert();
  }, [post]);

  if (!user) {
    return <LoadingSpinner />;
  } else {
    const postSchema = yup.object().shape({
      description: yup.string().max(666, t('errorTooLong', { length: 666 })),
      title: yup.string().max(69, t('errorTooLong', { length: 69 })),
      price: yup.number().when('accessType', {
        is: (at: PostAccessType) =>
          at === PostAccessType['post-access-type-pay'],
        then: (schema) =>
          schema
            .required()
            .min(5, t('errorAtLeastDollarAmount', { amount: 5 })),
      }),
    });

    const onSubmit = async (
      variables: PostEditInput,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { setSubmitting }: any
    ): Promise<void> => {
      try {
        variables.price = variables.price?.toString();
        await update(variables);
      } catch (e) {
        console.log('error', e as Error);
      } finally {
        setSubmitting(false);
      }
    };

    if (postInput) {
      return (
        <Formik
          initialValues={postInput}
          onSubmit={onSubmit}
          validationSchema={postSchema}
        >
          {({ dirty, isSubmitting, isValid }): ReactElement => {
            return (
              <PostEditInnerForm
                className={className}
                dirty={dirty}
                isSubmitting={isSubmitting}
                isValid={isValid}
                post={post}
                cancel={cancel}
              />
            );
          }}
        </Formik>
      );
    } else {
      return <LoadingSpinner />;
    }
  }
};

export default PostForm;
