import React from 'react';

interface InterfaceProps {
  className?: string;
}

const Keyhole: React.FC<InterfaceProps> = ({ className }) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        fill="currentColor"
        d="M48.318,24.159C48.318,10.816,37.503,0,24.159,0C10.816,0,0,10.816,0,24.159c0,10.214,6.349,18.93,15.309,22.463L1.282,100h19.928h5.898h19.93L33.011,46.622c8.96-3.533,15.307-12.248,15.307-22.463Z"
        transform="translate(25.841 0)"
      />
    </svg>
  );
};

export default Keyhole;
