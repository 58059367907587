import React, { ReactElement, useEffect, useState } from 'react';
import { CuratedList } from 'src/types';
import * as yup from 'yup';
import { StyledButton, ButtonSet, DeemphasizedButton } from 'src/styles';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import { UPDATE_CURATED_LIST_MUTATION } from 'src/queries';
import { SimpleInput, SimpleTextarea } from 'src/components/simple-form';
import { useBlocker } from 'react-router-dom';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';

interface CuratedListValues {
  curatedListId: string;
  description?: string;
  name: string;
}

interface InterfaceProps {
  curatedList: CuratedList;
  toggleForm: () => void;
}

const CuratedListSummaryForm: React.FC<InterfaceProps> = ({
  curatedList,
  toggleForm,
}) => {
  const { t } = useTranslation('general');
  const [updateCuratedListMutation] = useMutation(
    UPDATE_CURATED_LIST_MUTATION,
    {
      variables: {
        curatedListId: curatedList.id,
      } as CuratedListValues,
    }
  );
  const [isDirty, setIsDirty] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return currentLocation.pathname !== nextLocation.pathname && isDirty;
  });
  const confirm = useConfirmationModal();
  const cancelWrapper = async () => {
    if (!isDirty || (await confirm(t('formCancel')))) {
      toggleForm();
    }
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm(t('formCancel'), blocker);
    }
  }, [blocker.state]);

  const curatedListSchema = yup.object().shape({
    name: yup.string().max(69, t('errorTooLong', { length: 69 })),
    description: yup.string().max(666, t('errorTooLong', { length: 666 })),
  });

  const onSubmit = async (
    variables: CuratedListValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { setSubmitting }: any
  ): Promise<void> => {
    try {
      await updateCuratedListMutation({
        variables,
      });
      toggleForm();
    } catch (e) {
      console.log('error', e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        curatedListId: curatedList.id,
        description: curatedList.description || '',
        name: curatedList.name,
      }}
      onSubmit={onSubmit}
      validationSchema={curatedListSchema}
    >
      {({ dirty, isSubmitting }): ReactElement => {
        setIsDirty(dirty);

        return (
          <Form>
            <SimpleInput
              autoFocus={true}
              label={t('curatedListsName')}
              name="name"
            />
            <SimpleTextarea
              label={t('curatedListsDescription')}
              name="description"
              placeholder={t('curatedListsDescriptionDetail')}
            />
            <ButtonSet>
              <DeemphasizedButton type="button" onClick={cancelWrapper}>
                {t('cancel')}
              </DeemphasizedButton>
              <StyledButton type="submit" disabled={isSubmitting}>
                {t('submit')}
              </StyledButton>
            </ButtonSet>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CuratedListSummaryForm;
