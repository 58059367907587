/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client';
import { CREATE_CURATED_LIST_MUTATION, CURRENT_USER_QUERY } from 'src/queries';
import { CuratedList, User } from 'src/types';
import { useEnsureUser } from 'src/hooks/use-ensure-user';

export function useCreateCuratedList(): (name: string) => Promise<CuratedList> {
  const ensureUser = useEnsureUser();
  const [createCuratedListMutation] = useMutation(
    CREATE_CURATED_LIST_MUTATION,
    {
      update(cache, { data: { createCuratedList } }) {
        const { currentUser } = cache.readQuery({
          query: CURRENT_USER_QUERY,
        }) as { currentUser: User };
        const curatorialMembership = {
          ...createCuratedList.curatorialMemberships[0],
        };
        curatorialMembership.curatedList = createCuratedList;
        const curatorialMemberships = currentUser.curatorialMemberships || [];
        cache.writeQuery({
          query: CURRENT_USER_QUERY,
          data: {
            currentUser: {
              ...currentUser,
              curatorialMemberships: curatorialMemberships.concat([
                curatorialMembership,
              ]),
            },
          },
        });
      },
    }
  );

  return async (name: string): Promise<CuratedList> => {
    if (!createCuratedListMutation)
      throw new Error("createCuratedList didn't load");

    await ensureUser();

    const {
      data: { createCuratedList },
    } = await createCuratedListMutation({
      variables: {
        name,
      },
    });

    return createCuratedList;
  };
}
