import React from 'react';
import { FeedItem } from 'src/types';
import PostCard from 'src/components/post-card';

interface InterfaceProps {
  feedItem: FeedItem;
}

const FeedItemPostItem: React.FC<InterfaceProps> = ({ feedItem }) => {
  return (
    <PostCard
      key={feedItem.id}
      post={feedItem.post}
      shouldMute={false}
      shouldPlayOnView={true}
      useCarousel={true}
    />
  );
};

export default FeedItemPostItem;
