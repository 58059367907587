import React from 'react';
import CuratedListList from 'src/components/curated-list-list';
import useUser from '../../use-user';

const UserListsListsRouteView: React.FC = () => {
  const { user } = useUser();

  return (
    <CuratedListList
      paginationArgs={{
        userId: user.id,
      }}
      useGridLayout={true}
    />
  );
};

export default UserListsListsRouteView;
