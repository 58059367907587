/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { QueryType, SearchVariables } from './header-search-bar';

interface InterfaceProps {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const HeaderSearchBarListener: React.FC<InterfaceProps> = ({
  setFieldValue,
}) => {
  const location = useLocation();

  const queryTypeValues = Object.values(QueryType);
  const defaultValues = {
    query: '',
    queryType: queryTypeValues[0],
  };

  function getValuesFromQueryParams(search: string): SearchVariables {
    const queryParams = new URLSearchParams(search);

    return {
      query: queryParams.get('query') || defaultValues.query,
      queryType: (queryParams.get('queryType') ||
        defaultValues.queryType) as QueryType,
    };
  }

  useEffect(() => {
    setTimeout(() => {
      const newValues = !location.pathname.startsWith('/search')
        ? defaultValues
        : getValuesFromQueryParams(location.search);

      Object.entries(newValues).forEach(([key, value]) => {
        setFieldValue(key, value);
      });
    });
  }, [location]);

  return <></>;
};

export default HeaderSearchBarListener;
