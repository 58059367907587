import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import NavBar from 'src/components/nav-bar';

const SearchRouteNav: React.FC = () => {
  const { t } = useTranslation('general');
  const location = useLocation();

  return (
    <NavBar>
      {[
        {
          to: { pathname: '', search: location.search },
          end: true,
          content: t('all'),
        },
        {
          to: { pathname: 'users', search: location.search },
          content: t('users'),
        },
        {
          to: { pathname: 'vids', search: location.search },
          content: t('vids'),
        },
        {
          to: { pathname: 'pics', search: location.search },
          content: t('pics'),
        },
        {
          to: { pathname: 'lists', search: location.search },
          content: t('lists'),
        },
      ]}
    </NavBar>
  );
};

export default SearchRouteNav;
