import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  MinorMajorButtonSet,
  StyledButton,
  StyledError,
  DeemphasizedButton,
} from 'src/styles';
import { useAuth } from 'src/hooks/use-auth';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import {
  SubscriptionDiscountInput,
  SubscriptionDiscountType,
  SubscriptionPackageInput,
} from 'src/types';
import { SimpleCheckbox, SimpleInput } from 'src/components/simple-form';
import LoadingSpinner from '../loading-spinner';
import { useManageSubscriptionPackages } from 'src/hooks/use-manage-subscription-packages';
import { useQuery } from '@apollo/client';
import { SUBSCRIPTION_DISCOUNTS_QUERY } from 'src/queries';
import GraphqlResult from '../graphql-result';

const StyledForm = styled(Form)`
  padding: ${fibonacci(3)}rem;
`;

const StyledButtonSet = styled(MinorMajorButtonSet)`
  margin-top: ${fibonacci(3)}rem;
`;

const PackageRow = styled.div`
  display: flex;
  align-items: center;
`;

interface TotalProps {
  disabled?: false;
}

const Total = styled.div.attrs((props: TotalProps) => ({
  disabled: props.disabled || false,
}))`
  padding: 1rem;
  margin-bottom: 2rem;

  ${(props): string | undefined => {
    if (props.disabled) {
      return `
        opacity: ${goldenRatioInverse};
      `;
    }
  }}
`;

interface InterfaceProps {
  onReject: () => void;
  onResolve: () => void;
}

const SubscriptionPackageFormView: React.FC<InterfaceProps> = ({
  onReject,
  onResolve,
}) => {
  const { t } = useTranslation('general');
  const handleGraphQLErrors = useGraphQLErrors();
  const [globalError, setGlobalError] = useState<string | null>(null);
  const { user } = useAuth();
  const manageSubscriptionPackages = useManageSubscriptionPackages();

  const twoMonthPackageResponse = useQuery(SUBSCRIPTION_DISCOUNTS_QUERY, {
    variables: {
      type: SubscriptionDiscountType.twoMonthPackage,
      isActive: true,
      userId: user?.id,
    },
  });
  const threeMonthPackageResponse = useQuery(SUBSCRIPTION_DISCOUNTS_QUERY, {
    variables: {
      type: SubscriptionDiscountType.threeMonthPackage,
      isActive: true,
      userId: user?.id,
    },
  });

  if (!user) {
    return <LoadingSpinner />;
  } else {
    return (
      <GraphqlResult response={twoMonthPackageResponse}>
        {(twoMonthPackageData): ReactElement => {
          return (
            <GraphqlResult response={threeMonthPackageResponse}>
              {(threeMonthPackageData): ReactElement => {
                const twoMonthPackage = twoMonthPackageData
                  ? twoMonthPackageData.subscriptionDiscounts[0]
                  : null;
                const threeMonthPackage = threeMonthPackageData
                  ? threeMonthPackageData.subscriptionDiscounts[0]
                  : null;
                const packagesSchema = yup.object().shape({
                  subscriptionPrice: yup
                    .number()
                    .required(t('errorRequired'))
                    .min(5, t('errorAtLeastDollarAmount', { amount: 5 }))
                    .max(69, t('errorAtMostDollarAmount', { amount: 69 })),
                  twoMonthPackage: yup.object().shape({
                    percentage: yup
                      .number()
                      .integer(t('errorInteger'))
                      .required(t('errorRequired'))
                      .min(69, t('errorAtLeastAmount', { amount: 69 }))
                      .max(100, t('errorAtMostAmount', { amount: 100 })),
                  }),
                  threeMonthPackage: yup.object().shape({
                    percentage: yup
                      .number()
                      .integer(t('errorInteger'))
                      .required(t('errorRequired'))
                      .min(69, t('errorAtLeastAmount', { amount: 69 }))
                      .max(100, t('errorAtMostAmount', { amount: 100 })),
                  }),
                });

                const onSubmit = async (
                  values: SubscriptionPackageInput,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  { setSubmitting }: any
                ): Promise<void> => {
                  try {
                    if (!manageSubscriptionPackages) return;

                    setGlobalError(null);

                    await manageSubscriptionPackages(values);

                    onResolve();
                  } catch (e) {
                    handleGraphQLErrors(e as Error, (message: string) => {
                      setGlobalError(message);
                    });
                  } finally {
                    setSubmitting(false);
                  }
                };

                const generatePackage = (
                  type: SubscriptionDiscountType
                ): SubscriptionDiscountInput => {
                  return {
                    type,
                    percentage: 100,
                  };
                };

                return (
                  <Formik
                    initialValues={{
                      subscriptionPrice: (user.subscriptionPrice
                        ? parseFloat(user.subscriptionPrice.toString())
                        : 5
                      ).toFixed(2) as never,
                      twoMonthPackageEnabled: !!twoMonthPackage,
                      threeMonthPackageEnabled: !!threeMonthPackage,
                      twoMonthPackage: twoMonthPackage
                        ? {
                            type: twoMonthPackage.type,
                            percentage: twoMonthPackage.percentage,
                          }
                        : generatePackage(
                            SubscriptionDiscountType.twoMonthPackage
                          ),
                      threeMonthPackage: threeMonthPackage
                        ? {
                            type: threeMonthPackage.type,
                            percentage: threeMonthPackage.percentage,
                          }
                        : generatePackage(
                            SubscriptionDiscountType.threeMonthPackage
                          ),
                    }}
                    validationSchema={packagesSchema}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                  >
                    {({ isSubmitting, values }): ReactElement => (
                      <StyledForm data-testid="subscription-package-form">
                        <SimpleInput
                          data-testid="subscription-package-form__subscription-price"
                          autoComplete="transaction-amount"
                          autoFocus={true}
                          label={t('subscriptionPrice')}
                          min={5}
                          max={69}
                          name="subscriptionPrice"
                          step="0.01"
                          type="number"
                        />
                        <PackageRow>
                          <SimpleCheckbox
                            checked={values.twoMonthPackageEnabled}
                            label={t(
                              'subscriptionPackageTwoMonthPackageEnabled'
                            )}
                            name={`twoMonthPackageEnabled`}
                          />
                          <SimpleInput
                            data-testid="subscription-package-form__two-month-percentage"
                            disabled={!values.twoMonthPackageEnabled}
                            label={t(
                              'subscriptionPackageTwoMonthPackagePercentage'
                            )}
                            min={69}
                            max={100}
                            name="twoMonthPackage.percentage"
                            type="number"
                          />
                          <Total disabled={!values.twoMonthPackageEnabled}>
                            {t('price', {
                              amount:
                                (values.twoMonthPackage.percentage / 100) *
                                values.subscriptionPrice *
                                2,
                            })}
                          </Total>
                        </PackageRow>
                        <PackageRow>
                          <SimpleCheckbox
                            checked={values.threeMonthPackageEnabled}
                            label={t(
                              'subscriptionPackageThreeMonthPackageEnabled'
                            )}
                            name={`threeMonthPackageEnabled`}
                          />
                          <SimpleInput
                            data-testid="subscription-package-form__three-month-percentage"
                            disabled={!values.threeMonthPackageEnabled}
                            label={t(
                              'subscriptionPackageThreeMonthPackagePercentage'
                            )}
                            min={69}
                            max={100}
                            name="threeMonthPackage.percentage"
                            type="number"
                          />
                          <Total disabled={!values.threeMonthPackageEnabled}>
                            {t('price', {
                              amount:
                                (values.threeMonthPackage.percentage / 100) *
                                values.subscriptionPrice *
                                3,
                            })}
                          </Total>
                        </PackageRow>
                        {globalError && (
                          <StyledError>{globalError}</StyledError>
                        )}
                        <StyledButtonSet>
                          <DeemphasizedButton
                            data-testid="subscription-package-form__cancel"
                            type="button"
                            onClick={onReject}
                          >
                            {t('cancel')}
                          </DeemphasizedButton>
                          <StyledButton
                            data-testid="subscription-package-form__submit"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {t('submit')}
                          </StyledButton>
                        </StyledButtonSet>
                      </StyledForm>
                    )}
                  </Formik>
                );
              }}
            </GraphqlResult>
          );
        }}
      </GraphqlResult>
    );
  }
};

export default SubscriptionPackageFormView;
