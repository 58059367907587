import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { HeadingTwo, Paragraph, GlobalStyleVariables } from 'src/styles';
import HoneycombLogo from 'src/components/honeycomb-logo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${fibonacci(3)}rem;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: ${fibonacci(8)}rem;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    display: none;
  }
`;

const Logo = styled(HoneycombLogo)`
  height: ${fibonacci(7)}rem;
`;

const ReportPostFormAbout: React.FC = () => {
  const { t } = useTranslation('general');

  return (
    <Container>
      <HeadingTwo>{t('postsModerationReport')}</HeadingTwo>
      <Paragraph>{t('postsModerationReportAbout')}</Paragraph>
      <Icon>
        <Logo />
      </Icon>
    </Container>
  );
};

export default ReportPostFormAbout;
