/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { StyledButton, ButtonSet, DeemphasizedButton } from 'src/styles';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { useBlocker } from 'react-router-dom';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';
import MediaCollectionUploader from '../media-collection-uploader';

interface InterfaceProps {
  back: () => void;
  next: () => void;
}

const PostInnerFilterForm: React.FC<InterfaceProps> = ({ back, next }) => {
  const { t } = useTranslation('general');
  const blocker = useBlocker(() => {
    return true;
  });
  const confirm = useConfirmationModal();

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm(t('formCancel'), blocker);
    }
  }, [blocker.state]);

  return (
    <Form>
      <Field
        component={MediaCollectionUploader}
        mode="filter"
        name="mediaCollection"
      />
      <ButtonSet>
        <DeemphasizedButton type="button" onClick={back}>
          {t('back')}
        </DeemphasizedButton>
        <StyledButton type="button" onClick={next}>
          {t('next')}
        </StyledButton>
      </ButtonSet>
    </Form>
  );
};

export default PostInnerFilterForm;
