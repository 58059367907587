import gql from 'graphql-tag';
import { MEDIA_ITEM_FRAGMENT } from 'src/queries/fragments__simple';

export const PAGINATED_MEDIA_ITEMS_QUERY = gql`
  query paginatedMediaItems(
    $paginationArgs: PaginationArgsInput
    $metadata: PaginationMetadataInput
    $type: String
  ) {
    offsetBasedPaginatedEntries(
      type: $type
      metadata: $metadata
      paginationArgs: $paginationArgs
    ) {
      __typename
      ... on MediaItem {
        ...mediaItem
      }
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;
