import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { InvertingHoverButton } from 'src/styles';

interface InterfaceProps {
  className?: string;
  text: string;
  url: string;
}

const ShareButtonView: React.FC<InterfaceProps> = ({
  className,
  text,
  url,
}) => {
  const { t } = useTranslation('general');
  const share = async () => {
    if (navigator.share) {
      navigator.share({
        text,
        url,
      });
    } else {
      await navigator.clipboard.writeText(url);
      toast(t('urlCopiedToClipboard'));
    }
  };

  return (
    <InvertingHoverButton className={className} onClick={share}>
      <FontAwesomeIcon icon="share" />
    </InvertingHoverButton>
  );
};

export default ShareButtonView;
