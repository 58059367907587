import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import FileUploaderButton from 'src/components/file-uploader/file-uploader-button';
import { convertToFileUpload } from 'src/components/file-uploader/utils';
import FileUploaderQueueCarousel from 'src/components/file-uploader/queue/file-uploader-queue-carousel';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import MediaEditorModal from 'src/components/media-editor-modal';
import { FileFilter, FileUpload } from 'src/types';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalStyleVariables, PrimaryColorVanillaLink } from 'src/styles';
import { renderModifiedImage } from 'src/utils/uploads';
import Keyhole from 'src/components/icons/keyhole';
import { debounce } from 'ts-debounce';

const Container = styled.div`
  display: flex;
  position: relative;
  height: ${fibonacci(9)}rem;
  width: 100%;
  margin: ${fibonacci(3)}rem 0;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    flex-direction: column;
    height: ${fibonacci(9)}rem;
  }
`;

const QueueContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  background-color: rgba(${(props): string => props.theme.lowMutedColor}, 0.1);
  overflow: hidden;
`;

const StyledUploadButton = styled(FileUploaderButton)`
  flex-shrink: 0;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    height: ${fibonacci(6)}rem;
    width: 100%;
  }
`;

interface ProcessingScreenProps {
  isProcessing: boolean;
}

const ProcessingScreen = styled.div.attrs((props: ProcessingScreenProps) => ({
  isProcessing: props.isProcessing || false,
}))`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: ${fibonacci(8)}rem;
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  transition: opacity ${GlobalStyleVariables.baseDuration}ms;

  ${(props): string | undefined => {
    if (props.isProcessing) {
      return `
        opacity: ${goldenRatioInverse};
        pointer-events: initial;
      `;
    }
  }}
`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${fibonacci(3)}rem;

  a {
    position: relative;
    height: ${fibonacci(4)}rem;
    width: ${fibonacci(4)}rem;
    padding: ${goldenRatioInverse}rem;
    fill: rgba(${(props): string => props.theme.primaryColor}, 1);
  }
`;

interface ButtonProps {
  isSelected: boolean;
}

const Button = styled(PrimaryColorVanillaLink).attrs((props: ButtonProps) => ({
  isSelected: props.isSelected || false,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${fibonacci(4)}rem;
  width: ${fibonacci(4)}rem;
  border-radius: 50%;
  margin-left: 1rem;
  color: rgba(
    ${(props): string =>
      props.isSelected
        ? props.theme.backgroundColor
        : props.theme.primaryColor},
    1
  );
  background-color: rgba(
    ${(props): string =>
      props.isSelected
        ? props.theme.primaryColor
        : props.theme.backgroundColor},
    ${(props): string | number => (props.isSelected ? 1 : goldenRatioInverse)}
  );
`;

const CanvasContainer = styled.div`
  display: none;
`;

interface InterfaceProps {
  files: FileUpload[];
  mode?: string;
  insert: (index: number, file: FileUpload) => boolean;
  remove: (index: number) => void;
  replace: (index: number, file: FileUpload) => boolean;
  swap: (indexA: number, indexB: number) => void;
}

const FileUploaderView: React.FC<InterfaceProps> = ({
  files,
  mode = 'upload',
  insert,
  remove,
  replace,
  swap,
}) => {
  const [selectedFile, setSelectedFile] = useState<FileUpload | undefined>();
  const [isProcessingFiles, setIsProcessingFiles] = useState(false);
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const confirm = useConfirmationModal();
  const { t } = useTranslation('general');

  const saveFilteredImage = debounce((index: number, file: FileUpload) => {
    if (file) {
      if (file.type === 'image' && canvasContainerRef.current) {
        const canvas = document.createElement('canvas');
        canvasContainerRef.current.appendChild(canvas);
        const image = new Image();
        image.onload = (): void => {
          canvas.height = image.naturalHeight;
          canvas.width = image.naturalWidth;
          renderModifiedImage(file, canvas, image, {
            shouldApplyFilter: true,
          });
          canvas.toBlob(
            (blob): void => {
              if (blob) {
                const modifiedFile = blob as File;
                Object.defineProperty(modifiedFile, 'name', {
                  writable: true,
                  value: file.file.name,
                });
                file.filteredModifiedFile = modifiedFile;
                replace(index, file);
              }
            },
            file.type,
            1.0
          );
          canvas.remove();
        };
        image.src = file.result;
      }
    }
  }, 100);

  const addFilesToQueue = async (
    newFiles: FileList,
    initialIndex?: number
  ): Promise<void> => {
    setIsProcessingFiles(true);
    const fileUploads = await Promise.all(
      Array.from(newFiles).map((file) => {
        return convertToFileUpload(file);
      })
    );
    let index = initialIndex === undefined ? files.length : initialIndex;

    fileUploads.forEach((fileUpload) => {
      if (insert(index, fileUpload)) {
        fileUpload.filterIntensity = 50;
        saveFilteredImage(index, fileUpload);
        index += 1;
      }
    });
    setIsProcessingFiles(false);
  };

  const closeMediaEditorModal = (): void => {
    setSelectedFile(undefined);
  };

  const removeFileFromQueue = async (index: number): Promise<void> => {
    if (await confirm(t('fileUploaderRemoveConfirm'))) {
      remove(index);
    }
  };

  return (
    <>
      <Container>
        <QueueContainer>
          <FileUploaderQueueCarousel
            addFilesToQueue={addFilesToQueue}
            files={files}
            mode={mode}
            removeFile={removeFileFromQueue}
            saveFilteredImage={saveFilteredImage}
            swap={swap}
            selectFile={setSelectedFile}
          />
        </QueueContainer>
        {mode === 'upload' && (
          <StyledUploadButton
            addFiles={addFilesToQueue}
            files={files}
            icon="plus"
            multiple={true}
          />
        )}
        <MediaEditorModal
          closeModal={closeMediaEditorModal}
          save={() => {
            if (selectedFile) {
              saveFilteredImage(files.indexOf(selectedFile), selectedFile);
            }
          }}
          isOpen={selectedFile !== undefined}
          file={selectedFile}
        />
        <ProcessingScreen isProcessing={isProcessingFiles}>
          <FontAwesomeIcon icon="spinner" pulse />
        </ProcessingScreen>
        <CanvasContainer ref={canvasContainerRef} />
      </Container>
      {mode === 'filter' && (
        <FilterRow>
          <span>{t('fileUploaderApplyToAll')}</span>
          <Button
            isSelected={files.every(
              (file) => file.previewType === FileFilter.none
            )}
            onClick={() => {
              files.forEach((file, index) => {
                file.previewable = true;
                file.previewType = FileFilter.none;
                saveFilteredImage(index, file);
              });
            }}
          >
            <FontAwesomeIcon icon={['far', 'square']} />
          </Button>
          <Button
            isSelected={files.every(
              (file) => file.previewType === FileFilter.keyhole
            )}
            onClick={() => {
              files.forEach((file, index) => {
                file.previewable = false;
                file.previewType = FileFilter.keyhole;
                if (!file.filterIntensity) file.filterIntensity = 50;
                saveFilteredImage(index, file);
              });
            }}
          >
            <Keyhole />
          </Button>
          <Button
            isSelected={files.every(
              (file) => file.previewType === FileFilter.blur
            )}
            onClick={() => {
              files.forEach((file, index) => {
                file.previewable = false;
                file.previewType = FileFilter.blur;
                if (!file.filterIntensity) file.filterIntensity = 50;
                saveFilteredImage(index, file);
              });
            }}
          >
            <FontAwesomeIcon icon="tint" />
          </Button>
          <Button
            isSelected={files.every(
              (file) => file.previewType === FileFilter.locked
            )}
            onClick={() => {
              files.forEach((file, index) => {
                file.previewable = false;
                file.previewType = FileFilter.locked;
                saveFilteredImage(index, file);
              });
            }}
          >
            <FontAwesomeIcon icon="lock" />
          </Button>
        </FilterRow>
      )}
    </>
  );
};

export default FileUploaderView;
