/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { StyledButton } from 'src/styles';
import { CuratedList } from 'src/types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/use-auth';
import { Formik, Form } from 'formik';
import { useUnfollowCuratedList } from 'src/hooks/use-unfollow-curated-list';
import { useFollowCuratedList } from 'src/hooks/use-follow-curated-list';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  display: flex;
`;

interface InterfaceProps {
  curatedList: CuratedList;
  toggleEdit: () => void;
}

const CuratedListSummaryPrimaryButton: React.FC<InterfaceProps> = ({
  curatedList,
  toggleEdit,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const onUnfollow = useUnfollowCuratedList(curatedList);
  const onFollow = useFollowCuratedList(curatedList);
  const userIsOwner = user?.curatorialMemberships?.some(
    (curatorialMembership) => {
      return (
        curatorialMembership.isOwner &&
        curatorialMembership.curatedList.id === curatedList.id
      );
    }
  );
  const isFollowing = user?.followedCuratedLists?.some(
    ({ id }) => id === curatedList.id
  );

  if (userIsOwner) {
    return (
      <StyledButton onClick={toggleEdit}>{t('profilesEdit')}</StyledButton>
    );
  } else if (isFollowing) {
    return (
      <Formik initialValues={{}} onSubmit={onUnfollow}>
        {({ isSubmitting }): ReactElement => (
          <StyledForm>
            <StyledButton type="submit" disabled={isSubmitting}>
              {t('followUnfollow')}
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    );
  } else {
    return (
      <Formik initialValues={{}} onSubmit={onFollow}>
        {({ isSubmitting }): ReactElement => (
          <StyledForm>
            <StyledButton type="submit" disabled={isSubmitting}>
              {t('followFollow')}
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    );
  }
};

export default CuratedListSummaryPrimaryButton;
