import styled from 'styled-components';
import { GlobalStyleVariables } from 'src/styles/global';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { HeadingOne } from 'src/styles/components/typography';

export const MainContainer = styled.div`
  padding: 0 ${GlobalStyleVariables.mainContainerMargins}vw;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    padding: 0;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('${(props): string => props.theme.honeycombSrc}');
  background-repeat: repeat;
  opacity: ${goldenRatioInverse / 2};

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    display: none;
  }
`;

export const Ellipsis = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const MobilePadding = styled.div`
  @media ${GlobalStyleVariables.phoneMediaQuery} {
    padding: ${fibonacci(3)}rem;
  }
`;

export const Panel = styled(MainContainer)`
  padding: 0;
  border: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
`;

export const PanelHeading = styled(HeadingOne)`
  margin: ${fibonacci(3)}rem 1rem;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    font-size: ${GlobalStyleVariables.fontSizeTwoUp}rem;
  }
`;

export const CardLayout = styled.div`
  display: flex;

  > * {
    @media ${GlobalStyleVariables.phoneMediaQuery} {
      padding: 0;
      margin-bottom: ${fibonacci(3)}rem;
    }
  }
`;

export const CardVerticalLayout = styled(CardLayout)`
  flex-direction: column;
  align-items: center;
`;

export const CardGridLayout = styled(CardLayout)`
  flex-wrap: wrap;

  > * {
    width: 31vw;
    max-height: 95svh;
    margin: 0.25vw;

    @media ${GlobalStyleVariables.mediaQueryOneUp} {
      width: 23.5vw;
    }

    @media ${GlobalStyleVariables.mediaQueryTwoUp} {
      width: 18.5vw;
    }

    @media ${GlobalStyleVariables.mediaQueryThreeUp} {
      width: 15.5vw;
    }

    @media ${GlobalStyleVariables.phoneMediaQuery} {
      width: 100vw;
      margin: 0;
      margin-bottom: ${fibonacci(3)}rem;
    }
  }
`;

export const SquareCardGridLayout = styled(CardLayout)`
  flex-wrap: wrap;

  > * {
    width: 31vw;
    height: 31vw;
    margin: 0.25vw;

    @media ${GlobalStyleVariables.mediaQueryOneUp} {
      width: 23.5vw;
      height: 23.5vw;
    }

    @media ${GlobalStyleVariables.mediaQueryTwoUp} {
      width: 18.5vw;
      height: 18.5vw;
    }

    @media ${GlobalStyleVariables.mediaQueryThreeUp} {
      width: 15.5vw;
      height: 15.5vw;
    }

    @media ${GlobalStyleVariables.phoneMediaQuery} {
      width: 100vw;
      height: 100vw;
      margin: 0;
      margin-bottom: ${fibonacci(3)}rem;
    }
  }
`;

export const LineItemLayout = styled.ul`
  padding: 0;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    margin-bottom: ${fibonacci(3)}rem;
  }
`;

export const NavContent = styled.div`
  @media ${GlobalStyleVariables.phoneMediaQuery} {
    min-height: 100dvh;
  }
`;
