import React from 'react';
import { MainContainer } from 'src/styles';
import PostList from 'src/components/post-list';

const FeaturedRoute: React.FC = () => {
  return (
    <MainContainer>
      <PostList
        data-testid="featured-posts"
        useCarousel={true}
        paginationArgs={{
          isFeatured: true,
        }}
      />
    </MainContainer>
  );
};

export default FeaturedRoute;
