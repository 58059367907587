import React from 'react';
import { PaginatableType, ModerationRequest } from 'src/types';
import InfiniteScroll from 'src/components/infinite-scroll';
import NoContent from 'src/components/no-content';
import ListView from 'src/components/list-view';
import ModerationRequestItem from 'src/components/moderation-request-item';
import styled from 'styled-components';

const ModerationRequestList = styled(ListView)`
  margin: 0;

  li {
    margin-bottom: 1rem;
  }
`;

interface InterfaceProps {
  className?: string;
  entries: PaginatableType[];
  onLoadMore: () => Promise<void>;
}

const ModerationRequestListView: React.FC<InterfaceProps> = ({
  className,
  entries,
  onLoadMore,
}) => {
  if (!entries || entries.length === 0) {
    return <NoContent></NoContent>;
  }

  return (
    <InfiniteScroll onApproachingInfinity={onLoadMore}>
      <ModerationRequestList className={className}>
        {entries.map((moderationRequest) => {
          return (
            <ModerationRequestItem
              key={moderationRequest.id}
              moderationRequest={moderationRequest as ModerationRequest}
            />
          );
        })}
      </ModerationRequestList>
    </InfiniteScroll>
  );
};

export default ModerationRequestListView;
