import React from 'react';
import {
  MainContainer,
  HeadingOne,
  Paragraph,
  GlobalStyleVariables,
  HeadingTwo,
} from 'src/styles';
import CreatorApplicationForm from 'src/components/creator-application-form';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import { CreatorApplicationInput, User } from 'src/types';
import { CREATE_ARTIST_APPLICATION_MUTATION } from 'src/queries';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import HoneycombLogo from 'src/components/honeycomb-logo';
import { updateCurrentUser } from 'src/utils/data';

const StyledHeadingOne = styled(HeadingOne)`
  font-size: ${GlobalStyleVariables.fontSizeFourUp}rem;
  margin: ${fibonacci(3)}rem 0;
`;

const LeadParagraph = styled(Paragraph)`
  font-size: ${GlobalStyleVariables.fontSizeOneUp}rem;
`;

const MainContentContainer = styled.div`
  display: flex;
`;

const MainContentText = styled.div`
  @media ${GlobalStyleVariables.phoneMediaQuery} {
    padding: 0 ${GlobalStyleVariables.mainContainerMargins}vw;
  }
`;

const LogoContainer = styled.div`
  align-self: center;
  margin: ${fibonacci(5)}rem ${fibonacci(9)}rem 0;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    display: none;
  }
`;

const Logo = styled(HoneycombLogo)`
  height: ${fibonacci(9)}rem;
`;

const List = styled.ul`
  margin: ${fibonacci(3)}rem;
  padding: 0 ${fibonacci(3)}rem;
`;

const Item = styled.li`
  margin-top: ${fibonacci(1) * goldenRatioInverse}rem;
`;

const FootNote = styled.div`
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  color: rgba(${(props): string => props.theme.highMutedColor}, 1);
`;

const FormContainer = styled.div`
  margin: ${fibonacci(5)}rem 0;
`;

const FormDescription = styled(Paragraph)`
  margin-bottom: ${fibonacci(5)}rem;
`;

const CreatorRegistrationForm: React.FC = () => {
  const { t } = useTranslation('general');
  const navigate = useNavigate();
  const [createCreatorApplicationMutation] = useMutation(
    CREATE_ARTIST_APPLICATION_MUTATION,
    {
      update(cache) {
        updateCurrentUser(cache, (currentUser: User) => {
          return {
            ...currentUser,
            hasSubmittedCreatorApplication: true,
          };
        });
      },
    }
  );

  async function cancel(): Promise<void> {
    navigate({
      pathname: `/`,
    });
  }

  async function submit(
    creatorApplication: CreatorApplicationInput
  ): Promise<void> {
    await createCreatorApplicationMutation({
      variables: { creatorApplication },
    });
  }

  return (
    <MainContainer>
      <MainContentContainer>
        <MainContentText>
          <StyledHeadingOne>
            {t('creatorApplicationsAboutTitle')}
          </StyledHeadingOne>
          <LeadParagraph>
            {t('creatorApplicationsAboutDescriptionOne')}
          </LeadParagraph>
          <Paragraph>{t('creatorApplicationsAboutDescriptionTwo')}</Paragraph>
          <Paragraph>{t('creatorApplicationsAboutDescriptionThree')}</Paragraph>
          <List>
            <Item>{t('creatorApplicationsAboutPerksOne')}</Item>
            <Item>{t('creatorApplicationsAboutPerksTwo')}</Item>
            <Item>{t('creatorApplicationsAboutPerksThree')}</Item>
            <Item>{t('creatorApplicationsAboutPerksFour')}</Item>
            <Item>{t('creatorApplicationsAboutPerksFive')}</Item>
          </List>
          <FootNote>{t('creatorApplicationsAboutCollectiveMember')}</FootNote>
          <FormContainer>
            <HeadingTwo>{t('creatorApplicationsAboutFormTitle')}</HeadingTwo>
            <FormDescription>
              {t('creatorApplicationsAboutFormDescription')}
            </FormDescription>
            <CreatorApplicationForm cancel={cancel} submit={submit} />
          </FormContainer>
        </MainContentText>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </MainContentContainer>
    </MainContainer>
  );
};

export default CreatorRegistrationForm;
