import React, { useState } from 'react';
import FileUploaderSingleton from 'src/components/file-uploader/singleton';
import { FieldProps } from 'formik';
import { FileUpload } from 'src/types/input';
import { Image } from 'src/types';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';

const StyledFileUploaderSingleton = styled(FileUploaderSingleton)`
  > div {
    height: ${fibonacci(9)}rem;
    width: ${fibonacci(9)}rem;
    margin: ${fibonacci(3)}rem 0;
  }
`;

interface InterfaceProps extends FieldProps {
  originalAvatar?: Image;
}

const validateFileUpload = (file: FileUpload): boolean => {
  return file.type === 'image' || file.type === 'video';
};

const UserSummaryAvatarUploaderView: React.FC<InterfaceProps> = ({
  originalAvatar,
  field,
  form,
}) => {
  const [originalFile, setOriginalFile] = useState(
    originalAvatar ? { type: 'image', src: originalAvatar.src } : undefined
  );
  const file = form.values[field.name];

  const remove = (): void => {
    if (originalAvatar) {
      form.setFieldValue(field.name, { id: originalAvatar.id, delete: true });
      setOriginalFile(undefined);
    } else {
      form.setFieldValue(field.name, null);
    }
  };

  const replace = (fileUpload: FileUpload): void => {
    if (validateFileUpload(fileUpload)) {
      form.setFieldValue(field.name, fileUpload);
    }
  };

  return (
    <StyledFileUploaderSingleton
      file={file}
      forceRatio={1}
      originalFile={originalFile}
      remove={remove}
      replace={replace}
    />
  );
};

export default UserSummaryAvatarUploaderView;
