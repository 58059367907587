import gql from 'graphql-tag';
import {
  MEDIA_ITEM_FRAGMENT,
  USER_WITH_NAMES,
  PROFILE_FRAGMENT,
  COMMENT_FRAGMENT,
  CREDIT_FRAGMENT,
  CURATED_LIST_FRAGMENT,
  ALBUM_FRAGMENT,
  ESSENTIAL_USER_FRAGMENT,
  USER_SUBSCRIPTION_FRAGMENT,
} from 'src/queries/fragments__simple';

export const MEDIA_COLLECTION_WITH_PAGINATED_MEDIA_ITEMS_FRAGMENT = gql`
  fragment mediaCollectionWithPaginatedMediaItems on MediaCollection {
    id
    mediaItems(metadata: $mediaItemsMetadata) {
      ...mediaItem
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;

export const CURRENT_USER_CURATOR_FRAGMENT = gql`
  fragment currentUserCurator on User {
    username
    curatedPostCount
    curatedListCount
    curatorialMemberships {
      id
      isOwner
      curatedList {
        ...curatedList
        curatedPostListings {
          id
          post {
            id
          }
        }
      }
    }
  }
  ${CURATED_LIST_FRAGMENT}
`;

export const USER_WITH_AVATAR = gql`
  fragment userWithAvatar on User {
    ...userWithNames
    avatar {
      id
      src
    }
  }
  ${USER_WITH_NAMES}
`;

export const USER_MEMBER_AND_ARTIST_FRAGMENT = gql`
  fragment userMemberAndCreator on User {
    ...userWithNames
    postCount
    curatedPostCount
    curatedListCount
    likeCount
    curatedListFollowingCount
    subscriptionPostCount
    userFollowerCount
    userFollowingCount
    purchaseCount
    subscriptionPrice
    avatar {
      id
      src
    }
    profile {
      ...profile
    }
  }
  ${USER_WITH_NAMES}
  ${PROFILE_FRAGMENT}
`;

export const CURRENT_USER_FRAGMENT = gql`
  fragment currentUser on User {
    ...essentialUser
    displayName
    commentCount
    hasSubmittedCreatorApplication
    subscriptionPrice
    unseenNotificationCount
    activeUserSubscriptions {
      ...userSubscription
    }
    credits {
      post {
        id
      }
    }
    likedPosts {
      id
    }
    followedCuratedLists {
      id
    }
    followedUsers {
      ...essentialUser
    }
    permissions {
      canApproveCreatorApplications
      canFeatureLists
      canPostContent
      canModerateContent
    }
    purchasedPosts {
      id
    }
    restrictions {
      cannotPostAnything
      cannotPostComments
      cannotPostContent
      cannotModerate
    }
    ...currentUserCurator
  }
  ${CURRENT_USER_CURATOR_FRAGMENT}
  ${ESSENTIAL_USER_FRAGMENT}
  ${USER_SUBSCRIPTION_FRAGMENT}
`;

export const COMMENT_AND_USER_FRAGMENT = gql`
  fragment commentAndUser on Comment {
    ...comment
    user {
      ...userWithAvatar
      commentCount
    }
  }
  ${COMMENT_FRAGMENT}
  ${USER_WITH_AVATAR}
`;

export const COMMENT_AND_USER_WITH_CHILDREN_FRAGMENT = gql`
  fragment commentAndUserWithChildren on Comment {
    ...commentAndUser
    children(metadata: $commentChildrenMetadata) {
      ...commentAndUser
    }
  }
  ${COMMENT_AND_USER_FRAGMENT}
`;

export const ALBUM_AND_CREDITS_FRAGMENT = gql`
  fragment postAndCredits on Post {
    ...post
    mediaCollection {
      ...mediaCollectionWithPaginatedMediaItems
    }
    curatedListCount
    features {
      id
      curatedList {
        id
        name
      }
    }
    credits {
      ...credit
      user {
        ...essentialUser
        displayName
        subscriptionPrice
      }
    }
  }
  ${ALBUM_FRAGMENT}
  ${MEDIA_COLLECTION_WITH_PAGINATED_MEDIA_ITEMS_FRAGMENT}
  ${CREDIT_FRAGMENT}
  ${ESSENTIAL_USER_FRAGMENT}
`;

export const ALBUM_AND_CREDITS_WITH_COMMENTS_FRAGMENT = gql`
  fragment postAndCreditsWithComments on Post {
    ...postAndCredits
    comments(metadata: $commentsMetadata) {
      ...commentAndUserWithChildren
    }
  }
  ${ALBUM_AND_CREDITS_FRAGMENT}
  ${COMMENT_AND_USER_WITH_CHILDREN_FRAGMENT}
`;

export const FEED_ITEM_FRAGMENT = gql`
  fragment feedItem on FeedItem {
    id
    type
    post {
      ...postAndCredits
    }
    curatedList {
      ...curatedList
    }
    user {
      ...essentialUser
      displayName
    }
  }
  ${ALBUM_AND_CREDITS_FRAGMENT}
  ${CURATED_LIST_FRAGMENT}
  ${ESSENTIAL_USER_FRAGMENT}
`;

export const MODERATION_REQUEST_FRAGMENT = gql`
  fragment moderationRequest on ModerationRequest {
    id
    sortedAt
    isResolved
    moderationRequestReportCount
    moderationRequestResponseCount
    type
    post {
      ...postAndCredits
    }
    creatorApplication {
      id
      birthday
      citizenship
      legalName
      user {
        ...userWithNames
      }
    }
    comment {
      id
      text
      parent {
        id
      }
      post {
        ...postAndCreditsWithComments
      }
      user {
        ...userWithNames
      }
    }
    curatedList {
      id
      description
      name
      curatorialMemberships {
        id
        user {
          ...userWithNames
        }
      }
    }
    profile {
      id
      user {
        ...userWithNames
        profile {
          ...profile
        }
      }
    }
  }
  ${ALBUM_AND_CREDITS_FRAGMENT}
  ${ALBUM_AND_CREDITS_WITH_COMMENTS_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${USER_WITH_NAMES}
`;
