import React from 'react';
import styled from 'styled-components';
import { Post } from 'src/types';
import AddToCuratedListExisting from 'src/components/add-to-curated-list/add-to-curated-list-existing';
import AddToCuratedListNew from 'src/components/add-to-curated-list/add-to-curated-list-new';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { useAuth } from 'src/hooks/use-auth';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > :nth-child(2) {
    margin-top: ${fibonacci(3)}rem;
    border-top: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 6)}rem
      rgba(${(props): string => props.theme.primaryColor}, 1);
  }
`;

interface InterfaceProps {
  post: Post;
  hasListed: boolean;
  onReject: () => void;
  onResolve: () => void;
}

const AddToCuratedListForms: React.FC<InterfaceProps> = ({
  post,
  hasListed,
  onReject,
  onResolve,
}) => {
  const { user } = useAuth();
  const hasMemberships = user && user.curatedListCount > 0;

  return (
    <Container>
      <AddToCuratedListNew
        post={post}
        hasListed={hasListed}
        onReject={onReject}
        onResolve={onResolve}
      />
      {hasMemberships && (
        <AddToCuratedListExisting
          post={post}
          hasListed={hasListed}
          onReject={onReject}
          onResolve={onResolve}
        />
      )}
    </Container>
  );
};

export default AddToCuratedListForms;
