/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client';
import {
  CURRENT_USER_QUERY,
  MANAGE_SUBSCRIPTION_PACKAGE_MUTATION,
  SUBSCRIPTION_DISCOUNTS_QUERY,
} from 'src/queries';
import {
  CuratedList,
  SubscriptionDiscount,
  SubscriptionDiscountType,
  SubscriptionPackageInput,
  User,
} from 'src/types';
import { useEnsureUser } from 'src/hooks/use-ensure-user';

export function useManageSubscriptionPackages(): (
  subscriptionPackageInput: SubscriptionPackageInput
) => Promise<CuratedList> {
  const ensureUser = useEnsureUser();
  const [manageSubscriptionPackagesMutation] = useMutation(
    MANAGE_SUBSCRIPTION_PACKAGE_MUTATION,
    {
      update(cache, { data: { manageSubscriptionPackages } }) {
        const twoMonthPackage =
          manageSubscriptionPackages.subscriptionDiscounts.find(
            (p: SubscriptionDiscount) =>
              p.type === SubscriptionDiscountType.twoMonthPackage
          );
        const threeMonthPackage =
          manageSubscriptionPackages.subscriptionDiscounts.find(
            (p: SubscriptionDiscount) =>
              p.type === SubscriptionDiscountType.threeMonthPackage
          );

        const { currentUser } = cache.readQuery({
          query: CURRENT_USER_QUERY,
        }) as { currentUser: User };

        cache.writeQuery({
          query: SUBSCRIPTION_DISCOUNTS_QUERY,
          variables: {
            isActive: true,
            type: SubscriptionDiscountType.twoMonthPackage,
            userId: currentUser.id,
          },
          data: {
            subscriptionDiscounts: twoMonthPackage ? [twoMonthPackage] : [],
          },
        });
        cache.writeQuery({
          query: SUBSCRIPTION_DISCOUNTS_QUERY,
          variables: {
            isActive: true,
            type: SubscriptionDiscountType.threeMonthPackage,
            userId: currentUser.id,
          },
          data: {
            subscriptionDiscounts: threeMonthPackage ? [threeMonthPackage] : [],
          },
        });

        cache.writeQuery({
          query: CURRENT_USER_QUERY,
          data: {
            currentUser: {
              ...currentUser,
              subscriptionPrice: manageSubscriptionPackages.subscriptionPrice,
            },
          },
        });
      },
    }
  );

  return async (
    subscriptionPackagesInput: SubscriptionPackageInput
  ): Promise<CuratedList> => {
    if (!manageSubscriptionPackagesMutation)
      throw new Error("manageSubscriptionPackagesMutation didn't load");

    await ensureUser();

    const {
      data: { manageSubscriptionPackages },
    } = await manageSubscriptionPackagesMutation({
      variables: {
        subscriptionPackagesInput: {
          ...subscriptionPackagesInput,
          subscriptionPrice:
            subscriptionPackagesInput.subscriptionPrice.toString(),
        },
      },
    });

    return manageSubscriptionPackages;
  };
}
