import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import { goldenRatioInverse, fibonacci } from 'src/utils/math';
import { useLocation, useNavigate } from 'react-router';
import { GlobalStyleVariables } from 'src/styles/global';
import { SEARCH_SUGGESTIONS_QUERY } from 'src/queries/misc';
import {
  AutocompleteField,
  AutocompleteFieldUserSuggestion,
  AutocompleteFieldTagSuggestion,
} from 'src/components/autocomplete-field';
import { GenericOptionItem } from 'src/types';
import { User, Tag } from 'src/types';
import { InvertingHoverButton } from 'src/styles/components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderSearchBarListener from './header-search-bar-listener';
import { isMobile } from 'src/utils/detection';

const StyledForm = styled(Form)`
  display: flex;
  flex-grow: 1;
  max-width: ${fibonacci(11)}rem;
  margin: ${fibonacci(3)}rem;
  padding: 0 ${fibonacci(1) * goldenRatioInverse}rem;

  button {
    opacity: 0;
    pointer-events: none;
    transition: opacity ${GlobalStyleVariables.baseDuration}ms;
  }

  &:focus-within {
    button {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    margin: ${fibonacci(1) * goldenRatioInverse}rem;
  }
`;

const SearchButton = styled(InvertingHoverButton)`
  flex-grow: 0;
  margin-left: ${fibonacci(1) * goldenRatioInverse}rem;
  border: 0;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    display: none;
  }
`;

export enum QueryType {
  everything = 'everything',
  videos = 'videos',
  pictures = 'pictures',
  users = 'users',
  curatedLists = 'curatedLists',
}

export interface SearchVariables {
  query: string;
  queryType: QueryType;
}

const HeaderSearchBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('general');

  const queryTypeValues = Object.values(QueryType);
  const defaultValues = {
    query: '',
    queryType: queryTypeValues[0],
  };
  const queryParams = new URLSearchParams(location.search);

  return (
    <Formik
      initialValues={{
        query: queryParams.get('query') || defaultValues.query,
        queryType: (queryParams.get('queryType') ||
          defaultValues.queryType) as QueryType,
      }}
      onSubmit={(variables: SearchVariables, { setSubmitting }): void => {
        navigate(
          `/search?queryType=${variables.queryType}&query=${encodeURIComponent(
            variables.query
          )}`
        );
        setSubmitting(false);

        if (isMobile() && (document.activeElement as HTMLInputElement)?.blur)
          (document.activeElement as HTMLInputElement).blur();
      }}
    >
      {({ setFieldValue, submitForm }): ReactElement => {
        return (
          <StyledForm>
            <HeaderSearchBarListener setFieldValue={setFieldValue} />
            <Field
              data-testid="header-search__field"
              autoComplete="off"
              component={AutocompleteField}
              name="query"
              placeholder={t('search')}
              query={SEARCH_SUGGESTIONS_QUERY}
              serializeEntries={(
                data: {
                  userSuggestions: User[];
                  tagSuggestions: Tag[];
                },
                close: () => void
              ): GenericOptionItem[] => {
                const users =
                  data && data.userSuggestions ? data.userSuggestions : [];
                const tags =
                  data && data.tagSuggestions ? data.tagSuggestions : [];
                const entries = [...tags, ...users];

                return entries.map((entry: User | Tag) => {
                  if ((entry as User).username) {
                    entry = entry as User;
                    return {
                      value: entry.username,
                      onClick: (): void => {
                        navigate(`/${(entry as User).username}`);
                        close();
                      },
                      label: (
                        <AutocompleteFieldUserSuggestion
                          user={entry}
                          data-testid="header-search__suggestion"
                        />
                      ),
                    };
                  } else if ((entry as Tag).name) {
                    entry = entry as Tag;
                    return {
                      value: entry.name,
                      onClick: (): void => {
                        setFieldValue('query', `#${(entry as Tag).name}`);
                        submitForm();
                        close();
                      },
                      label: (
                        <AutocompleteFieldTagSuggestion
                          tag={entry}
                          data-testid="header-search__suggestion"
                        />
                      ),
                    };
                  } else {
                    return { value: '', label: '' };
                  }
                });
              }}
            />
            <SearchButton>
              <FontAwesomeIcon icon="search" />
            </SearchButton>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default HeaderSearchBar;
