import React from 'react';
import styled from 'styled-components';

const width = 12;

const StyledScrollbar = styled.div`
  overflow: auto;

  ::-webkit-scrollbar {
    height: ${width}px;
    width: ${width}px;
    border-radius: ${width / 2}px;
    background-color: rgba(${(props): string => props.theme.highMutedColor}, 1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${width / 2}px;
    background: rgba(${(props): string => props.theme.primaryColor}, 1);
  }
`;

interface InterfaceProps {
  className?: string;
}

const StyledScrollbarView: React.FC<InterfaceProps> = ({
  className,
  children,
}) => {
  return <StyledScrollbar className={className}>{children}</StyledScrollbar>;
};

export default StyledScrollbarView;
