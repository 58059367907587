import React from 'react';
import { fibonacci } from 'src/utils/math';
import styled from 'styled-components';
import { GlobalStyleVariables } from 'src/styles';
import { User } from 'src/types';
import UserSummary from 'src/components/user-summary';

const UserPage = styled.div`
  margin: ${fibonacci(3)}rem 0;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    margin: 0;
  }
`;

interface InterfaceProps {
  user: User;
}

const UserRouteContent: React.FC<InterfaceProps> = ({ user }) => {
  return (
    <UserPage>
      <UserSummary user={user} />
    </UserPage>
  );
};

export default UserRouteContent;
