import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { GlobalStyleVariables } from 'src/styles';
import { FileUpload } from 'src/types';

const Button = styled.div`
  position: relative;
  display: block;
  width: ${fibonacci(8)}rem;
  border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  color: rgba(${(props): string => props.theme.primaryColor}, 1);
  overflow: hidden;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    width: initial;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-item: center;
  justify-content: center;
  height: 100%;
  width: 100% !important;
  padding: 1rem;
  box-sizing: border-box !important;
  pointer-events: none;
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
`;

interface InterfaceProps {
  accept?: string;
  addFiles: (files: FileList, index?: number) => Promise<void>;
  className?: string;
  content?: ReactElement;
  files?: FileUpload[];
  icon?: IconProp;
  multiple?: boolean;
}

const FileUploaderButton: React.FC<InterfaceProps> = ({
  accept = '.jpg,.jpeg,.png,.webm,.mpg,.mp2,.mpeg,.mpe,.mpv,.mp4,.m4p,.m4v,.ogg,.avi',
  addFiles,
  className,
  content,
  files,
  icon,
  multiple,
}) => {
  const [ref, setRef] = useState<HTMLInputElement | null>(null);

  const onChange = useCallback(
    (event: Event): void => {
      const newFiles = (event.target as HTMLInputElement).files;

      if (!newFiles) return;

      addFiles(newFiles, files?.length);
    },
    [files]
  );

  useEffect(() => {
    if (ref) {
      ref.value = '';
      ref.addEventListener('input', onChange);

      return (): void => {
        ref.removeEventListener('input', onChange);
      };
    }
  }, [onChange, ref]);

  return (
    <>
      <Button className={className}>
        {content ? content : <StyledIcon icon={icon ?? 'photo-video'} />}
      </Button>
      <Input type="file" ref={setRef} multiple={multiple} accept={accept} />
    </>
  );
};

export default FileUploaderButton;
