import React from 'react';
import UserList from 'src/components/user-list';
import { MainContainer } from 'src/styles/components/layout';

const UsersView: React.FC = () => {
  return (
    <MainContainer>
      <UserList />
    </MainContainer>
  );
};

export default UsersView;
