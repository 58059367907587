import React from 'react';
import styled from 'styled-components';
import { Post } from 'src/types';
import { GlobalStyleVariables } from 'src/styles';
import PostSummarySumplementaryInfo from '../post-summary/post-summary-supplementary-info';

const StatsContainer = styled.div`
  margin-top: 1rem;
  text-align: left;
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  font-weight: bold;

  > * {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;

    :not(:last-child):after {
      content: '|';
      padding: 0 1rem;
    }
  }
`;

interface InterfaceProps {
  post: Post;
}

const PostCardStats: React.FC<InterfaceProps> = ({ post }) => {
  return (
    <StatsContainer>
      <PostSummarySumplementaryInfo post={post} />
    </StatsContainer>
  );
};

export default PostCardStats;
