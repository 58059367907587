import React, { ReactElement, useEffect } from 'react';
import { PaginatableType, Post } from 'src/types';
import InfiniteScroll from 'src/components/infinite-scroll';
import NoContent from 'src/components/no-content';
import PostCard from 'src/components/post-card';
import {
  CardVerticalLayout,
  CardGridLayout,
  GlobalStyleVariables,
  Background,
} from 'src/styles';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';

const ListContainer = styled.div`
  position: relative;
`;

const VerticalPostCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin-bottom: ${fibonacci(3)}rem;
  padding: ${fibonacci(3)}rem;
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  overflow: hidden;
  z-index: 1;

  img,
  video {
    max-height: 50svh;
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    margin-bottom: 0 !important;
    border: 0;
    padding: ${fibonacci(3)}rem 0;
    width: 100vw;
    z-index: initial;

    img,
    video {
      max-height: 75svh;
      width: 100vw;
    }
  }
`;

interface InterfaceProps {
  className?: string;
  entries: PaginatableType[];
  scrollElementRef?: HTMLDivElement | null;
  shouldPlayOnView?: boolean;
  shouldMute?: boolean;
  useCarousel?: boolean;
  useGridLayout?: boolean;
  onLoadMore: () => Promise<void>;
  setCarouselLength?: (carouselLength: number) => void;
}

const PostListView: React.FC<InterfaceProps> = ({
  className,
  entries,
  scrollElementRef,
  shouldPlayOnView,
  shouldMute,
  useCarousel,
  useGridLayout,
  onLoadMore,
  setCarouselLength,
}) => {
  useEffect(() => {
    if (setCarouselLength && !!entries) {
      setCarouselLength(entries.length);
    }
  }, [entries.length]);

  if (!entries || entries.length === 0) {
    return <NoContent></NoContent>;
  }

  // TODO: aggregate these with a graphdb query;
  // you know, once we're using graphdb :)
  const usedAblumIds = [] as string[];
  entries = entries.filter((post) => {
    post = post as Post;
    const canDisplay = usedAblumIds.indexOf(post.id) === -1;
    usedAblumIds.push(post.id);
    return canDisplay;
  });

  function renderEntries(): ReactElement[] {
    return entries.map((post) => {
      post = post as Post;

      return useCarousel ? (
        <VerticalPostCardContainer key={post.id}>
          <PostCard
            post={post}
            useCarousel={useCarousel}
            shouldPlayOnView={shouldPlayOnView}
            shouldMute={shouldMute}
          />
        </VerticalPostCardContainer>
      ) : (
        <PostCard
          key={post.id}
          post={post}
          useCarousel={useCarousel}
          shouldPlayOnView={shouldPlayOnView}
          shouldMute={shouldMute}
        />
      );
    });
  }

  return (
    <InfiniteScroll
      onApproachingInfinity={onLoadMore}
      axis={scrollElementRef ? 'x' : 'y'}
      scrollElementRef={scrollElementRef}
    >
      {scrollElementRef ? (
        renderEntries()
      ) : useGridLayout ? (
        <CardGridLayout className={className}>{renderEntries()}</CardGridLayout>
      ) : (
        <ListContainer>
          <Background />
          <CardVerticalLayout className={className}>
            {renderEntries()}
          </CardVerticalLayout>
        </ListContainer>
      )}
    </InfiniteScroll>
  );
};

export default PostListView;
