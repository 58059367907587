import { PaginationMetadataInput } from 'src/types';

export function generateCommentsMetadata(): PaginationMetadataInput {
  return {
    offset: 0,
    limit: 3,
  };
}

export function generateCommentChildrenMetadata(): PaginationMetadataInput {
  return {
    offset: 0,
    limit: 2,
  };
}

export function generateMediaItemsMetadata(): PaginationMetadataInput {
  return {
    offset: 0,
    limit: 10,
  };
}

export function generateMediaItemsMetadataNoPreview(): PaginationMetadataInput {
  return {
    offset: 0,
    limit: 0,
  };
}

export function generateMediaItemsMetadataPreview(): PaginationMetadataInput {
  return {
    offset: 0,
    limit: 1,
  };
}

export function generateGridPaginationMetadata(): PaginationMetadataInput {
  return {
    offset: 0,
    limit: 15,
  };
}

export function generatePaginationMetadata(): PaginationMetadataInput {
  return {
    offset: 0,
    limit: 5,
  };
}
