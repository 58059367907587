import React from 'react';
import { Outlet } from 'react-router-dom';
import { NavContent } from 'src/styles';
import { User } from 'src/types';

interface InterfaceProps {
  user: User;
}

const UserRouteRouter: React.FC<InterfaceProps> = ({ user }) => {
  return (
    <NavContent>
      <Outlet context={{ user }} />
    </NavContent>
  );
};

export default UserRouteRouter;
