import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, HoneyTheme } from 'src/styles';
import setupFontLibrary from 'src/components/app/font-library';
import LoadingSpinner from 'src/components/loading-spinner';
import Modal from 'react-modal';
import { AuthProvider } from 'src/hooks/use-auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './app-router';

setupFontLibrary();

const theme = HoneyTheme;

const AppView: React.FC = () => {
  Modal.setAppElement('#root');

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoadingSpinner />}>
          <GlobalStyle />
          <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
          <AppRouter />
        </Suspense>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default AppView;
