import gql from 'graphql-tag';
import {
  CURRENT_USER_CURATOR_FRAGMENT,
  MEDIA_COLLECTION_WITH_PAGINATED_MEDIA_ITEMS_FRAGMENT,
} from 'src/queries/fragments__complex';
import {
  ALBUM_FRAGMENT,
  CURATED_LIST_FRAGMENT,
} from 'src/queries/fragments__simple';

export const CURATED_LIST_QUERY = gql`
  query CuratedList($curatedListId: String) {
    curatedList(curatedListId: $curatedListId) {
      ...curatedList
    }
  }
  ${CURATED_LIST_FRAGMENT}
`;

export const CREATE_CURATED_LIST_MUTATION = gql`
  mutation CreateCuratedList($name: String) {
    createCuratedList(name: $name) {
      ...curatedList
      curatedPostListings {
        id
        post {
          id
        }
      }
      curatorialMemberships {
        id
        isOwner
      }
    }
  }
  ${CURATED_LIST_FRAGMENT}
`;

export const DEFEATURE_CURATED_LIST_MUTATION = gql`
  mutation DefeatureCuratedList($curatedListId: String) {
    defeatureCuratedList(curatedListId: $curatedListId) {
      ...curatedList
    }
  }
  ${CURATED_LIST_FRAGMENT}
`;

export const FEATURE_CURATED_LIST_MUTATION = gql`
  mutation FeatureCuratedList($curatedListId: String) {
    featureCuratedList(curatedListId: $curatedListId) {
      ...curatedList
    }
  }
  ${CURATED_LIST_FRAGMENT}
`;

export const PAGINATED_CURATED_LIST_QUERY = gql`
  query PaginatedCuratedLists(
    $postListingsMetadata: PaginationMetadataInput
    $mediaItemsMetadata: PaginationMetadataInput
    $metadata: PaginationMetadataInput
    $paginationArgs: PaginationArgsInput
    $type: String
  ) {
    offsetBasedPaginatedEntries(
      type: $type
      metadata: $metadata
      paginationArgs: $paginationArgs
    ) {
      __typename
      ... on CuratedList {
        ...curatedList
        curatedPostListings(metadata: $postListingsMetadata) {
          id
          post {
            id
            mediaCollection {
              ...mediaCollectionWithPaginatedMediaItems
            }
          }
        }
      }
    }
  }
  ${CURATED_LIST_FRAGMENT}
  ${MEDIA_COLLECTION_WITH_PAGINATED_MEDIA_ITEMS_FRAGMENT}
`;

export const UPDATE_CURATED_LIST_MUTATION = gql`
  mutation UpdateCuratedList(
    $curatedListId: String
    $description: String
    $name: String
  ) {
    updateCuratedList(
      curatedListId: $curatedListId
      description: $description
      name: $name
    ) {
      ...curatedList
    }
  }
  ${CURATED_LIST_FRAGMENT}
`;

export const ADD_TO_CURATED_LIST_MUTATION = gql`
  mutation AddToCuratedList($postId: String, $curatedListId: String) {
    addToCuratedList(postId: $postId, curatedListId: $curatedListId) {
      id
      post {
        ...post
      }
      curatedList {
        id
      }
      user {
        ...currentUserCurator
      }
    }
  }
  ${ALBUM_FRAGMENT}
  ${CURRENT_USER_CURATOR_FRAGMENT}
`;

export const REMOVE_FROM_CURATED_LIST_MUTATION = gql`
  mutation RemoveFromCuratedList($curatedPostListingId: String) {
    removeFromCuratedList(curatedPostListingId: $curatedPostListingId) {
      id
      post {
        id
      }
      curatedList {
        id
      }
      user {
        ...currentUserCurator
      }
    }
  }
  ${CURRENT_USER_CURATOR_FRAGMENT}
`;
