import gql from 'graphql-tag';

// Account

export const ESSENTIAL_USER_FRAGMENT = gql`
  fragment essentialUser on User {
    id
    username
  }
`;

export const USER_WITH_NAMES = gql`
  fragment userWithNames on User {
    id
    displayName
    pronouns
    username
  }
`;

// CMS

export const ALBUM_FRAGMENT = gql`
  fragment post on Post {
    id
    accessType
    likeCount
    commentCount
    description
    imageCount
    insertedAt
    price
    purchaseCount
    title
    topLevelCommentCount
    videoCount
    videoDuration
  }
`;

export const COMMENT_FRAGMENT = gql`
  fragment comment on Comment {
    id
    commentCount
    insertedAt
    isDeleted
    text
  }
`;

export const CREDIT_FRAGMENT = gql`
  fragment credit on Credit {
    id
    canModify
    isVerified
    role
    title
  }
`;

// Curation

export const CURATED_LIST_FRAGMENT = gql`
  fragment curatedList on CuratedList {
    id
    curatedPostCount
    description
    isFeatured
    name
    userFollowerCount
    curatorialMemberships {
      id
      user {
        id
        username
        displayName
      }
    }
  }
`;

// Media

export const MEDIA_ITEM_FRAGMENT = gql`
  fragment mediaItem on MediaItem {
    id
    previewable
    order
    mediaCollection {
      id
    }
    image {
      id
      caption
      filterIntensity
      src
      previewSrc
      previewType
    }
    video {
      id
      caption
      filterIntensity
      src
      previewSrc
      previewType
    }
  }
`;

// Membership

export const PROFILE_FRAGMENT = gql`
  fragment profile on Profile {
    id
    description
    website
  }
`;

export const SUBSCRIPTION_DISCOUNT_FRAGMENT = gql`
  fragment subscriptionDiscount on SubscriptionDiscount {
    id
    activeStarting
    activeUntil
    duration
    isActive
    percentage
    type
    validSubscriberTypes
  }
`;

export const USER_SUBSCRIPTION_FRAGMENT = gql`
  fragment userSubscription on UserSubscription {
    id
    activeStarting
    activeUntil
    autorenew
    totalPaid
    type
    subscriber {
      id
      displayName
      username
    }
    subscribed {
      id
      displayName
      username
    }
  }
`;

// Moderation

export const MODERATION_REQUEST_REPORT_FRAGMENT = gql`
  fragment moderationRequestReport on ModerationRequestReport {
    id
    comment
    isResolved
    user {
      id
      displayName
      username
    }
  }
`;

export const MODERATION_REQUEST_RESPONSE_FRAGMENT = gql`
  fragment moderationRequestResponse on ModerationRequestResponse {
    id
    comment
    hasConfirmed
    shouldRemove
    insertedAt
    user {
      id
      displayName
      username
    }
    moderationRequestResponseFieldRevisions {
      id
      field
      original
      revision
    }
    moderationRequestResponseUserActions {
      id
      activeUntil
      cannotPostAnything
      cannotPostComments
      cannotPostContent
      cannotModerate
      user {
        id
        displayName
        username
      }
    }
  }
`;

// Notifications

export const NOTIFICATION_FRAGMENT = gql`
  fragment notification on Notification {
    id
    isRead
    isSeen
    sortedAt
    type
    count
    post {
      id
      title
    }
    comment {
      id
      parent {
        id
      }
    }
    moderationRequestResponse {
      id
      comment
    }
    userRestriction {
      id
      activeUntil
      cannotPostAnything
      cannotPostComments
      cannotPostContent
      cannotModerate
    }
    latestUser {
      id
      displayName
      username
    }
  }
`;
