import React from 'react';
import CuratedListList from 'src/components/curated-list-list';

interface InterfaceProps {
  postId: string;
}

const PostListListView: React.FC<InterfaceProps> = ({ postId }) => {
  return (
    <CuratedListList
      paginationArgs={{
        postId,
      }}
      useGridLayout={true}
    />
  );
};

export default PostListListView;
