import React, { ReactElement } from 'react';
import { GlobalStyleVariables } from 'src/styles';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import StyledScrollbar from 'src/components/styled-scrollbar';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;

  > * {
    border-right: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 6)}rem
      rgba(${(props): string => props.theme.primaryColor}, 1);

    &:last-child {
      border-right: 0;
    }

    @media ${GlobalStyleVariables.phoneMediaQuery} {
      border-right: 0;

      border-bottom: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 6)}rem
        rgba(${(props): string => props.theme.primaryColor}, 1);

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    display: block;
    overflow: auto;
  }
`;

interface PaneInterfaceProps {
  basis: number;
}

const Pane = styled(StyledScrollbar).attrs((props: PaneInterfaceProps) => ({
  basis: props.basis || 100,
}))`
  flex-grow: 1;
  flex-basis: ${(props): number => props.basis}%;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    flex-basis: 1;
    flex-shrink: 0;
  }
`;

interface ElementWithOptions {
  element: ReactElement;
  basis?: number;
}

interface InterfaceProps {
  children: (ReactElement | ElementWithOptions)[];
  closeModal: () => void;
  contentLabel: string;
  isOpen: boolean;
}

const isElementWithOptions = (
  tbd: ReactElement | ElementWithOptions
): tbd is ElementWithOptions => {
  return 'element' in tbd;
};

const PanedModelView: React.FC<InterfaceProps> = ({
  children,
  closeModal,
  contentLabel,
  isOpen,
}) => {
  return (
    <ReactModal
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={closeModal}
      closeTimeoutMS={GlobalStyleVariables.baseDuration}
      className="PanedModal"
      overlayClassName="PanedModalOverlay"
    >
      <Container>
        {children.map((child, index) => {
          if (isElementWithOptions(child)) {
            return (
              <Pane key={index} basis={child.basis}>
                {child.element}
              </Pane>
            );
          } else {
            return <Pane key={index}>{child}</Pane>;
          }
        })}
      </Container>
    </ReactModal>
  );
};

export default PanedModelView;
