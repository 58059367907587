import React from 'react';
import { MainContainer, HeadingOne, Paragraph, StyledButton } from 'src/styles';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';

const StyledHeadingOne = styled(HeadingOne)`
  margin: ${fibonacci(3)}rem 0;
`;

const RestyledButton = styled(StyledButton)`
  margin: ${fibonacci(5)}rem 0;
  padding: 1rem ${fibonacci(3)}rem;
`;

const CreatorRegistrationApproved: React.FC = () => {
  const { t } = useTranslation('general');
  const navigate = useNavigate();

  function done(): void {
    navigate({
      pathname: '/',
    });
  }

  return (
    <MainContainer>
      <StyledHeadingOne>
        {t('creatorApplicationsApprovedTitle')}
      </StyledHeadingOne>
      <Paragraph>{t('creatorApplicationsApprovedDescription')}</Paragraph>
      <RestyledButton onClick={done}>
        {t('creatorApplicationsApprovedToHome')}
      </RestyledButton>
    </MainContainer>
  );
};

export default CreatorRegistrationApproved;
