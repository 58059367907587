import React from 'react';
import NoContent from 'src/components/no-content';
import { useTranslation } from 'react-i18next';
import UserFollowingRouteSelector from 'src/routes/user/following/following-route-selector';
import useUser from '../use-user';

const UserFollowingRouteView: React.FC = () => {
  const { t } = useTranslation('general');
  const { user } = useUser();

  const totalFollowing =
    user.userFollowingCount + user.curatedListFollowingCount;

  if (totalFollowing === 0) {
    return <NoContent>{t('noFollowing')}</NoContent>;
  } else {
    return <UserFollowingRouteSelector user={user} />;
  }
};

export default UserFollowingRouteView;
