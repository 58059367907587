import React from 'react';
import { useTranslation } from 'react-i18next';
import NavBar from 'src/components/nav-bar';
import { CuratedList } from 'src/types';

interface InterfaceProps {
  curatedList: CuratedList;
}

const ListRouteNav: React.FC<InterfaceProps> = ({ curatedList }) => {
  const { t } = useTranslation('general');
  const content = [
    {
      to: '',
      end: true,
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('curatedListsPostsWithCount', {
        count: curatedList.curatedPostCount,
      }),
    },
    {
      to: 'followers',
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('followersWithCount', {
        count: curatedList.userFollowerCount,
      }),
    },
  ];

  return <NavBar>{content}</NavBar>;
};

export default ListRouteNav;
