import React from 'react';
import LikeorsList from 'src/routes/post/likers/likers/likers-list';
import usePost from '../../use-post';

const PostLikersRouteView: React.FC = () => {
  const { post } = usePost();

  return <LikeorsList likedPostId={post.id} />;
};

export default PostLikersRouteView;
