import React from 'react';
import { Post, PostAccessType } from 'src/types';
import VideoPlayer from 'src/components/video-player';
import NoContent from '../no-content';
import { useTranslation } from 'react-i18next';

interface InterfaceProps {
  className?: string;
  post: Post;
  shouldMute?: boolean;
  shouldPause?: boolean;
  shouldPlayOnView?: boolean;
  shouldShowText?: boolean;
  uncontrollable?: boolean;
}

const PostPreviewView: React.FC<InterfaceProps> = ({
  className,
  post,
  shouldMute,
  shouldPause = true,
  shouldPlayOnView,
  shouldShowText,
  uncontrollable,
}) => {
  const { t } = useTranslation('general');

  if (!post) return <></>;

  const preview = post.mediaCollection?.mediaItems
    ? post.mediaCollection.mediaItems[0]
    : null;

  if (preview?.video?.src || preview?.video?.previewSrc) {
    return (
      <VideoPlayer
        key={post.id}
        shouldMute={shouldMute}
        shouldPause={shouldPause}
        shouldPlayOnView={shouldPlayOnView}
        src={
          (!preview.video.src
            ? preview.video.previewSrc
            : preview.video.src) as string
        }
        uncontrollable={uncontrollable}
        className={className}
      />
    );
  } else if (preview?.image?.src || preview?.image?.previewSrc) {
    return (
      <img
        key={post.id}
        src={!preview.image.src ? preview.image.previewSrc : preview.image.src}
        alt={preview.image.caption}
        className={className}
      />
    );
  } else {
    return (
      <NoContent icon="lock" className={className}>
        {shouldShowText ? (
          post.accessType ===
          PostAccessType['post-access-type-subscription'] ? (
            t('postsMustBeSubbed')
          ) : (
            t('postsMustBePurchased')
          )
        ) : (
          <span />
        )}
      </NoContent>
    );
  }
};

export default PostPreviewView;
