/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { ButtonSet, invertingButtonMixin } from 'src/styles';
import { fibonacci } from 'src/utils/math';
import styled from 'styled-components';
import { GlobalStyleVariables } from 'src/styles/global';
import { NavLink } from 'react-router-dom';

const Nav = styled(ButtonSet)`
  position: sticky;
  top: ${GlobalStyleVariables.headerHeight}rem;
  height: ${GlobalStyleVariables.headerHeight}rem;
  z-index: 9;
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
`;

const Link = styled(NavLink)`
  ${invertingButtonMixin}

  height: ${fibonacci(5)}rem;
`;

export interface NavBarLinkParams {
  to: any;
  end?: boolean;
  content: string;
  preventScrollReset?: boolean;
  scrollToNavOnClick?: boolean;
}

interface InterfaceProps {
  children: NavBarLinkParams[];
  className?: string;
}

const NavBarView: React.FC<InterfaceProps> = ({ children, className }) => {
  const navRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <span ref={anchorRef} />
      <Nav as="nav" className={className} ref={navRef}>
        {children.map((child, index) => {
          return (
            <Link
              to={child.to}
              end={child.end}
              preventScrollReset={child.preventScrollReset}
              className={({ isActive }) => (isActive ? 'active' : '')}
              key={index}
              onClick={() => {
                if (
                  child.scrollToNavOnClick &&
                  navRef.current &&
                  anchorRef.current
                ) {
                  const top = anchorRef.current.getBoundingClientRect().top;

                  if (top < 0) {
                    window.scrollTo(
                      0,
                      Math.round(window.pageYOffset + top) -
                        navRef.current.offsetHeight
                    );
                  }
                }
              }}
            >
              {child.content}
            </Link>
          );
        })}
      </Nav>
    </>
  );
};

export default NavBarView;
