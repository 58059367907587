import React from 'react';
import { Notification } from 'src/types';
import { useTranslation } from 'react-i18next';
import NotificationItemWrapper from 'src/components/notification-item/notification-item-wrapper';

interface InterfaceProps {
  notification: Notification;
  onClick: () => void;
}

const NotificationItemWelcome: React.FC<InterfaceProps> = ({
  notification,
  onClick,
}) => {
  const { t } = useTranslation('general');

  return (
    <NotificationItemWrapper notification={notification} onClick={onClick}>
      {t('notificationsWelcome')}
    </NotificationItemWrapper>
  );
};

export default NotificationItemWelcome;
