import { useMutation } from '@apollo/client';
import { ADD_TO_CURATED_LIST_MUTATION } from 'src/queries';
import { Post, CuratedList } from 'src/types';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { updateCurrentUser, updatePaginatedPosts } from 'src/utils/data';
import { postTitle } from 'src/utils/formatting';

export function useAddToCuratedList(
  post: Post
): (curatedList: CuratedList) => Promise<void> {
  const { t } = useTranslation('general');
  const [addToCuratedListMutation] = useMutation(ADD_TO_CURATED_LIST_MUTATION, {
    update(cache, { data: { addToCuratedList } }) {
      updateCurrentUser(cache, () => {
        return addToCuratedList.user;
      });

      updatePaginatedPosts(
        cache,
        {
          curatedListId: addToCuratedList.curatedList.id,
        },
        (posts) => {
          return [addToCuratedList.post, ...posts];
        }
      );
    },
  });
  const handleGraphQLErrors = useGraphQLErrors();

  return async (curatedList: CuratedList): Promise<void> => {
    try {
      await addToCuratedListMutation({
        variables: {
          postId: post.id,
          curatedListId: curatedList.id,
        },
      });

      toast(t('curatedListsAdded', { title: postTitle(post) }));
    } catch (e) {
      handleGraphQLErrors(e as Error, (message) => {
        toast(message, { type: 'error' });
      });
    }
  };
}
