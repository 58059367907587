import React from 'react';
import { StyledLabel, StyledFieldError, StyledLabelName } from 'src/styles';
import { Field } from 'formik';
import SelectField from 'src/components/select-field';
import { GenericOptionItem } from 'src/types';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import styled from 'styled-components';

const StyledField = styled(Field)`
  justify-content: flex-start;
  margin-top: 1rem;
  width: 100%;
  border: 0;
  background: transparent;
  border-bottom: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
    rgba(${(props): string => props.theme.primaryColor}, ${goldenRatioInverse});

  a {
    text-align: left;
  }

  > div {
    top: 104%;
    left: -${fibonacci(1) * Math.pow(goldenRatioInverse, 5)}rem;
    right: initial;

    > div {
      border: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
        rgba(
          ${(props): string => props.theme.primaryColor},
          ${goldenRatioInverse}
        );
      border-top: 0;
    }
  }
`;

interface InterfaceProps {
  className?: string;
  disabled?: boolean;
  dropdownIcon?: boolean | JSX.Element;
  label: string;
  name: string;
  options: GenericOptionItem[];
}

const SelectView: React.FC<InterfaceProps> = ({
  className,
  disabled,
  dropdownIcon,
  label,
  name,
  options,
}) => {
  return (
    <StyledLabel disabled={disabled}>
      <StyledLabelName>{label}</StyledLabelName>
      <StyledFieldError name={name} component="div" />
      <StyledField
        className={className}
        component={SelectField}
        dropdownIcon={dropdownIcon}
        name={name}
        options={options}
      />
    </StyledLabel>
  );
};

export default SelectView;
