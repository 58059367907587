/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from 'src/types';
import { useNavigate } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { PAGINATED_ALBUM_QUERY } from 'src/queries';
import { generateMediaItemsMetadataPreview } from 'src/utils/pagination';

export function useReloadAndViewUser(user: User): () => Promise<void> {
  const navigate = useNavigate();
  const [getPosts] = useLazyQuery(PAGINATED_ALBUM_QUERY);

  return async (): Promise<void> => {
    await getPosts({
      variables: {
        mediaItemsMetadata: generateMediaItemsMetadataPreview(),
        metadata: { offset: 0, limit: 20 },
        paginationArgs: { userId: user.id },
        type: 'Post',
      },
    });

    setTimeout(() => {
      navigate({
        pathname: `/${user.username}`,
      });
    }, 10);
  };
}
