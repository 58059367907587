import React from 'react';
import { User } from 'src/types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';

const Container = styled.div`
  margin: 1rem 0;
  padding: ${fibonacci(5)}rem ${fibonacci(3)}rem;
  border: dotted ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  text-align: center;
`;

interface InterfaceProps {
  user?: User;
}

const BanScreenView: React.FC<InterfaceProps> = ({ children, user }) => {
  const { t } = useTranslation('general');

  if (user?.restrictions?.cannotPostContent) {
    return <Container>{t('youAreBanned')}</Container>;
  } else {
    return <>{children}</>;
  }
};

export default BanScreenView;
