import React from 'react';
import PostForm from 'src/components/post-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_ALBUM_MUTATION } from 'src/queries';
import { PostInput } from 'src/types';
import { uploadPost } from 'src/utils/uploads';

const PublishRouteView: React.FC = () => {
  const navigate = useNavigate();
  const [createPostMutation] = useMutation(CREATE_ALBUM_MUTATION);

  async function cancel(): Promise<void> {
    navigate({
      pathname: `/`,
    });
  }

  async function submit(post: PostInput): Promise<void> {
    const { id } = await uploadPost(post, createPostMutation);

    navigate({
      pathname: `/posts/${id}`,
    });
  }

  return <PostForm cancel={cancel} submit={submit} />;
};

export default PublishRouteView;
