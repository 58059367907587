import React from 'react';
import { ModerationRequest } from 'src/types';
import { useTranslation } from 'react-i18next';
import ModerationRequestItemWrapper from 'src/components/moderation-request-item/moderation-request-item-wrapper';
import { HeadingTwo } from 'src/styles';
import styled from 'styled-components';
import MediaItemCarousel from 'src/components/media-item-carousel';
import PostSummary from 'src/components/post-summary';
import { fibonacci } from 'src/utils/math';
import ModerationRequestItemReports from 'src/components/moderation-request-item/moderation-request-item-reports';
import CommentList from 'src/components/comment-list';

const Carousel = styled(MediaItemCarousel)`
  display: flex;
  height: ${fibonacci(9)}rem;
`;

interface InterfaceProps {
  moderationRequest: ModerationRequest;
}

const ModerationRequestItemCommentModerationRequest: React.FC<InterfaceProps> =
  ({ moderationRequest }) => {
    const { t } = useTranslation('general');
    const comment = moderationRequest.comment;

    return (
      <ModerationRequestItemWrapper
        moderationRequest={moderationRequest}
        isDisciplinary={true}
        users={[comment.user]}
        fields={[
          {
            field: 'text',
            original: comment.text,
            type: 'textarea',
          },
        ]}
      >
        <HeadingTwo>{t('moderationCommentModerationRequest')}</HeadingTwo>
        <ModerationRequestItemReports moderationRequest={moderationRequest} />
        <Carousel
          paginationArgs={{
            mediaCollectionId: comment.post.mediaCollection.id,
          }}
        />
        <PostSummary post={comment.post} />
        <CommentList
          activeCommentId={comment.id}
          activeParentId={comment.parent ? comment.parent.id : undefined}
          post={comment.post}
          paginationArgs={{
            postId: comment.post.id,
          }}
        />
      </ModerationRequestItemWrapper>
    );
  };

export default ModerationRequestItemCommentModerationRequest;
