import React from 'react';
import styled from 'styled-components';
import { User } from 'src/types';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { useNavigate } from 'react-router';
import { Ellipsis, PrimaryColorLink } from 'src/styles';
import { GlobalStyleVariables } from 'src/styles/global';
import UserCardMediaItem from 'src/components/user-card/user-card-media-item';
import UserCardFollowButton from 'src/components/user-card/user-card-follow-button';
import { useDoubleTap } from 'use-double-tap';
import { parseMarkdown } from 'src/utils/security';

const Container = styled.div`
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${(1 - goldenRatioInverse) * (100 / 2.5)}rem;
`;

const InfoContainerContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${fibonacci(1) * goldenRatioInverse}rem;
  background: rgba(
    ${(props): string => props.theme.backgroundColor},
    ${goldenRatioInverse}
  );
  overflow: hidden;
`;

const Username = styled(PrimaryColorLink)`
  display: block;
  flex-grow: 1;
  padding: 1rem 0;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Bio = styled(Ellipsis)`
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

interface InterfaceProps {
  user: User;
}

const UserCardView: React.FC<InterfaceProps> = ({ user }) => {
  const navigate = useNavigate();
  const onNav = useDoubleTap(() => {
    navigate({
      pathname: `/${user.username}`,
    });
  });

  return (
    <Container {...onNav}>
      <UserCardMediaItem user={user} />
      <InfoContainer>
        <InfoContainerContent>
          <Username to={`/${user.username}`}>@{user.username}</Username>
          <Bio
            dangerouslySetInnerHTML={parseMarkdown(user.profile.description)}
          />
        </InfoContainerContent>
      </InfoContainer>
      <ButtonContainer>
        <UserCardFollowButton user={user} />
      </ButtonContainer>
    </Container>
  );
};

export default UserCardView;
