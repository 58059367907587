import gql from 'graphql-tag';
import { NOTIFICATION_FRAGMENT } from 'src/queries/fragments__simple';

export const PAGINATED_NOTIFICATIONS_QUERY = gql`
  query paginatedNotifications(
    $paginationArgs: PaginationArgsInput
    $metadata: PaginationMetadataInput
    $type: String
  ) {
    offsetBasedPaginatedEntries(
      type: $type
      metadata: $metadata
      paginationArgs: $paginationArgs
    ) {
      __typename
      ... on Notification {
        ...notification
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const SEE_NOTIFICATIONS_MUTATION = gql`
  mutation SeeNotifications($notificationIds: [ID]) {
    seeNotifications(notificationIds: $notificationIds) {
      id
      isSeen
    }
  }
`;

export const READ_NOTIFICATION_MUTATION = gql`
  mutation ReadNotification($notificationId: ID) {
    readNotification(notificationId: $notificationId) {
      id
      isRead
    }
  }
`;
