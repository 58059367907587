import React from 'react';
import * as Diff from 'diff';
import styled from 'styled-components';

const Unmodified = styled.span``;

const Added = styled.span`
  color: green;
`;

const Removed = styled.span`
  color: red;
`;

interface InterfaceProps {
  original?: string;
  revision?: string;
}

const DiffedTextView: React.FC<InterfaceProps> = ({ original, revision }) => {
  const diff = Diff.diffChars(original || '', revision || '');

  return (
    <>
      {diff.map((segment, index) => {
        if (segment.added) {
          return <Added key={index}>{segment.value}</Added>;
        } else if (segment.removed) {
          return <Removed key={index}>{segment.value}</Removed>;
        } else {
          return <Unmodified key={index}>{segment.value}</Unmodified>;
        }
      })}
    </>
  );
};

export default DiffedTextView;
