import React from 'react';
import { useLocation } from 'react-router';
import PostList from 'src/components/post-list';
import CuratedListCarousel from 'src/components/curated-list-carousel';
import UserCarousel from 'src/components/user-carousel';
import SearchSort from 'src/routes/search/search-sort-view';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles';

const StyledPanel = styled.div`
  margin-bottom: 1rem;
`;

const StyledPostList = styled(PostList)`
  padding-top: ${fibonacci(3)}rem;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    padding-top: 0;
  }
`;

const SearchRouteView: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query') || '';
  const queryState = (location.state || {}) as { [key: string]: string };
  const nonPostOrder =
    !queryState.orderType ||
    queryState.orderType === 'orderFeatured' ||
    queryState.orderType === 'orderAffordable'
      ? 'orderPopular'
      : queryState.orderType;

  return (
    <>
      <SearchSort isPost={true} />
      <StyledPanel>
        <UserCarousel
          hideIfEmpty={true}
          paginationArgs={{
            query,
            order: nonPostOrder,
          }}
        />
      </StyledPanel>
      <div>
        <CuratedListCarousel
          hideIfEmpty={true}
          paginationArgs={{
            query,
            order: nonPostOrder,
          }}
        />
      </div>
      <StyledPostList
        paginationArgs={{
          query,
          order: queryState.orderType || 'orderPopular',
        }}
        useCarousel={true}
      />
    </>
  );
};

export default SearchRouteView;
