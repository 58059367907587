import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Ellipsis, MutedLink } from 'src/styles';
import { useTranslation } from 'react-i18next';
import { CuratorialMembership } from 'src/types/schema';
import { GlobalStyleVariables } from 'src/styles/global';

const CuratorialMembershipContainer = styled.div`
  flex-shrink: 0;
  padding: 0 1rem;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface InterfaceProps {
  curatorialMemberships: CuratorialMembership[];
}

const CuratedListCardCurators: React.FC<InterfaceProps> = ({
  curatorialMemberships,
}) => {
  const { t } = useTranslation('general');

  function renderCurator(
    curatorialMembership: CuratorialMembership
  ): ReactElement {
    const user = curatorialMembership.user;

    return (
      <MutedLink to={`/${user.username}`}>
        <Ellipsis>@{user.username}</Ellipsis>
      </MutedLink>
    );
  }

  if (curatorialMemberships.length === 1) {
    return (
      <CuratorialMembershipContainer>
        {renderCurator(curatorialMemberships[0])}
      </CuratorialMembershipContainer>
    );
  } else if (curatorialMemberships.length === 2) {
    return (
      <CuratorialMembershipContainer>
        {renderCurator(curatorialMemberships[0])} &amp;{' '}
        {renderCurator(curatorialMemberships[1])}
      </CuratorialMembershipContainer>
    );
  } else {
    const user = curatorialMemberships[0].user;

    return (
      <CuratorialMembershipContainer>
        <MutedLink to={`/${user.username}`}>
          <Ellipsis>
            {t('userAndOthers', {
              user: `@${user.username}`,
              count: curatorialMemberships.length - 1,
            })}
          </Ellipsis>
        </MutedLink>
      </CuratorialMembershipContainer>
    );
  }
};

export default CuratedListCardCurators;
