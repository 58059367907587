import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { MutedLink } from 'src/styles';
import { useTranslation } from 'react-i18next';
import { Credit } from 'src/types/schema';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles/global';

const CreditContainer = styled.div`
  flex-shrink: 0;
  margin-top: ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface InterfaceProps {
  credits: Credit[];
}

const PostCardCredits: React.FC<InterfaceProps> = ({ credits }) => {
  const { t } = useTranslation('general');

  function renderCredit(credit: Credit): ReactElement {
    const user = credit.user;

    return <MutedLink to={`/${user.username}`}>@{user.username}</MutedLink>;
  }

  if (credits.length === 1) {
    return <CreditContainer>{renderCredit(credits[0])}</CreditContainer>;
  } else if (credits.length === 2) {
    return (
      <CreditContainer>
        {renderCredit(credits[0])} &amp; {renderCredit(credits[1])}
      </CreditContainer>
    );
  } else {
    const user = credits[0].user;

    return (
      <CreditContainer>
        <MutedLink to={`/${user.username}`}>
          {t('userAndOthers', {
            user: `@${user.username}`,
            count: credits.length - 1,
          })}
        </MutedLink>
      </CreditContainer>
    );
  }
};

export default PostCardCredits;
