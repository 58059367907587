import React from 'react';
import { useTranslation } from 'react-i18next';
import NavBar from 'src/components/nav-bar';
import { NavBarLinkParams } from 'src/components/nav-bar/nav-bar-view';
import { Post } from 'src/types';

interface InterfaceProps {
  post: Post;
}

const PostRouteNav: React.FC<InterfaceProps> = ({ post }) => {
  const { t } = useTranslation('general');

  const options = [
    {
      to: '',
      end: true,
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('postsCommentsWithCount', { count: post.commentCount }),
    } as NavBarLinkParams,
  ];

  if (post.curatedListCount > 0) {
    options.push({
      to: `lists`,
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('postsListedWithCount', { count: post.curatedListCount }),
    });
  }

  if (post.likeCount > 0) {
    options.push({
      to: `likes`,
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('postsLikedWithCount', { count: post.likeCount }),
    });
  }

  return <NavBar>{options}</NavBar>;
};

export default PostRouteNav;
