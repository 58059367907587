import React from 'react';
import { PrimaryColorVanillaLink } from 'src/styles';
import { useTranslation } from 'react-i18next';

interface InterfaceProps {
  toggleLoginModal: () => void;
  toggleRegisterModal: () => void;
}

const HeaderLoginButtons: React.FC<InterfaceProps> = ({
  toggleLoginModal,
  toggleRegisterModal,
}) => {
  const { t } = useTranslation('general');

  return (
    <div>
      <PrimaryColorVanillaLink
        data-testid="login-link"
        onClick={toggleLoginModal}
      >
        {t('login')}
      </PrimaryColorVanillaLink>
      /
      <PrimaryColorVanillaLink
        data-testid="register-link"
        onClick={toggleRegisterModal}
      >
        {t('register')}
      </PrimaryColorVanillaLink>
    </div>
  );
};

export default HeaderLoginButtons;
