import React from 'react';
import { CuratedList } from 'src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/use-auth';
import { useFeatureCuratedList } from 'src/hooks/use-feature-curated-list';
import { useDefeatureCuratedList } from 'src/hooks/use-defeature-curated-list';
import DropdownButtonWithArrowNav from 'src/components/dropdown-button-with-arrow-nav';
import { useAccountModals } from 'src/hooks/use-account-modals';
import { useEnsureUser } from 'src/hooks/use-ensure-user';

const StyledDropdownButtonWithArrowNav = styled(DropdownButtonWithArrowNav)`
  a {
    width: ${fibonacci(8)}rem;
    text-align: right;
    padding: ${fibonacci(3) / 2}rem ${fibonacci(3)}rem;
  }
`;

interface InterfaceProps {
  curatedList: CuratedList;
}

const CuratedListSummaryMenu: React.FC<InterfaceProps> = ({ curatedList }) => {
  const { t } = useTranslation('general');
  const { user } = useAuth();
  const { openModal } = useAccountModals();
  const ensureUser = useEnsureUser();
  const featureCuratedList = useFeatureCuratedList(curatedList);
  const defeatureCuratedList = useDefeatureCuratedList(curatedList);

  const options = [];

  const report = async (): Promise<void> => {
    await ensureUser();
    if (openModal) await openModal('reportCuratedList', { curatedList });
  };

  if (user?.permissions?.canFeatureLists) {
    if (curatedList.isFeatured) {
      options.push({
        label: t('curatedListsDefeature'),
        value: defeatureCuratedList,
      });
    } else {
      options.push({
        label: t('curatedListsFeature'),
        value: featureCuratedList,
      });
    }
  }

  options.push({
    label: t('curatedListsModerationReport'),
    value: report,
  });

  function onChange(cb: () => void): void {
    cb();
  }

  return (
    <StyledDropdownButtonWithArrowNav
      button={<FontAwesomeIcon icon="ellipsis-h" />}
      onChange={onChange}
      options={options}
    />
  );
};

export default CuratedListSummaryMenu;
