import { default as Carousel } from 'src/components/carousel/carousel-view';
import { GlobalStyleVariables } from 'src/styles';
import { fibonacci } from 'src/utils/math';
import styled from 'styled-components';

export default Carousel;

export const StyledCarousel = styled(Carousel)`
  display: flex;
  height: 100%;

  > div {
    width: ${fibonacci(9)}rem;
    height: ${fibonacci(9)}rem;

    margin: 0 1rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    > div {
      width: 100vw;
      height: 100vw;
    }
  }
`;
