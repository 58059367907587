import moment from 'moment';
import { Post } from 'src/types';

export function formatDuration(duration: number): string {
  const videoHours = Math.floor(duration / 3600);
  const videoMinutes = Math.floor((duration % 3600) / 60);
  const videoSeconds = duration % 60;
  const videoParts = [];
  if (videoHours > 0) videoParts.push(videoHours);
  videoParts.push(videoMinutes);
  videoParts.push(videoSeconds);
  return videoParts.join(':');
}

export function postTitle(post: Post): string {
  return post.title ? post.title : moment(post.insertedAt).format('MM/DD/YY');
}
