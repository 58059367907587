import React from 'react';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import Root from 'src/components/app/root';
import PostIndexRoute from 'src/routes/post/index';
import PostCommentsRoute from 'src/routes/post/comments';
import PostListsRoute from 'src/routes/post/lists';
import CreatorRegistrationRoute from 'src/routes/creator-registration';
import FeaturedRoute from 'src/routes/featured';
import HomeRoute from 'src/routes/home';
import ListRoute from 'src/routes/list';
import ModerationRoute from 'src/routes/moderation';
import NotificationsRoute from 'src/routes/notifications';
import PublishRoute from 'src/routes/publish';
import SearchRoute from 'src/routes/search';
import UserRoute from 'src/routes/user';
import UserListsRoute from 'src/routes/user/likes';
import UserListLikeRoute from 'src/routes/user/likes/everything';
import UserListsListsRoute from 'src/routes/user/likes/lists';
import UserFollowersRoute from 'src/routes/user/followers';
import UserFollowingRoute from 'src/routes/user/following';
import UserFollowingListsRoute from 'src/routes/user/following/lists';
import UserFollowingUsersRoute from 'src/routes/user/following/users';
import UserUploadsRoute from 'src/routes/user/uploads';
import UsersRoute from 'src/routes/users';
import ListCollectionRoute from 'src/routes/list/collection';
import ListFollowersRoute from 'src/routes/list/followers';
import SearchAllRoute from 'src/routes/search/all';
import SearchUsersRoute from 'src/routes/search/users';
import SearchVidsRoute from 'src/routes/search/vids';
import SearchPicsRoute from 'src/routes/search/pics';
import SearchListsRoute from 'src/routes/search/lists';
import { useAuth } from 'src/hooks/use-auth';
import UserListPurchasesRoute from 'src/routes/user/likes/purchases';
import PostBuyersRoute from 'src/routes/post/likers/buyers';
import PostLikersParentRoute from 'src/routes/post/likers';
import PostLikersRoute from 'src/routes/post/likers/likers';

const AppRouter: React.FC = () => {
  const { startedWithAuthToken, user } = useAuth();

  const router = createBrowserRouter([
    {
      path: '/',
      Component: Root,
      children: [
        {
          index: true,
          Component: HomeRoute,
          loader() {
            if (!user && !startedWithAuthToken) {
              return redirect('featured');
            }

            return null;
          },
        },
        {
          path: 'creator_registration',
          Component: CreatorRegistrationRoute,
        },
        { path: 'featured', Component: FeaturedRoute },
        {
          path: 'lists/:curatedListId',
          Component: ListRoute,
          children: [
            { index: true, Component: ListCollectionRoute },
            { path: 'followers', Component: ListFollowersRoute },
          ],
        },
        { path: 'moderation', Component: ModerationRoute },
        { path: 'notifications', Component: NotificationsRoute },
        {
          path: 'posts/:postId',
          Component: PostIndexRoute,
          children: [
            { index: true, Component: PostCommentsRoute },
            { path: 'lists', Component: PostListsRoute },
            {
              path: 'likes',
              Component: PostLikersParentRoute,
              children: [
                { index: true, Component: PostLikersRoute },
                { path: 'buyers', Component: PostBuyersRoute },
              ],
            },
          ],
        },
        { path: 'publish', Component: PublishRoute },
        {
          path: 'search',
          Component: SearchRoute,
          children: [
            { index: true, Component: SearchAllRoute },
            { path: 'users', Component: SearchUsersRoute },
            { path: 'vids', Component: SearchVidsRoute },
            { path: 'pics', Component: SearchPicsRoute },
            { path: 'lists', Component: SearchListsRoute },
          ],
        },
        { path: 'users', Component: UsersRoute },
        {
          path: ':username',
          Component: UserRoute,
          children: [
            { index: true, Component: UserUploadsRoute },
            {
              path: 'likes',
              Component: UserListsRoute,
              children: [
                { index: true, Component: UserListLikeRoute },
                { path: 'purchases', Component: UserListPurchasesRoute },
                { path: 'lists', Component: UserListsListsRoute },
              ],
            },
            {
              path: 'following',
              Component: UserFollowingRoute,
              children: [
                { index: true, Component: UserFollowingUsersRoute },
                { path: 'lists', Component: UserFollowingListsRoute },
              ],
            },
            { path: 'followers', Component: UserFollowersRoute },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
