import React from 'react';
import PostSummary from 'src/components/post-summary';
import styled from 'styled-components';
import MediaItemCarousel from 'src/components/media-item-carousel';
import { GlobalStyleVariables } from 'src/styles/global';
import { Post, PostAccessType } from 'src/types';
import { useTranslation } from 'react-i18next';
import { usePurchasePost } from 'src/hooks/use-purchase-post';
import { useSubscribe } from 'src/hooks/use-subscribe';
import { useReloadAndViewPost } from 'src/hooks/use-reload-and-view-post';

const BigMediaItemCarousel = styled(MediaItemCarousel)`
  width: 100%;
  align-items: center;
  cursor: default;

  > div {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 100%;

    img,
    video {
      max-height: calc(
        100svh - ${GlobalStyleVariables.headerHeight}rem - 18rem
      );
      flex-shrink: 0;
      display: block;
      width: 100%;
    }

    img {
      object-fit: contain;
    }
  }

  &:focus {
    outline: 0;
  }
`;

interface InterfaceProps {
  post: Post;
  setIsEditing: (isEditing: boolean) => void;
}

const PostRouteContent: React.FC<InterfaceProps> = ({ post, setIsEditing }) => {
  const { t } = useTranslation('general');
  const onPurchase = usePurchasePost(post);
  const onSubscribe = useSubscribe(post.credits[0].user);
  const onReloadCurrentPost = useReloadAndViewPost(post);

  const subscribe = async () => {
    await onSubscribe(null, {});
    await onReloadCurrentPost();
  };

  return (
    <>
      <BigMediaItemCarousel
        alwaysShowIndexer={true}
        canClickToPlay={true}
        paginationArgs={{
          mediaCollectionId: post.mediaCollection.id,
        }}
        noContentText={
          post.accessType === PostAccessType['post-access-type-pay']
            ? t('postsToViewPay')
            : post.accessType ===
              PostAccessType['post-access-type-subscription']
            ? t('postsToViewSubscribe')
            : undefined
        }
        noContentClickCb={
          post.accessType === PostAccessType['post-access-type-pay']
            ? onPurchase
            : post.accessType ===
              PostAccessType['post-access-type-subscription']
            ? subscribe
            : undefined
        }
      />
      <PostSummary
        post={post}
        setIsEditing={setIsEditing}
        disableHeaderLink={true}
      />
    </>
  );
};

export default PostRouteContent;
