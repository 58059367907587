import React from 'react';
import { Tag } from 'src/types';
import { AutocompleteFieldSuggestion } from '.';
import { useTranslation } from 'react-i18next';
import { Ellipsis } from 'src/styles';

interface InterfaceProps {
  tag: Tag;
}

const AutocompleteFieldTagSuggestion: React.FC<InterfaceProps> = ({
  tag,
  ...props
}) => {
  const { t } = useTranslation('general');

  return (
    <AutocompleteFieldSuggestion {...props}>
      <Ellipsis>#{tag.name}</Ellipsis>
      <span>-</span>
      <span>{t('tagsTotalUsage', { count: tag.discoverableCount })}</span>
    </AutocompleteFieldSuggestion>
  );
};

export default AutocompleteFieldTagSuggestion;
