import React from 'react';
import DropdownButtonWithArrowNav from 'src/components/dropdown-button-with-arrow-nav';
import {
  GenericOptionItem,
  GenericOptionItemLabel,
  GenericOptionItemValue,
} from 'src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const DropdownIconContainer = styled.span`
  margin-left: 1rem;
`;

interface InterfaceProps {
  className?: string;
  dropdownIcon?: boolean | JSX.Element;
  label?: GenericOptionItemLabel;
  onChange: (value: GenericOptionItemValue) => void;
  options: GenericOptionItem[];
  value: GenericOptionItemValue;
}

const SelectView: React.FC<InterfaceProps> = ({
  className,
  dropdownIcon = <FontAwesomeIcon icon="caret-down" />,
  label,
  onChange,
  options,
  value,
}) => {
  const selectedOption = options.find((option) => option.value === value);
  const buttonText = selectedOption ? selectedOption.label : label || '';
  const button =
    dropdownIcon === false ? (
      buttonText
    ) : (
      <>
        {buttonText}
        <DropdownIconContainer>{dropdownIcon}</DropdownIconContainer>
      </>
    );

  return (
    <DropdownButtonWithArrowNav
      className={className}
      button={button}
      initialIndex={selectedOption ? options.indexOf(selectedOption) : 0}
      onChange={onChange}
      options={options}
    />
  );
};

export default SelectView;
