import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Carousel from 'src/components/carousel';
import FileUploaderQueueItem from 'src/components/file-uploader/queue/file-uploader-queue-item';
import { FileUpload } from 'src/types';

const FileUploaderCarousel = styled(Carousel)`
  display: flex;
  height: 100%;

  > div {
    display: flex;
    height: 100%;
    margin: 0 1px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    img,
    video {
      height: 100%;
      flex: 0 0 auto;
      align-self: center;
    }
  }
`;

export interface DragIndexContainer {
  dragIndex?: number;
}

interface InterfaceProps {
  addFilesToQueue: (files: FileList, index?: number) => Promise<void>;
  className?: string;
  files: FileUpload[];
  mode: string;
  removeFile: (index: number) => void;
  saveFilteredImage: (index: number, file: FileUpload) => void;
  swap: (indexA: number, indexB: number) => void;
  selectFile: (file: FileUpload) => void;
}

const FileUploaderQueueCarousel: React.FC<InterfaceProps> = ({
  addFilesToQueue,
  className,
  files,
  mode,
  removeFile,
  saveFilteredImage,
  swap,
  selectFile,
}) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [dragIndexContainer] = useState({ dragIndex: 0 } as DragIndexContainer);
  const setDragIndex = (index: number | undefined): void => {
    dragIndexContainer.dragIndex = index;
  };

  const moveItem = useCallback(
    (hoverIndex: number) => {
      const { dragIndex } = dragIndexContainer;
      if (dragIndex === undefined || dragIndex === hoverIndex) return;

      swap(dragIndex, hoverIndex);
      dragIndexContainer.dragIndex = hoverIndex;
    },
    [swap, dragIndexContainer]
  );
  return (
    <FileUploaderCarousel
      className={className}
      scrollElementRef={ref}
      setScrollElementRef={setRef}
    >
      {() => {
        return (
          <>
            {files.map((file, index) => {
              return (
                <FileUploaderQueueItem
                  addFilesToQueue={addFilesToQueue}
                  dragIndexContainer={dragIndexContainer}
                  key={file.result}
                  file={file}
                  index={index}
                  mode={mode}
                  onDrag={setDragIndex}
                  moveItem={moveItem}
                  removeFile={removeFile}
                  saveFilteredImage={saveFilteredImage}
                  selectFile={selectFile}
                />
              );
            })}
          </>
        );
      }}
    </FileUploaderCarousel>
  );
};

export default FileUploaderQueueCarousel;
