import React, { Fragment } from 'react';
import { CuratedList } from 'src/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const MutedDiv = styled.div`
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
`;

interface InterfaceProps {
  curatedList: CuratedList;
}

const CuratedListSummarySumplementaryInfo: React.FC<InterfaceProps> = ({
  curatedList,
}) => {
  const { t } = useTranslation('general');

  return (
    <Fragment>
      {curatedList.isFeatured && <span>{t('curatedListsIsFeatured')}</span>}
      {curatedList.curatedPostCount > 0 && (
        <MutedDiv>
          {t('curatedListsPostsWithCount', {
            count: curatedList.curatedPostCount,
          })}
        </MutedDiv>
      )}
    </Fragment>
  );
};

export default CuratedListSummarySumplementaryInfo;
