import React from 'react';
import { PaginatableType, Comment, Post } from 'src/types';
import NoContent from 'src/components/no-content';
import CommentCard from 'src/components/comment-card';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { fibonacci } from 'src/utils/math';

const List = styled.div`
  > * {
    margin: ${fibonacci(3)}rem 0;
  }
`;

const LoadMore = styled.a`
  display: block;
  margin: ${fibonacci(3)}rem 0;
  cursor: pointer;
`;

interface InterfaceProps {
  activeCommentId?: string;
  activeParentId?: string;
  post: Post;
  entries: PaginatableType[];
  onLoadMore: () => Promise<void>;
  openReply?: () => void;
  parent?: Comment;
}

const CommentListView: React.FC<InterfaceProps> = ({
  activeCommentId,
  activeParentId,
  post,
  entries,
  onLoadMore,
  openReply,
  parent,
}) => {
  const { t } = useTranslation('general');

  if (!entries || entries.length === 0) {
    return <NoContent></NoContent>;
  }

  // TODO: aggregate these with a graphdb query;
  // you know, once we're using graphdb :)
  const usedCommentIds = [] as string[];
  entries = entries.filter((comment) => {
    comment = comment as Comment;
    const canDisplay = usedCommentIds.indexOf(comment.id) === -1;
    usedCommentIds.push(comment.id);
    return canDisplay;
  });

  const unloadedEntries = parent
    ? parent.commentCount - entries.length
    : post
    ? post.topLevelCommentCount - entries.length
    : 0;

  if (
    !activeParentId &&
    activeCommentId &&
    unloadedEntries > 0 &&
    !entries.some((entry) => entry.id === activeCommentId)
  ) {
    onLoadMore();
  }

  return (
    <div>
      {unloadedEntries > 0 && (
        <LoadMore onClick={onLoadMore}>
          {t('commentsLoadMore', { amount: unloadedEntries })}
        </LoadMore>
      )}
      <List>
        {entries
          .slice(0)
          .reverse()
          .map((comment) => {
            comment = comment as Comment;

            return (
              <CommentCard
                key={comment.id}
                activeCommentId={activeCommentId}
                activeParentId={activeParentId}
                post={post}
                comment={comment}
                parent={parent}
                openReply={openReply}
              />
            );
          })}
      </List>
    </div>
  );
};

export default CommentListView;
