/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent } from 'react';
import {
  StyledLabel,
  StyledLabelName,
  StyledFieldError,
  StyledInput,
} from 'src/styles';
import { isMobile } from 'src/utils/detection';

interface InterfaceProps {
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  label: string;
  min?: number;
  max?: number;
  name: string;
  placeholder?: string;
  step?: string;
  type?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputView: React.FC<InterfaceProps> = ({
  autoComplete,
  autoFocus,
  disabled,
  label,
  min,
  max,
  name,
  placeholder,
  step,
  type,
  onChange,
  ...props
}) => {
  if (isMobile()) autoFocus = false;

  return (
    <StyledLabel disabled={disabled}>
      <StyledLabelName>{label}</StyledLabelName>
      <StyledFieldError name={name} component="div" />
      {onChange ? (
        <StyledInput
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          min={min}
          max={max}
          name={name}
          placeholder={placeholder}
          step={step}
          type={type}
          onChange={onChange}
          {...props}
        />
      ) : (
        <StyledInput
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          min={min}
          max={max}
          name={name}
          placeholder={placeholder}
          step={step}
          type={type}
          {...props}
        />
      )}
    </StyledLabel>
  );
};

export default InputView;
