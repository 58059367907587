import React from 'react';
import { StyledLabel, StyledFieldError, StyledInput } from 'src/styles';
import { Field } from 'formik';
import SelectField from 'src/components/select-field';
import styled from 'styled-components';
import { GenericOptionItem } from 'src/types';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';

const Container = styled.div`
  display: flex;
`;

const StyledField = styled(Field)`
  justify-content: flex-start;

  a {
    text-align: left;
  }

  > div {
    left: -${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem;
    right: initial;
  }
`;

interface InterfaceProps {
  className?: string;
  disabled?: boolean;
  dropdownIcon?: boolean | JSX.Element;
  name: string;
  placeholder?: string;
  selectName: string;
  selectOptions: GenericOptionItem[];
}

const SelectInputView: React.FC<InterfaceProps> = ({
  className,
  disabled,
  dropdownIcon,
  name,
  placeholder,
  selectName,
  selectOptions,
}) => {
  return (
    <StyledLabel disabled={disabled}>
      <StyledFieldError name={name} component="div" />
      <Container>
        <StyledField
          className={className}
          component={SelectField}
          dropdownIcon={dropdownIcon}
          name={selectName}
          options={selectOptions}
        />
        <StyledInput name={name} placeholder={placeholder} />
      </Container>
    </StyledLabel>
  );
};

export default SelectInputView;
