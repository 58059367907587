import React from 'react';
import styled from 'styled-components';
import { Post } from 'src/types';
import PostPreview from 'src/components/post-preview';

const MediaItemContainer = styled.div`
  position: relative;
`;

const StyledPostPreview = styled(PostPreview)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

interface InterfaceProps {
  post: Post;
  shouldMute?: boolean;
  shouldPlayOnView?: boolean;
}

const PostCardMediaItem: React.FC<InterfaceProps> = ({
  post,
  shouldMute = false,
  shouldPlayOnView = true,
}) => {
  return (
    <MediaItemContainer>
      <StyledPostPreview
        post={post}
        shouldMute={shouldMute}
        shouldPlayOnView={shouldPlayOnView}
      />
    </MediaItemContainer>
  );
};

export default PostCardMediaItem;
