import gql from 'graphql-tag';
import { MODERATION_REQUEST_FRAGMENT } from 'src/queries/fragments__complex';
import {
  MODERATION_REQUEST_RESPONSE_FRAGMENT,
  MODERATION_REQUEST_REPORT_FRAGMENT,
} from 'src/queries/fragments__simple';

export const PAGINATED_MODERATION_REQUEST_QUERY = gql`
  query paginatedModerationRequests(
    $commentsMetadata: PaginationMetadataInput
    $commentChildrenMetadata: PaginationMetadataInput
    $mediaItemsMetadata: PaginationMetadataInput
    $paginationArgs: PaginationArgsInput
    $metadata: PaginationMetadataInput
    $type: String
  ) {
    offsetBasedPaginatedEntries(
      type: $type
      metadata: $metadata
      paginationArgs: $paginationArgs
    ) {
      __typename
      ... on ModerationRequest {
        ...moderationRequest
        moderationRequestResponses {
          ...moderationRequestResponse
        }
      }
    }
  }
  ${MODERATION_REQUEST_FRAGMENT}
  ${MODERATION_REQUEST_RESPONSE_FRAGMENT}
`;

export const PAGINATED_MODERATION_REQUEST_REPORT_QUERY = gql`
  query paginatedModerationRequestReports(
    $paginationArgs: PaginationArgsInput
    $metadata: PaginationMetadataInput
    $type: String
  ) {
    offsetBasedPaginatedEntries(
      type: $type
      metadata: $metadata
      paginationArgs: $paginationArgs
    ) {
      __typename
      ... on ModerationRequestReport {
        ...moderationRequestReport
      }
    }
  }
  ${MODERATION_REQUEST_REPORT_FRAGMENT}
`;

export const SUBMIT_MODERATION_REQUEST_REPORT_MUTATION = gql`
  mutation SubmitModerationRequestReport(
    $moderationRequestReport: ModerationRequestReportInput
  ) {
    submitModerationRequestReport(
      moderationRequestReport: $moderationRequestReport
    ) {
      id
    }
  }
`;

export const RESPOND_TO_MODERATION_REQUEST_MUTATION = gql`
  mutation RespondToModerationRequest(
    $commentsMetadata: PaginationMetadataInput
    $commentChildrenMetadata: PaginationMetadataInput
    $mediaItemsMetadata: PaginationMetadataInput
    $moderationRequestResponse: ModerationRequestResponseInput
  ) {
    respondToModerationRequest(
      moderationRequestResponse: $moderationRequestResponse
    ) {
      ...moderationRequest
      moderationRequestResponses {
        ...moderationRequestResponse
      }
    }
  }
  ${MODERATION_REQUEST_FRAGMENT}
  ${MODERATION_REQUEST_RESPONSE_FRAGMENT}
`;
