import React from 'react';
import PostList from 'src/components/post-list';
import useList from '../use-list';

const ListCollectionRouteView: React.FC = () => {
  const { curatedList } = useList();

  return (
    <PostList
      useCarousel={true}
      paginationArgs={{ curatedListId: curatedList.id }}
    />
  );
};

export default ListCollectionRouteView;
