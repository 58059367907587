import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import GraphqlResult from 'src/components/graphql-result';
import { ALBUM_BY_ID_QUERY } from 'src/queries';
import { MainContainer } from 'src/styles';
import {
  generateMediaItemsMetadata,
  generateCommentsMetadata,
  generateCommentChildrenMetadata,
} from 'src/utils/pagination';
import { useParams } from 'react-router-dom';
import PostIndexRoute from 'src/routes/post/post-route-view';

const PostRouteView: React.FC = () => {
  const { postId } = useParams<{ postId?: string }>();
  const remoteResponse = useQuery(ALBUM_BY_ID_QUERY, {
    variables: {
      commentsMetadata: generateCommentsMetadata(),
      commentChildrenMetadata: generateCommentChildrenMetadata(),
      mediaItemsMetadata: generateMediaItemsMetadata(),
      postId: postId,
    },
  });

  return (
    <MainContainer>
      <GraphqlResult response={remoteResponse}>
        {({ post }): ReactElement => (
          <>
            <PostIndexRoute post={post} />
          </>
        )}
      </GraphqlResult>
    </MainContainer>
  );
};

export default PostRouteView;
