import React from 'react';
import {
  MainContainer,
  PanelHeading,
  Panel,
  PrimaryColorLink,
  Background,
  GlobalStyleVariables,
} from 'src/styles';
import { useAuth } from 'src/hooks/use-auth';
import FeedItemList from 'src/components/feed-item-list';
import { useTranslation } from 'react-i18next';
import PostCarousel from 'src/components/post-carousel';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';

const StyledPanel = styled(Panel)`
  z-index: 1;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    z-index: initial;
  }
`;

const StyledMainContainer = styled(MainContainer)`
  position: relative;
  z-index: 1;
  padding-top: ${fibonacci(6)}rem;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    padding-top: 0;
    z-index: initial;
  }
`;

const HomeRoute: React.FC = () => {
  const { startedWithAuthToken, user } = useAuth();
  const { t } = useTranslation('general');

  return (
    <>
      {user ? (
        <>
          <StyledPanel>
            <PanelHeading>
              <PrimaryColorLink to="/featured">
                {t('featuredContentTitle')}
              </PrimaryColorLink>
            </PanelHeading>
            <PostCarousel
              paginationArgs={{
                isFeatured: true,
              }}
              shouldMute={true}
            />
          </StyledPanel>
          <StyledMainContainer>
            <Background />
            <FeedItemList />
          </StyledMainContainer>
        </>
      ) : startedWithAuthToken ? (
        <StyledMainContainer>
          <Background />
        </StyledMainContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default HomeRoute;
