import React, { useState, createRef, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { useTranslation } from 'react-i18next';
import { GlobalStyleVariables } from 'src/styles/global';
import ResponsiveCanvas from 'src/components/responsive-canvas/index';
import {
  getImageCoordinates,
  getOriginalShapreCoordinates,
  getSquareCoordinates,
  drawGrid,
  canvasWheel,
  canvasClick,
  canvasTouch,
} from 'src/components/image-editor/canvas-utils';
import { PrimaryColorVanillaLink } from 'src/styles/components/link';
import { FileUpload, FileUploadModifications } from 'src/types';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100vw;
  user-select: none;
`;

const TopNav = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: ${fibonacci(5)}rem;
  padding: 0 ${fibonacci(3)}rem;
`;

const TopNavControl = styled(PrimaryColorVanillaLink)`
  display: inline-flex;
  align-items: center;
  font-size: ${GlobalStyleVariables.fontSizeTwoUp}rem;
`;

const TopNavControlIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`;

const TopNavControlBold = styled(TopNavControl)`
  font-weight: bold;
  text-transform: uppercase;
`;

const ImageCanvas = styled(ResponsiveCanvas)`
  flex-grow: 1;
  height: calc(100dvh - ${fibonacci(7) - fibonacci(5)}rem);
  width: 100%;
  cursor: pointer;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${fibonacci(7)}rem;
  padding: 0 ${fibonacci(3)}rem;
`;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 4rem;
  cursor: pointer;
`;

const CenteredControlContainer = styled(ControlContainer)`
  justify-content: center;
  min-width: ${fibonacci(5)}rem;
`;

const ControlText = styled.div`
  margin-top: 1rem;
  font-size: ${GlobalStyleVariables.baseFontSize}rem;
`;

const ControlIcon = styled.div`
  border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  border-radius: ${fibonacci(1) * goldenRatioInverse}rem;
`;

interface ControlOriginalProps {
  ratio: number;
}

const ControlOriginal = styled(ControlIcon).attrs(
  (props: ControlOriginalProps) => ({
    ratio: props.ratio,
  })
)`
  ${(props): string => {
    if (props.ratio > 1) {
      return `
        width: ${fibonacci(5) / props.ratio}rem;
        height: ${fibonacci(5)}rem;
      `;
    } else {
      return `
        width: ${fibonacci(5)}rem;
        height: ${fibonacci(5) * props.ratio}rem;
      `;
    }
  }}
`;

const ControlSquare = styled(ControlIcon)`
  width: ${fibonacci(5)}rem;
  height: ${fibonacci(5)}rem;
`;

interface InterfaceProps {
  cancel: () => void;
  file: FileUpload;
  forceRatio?: number;
  save: (modifications: FileUploadModifications) => void;
}

const ImageEditorView: React.FC<InterfaceProps> = ({
  cancel,
  file,
  forceRatio,
  save,
}) => {
  const containerRef = createRef<HTMLDivElement>();
  const canvasRef = createRef<HTMLCanvasElement>();
  const [image] = useState(new Image());
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  image.onload = (): void => {
    setImageIsLoaded(true);
  };
  image.src = file.result;

  const { t } = useTranslation('general');
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [cropCoordinates] = useState(
    file.modifications.cropCoordinates || [0, 0, 1, 1]
  );
  const setCropCoordinates = (newCoordinates: number[]) => {
    cropCoordinates.splice(0, cropCoordinates.length);

    newCoordinates.forEach((c) => cropCoordinates.push(c));

    forceUpdate();
  };

  const [rotation, setRotation] = useState(file.modifications.rotation || 0);
  const [isHorizontallyFlipped, setIsHorizontallyFlipped] = useState(
    file.modifications.isHorizontallyFlipped || false
  );

  useEffect(() => {
    if (forceRatio === 1 && canvasRef.current && imageIsLoaded) {
      setToSquare();
    }
  }, [imageIsLoaded, canvasRef.current, forceRatio]);

  useEffect(() => {
    const containerElement = containerRef.current;
    const canvasElement = canvasRef.current;
    if (containerElement && canvasElement) {
      const context = canvasElement.getContext('2d');

      if (context) {
        const boundCanvasWheel = (event: WheelEvent): void => {
          canvasWheel(cropCoordinates, setCropCoordinates, event, forceRatio);
        };
        const boundCanvasClick = (event: MouseEvent): void => {
          canvasClick(
            containerElement,
            canvasElement,
            context,
            image,
            rotation,
            isHorizontallyFlipped,
            cropCoordinates,
            setCropCoordinates,
            event,
            forceRatio
          );
        };
        const boundCanvasTouch = (event: TouchEvent): void => {
          canvasTouch(
            containerElement,
            canvasElement,
            context,
            image,
            rotation,
            isHorizontallyFlipped,
            cropCoordinates,
            setCropCoordinates,
            event,
            forceRatio
          );
        };

        canvasElement.addEventListener('wheel', boundCanvasWheel);
        canvasElement.addEventListener('mousedown', boundCanvasClick);
        canvasElement.addEventListener('touchstart', boundCanvasTouch);

        return (): void => {
          canvasElement.removeEventListener('wheel', boundCanvasWheel);
          canvasElement.removeEventListener('mousedown', boundCanvasClick);
          canvasElement.removeEventListener('touchstart', boundCanvasTouch);
        };
      }
    }
  });

  const renderImageOverlay = (canvas: HTMLCanvasElement): void => {
    canvas.height = canvas.offsetHeight;
    canvas.width = canvas.offsetWidth;
    const context = canvas.getContext('2d');

    if (context) {
      const imageCoordinates = getImageCoordinates(canvas, image, rotation);
      context.translate(canvas.width / 2, canvas.height / 2);
      context.rotate((rotation * Math.PI) / 180);
      if (isHorizontallyFlipped) {
        context.scale(-1, 1);
      }
      context.drawImage(
        image,
        imageCoordinates.x,
        imageCoordinates.y,
        imageCoordinates.width,
        imageCoordinates.height
      );
      drawGrid(canvas, context, cropCoordinates, imageCoordinates);
    }
  };

  const setToOriginal = (): void => {
    const newCoordinates = getOriginalShapreCoordinates(cropCoordinates);

    setCropCoordinates(newCoordinates);
  };

  const setToSquare = (): void => {
    if (canvasRef.current) {
      const imageCoordinates = getImageCoordinates(
        canvasRef.current,
        image,
        rotation
      );
      const newCoordinates = getSquareCoordinates(
        cropCoordinates,
        imageCoordinates
      );

      setCropCoordinates(newCoordinates);
    }
  };

  const rotate = (): void => {
    const newRotation = rotation + 90;
    setRotation(newRotation > 270 ? 0 : newRotation);
  };

  const flip = (): void => {
    setIsHorizontallyFlipped(!isHorizontallyFlipped);
  };

  return (
    <Container ref={containerRef}>
      <TopNav>
        <TopNavControl onClick={cancel}>
          <TopNavControlIcon icon="times" /> {t('imageEditorCancel')}
        </TopNavControl>
        <TopNavControlBold
          onClick={(): void =>
            save({
              cropCoordinates,
              isHorizontallyFlipped,
              rotation,
            })
          }
        >
          {t('imageEditorSave')}
        </TopNavControlBold>
      </TopNav>
      <ImageCanvas render={renderImageOverlay} ref={canvasRef} />
      <Controls>
        {!forceRatio ? (
          <>
            <ControlContainer onClick={setToOriginal}>
              <ControlOriginal
                ratio={image.naturalHeight / image.naturalWidth}
              />
              <ControlText>{t('imageEditorOriginal')}</ControlText>
            </ControlContainer>
            <ControlContainer onClick={setToSquare}>
              <ControlSquare />
              <ControlText>{t('imageEditorSquare')}</ControlText>
            </ControlContainer>
          </>
        ) : (
          <>
            <ControlContainer />
            <ControlContainer />
          </>
        )}
        <CenteredControlContainer onClick={rotate}>
          <FontAwesomeIcon icon="sync-alt" />
        </CenteredControlContainer>
        <CenteredControlContainer onClick={flip}>
          {(rotation / 90) % 2 === 0 ? (
            <FontAwesomeIcon icon="arrows-alt-h" />
          ) : (
            <FontAwesomeIcon icon="arrows-alt-v" />
          )}
        </CenteredControlContainer>
      </Controls>
    </Container>
  );
};

export default ImageEditorView;
