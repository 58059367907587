import React from 'react';
import NotificationList from 'src/components/notification-list';
import { MainContainer } from 'src/styles';

const NotificationsRouteView: React.FC = () => {
  return (
    <MainContainer>
      <NotificationList />
    </MainContainer>
  );
};

export default NotificationsRouteView;
