import React from 'react';
import { fibonacci } from 'src/utils/math';
import styled from 'styled-components';
import { CuratedList } from 'src/types';
import CuratedListSummary from 'src/components/curated-list-summary';

const ListPage = styled.div`
  margin-bottom: ${fibonacci(3)}rem;
`;

interface InterfaceProps {
  curatedList: CuratedList;
}

const ListRouteContent: React.FC<InterfaceProps> = ({ curatedList }) => {
  return (
    <ListPage>
      <CuratedListSummary curatedList={curatedList} />
    </ListPage>
  );
};

export default ListRouteContent;
