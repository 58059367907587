import React from 'react';
import { Post } from 'src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/use-auth';
import DropdownButtonWithArrowNav from 'src/components/dropdown-button-with-arrow-nav';
import { useAccountModals } from 'src/hooks/use-account-modals';
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';
import { DELETE_ALBUM_MUTATION } from 'src/queries';
import { useMutation } from '@apollo/client';

const StyledDropdownButtonWithArrowNav = styled(DropdownButtonWithArrowNav)`
  a {
    width: ${fibonacci(8)}rem;
    text-align: right;
    padding: ${fibonacci(3) / 2}rem ${fibonacci(3)}rem;
  }
`;

interface InterfaceProps {
  post: Post;
  setIsEditing?: (isEditing: boolean) => void;
}

const PostSummaryMenu: React.FC<InterfaceProps> = ({ post, setIsEditing }) => {
  const { t } = useTranslation('general');
  const { user } = useAuth();
  const { openModal } = useAccountModals();
  const navigate = useNavigate();
  const ensureUser = useEnsureUser();
  const confirm = useConfirmationModal();

  const [deletePostMutation] = useMutation(DELETE_ALBUM_MUTATION);

  const deletePost = async (): Promise<void> => {
    if (await confirm(t('postsDeleteConfirm'))) {
      await deletePostMutation({
        variables: {
          postId: post.id,
        },
      });
      navigate({
        pathname: `/`,
      });
    }
  };

  const report = async (): Promise<void> => {
    await ensureUser();
    if (openModal) await openModal('reportPost', { post });
  };

  const options = [];

  const credit = post.credits.find((c) => c.user.id === user?.id);

  if (credit?.canModify) {
    if (setIsEditing) {
      options.push({
        label: t('edit'),
        value: () => setIsEditing(true),
      });
    }
    options.push({
      label: t('delete'),
      value: deletePost,
    });
  }

  options.push({
    label: t('postsModerationReport'),
    value: report,
  });

  function onChange(cb: () => void): void {
    cb();
  }

  return (
    <StyledDropdownButtonWithArrowNav
      button={<FontAwesomeIcon icon="ellipsis-h" />}
      onChange={onChange}
      options={options}
    />
  );
};

export default PostSummaryMenu;
