import React from 'react';
import ListList from 'src/routes/post/lists/list-list';
import usePost from '../use-post';

const PostListsRouteView: React.FC = () => {
  const { post } = usePost();

  return <ListList postId={post.id} />;
};

export default PostListsRouteView;
