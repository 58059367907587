import React, { useState } from 'react';
import { StyledCarousel } from 'src/components/carousel';
import PostList from 'src/components/post-list';
import { PaginationArgs } from 'src/types';
import { useLocation } from 'react-router';
import { useUpdateHistoryState } from 'src/hooks/use-update-history-state';

interface InterfaceProps {
  className?: string;
  paginationArgs?: PaginationArgs;
  shouldMute?: boolean;
}

const PostCarouselView: React.FC<InterfaceProps> = ({
  className,
  paginationArgs,
  shouldMute,
}) => {
  const location = useLocation();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const stringifiedArgs = JSON.stringify(paginationArgs);
  const id = `post: ${stringifiedArgs}`;
  const updateIndex = useUpdateHistoryState(id);
  const state = location.state as { [key: string]: number };

  return (
    <StyledCarousel
      className={className}
      initialIndex={state ? state[id] : 0}
      scrollElementRef={ref}
      setScrollElementRef={setRef}
      updateIndex={updateIndex}
    >
      {(setCarouselLength) => {
        return (
          <PostList
            paginationArgs={paginationArgs}
            scrollElementRef={ref}
            setCarouselLength={setCarouselLength}
            shouldMute={shouldMute}
          ></PostList>
        );
      }}
    </StyledCarousel>
  );
};

export default PostCarouselView;
