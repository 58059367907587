import { useCallback, useState } from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import {
  OffsetBasedPaginationResponse,
  PaginationQueryVariables,
  PaginationResult,
} from 'src/types';
import { timeout } from 'src/utils/async';
import { DocumentNode } from 'graphql';

export function useOffsetBasedPagination(
  query: DocumentNode,
  variables: PaginationQueryVariables,
  options?: QueryHookOptions
): PaginationResult {
  const [isFetching, setIsFetching] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [cachedVariables, setCachedVariables] = useState(
    JSON.stringify(variables.metadata)
  );

  const { data, loading, error, fetchMore } =
    useQuery<OffsetBasedPaginationResponse>(query, {
      variables,
      ...options,
    });

  const entries = data?.offsetBasedPaginatedEntries;

  const onLoadMore = useCallback(async (): Promise<void> => {
    if (!entries) return;

    const offset = entries.length;
    const limit = variables.metadata.limit || 2;
    const metadata = { offset, limit };

    if (
      isFullyLoaded ||
      isFetching ||
      cachedVariables === JSON.stringify(metadata)
    )
      return;

    setIsFetching(true);
    setCachedVariables(JSON.stringify(metadata));

    await fetchMore({
      query: query,
      variables: { ...variables, metadata },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) throw Error('no pagination result');

        const previousEntries = previousResult.offsetBasedPaginatedEntries;
        const newEntries = fetchMoreResult.offsetBasedPaginatedEntries;
        const isNowFullyLoaded = !newEntries || newEntries.length === 0;
        if (isNowFullyLoaded || newEntries.length < limit) {
          setIsFullyLoaded(true);
        }

        const entries = isNowFullyLoaded
          ? previousEntries
          : [...previousEntries, ...newEntries];

        return {
          offsetBasedPaginatedEntries: entries,
        } as OffsetBasedPaginationResponse;
      },
    });

    await timeout(100);
    setIsFetching(false);
  }, [
    isFullyLoaded,
    isFetching,
    cachedVariables,
    entries,
    variables,
    fetchMore,
  ]);

  if (loading || error) return { loading, error };
  else if (!data) return { error: { stack: 'no data' } as Error };
  else if (entries) {
    return {
      data: {
        entries,
        isFullyLoaded,
        onLoadMore,
      },
    };
  } else {
    return {};
  }
}
