/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export function aggregateGraphqlErrors(
  e: any,
  t: any
): { [x: string]: string } {
  return e.graphQLErrors.reduce(
    (
      accumulator: { [x: string]: string },
      error: { attribute: string; message: string }
    ) => {
      accumulator[error.attribute] = accumulator[error.attribute]
        ? `${accumulator[error.attribute]}; ${t(error.message)}`
        : t(error.message);

      return accumulator;
    },
    {}
  );
}
