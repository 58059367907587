import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import {
  HeadingTwo,
  Paragraph,
  DefaultLink,
  GlobalStyleVariables,
} from 'src/styles';
import HoneycombLogo from 'src/components/honeycomb-logo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${fibonacci(3)}rem;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: ${fibonacci(8)}rem;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    display: none;
  }
`;

const Logo = styled(HoneycombLogo)`
  height: ${fibonacci(7)}rem;
`;

interface InterfaceProps {
  goToRegisterForm: () => void;
}

const LoginFormAbout: React.FC<InterfaceProps> = ({ goToRegisterForm }) => {
  const { t } = useTranslation('general');

  return (
    <Container data-testid="login-form-about">
      <HeadingTwo>{t('login')}</HeadingTwo>
      <Paragraph>{t('loginAbout')}</Paragraph>
      <Paragraph>
        {t('loginNoAccount')}{' '}
        <DefaultLink
          data-testid="login-form-about__register"
          onClick={goToRegisterForm}
        >
          {t('loginNoAccountLink')}
        </DefaultLink>
      </Paragraph>
      <Icon>
        <Logo />
      </Icon>
    </Container>
  );
};

export default LoginFormAbout;
