import React from 'react';
import { Post } from 'src/types';
import ReportForm from 'src/components/report-form';

interface InterfaceProps {
  post: Post;
  onReject: () => void;
  onResolve: () => void;
}

const ReportPostFormView: React.FC<InterfaceProps> = ({
  post,
  onReject,
  onResolve,
}) => {
  return (
    <ReportForm
      initialValues={{ postId: post.id, comment: '' }}
      onReject={onReject}
      onResolve={onResolve}
    />
  );
};

export default ReportPostFormView;
