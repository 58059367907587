/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export function getItem(key: string): any {
  return localStorage.getItem(key);
}

export function removeItem(key: string): any {
  return localStorage.removeItem(key);
}

export function setItem(key: string, value: any): void {
  localStorage.setItem(key, value);
  window.dispatchEvent(new Event(`localStorage: ${key}`));

  return value;
}

export function getSessionItem(key: string): any {
  return sessionStorage.getItem(key);
}

export function removeSessionItem(key: string): any {
  return sessionStorage.removeItem(key);
}

export function setSessionItem(key: string, value: any): void {
  sessionStorage.setItem(key, value);
  window.dispatchEvent(new Event(`sessionStorage: ${key}`));

  return value;
}
