import React, { ReactElement } from 'react';
import FeedItemListView from 'src/components/feed-item-list/feed-item-list-view';
import { PaginationQueryVariables, PaginationArgs } from 'src/types';
import { PAGINATED_FEED_ITEM_QUERY } from 'src/queries';
import GraphqlResult from 'src/components/graphql-result';
import { useOffsetBasedPagination } from 'src/hooks';
import {
  generateMediaItemsMetadata,
  generatePaginationMetadata,
} from 'src/utils/pagination';

const variables: PaginationQueryVariables = {
  metadata: generatePaginationMetadata(),
  type: 'FeedItem',
  mediaItemsMetadata: generateMediaItemsMetadata(),
};

interface InterfaceProps {
  paginationArgs?: PaginationArgs;
}

const FeedItemListQuery: React.FC<InterfaceProps> = ({
  paginationArgs = {},
}) => {
  const response = useOffsetBasedPagination(PAGINATED_FEED_ITEM_QUERY, {
    ...variables,
    paginationArgs,
  });

  return (
    <GraphqlResult response={response}>
      {(data): ReactElement => <FeedItemListView {...data} />}
    </GraphqlResult>
  );
};

export default FeedItemListQuery;
