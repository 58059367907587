/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CURRENT_USER_QUERY,
  USER_BY_USERNAME_QUERY,
  PAGINATED_ALBUM_QUERY,
  PAGINATED_USER_QUERY,
  PAGINATED_COMMENTS_QUERY,
  PAGINATED_COMMENTS_WITH_CHILDREN_QUERY,
  PAGINATED_CURATED_LIST_QUERY,
} from 'src/queries';
import { User, Post, Comment, CuratedList } from 'src/types';
import { ApolloCache } from '@apollo/client';
import {
  generateMediaItemsMetadata,
  generatePaginationMetadata,
  generateCommentChildrenMetadata,
  generateCommentsMetadata,
} from 'src/utils/pagination';

export function updateCurrentUser(
  cache: ApolloCache<any>,
  update: (currentUser: User) => object
): void {
  try {
    const { currentUser } = readQuery(cache, {
      query: CURRENT_USER_QUERY,
    }) as { currentUser: User };
    cache.writeQuery({
      query: CURRENT_USER_QUERY,
      data: {
        currentUser: {
          ...currentUser,
          ...update(currentUser),
        },
      },
    });

    const { userByUsername } = readQuery(cache, {
      query: USER_BY_USERNAME_QUERY,
      variables: {
        username: currentUser.username,
        mediaItemsMetadata: generateMediaItemsMetadata(),
      },
    }) as { userByUsername: User };

    cache.writeQuery({
      query: USER_BY_USERNAME_QUERY,
      data: {
        userByUsername: {
          ...userByUsername,
          ...update(currentUser),
        },
      },
    });
  } catch (e) {
    if ((e as Error).name !== 'Invariant Violation') console.log(e);
  }
}

export function updatePaginatedPosts(
  cache: ApolloCache<any>,
  paginationArgs: object,
  update: (offsetBasedPaginatedEntries: Post[]) => object
): void {
  try {
    const { offsetBasedPaginatedEntries } = readQuery(cache, {
      query: PAGINATED_ALBUM_QUERY,
      variables: {
        metadata: generatePaginationMetadata(),
        type: 'Post',
        mediaItemsMetadata: generateMediaItemsMetadata(),
        paginationArgs,
      },
    }) as { offsetBasedPaginatedEntries: Post[] };

    cache.writeQuery({
      query: PAGINATED_ALBUM_QUERY,
      variables: {
        metadata: generatePaginationMetadata(),
        type: 'Post',
        mediaItemsMetadata: generateMediaItemsMetadata(),
        paginationArgs,
      },
      data: {
        offsetBasedPaginatedEntries: update(offsetBasedPaginatedEntries),
      },
    });
  } catch (e) {
    if ((e as Error).name !== 'Invariant Violation') console.log(e);
  }
}

export function updatePaginatedComments(
  cache: ApolloCache<any>,
  paginationArgs: { parentId?: string },
  update: (offsetBasedPaginatedEntries: Comment[]) => object
): void {
  try {
    const query = paginationArgs.parentId
      ? PAGINATED_COMMENTS_QUERY
      : PAGINATED_COMMENTS_WITH_CHILDREN_QUERY;
    const { offsetBasedPaginatedEntries } = readQuery(cache, {
      query,
      variables: {
        commentChildrenMetadata: generateCommentChildrenMetadata(),
        metadata: generateCommentsMetadata(),
        type: 'Comment',
        paginationArgs,
      },
    }) as { offsetBasedPaginatedEntries: Comment[] };

    cache.writeQuery({
      query,
      variables: {
        commentChildrenMetadata: generateCommentChildrenMetadata(),
        metadata: generateCommentsMetadata(),
        type: 'Comment',
        paginationArgs,
      },
      data: {
        offsetBasedPaginatedEntries: update(offsetBasedPaginatedEntries),
      },
    });
  } catch (e) {
    if ((e as Error).name !== 'Invariant Violation') console.log(e);
  }
}

export function updatePaginatedCuratedLists(
  cache: ApolloCache<any>,
  paginationArgs: object,
  update: (offsetBasedPaginatedEntries: CuratedList[]) => object
): void {
  try {
    const { offsetBasedPaginatedEntries } = readQuery(cache, {
      query: PAGINATED_CURATED_LIST_QUERY,
      variables: {
        metadata: generatePaginationMetadata(),
        type: 'CuratedList',
        mediaItemsMetadata: generateMediaItemsMetadata(),
        paginationArgs,
      },
    }) as { offsetBasedPaginatedEntries: CuratedList[] };

    cache.writeQuery({
      query: PAGINATED_CURATED_LIST_QUERY,
      variables: {
        metadata: generatePaginationMetadata(),
        type: 'CuratedList',
        mediaItemsMetadata: generateMediaItemsMetadata(),
        paginationArgs,
      },
      data: {
        offsetBasedPaginatedEntries: update(offsetBasedPaginatedEntries),
      },
    });
  } catch (e) {
    if ((e as Error).name !== 'Invariant Violation') console.log(e);
  }
}

export function updatePaginatedUsers(
  cache: ApolloCache<any>,
  paginationArgs: object,
  update: (offsetBasedPaginatedEntries: User[]) => object
): void {
  try {
    const { offsetBasedPaginatedEntries } = readQuery(cache, {
      query: PAGINATED_USER_QUERY,
      variables: {
        metadata: generatePaginationMetadata(),
        type: 'User',
        paginationArgs,
      },
    }) as { offsetBasedPaginatedEntries: User[] };

    cache.writeQuery({
      query: PAGINATED_USER_QUERY,
      variables: {
        metadata: generatePaginationMetadata(),
        type: 'User',
        paginationArgs,
      },
      data: {
        offsetBasedPaginatedEntries: update(offsetBasedPaginatedEntries),
      },
    });
  } catch (e) {
    if ((e as Error).name !== 'Invariant Violation') console.log(e);
  }
}

function readQuery(cache: ApolloCache<any>, query: any): any {
  const result = cache.readQuery(query);

  if (result) return result;
  else {
    const error = new Error();

    error.name = 'Invariant Violation';

    throw error;
  }
}
