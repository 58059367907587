/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from 'src/types';
import { useAccountModals } from 'src/hooks/use-account-modals';
import { useEnsureUser } from './use-ensure-user';

export function useSubscribe(
  subscribed: User
): (_: any, { setSubmitting }: any) => Promise<void> {
  const { openModal } = useAccountModals();
  const ensureUser = useEnsureUser();

  return async (): Promise<void> => {
    await ensureUser();

    return new Promise((resolve, reject): void => {
      if (!openModal) reject();
      else {
        openModal('subscribe', {
          subscribed,
        }).then(
          (subscribed: boolean) => {
            if (subscribed) resolve();
            else reject();
          },
          () => reject()
        );
      }
    });
  };
}
