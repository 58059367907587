import React, { Fragment } from 'react';
import { Post, PostAccessType } from 'src/types';
import { MutedLink } from 'src/styles';
import { useTranslation } from 'react-i18next';
import { formatDuration } from 'src/utils/formatting';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  checkIfUserHasSubscribedToAnPostCreator,
  checkIfUserIsPostCreator,
} from 'src/utils/computes';
import { useAuth } from 'src/hooks/use-auth';

const MutedDiv = styled.div`
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
`;

interface InterfaceProps {
  post: Post;
}

const PostSummarySumplementaryInfo: React.FC<InterfaceProps> = ({ post }) => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const { likeCount, imageCount, purchaseCount, videoCount, videoDuration } =
    post;
  const formattedDuration = formatDuration(videoDuration);
  const isPostCreator = checkIfUserIsPostCreator(user, post);
  const hasSubscribed = checkIfUserHasSubscribedToAnPostCreator(user, post);

  return (
    <Fragment>
      {post.accessType === PostAccessType['post-access-type-pay'] ? (
        <MutedDiv>{t('price', { amount: post.price })}</MutedDiv>
      ) : post.accessType === PostAccessType['post-access-type-free'] ? (
        <MutedDiv>{t('post-access-type-free')}</MutedDiv>
      ) : !isPostCreator && !hasSubscribed ? (
        <MutedDiv>
          <FontAwesomeIcon icon="lock" />
        </MutedDiv>
      ) : (
        <MutedDiv>
          <FontAwesomeIcon icon="unlock" />
        </MutedDiv>
      )}

      {imageCount > 0 && (
        <MutedDiv>{t('imagesWithCount', { count: imageCount })}</MutedDiv>
      )}

      {videoCount > 0 && (
        <MutedDiv>
          {t('videosWithCountAndDuration', {
            count: videoCount,
            duration: formattedDuration,
          })}
        </MutedDiv>
      )}

      {likeCount > 0 && (
        <MutedLink to="likes" preventScrollReset={true}>
          {post.likeCount} <FontAwesomeIcon icon="star" />
        </MutedLink>
      )}

      {purchaseCount > 0 && (
        <MutedLink to="likes/buyers" preventScrollReset={true}>
          {t('purchasesWithCount', {
            count: purchaseCount,
          })}
        </MutedLink>
      )}

      {post.features.map((feature) => {
        return (
          <MutedLink key={feature.id} to={`/lists/${feature.curatedList.id}`}>
            {t('curatedListsFeaturedIn', {
              name: feature.curatedList.name,
            })}
          </MutedLink>
        );
      })}
    </Fragment>
  );
};

export default PostSummarySumplementaryInfo;
