/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Post } from 'src/types';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { StyledButton } from 'src/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { useAuth } from 'src/hooks/use-auth';
import { useLikePost } from 'src/hooks/use-like-post';
import { useDelikePost } from 'src/hooks/use-unlike-post';
import { usePurchasePost } from 'src/hooks/use-purchase-post';
import { useReloadAndViewPost } from 'src/hooks/use-reload-and-view-post';
import { useSubscribe } from 'src/hooks/use-subscribe';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import {
  checkIfUserHasLikedPost,
  checkIfUserMustPayForPost,
  checkIfUserHasPurchasedPost,
  checkIfUserHasSubscribedToAnPostCreator,
  checkIfUserMustSubscribeForPost,
  checkIfUserIsPostCreator,
} from 'src/utils/computes';

const StyledForm = styled(Form)`
  display: flex;
`;

const BorderlessButton = styled(StyledButton)`
  border: 0;
  background: transparent;

  &:disabled {
    color: rgba(
      ${(props): string => props.theme.highMutedColor},
      ${goldenRatioInverse}
    );
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
`;

const Price = styled.span`
  display: block;
  margin-right: ${fibonacci(1) * goldenRatioInverse}rem;
`;

interface InterfaceProps {
  post: Post;
}

const PostCardAcquireButton: React.FC<InterfaceProps> = ({ post }) => {
  const { t } = useTranslation('general');
  const { user } = useAuth();
  const onLike = useLikePost(post);
  const onDelike = useDelikePost(post);
  const onPurchase = usePurchasePost(post);
  const onSubscribe = useSubscribe(post.credits[0].user);
  const onReloadCurrentPost = useReloadAndViewPost(post);
  const isPostCreator = checkIfUserIsPostCreator(user, post);
  const hasLiked = checkIfUserHasLikedPost(user, post);
  const hasPaid = checkIfUserHasPurchasedPost(user, post);
  const hasSubscribed = checkIfUserHasSubscribedToAnPostCreator(user, post);
  const mustPay =
    checkIfUserMustPayForPost(user, post) && !hasPaid && !isPostCreator;
  const mustSubscribe =
    checkIfUserMustSubscribeForPost(user, post) &&
    !hasSubscribed &&
    !isPostCreator;

  async function onSubscribeAndReload() {
    await onSubscribe(null, {});
    await onReloadCurrentPost();
  }

  return (
    <>
      {mustSubscribe ? (
        <Formik initialValues={{}} onSubmit={onSubscribeAndReload}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <BorderlessButton
                type="submit"
                disabled={isSubmitting}
                data-for={`post_acquire_${post.id}`}
                data-tip
              >
                <PriceContainer>
                  <FontAwesomeIcon icon="unlock" />
                </PriceContainer>
              </BorderlessButton>
            </StyledForm>
          )}
        </Formik>
      ) : mustPay ? (
        <Formik initialValues={{}} onSubmit={onPurchase}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <BorderlessButton
                type="submit"
                disabled={isSubmitting}
                data-for={`post_acquire_${post.id}`}
                data-tip
              >
                <PriceContainer>
                  <Price>{t('price', { amount: post.price })}</Price>
                </PriceContainer>
              </BorderlessButton>
            </StyledForm>
          )}
        </Formik>
      ) : hasLiked ? (
        <Formik initialValues={{}} onSubmit={onDelike}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <BorderlessButton
                type="submit"
                disabled={isSubmitting}
                data-for={`post_acquire_${post.id}`}
                data-tip
              >
                <PriceContainer>
                  <FontAwesomeIcon icon={['fas', 'star']} />
                </PriceContainer>
              </BorderlessButton>
            </StyledForm>
          )}
        </Formik>
      ) : (
        <Formik initialValues={{}} onSubmit={onLike}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <BorderlessButton
                type="submit"
                disabled={isSubmitting}
                data-for={`post_acquire_${post.id}`}
                data-tip
              >
                <PriceContainer>
                  <FontAwesomeIcon icon={['far', 'star']} />
                </PriceContainer>
              </BorderlessButton>
            </StyledForm>
          )}
        </Formik>
      )}
      <ReactTooltip
        id={`post_acquire_${post.id}`}
        effect="solid"
        place="bottom"
        getContent={(): string => {
          return mustSubscribe
            ? t('subscribe')
            : mustPay
            ? t('purchase')
            : hasLiked
            ? t('likeUnlike')
            : t('likeLike');
        }}
      />
    </>
  );
};

export default PostCardAcquireButton;
