import React, { Fragment } from 'react';
import { User } from 'src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isSafeUrl } from 'src/utils/security';
import { NoWrap } from 'src/styles';

interface InterfaceProps {
  user: User;
}

const UserSummarySumplementaryInfo: React.FC<InterfaceProps> = ({ user }) => {
  const { website } = user.profile;
  const fullUrl =
    website && website.split('://').length > 1 ? website : `http://${website}`;

  return (
    <Fragment>
      {user.pronouns && <NoWrap>{user.pronouns}</NoWrap>}

      {website && fullUrl && isSafeUrl(fullUrl) && (
        <NoWrap>
          <a href={fullUrl} target="blank">
            <FontAwesomeIcon icon="link" /> {website}
          </a>
        </NoWrap>
      )}
    </Fragment>
  );
};

export default UserSummarySumplementaryInfo;
