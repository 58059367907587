import React from 'react';
import { useLocation } from 'react-router';
import PostList from 'src/components/post-list';
import SearchSort from 'src/routes/search/search-sort-view';

const SearchRouteView: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query') || '';
  const queryState = (location.state || {}) as { [key: string]: string };

  return (
    <>
      <SearchSort isPost={true} />
      <PostList
        paginationArgs={{
          hasVideo: true,
          query,
          order: queryState.orderType || 'orderPopular',
        }}
        useCarousel={true}
      />
    </>
  );
};

export default SearchRouteView;
