/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { PaginatableType, MediaItem } from 'src/types';
import InfiniteScroll from 'src/components/infinite-scroll';
import MediaItemCard from 'src/components/media-item-card';

interface InterfaceProps {
  canClickToPlay?: boolean;
  currentIndex: number;
  entries: PaginatableType[];
  noContentText?: string;
  noContentClickCb?: (_: any, { setSubmitting }: any) => Promise<void>;
  onLoadMore: () => Promise<void>;
  scrollElementRef?: HTMLDivElement | null;
  shouldMute?: boolean;
  shouldPlayOnView?: boolean;
  setCarouselLength?: (carouselLength: number) => void;
}

const MediaCollectionView: React.FC<InterfaceProps> = ({
  canClickToPlay,
  currentIndex,
  entries,
  noContentText,
  noContentClickCb,
  onLoadMore,
  scrollElementRef,
  shouldMute,
  shouldPlayOnView,
  setCarouselLength,
}) => {
  useEffect(() => {
    if (setCarouselLength && !!entries) {
      setCarouselLength(entries.length);
    }
  }, [entries.length]);

  return (
    <InfiniteScroll
      axis="x"
      scrollElementRef={scrollElementRef}
      onApproachingInfinity={onLoadMore}
    >
      {entries.map((mediaItem, index) => {
        mediaItem = mediaItem as MediaItem;

        return (
          <MediaItemCard
            canClickToPlay={canClickToPlay}
            isVisible={index === currentIndex}
            mediaItem={mediaItem}
            noContentText={noContentText}
            noContentClickCb={noContentClickCb}
            shouldMute={shouldMute}
            shouldPlayOnView={shouldPlayOnView}
            key={mediaItem.id}
          />
        );
      })}
    </InfiniteScroll>
  );
};

export default MediaCollectionView;
