import React from 'react';
import styled from 'styled-components';
import { GlobalStyleVariables, HeadingTwoMixin } from 'src/styles';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';

const Summary = styled.div`
  display: flex;
  margin: 1rem 0 ${fibonacci(5)}rem;
  padding-top: 1rem;

  .summary__lead {
    flex-shrink: 0;
    width: ${(1 - goldenRatioInverse) * 100}rem;
  }

  .summary__primary {
    flex-grow: 1;
    padding-left: ${fibonacci(5)}rem;
    overflow: hidden;
  }

  .summary__header {
    ${HeadingTwoMixin}
  }

  .summary__credits {
    margin-top: ${fibonacci(1) * goldenRatioInverse}rem;
  }

  .summary__buttons {
    margin-top: ${fibonacci(3)}rem;
  }

  .summary__main {
    margin-top: 1rem;
  }

  .summary__supplementary-list {
    margin-top: 1rem;
    text-align: left;
    color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
    font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
    font-weight: bold;

    > * {
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;

      :not(:last-child):after {
        content: '|';
        padding: 0 1rem;
      }
    }
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    flex-direction: column;
    margin: 0;
    padding: 1rem;

    .summary__lead {
      width: 100%;
    }

    .summary__primary {
      margin-top: 1rem;
      padding-left: 0;
    }
  }
`;

interface InterfaceProps {
  className?: string;
}

const SummaryView: React.FC<InterfaceProps> = ({ children, className }) => {
  return <Summary className={className}>{children}</Summary>;
};

export default SummaryView;
