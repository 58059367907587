import React, { Fragment } from 'react';
import { ModerationRequestResponse } from 'src/types';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import styled from 'styled-components';
import UsernameView from 'src/components/username';
import { useTranslation } from 'react-i18next';
import DiffedText from 'src/components/diffed-text';
import { PrimaryColorLink } from 'src/styles';

const Response = styled.div`
  margin-top: 1rem;
`;

const ResponseComment = styled.div`
  margin-top: ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem;
`;

const ModerationActions = styled.span`
  > * {
    :after {
      content: ', ';
    }

    &:last-child {
      :after {
        content: '';
      }
    }
  }
`;

interface InterfaceProps {
  moderationRequestResponses: ModerationRequestResponse[];
  isDisciplinary?: boolean;
}

const ModerationRequestItemResponsesEntries: React.FC<InterfaceProps> = ({
  moderationRequestResponses,
  isDisciplinary,
}) => {
  const { t } = useTranslation('general');

  return (
    <div>
      {moderationRequestResponses
        .slice(0)
        .reverse()
        .map((moderationRequestResponse) => {
          moderationRequestResponse =
            moderationRequestResponse as ModerationRequestResponse;
          return (
            <Response key={moderationRequestResponse.id}>
              <UsernameView user={moderationRequestResponse.user} />
              {isDisciplinary ? (
                <>
                  {moderationRequestResponse.hasConfirmed
                    ? t('moderationResponseTookAction')
                    : t('moderationResponseDismissed')}
                  {': '}
                  <ModerationActions>
                    {moderationRequestResponse.moderationRequestResponseUserActions &&
                      moderationRequestResponse.moderationRequestResponseUserActions.map(
                        (userAction) => {
                          const restrictions = [];

                          if (userAction?.cannotModerate) {
                            restrictions.push(
                              t('userRestrictionCannotModerate')
                            );
                          }

                          if (userAction?.cannotPostAnything) {
                            restrictions.push(
                              t('userRestrictionCannotPostAnything')
                            );
                          }

                          if (userAction?.cannotPostComments) {
                            restrictions.push(
                              t('userRestrictionCannotPostComments')
                            );
                          }

                          if (userAction?.cannotPostContent) {
                            restrictions.push(
                              t('userRestrictionCannotPostContent')
                            );
                          }

                          return (
                            <Fragment key={userAction.id}>
                              <PrimaryColorLink
                                to={`/${userAction.user.username}`}
                              >
                                {restrictions.length > 0
                                  ? userAction.activeUntil
                                    ? t(
                                        'moderationResponseUserRestrictionAddedUntil',
                                        {
                                          restrictions,
                                          until: userAction.activeUntil,
                                          user: userAction.user.username,
                                        }
                                      )
                                    : t(
                                        'moderationResponseUserRestrictionAdded',
                                        {
                                          restrictions,
                                          user: userAction.user.username,
                                        }
                                      )
                                  : t(
                                      'moderationResponseUserRestrictionRemoved',
                                      {
                                        user: userAction.user.username,
                                      }
                                    )}
                              </PrimaryColorLink>
                            </Fragment>
                          );
                        }
                      )}
                    {moderationRequestResponse.moderationRequestResponseFieldRevisions &&
                      moderationRequestResponse.moderationRequestResponseFieldRevisions.map(
                        (fieldRevision) => {
                          return (
                            <span key={fieldRevision.id}>
                              {t('moderationResponseFieldRevision', {
                                field: fieldRevision.field,
                              })}
                              {`: "`}
                              <DiffedText
                                original={fieldRevision.original}
                                revision={fieldRevision.revision}
                              />
                              {`"`}
                            </span>
                          );
                        }
                      )}
                  </ModerationActions>
                </>
              ) : (
                <>
                  {moderationRequestResponse.hasConfirmed
                    ? t('moderationResponseAccepted')
                    : t('moderationResponseRejected')}
                </>
              )}
              {moderationRequestResponse.comment && (
                <ResponseComment>
                  {moderationRequestResponse.comment}
                </ResponseComment>
              )}
            </Response>
          );
        })}
    </div>
  );
};

export default ModerationRequestItemResponsesEntries;
