import styled from 'styled-components';
import { GlobalStyleVariables } from 'src/styles/global';

const FeedItemPreamble = styled.div`
  margin-bottom: 1rem;
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  max-width: 100%;
`;

export default FeedItemPreamble;
