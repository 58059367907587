import { useNavigate, useLocation } from 'react-router';

export function useUpdateHistoryState(id: string): (index: number) => void {
  const navigate = useNavigate();
  const location = useLocation();

  return (index: number): void => {
    navigate(
      { pathname: '.', search: location?.search },
      {
        state: {
          ...(location?.state as object),
          [id]: index,
        },
        preventScrollReset: true,
        replace: true,
      }
    );
  };
}
