/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import {
  StyledButton,
  ButtonSet,
  DeemphasizedButton,
  StyledError,
} from 'src/styles';
import { useTranslation } from 'react-i18next';
import { Form, Field, FormikErrors } from 'formik';
import { PostAccessType, PostInput } from 'src/types';
import MediaCollectionUploader from 'src/components/media-collection-uploader';
import { useBlocker } from 'react-router-dom';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';
import styled from 'styled-components';
import { SimpleDeconstructedSelect, SimpleInput } from '../simple-form';
import { fibonacci } from 'src/utils/math';
import { generateGenericOptionItemsFromEnum } from 'src/utils/generators';

const StyledSimpleDeconstructedSelect = styled(SimpleDeconstructedSelect)`
  margin: 1rem 0;
  width: 100%;

  button {
    flex-grow: 1;
    padding-left: ${fibonacci(4)}rem;
    padding-right: ${fibonacci(4)}rem;
  }
`;

const StyledButtonSet = styled(ButtonSet)`
  margin-top: ${fibonacci(4)}rem;
`;

interface InterfaceProps {
  dirty: boolean;
  errors: FormikErrors<PostInput>;
  globalError: string | null;
  hasSaved: boolean;
  values: PostInput;
  cancel: () => void;
  forceUpdate: () => void;
  next: () => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const PostInnerUploadForm: React.FC<InterfaceProps> = ({
  dirty,
  errors,
  globalError,
  hasSaved,
  values,
  cancel,
  forceUpdate,
  next,
  setFieldValue,
}) => {
  const { t } = useTranslation('general');
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      currentLocation.pathname !== nextLocation.pathname && dirty && !hasSaved
    );
  });
  const confirm = useConfirmationModal();

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm(t('formCancel'), blocker);
    }
  }, [blocker.state]);

  const accessTypeOptions = generateGenericOptionItemsFromEnum(
    t,
    PostAccessType
  );

  return (
    <Form>
      <Field component={MediaCollectionUploader} name="mediaCollection" />
      <StyledSimpleDeconstructedSelect
        canDeselect={false}
        name="accessType"
        options={accessTypeOptions}
        onSelect={() => {
          setFieldValue('price', 5);
          forceUpdate();
        }}
      />
      {values.accessType === PostAccessType['post-access-type-pay'] ? (
        <SimpleInput
          label={t('postsPrice')}
          min={1}
          name="price"
          step="0.01"
          type="number"
        />
      ) : null}
      {globalError && <StyledError>{globalError}</StyledError>}
      <StyledButtonSet>
        <DeemphasizedButton type="button" onClick={cancel}>
          {t('cancel')}
        </DeemphasizedButton>
        <StyledButton
          type="button"
          disabled={
            values.mediaCollection.mediaItems.length === 0 || !!errors.price
          }
          onClick={next}
        >
          {t('next')}
        </StyledButton>
      </StyledButtonSet>
    </Form>
  );
};

export default PostInnerUploadForm;
