import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { SecondaryNavBar } from 'src/components/nav-bar';
import usePost from '../use-post';

const PostLikersParentRouteView: React.FC = () => {
  const { t } = useTranslation('general');
  const { post } = usePost();
  const options = [];

  options.push({
    to: ``,
    end: true,
    preventScrollReset: true,
    scrollToNavOnClick: true,
    content: t('postsLikedWithCount', {
      count: post.likeCount,
    }),
  });

  if (post.purchaseCount > 0) {
    options.push({
      to: `buyers`,
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('purchasesWithCount', {
        count: post.purchaseCount,
      }),
    });
  }

  return (
    <>
      {options.length > 1 && <SecondaryNavBar>{options}</SecondaryNavBar>}
      <Outlet context={{ post }} />
    </>
  );
};

export default PostLikersParentRouteView;
