import React from 'react';
import { FieldProps } from 'formik';
import Select from 'src/components/select';
import { GenericOptionItem, GenericOptionItemValue } from 'src/types';

interface InterfaceProps {
  className?: string;
  dropdownIcon?: boolean | JSX.Element;
  onChange?: () => void;
  options: GenericOptionItem[];
}

const SelectFieldView: React.FC<InterfaceProps & FieldProps> = ({
  className,
  dropdownIcon,
  options,
  onChange,
  field,
  form,
}) => {
  function onChangeAugmented(value: GenericOptionItemValue): void {
    form.setFieldValue(field.name, value);

    if (onChange) {
      onChange();
    }
  }

  return (
    <Select
      className={className}
      dropdownIcon={dropdownIcon}
      options={options}
      value={field.value}
      onChange={onChangeAugmented}
    />
  );
};

export default SelectFieldView;
