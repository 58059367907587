/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { UnstyledList, UnstyledListItem } from 'src/styles/components/list';
import { PrimaryColorVanillaLink } from 'src/styles/components/link';
import { fibonacci } from 'src/utils/math';
import { GenericOptionItem, GenericOptionItemValue } from 'src/types';

interface MenuOptionInteface {
  isFocused: boolean;
}

const MenuOption = styled(PrimaryColorVanillaLink).attrs(
  (props: MenuOptionInteface) => ({
    isFocused: props.isFocused || false,
  })
)`
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem ${fibonacci(3)}rem;
  background-color: rgba(
    ${(props): string =>
      props.isFocused ? props.theme.primaryColor : props.theme.backgroundColor},
    1
  );
  color: rgba(
    ${(props): string =>
      props.isFocused ? props.theme.backgroundColor : props.theme.primaryColor},
    1
  );

  &:hover {
    background-color: rgba(${(props): string => props.theme.primaryColor}, 1);
    color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  }
`;

interface InterfaceProps {
  className?: string;
  initialIndex?: number;
  items: GenericOptionItem[];
  onChange?: (value: GenericOptionItemValue) => void;
}

const ArrowNavListView: React.FC<InterfaceProps> = ({
  className,
  initialIndex = 0,
  items,
  onChange,
  ...props
}) => {
  const [focusIndex, setFocusIndex] = useState(initialIndex);

  function performSelect(item: GenericOptionItem): void {
    if (item.onClick) {
      item.onClick();
    } else if (onChange) {
      setFocusIndex(items.map((item) => item.value).indexOf(item.value));
      onChange(item.value);
    }
  }

  function select(item: GenericOptionItem): () => void {
    return (): void => {
      performSelect(item);
    };
  }

  function onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && focusIndex !== -1) {
      event.preventDefault();
      performSelect(items[focusIndex]);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const newIndex = focusIndex - 1;
      setFocusIndex(newIndex < -1 ? items.length - 1 : newIndex);
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      const newIndex = focusIndex + 1;
      setFocusIndex(newIndex >= items.length ? -1 : newIndex);
    }
  }

  useEffect(() => {
    if (items.length > 0) {
      document.addEventListener('keydown', onKeyDown);

      return (): void => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }
  });

  return (
    <UnstyledList className={className} {...props}>
      {items.map((item, index) => {
        return (
          <UnstyledListItem key={item.value || index}>
            <MenuOption
              isFocused={focusIndex === index}
              onMouseDown={select(item)}
              tabIndex={1}
            >
              {item.label}
            </MenuOption>
          </UnstyledListItem>
        );
      })}
    </UnstyledList>
  );
};

export default ArrowNavListView;
