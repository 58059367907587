/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from 'src/components/carousel';
import MediaItemList from 'src/components/media-item-list';
import { PaginationArgs } from 'src/types';
import { useLocation } from 'react-router';
import { useUpdateHistoryState } from 'src/hooks/use-update-history-state';

const MediaItemCarousel = styled(Carousel)`
  display: flex;
  height: 100%;

  > div {
    display: flex;
    height: 100%;
    margin: 0 1px;
    justify-content: center;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    img,
    video {
      height: 100%;
      flex: 0 0 auto;
      align-self: center;
    }
  }
`;

interface InterfaceProps {
  alwaysShowIndexer?: boolean;
  canClickToPlay?: boolean;
  className?: string;
  paginationArgs: PaginationArgs;
  noContentText?: string;
  shouldMute?: boolean;
  shouldPlayOnView?: boolean;
  noContentClickCb?: (_: any, { setSubmitting }: any) => Promise<void>;
  updateIndex?: (index: number) => void;
}

const MediaItemCarouselView: React.FC<InterfaceProps> = ({
  alwaysShowIndexer,
  canClickToPlay,
  className,
  paginationArgs,
  noContentText,
  noContentClickCb,
  shouldMute,
  shouldPlayOnView,
  updateIndex,
}) => {
  const location = useLocation();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const stringifiedArgs = JSON.stringify(paginationArgs);
  const indexId = `media-item: ${stringifiedArgs}`;
  const state = location.state as { [key: string]: number };
  const initialIndex = (state && state[indexId]) ?? 0;
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const updateHistoryState = useUpdateHistoryState(indexId);
  const updateInnerIndex = (index: number): void => {
    setCurrentIndex(index);
    updateHistoryState(index);
    if (updateIndex && index !== undefined) {
      updateIndex(index);
    }
  };

  return (
    <MediaItemCarousel
      alwaysShowIndexer={alwaysShowIndexer}
      className={className}
      initialIndex={state ? state[indexId] : 0}
      scrollElementRef={ref}
      setScrollElementRef={setRef}
      updateIndex={updateInnerIndex}
    >
      {(setCarouselLength) => (
        <MediaItemList
          canClickToPlay={canClickToPlay}
          currentIndex={currentIndex}
          paginationArgs={paginationArgs}
          noContentText={noContentText}
          noContentClickCb={noContentClickCb}
          scrollElementRef={ref}
          setCarouselLength={setCarouselLength}
          shouldMute={shouldMute}
          shouldPlayOnView={shouldPlayOnView}
        ></MediaItemList>
      )}
    </MediaItemCarousel>
  );
};

export default MediaItemCarouselView;
