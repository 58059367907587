import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { CuratedList } from 'src/types';
import { StyledButton } from 'src/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { useAuth } from 'src/hooks/use-auth';
import { useUnfollowCuratedList } from 'src/hooks/use-unfollow-curated-list';
import { useFollowCuratedList } from 'src/hooks/use-follow-curated-list';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const StyledForm = styled(Form)`
  display: flex;
`;

const RestyledButton = styled(StyledButton)`
  background: rgba(${(props): string => props.theme.primaryColor}, 1);
  color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  border-style: dotted;

  &:focus {
    border-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  }
`;

const Slash = styled(FontAwesomeIcon)`
  position: absolute;
`;

interface InterfaceProps {
  curatedList: CuratedList;
}

const CuratedListCardFollowButton: React.FC<InterfaceProps> = ({
  curatedList,
}) => {
  const { t } = useTranslation('general');
  const { user } = useAuth();
  const onUnfollow = useUnfollowCuratedList(curatedList);
  const onFollow = useFollowCuratedList(curatedList);
  const isFollowing = user
    ? user.followedCuratedLists.some(({ id }) => id === curatedList.id)
    : false;

  return (
    <>
      {isFollowing ? (
        <Formik initialValues={{}} onSubmit={onUnfollow}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <RestyledButton
                type="submit"
                disabled={isSubmitting}
                data-for={`list_${curatedList.id}`}
                data-tip
              >
                <FontAwesomeIcon icon="rss" />
                <Slash icon="slash" />
              </RestyledButton>
            </StyledForm>
          )}
        </Formik>
      ) : (
        <Formik initialValues={{}} onSubmit={onFollow}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <RestyledButton
                type="submit"
                disabled={isSubmitting}
                data-for={`list_${curatedList.id}`}
                data-tip
              >
                <FontAwesomeIcon icon="rss" />
              </RestyledButton>
            </StyledForm>
          )}
        </Formik>
      )}
      <ReactTooltip
        id={`list_${curatedList.id}`}
        effect="solid"
        place="bottom"
        getContent={(): string => {
          return isFollowing ? t('followUnfollow') : t('followFollow');
        }}
      />
    </>
  );
};

export default CuratedListCardFollowButton;
