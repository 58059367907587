import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fibonacci } from 'src/utils/math';

const LoadingSpinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fibonacci(7)}rem;
`;

const LoadingSpinnerView: React.FC = () => {
  return (
    <LoadingSpinner>
      <FontAwesomeIcon icon="spinner" pulse />
    </LoadingSpinner>
  );
};

export default LoadingSpinnerView;
