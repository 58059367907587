import React from 'react';
import styled from 'styled-components';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import Header from 'src/components/header';
import { AccountModalsProvider } from 'src/hooks/use-account-modals';
import { ConfirmationModalProvider } from 'src/hooks/use-confirmation-modal';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Root: React.FC = () => {
  return (
    <AccountModalsProvider>
      <ConfirmationModalProvider>
        <ScrollRestoration />
        <Header />

        <Main>
          <Outlet />
        </Main>
      </ConfirmationModalProvider>
    </AccountModalsProvider>
  );
};

export default Root;
