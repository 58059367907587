import React from 'react';
import NoContent from 'src/components/no-content';
import { useTranslation } from 'react-i18next';
import UserList from 'src/components/user-list';
import useList from '../use-list';

const ListFollowersRouteView: React.FC = () => {
  const { t } = useTranslation('general');
  const { curatedList } = useList();

  if (curatedList.userFollowerCount === 0) {
    return <NoContent>{t('noFollowers')}</NoContent>;
  } else {
    return (
      <UserList
        paginationArgs={{
          curatedListFollowedId: curatedList.id,
        }}
        useLineItemLayout={true}
      />
    );
  }
};

export default ListFollowersRouteView;
