import React, { useState } from 'react';
import { CuratedList } from 'src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DashDotDotButtonSet,
  Ellipsis,
  GlobalStyleVariables,
  InvertingHoverButton,
  PrimaryColorLink,
} from 'src/styles';
import Summary from 'src/components/summary';
import CuratedListSummarySumplementaryInfo from 'src/components/curated-list-summary/curated-list-summary-supplementary-info';
import CuratedListSummaryForm from 'src/components/curated-list-summary/curated-list-summary-form';
import CuratedListSummaryMenu from 'src/components/curated-list-summary/curated-list-summary-menu';
import CuratedListSummaryPrimaryButton from 'src/components/curated-list-summary/curated-list-summary-primary-button';
import { parseMarkdown } from 'src/utils/security';
import ExpandingList from '../expanding-list';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import UsernameView from 'src/components/username';

export const ExpandingCreditsList = styled(ExpandingList)`
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  font-weight: bold;

  li:not(:first-child),
  .expanding-list__more {
    display: block;
    margin-top: ${fibonacci(1) * Math.pow(goldenRatioInverse, 3)}rem;
  }

  a {
    color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
  }
`;

interface InterfaceProps {
  className?: string;
  curatedList: CuratedList;
  disableHeaderLink?: boolean;
  hideHeader?: boolean;
}

const CuratedListSummaryView: React.FC<InterfaceProps> = ({
  className,
  disableHeaderLink,
  curatedList,
  hideHeader,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  function toggleEdit(): void {
    setIsEditing(!isEditing);
  }

  return (
    <Summary className={className}>
      {isEditing ? (
        <CuratedListSummaryForm
          curatedList={curatedList}
          toggleForm={toggleEdit}
        />
      ) : (
        <>
          <section className="summary__lead">
            {!hideHeader && (
              <>
                <h1 className="summary__header">
                  {disableHeaderLink ? (
                    <Ellipsis>{curatedList.name}</Ellipsis>
                  ) : (
                    <PrimaryColorLink to={`/lists/${curatedList.id}`}>
                      <Ellipsis>{curatedList.name}</Ellipsis>
                    </PrimaryColorLink>
                  )}
                </h1>
                <div className="summary__credits">
                  <ExpandingCreditsList contractedLength={1}>
                    {[...curatedList.curatorialMemberships].map((cm) => {
                      return (
                        <UsernameView
                          user={cm.user}
                          key={cm.user.id}
                        ></UsernameView>
                      );
                    })}
                  </ExpandingCreditsList>
                </div>
              </>
            )}
            <div className="summary__buttons">
              <DashDotDotButtonSet>
                <CuratedListSummaryPrimaryButton
                  curatedList={curatedList}
                  toggleEdit={toggleEdit}
                />
                <InvertingHoverButton>
                  <FontAwesomeIcon icon="share" />
                </InvertingHoverButton>
                <CuratedListSummaryMenu curatedList={curatedList} />
              </DashDotDotButtonSet>
            </div>
          </section>
          <section className="summary__primary">
            <div className="summary__supplementary-list">
              <CuratedListSummarySumplementaryInfo curatedList={curatedList} />
            </div>
            <Ellipsis
              className="summary__main"
              dangerouslySetInnerHTML={parseMarkdown(curatedList.description)}
            />
          </section>
        </>
      )}
    </Summary>
  );
};

export default CuratedListSummaryView;
