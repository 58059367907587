import React from 'react';
import {
  StyledLabel,
  StyledLabelName,
  StyledFieldError,
  GlobalStyleVariables,
} from 'src/styles';
import styled from 'styled-components';
import { goldenRatioInverse, fibonacci } from 'src/utils/math';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CheckboxLabel = styled(StyledLabel)`
  display: flex;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  position: relative;
  margin-right: 1rem;
`;

const Check = styled.span`
  display: none;
  position: absolute;
  top: 0;
  height: ${fibonacci(3)}rem;
  width: ${fibonacci(3)}rem;
  align-items: center;
  justify-content: center;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  opacity: ${goldenRatioInverse};
`;

const StyledCheckbox = styled(Field)`
  display: block;
  appearance: none;
  margin: 0;
  height: ${fibonacci(3)}rem;
  width: ${fibonacci(3)}rem;
  border: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  outline: none;
  transition-duration: ${GlobalStyleVariables.baseDuration}ms;
  background-color: transparent;
  cursor: pointer;

  &:active {
    border-style: solid;
  }

  &:checked {
    border-style: solid;
    background-color: rgba(${(props): string => props.theme.primaryColor}, 1);

    &:active {
      border-style: dashed;
    }

    + span {
      display: flex;
    }
  }
`;

interface InterfaceProps {
  checked?: boolean;
  disabled?: boolean;
  label: string;
  name: string;
}

const CheckboxView: React.FC<InterfaceProps> = ({
  checked,
  disabled,
  label,
  name,
}) => {
  return (
    <CheckboxLabel disabled={disabled}>
      <CheckboxContainer>
        <StyledCheckbox
          name={name}
          checked={checked}
          disabled={disabled}
          type="checkbox"
        />
        <Check>
          <FontAwesomeIcon icon="check" />
        </Check>
      </CheckboxContainer>
      <StyledLabelName>{label}</StyledLabelName>
      <StyledFieldError name={name} component="div" />
    </CheckboxLabel>
  );
};

export default CheckboxView;
