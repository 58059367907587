import React from 'react';
import { User } from 'src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { useTranslation } from 'react-i18next';
import DropdownButtonWithArrowNav from 'src/components/dropdown-button-with-arrow-nav';
import { useAccountModals } from 'src/hooks/use-account-modals';
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { useUnfollowUser } from 'src/hooks/use-unfollow-user';
import { useAuth } from 'src/hooks/use-auth';
import { checkIfUserFollowsUser } from 'src/utils/computes';
import { useFollowUser } from 'src/hooks/use-follow-user';

const StyledDropdownButtonWithArrowNav = styled(DropdownButtonWithArrowNav)`
  a {
    width: ${fibonacci(8)}rem;
    text-align: right;
    padding: ${fibonacci(3) / 2}rem ${fibonacci(3)}rem;
  }
`;

interface InterfaceProps {
  user: User;
}

const UserSummaryMenu: React.FC<InterfaceProps> = ({ user }) => {
  const { t } = useTranslation('general');
  const { openModal } = useAccountModals();
  const ensureUser = useEnsureUser();
  const { user: authUser } = useAuth();
  const onUnfollow = useUnfollowUser(user);
  const onFollow = useFollowUser(user);

  const isFollowing = checkIfUserFollowsUser(authUser, user);

  const options = [];

  if (isFollowing) {
    options.push({
      label: t('followUnfollow'),
      value: onUnfollow,
    });
  } else {
    options.push({
      label: t('followFollow'),
      value: onFollow,
    });
  }

  const report = async (): Promise<void> => {
    await ensureUser();
    if (openModal) {
      await openModal('reportProfile', { profile: user.profile });
    }
  };

  options.push({
    label: t('profilesModerationReport'),
    value: report,
  });

  function onChange(cb: () => void): void {
    cb();
  }

  return (
    <StyledDropdownButtonWithArrowNav
      button={<FontAwesomeIcon icon="ellipsis-h" />}
      onChange={onChange}
      options={options}
    />
  );
};

export default UserSummaryMenu;
