import React, { useEffect, createRef } from 'react';
import { FontAwesomeButton, InvertingSVGVanillaLink } from 'src/styles';
import { useAuth } from 'src/hooks/use-auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ReactModal from 'react-modal';
import { useAccountModals } from 'src/hooks/use-account-modals';
import { ArrowNavList } from 'src/components/arrow-nav-list';
import styled from 'styled-components';
import { GenericOptionItem } from 'src/types';

const Container = styled.div`
  display: flex;
`;

const StyledArrowNavList = styled(ArrowNavList)`
  text-align: right;
`;

interface InterfaceProps {
  menuIsOpen: boolean;
  setMenuIsOpen: (state: boolean) => void;
}

const HeaderUserMenu: React.FC<InterfaceProps> = ({
  menuIsOpen,
  setMenuIsOpen,
}) => {
  const { user, logout } = useAuth();
  const { t } = useTranslation('general');
  const { openModal } = useAccountModals();
  const navigate = useNavigate();
  const ref = createRef<HTMLAnchorElement>();

  const toggleMenu = (): void => {
    setMenuIsOpen(!menuIsOpen);
  };

  const toProfile = (): void => {
    if (!user) return;

    navigate(`/${user.username}`);

    toggleMenu();
  };

  const manageSubscriptionPackages = (): void => {
    if (!user) return;

    if (openModal) openModal('subscriptionPackageForm');
    toggleMenu();
  };

  const manageSubscriptionPromotions = (): void => {
    if (!user) return;

    if (openModal) openModal('subscriptionPromotionForm');
    toggleMenu();
  };

  const toModeration = (): void => {
    navigate('/moderation');
    toggleMenu();
  };

  const handleLogout = (): void => {
    if (!logout) return;

    logout();
    toggleMenu();
  };

  const items = [
    {
      label: <div data-testid="user-menu__profile">{t('profile')}</div>,
      onClick: toProfile,
    },
  ] as GenericOptionItem[];

  if (user?.permissions?.canPostContent) {
    items.push({
      label: t('subscriptionPackageFormOpen'),
      onClick: manageSubscriptionPackages,
    });
    items.push({
      label: t('subscriptionPromotionFormOpen'),
      onClick: manageSubscriptionPromotions,
    });
  }

  if (
    user?.permissions?.canModerateContent ||
    user?.permissions?.canApproveCreatorApplications
  ) {
    items.push({
      label: t('moderationModerate'),
      onClick: toModeration,
    });
  }

  items.push({
    label: t('logout'),
    onClick: handleLogout,
  });

  function onKeyDown(event: KeyboardEvent): void {
    if (
      event.key === 'Enter' &&
      ref.current &&
      ref.current === document.activeElement
    ) {
      event.preventDefault();
      toggleMenu();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <Container>
      <InvertingSVGVanillaLink
        tabIndex={0}
        ref={ref}
        data-testid="user-menu__toggle"
        onClick={toggleMenu}
      >
        <FontAwesomeButton icon="bars" data-testid="user-menu__button" />
      </InvertingSVGVanillaLink>

      <div data-testid="user-menu__modal">
        <ReactModal
          ariaHideApp={process.env.NODE_ENV !== 'test'}
          isOpen={menuIsOpen}
          onRequestClose={toggleMenu}
          closeTimeoutMS={0}
          className="UserMenuModal"
          overlayClassName="UserMenuModalOverlay"
        >
          <StyledArrowNavList items={items} />
        </ReactModal>
      </div>
    </Container>
  );
};

export default HeaderUserMenu;
