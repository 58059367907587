/* eslint-disable @typescript-eslint/no-explicit-any */
import { Post } from 'src/types';
import { useNavigate } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { ALBUM_BY_ID_QUERY } from 'src/queries';
import { timeout } from 'src/utils/async';

export function useReloadAndViewPost(post: Post): () => Promise<void> {
  const navigate = useNavigate();
  const [getPost] = useLazyQuery(ALBUM_BY_ID_QUERY);

  return async (): Promise<void> => {
    const offset = 0;
    const limit = post.imageCount + post.videoCount;

    await getPost({
      variables: {
        postId: post.id,
        mediaItemsMetadata: { offset, limit },
      },
    });

    await timeout(10);

    navigate({
      pathname: `/posts/${post.id}`,
    });
  };
}
