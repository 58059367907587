/* eslint-disable @typescript-eslint/no-explicit-any */
import { Post, MediaItem, PostAccessType } from 'src/types';
import {
  PostInput,
  FileUpload,
  ImageInput,
  VideoInput,
  FileFilter,
  FilterOperation,
  PostEditInput,
} from 'src/types/input';

function extractFile(
  files: any,
  index: number,
  item?: ImageInput | VideoInput
): ImageInput | VideoInput | undefined {
  if (!item) return;
  const file = item.file || item.modifiedFile;
  const filteredFile = item.filteredModifiedFile;
  if (!file) return;

  item = {
    ...item,
  };

  if (item.type === 'video') {
    (item as VideoInput).duration = Math.round(
      item.modifications?.duration || 0
    );
  }

  if (!item.id) {
    item.extension = getExtension(file.name);
  }

  files[`src:${index}`] = file;
  if (filteredFile) {
    files[`previewSrc:${index}`] = filteredFile;
    item.filterOperation = item.previewSrc
      ? FilterOperation.replace
      : FilterOperation.upload;
  } else if (item.previewSrc) {
    item.filterOperation = FilterOperation.delete;
  }
  delete item.file;
  delete item.filteredModifiedFile;
  delete item.modifiedFile;
  delete item.modifications;
  delete item.previewSrc;
  delete item.result;
  delete item.type;

  return item;
}

export function getExtension(fileName: string): string {
  const segments = fileName.split('.');
  return segments[segments.length - 1];
}

export async function uploadFile(
  uploadUrl: string,
  file: File
): Promise<Response> {
  return fetch(uploadUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  });
}

export async function uploadPost(
  post: PostInput | PostEditInput,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  upload: (options: any) => Promise<any>,
  originalPost?: Post
): Promise<Post> {
  const isNew = !('id' in post);

  if (post.accessType === PostAccessType['post-access-type-free']) {
    post.mediaCollection.mediaItems.forEach((mi) => {
      if (mi.image) mi.image.previewType = FileFilter.none;
      if (mi.video) mi.video.previewType = FileFilter.none;
    });
  }

  post.mediaCollection.mediaItems.forEach((mi) => {
    if (
      mi.image?.previewType === FileFilter.none ||
      mi.video?.previewType === FileFilter.none ||
      mi.image?.previewType === FileFilter.locked ||
      mi.video?.previewType === FileFilter.locked
    ) {
      delete mi.image?.filterIntensity;
      delete mi.image?.filteredModifiedFile;
      delete mi.video?.filterIntensity;
      delete mi.video?.filteredModifiedFile;
    }
  });

  const mediaItemFiles = {} as any;

  post = {
    ...post,
    mediaCollection: {
      ...post.mediaCollection,
      mediaItems: post.mediaCollection.mediaItems.map((mediaItem, index) => {
        mediaItem = {
          ...mediaItem,
          image: extractFile(mediaItemFiles, index, mediaItem.image),
          video: extractFile(
            mediaItemFiles,
            index,
            mediaItem.video
          ) as VideoInput,
        };
        delete mediaItem.fileUpload;
        return mediaItem;
      }),
    },
  };

  const response = await upload({ variables: { post } });
  const result = isNew ? response.data.createPost : response.data.updatePost;

  const uploads = (result.mediaCollection.mediaItems as MediaItem[])
    .sort((a, b) => a.order - b.order)
    .reduce((uploads, mediaItem, index) => {
      const item = mediaItem.image || mediaItem.video;
      if (item !== undefined) {
        if (isNew) {
          uploads.push(
            uploadFile(item.uploadUrl, mediaItemFiles[`src:${index}`] as File)
          );
        }

        if (item.previewUploadUrl) {
          const originalMediaItem =
            originalPost?.mediaCollection.mediaItems.find(
              (mi) => mi.id === mediaItem.id
            );
          const originalItem =
            originalMediaItem?.image || originalMediaItem?.video;
          if (
            (originalItem?.filterIntensity !== item.filterIntensity ||
              originalItem?.previewType !== item.previewType) &&
            mediaItemFiles[`previewSrc:${index}`]
          ) {
            uploads.push(
              uploadFile(
                item.previewUploadUrl,
                mediaItemFiles[`previewSrc:${index}`]
              )
            );
          }
        }
      } else {
        uploads.push(Promise.reject());
      }

      return uploads;
    }, [] as Promise<Response>[]);

  await Promise.all(uploads);

  return result;
}

interface RenderModifiedImageOptions {
  shouldApplyFilter?: boolean;
  shouldUseCanvasHeight?: boolean;
}

export function renderModifiedImage(
  file: FileUpload,
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  options?: RenderModifiedImageOptions
): void {
  const context = canvas.getContext('2d', { willReadFrequently: true });

  if (context) {
    const {
      cropCoordinates = [0, 0, 1, 1],
      isHorizontallyFlipped = false,
      rotation = 0,
    } = file.modifications;
    const isSideways = rotation === 90 || rotation === 270;
    const croppedWidth =
      (cropCoordinates[2] - cropCoordinates[0]) * image.naturalWidth;
    const croppedHeight =
      (cropCoordinates[3] - cropCoordinates[1]) * image.naturalHeight;
    const canvasWidth = options?.shouldUseCanvasHeight
      ? isSideways
        ? croppedHeight * (canvas.offsetHeight / croppedWidth)
        : croppedWidth * (canvas.offsetHeight / croppedHeight)
      : isSideways
      ? croppedHeight
      : croppedWidth;
    const canvasHeight = options?.shouldUseCanvasHeight
      ? canvas.offsetHeight
      : isSideways
      ? croppedWidth
      : croppedHeight;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    context.save();
    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate((rotation * Math.PI) / 180);
    if (isHorizontallyFlipped) {
      context.scale(-1, 1);
    }
    const width = isSideways ? canvas.height : canvas.width;
    const height = isSideways ? canvas.width : canvas.height;

    context.drawImage(
      image,
      image.naturalWidth * cropCoordinates[0],
      image.naturalHeight * cropCoordinates[1],
      croppedWidth,
      croppedHeight,
      -width / 2,
      -height / 2,
      width,
      height
    );

    if (options?.shouldApplyFilter) {
      if (file.previewType === FileFilter.keyhole) {
        context.restore();

        const heightFactor = canvas.height / 380;
        const scaleDivisor = 27;
        const scaleQuotient = (file.filterIntensity ?? 100) / scaleDivisor;
        const scaleFactor = scaleQuotient * heightFactor;
        const scaleFactorRatio =
          scaleFactor / ((100 / scaleDivisor) * heightFactor);
        const xOffset =
          canvas.width / 2 -
          (canvas.height * 0.51682) / 2 -
          (canvas.width / 2 - (canvas.height * 0.51682) / 2) *
            (1 - (file.filterIntensity ?? 100) / 100);
        const xModifier =
          (canvas.width - canvas.width * scaleFactorRatio) / 2 + xOffset;
        const yModifier =
          (canvas.height - canvas.height * scaleFactorRatio) / 2;

        context.beginPath();
        context.fillStyle = 'black';

        context.moveTo(
          48.318 * scaleFactor + xModifier,
          24.159 * scaleFactor + yModifier
        );

        context.lineTo(48.318 * scaleFactor + xModifier, canvas.height);
        context.lineTo(0, canvas.height);
        context.lineTo(0, 0);
        context.lineTo(canvas.width, 0);
        context.lineTo(canvas.width, canvas.height);
        context.lineTo(48.318 * scaleFactor + xModifier, canvas.height);
        context.lineTo(
          48.318 * scaleFactor + xModifier,
          24.159 * scaleFactor + yModifier
        );

        context.bezierCurveTo(
          48.318 * scaleFactor + xModifier,
          10.816 * scaleFactor + yModifier,
          37.503 * scaleFactor + xModifier,
          0 * scaleFactor + yModifier,
          24.159 * scaleFactor + xModifier,
          0 * scaleFactor + yModifier
        );
        context.bezierCurveTo(
          10.816 * scaleFactor + xModifier,
          0 * scaleFactor + yModifier,
          0 * scaleFactor + xModifier,
          10.816 * scaleFactor + yModifier,
          0 * scaleFactor + xModifier,
          24.159 * scaleFactor + yModifier
        );
        context.bezierCurveTo(
          0 * scaleFactor + xModifier,
          34.373 * scaleFactor + yModifier,
          6.349 * scaleFactor + xModifier,
          43.089 * scaleFactor + yModifier,
          15.309 * scaleFactor + xModifier,
          46.622 * scaleFactor + yModifier
        );

        context.lineTo(
          1.282 * scaleFactor + xModifier,
          100 * scaleFactor + yModifier
        );
        context.lineTo(
          21.21 * scaleFactor + xModifier,
          100 * scaleFactor + yModifier
        );
        context.lineTo(
          27.108 * scaleFactor + xModifier,
          100 * scaleFactor + yModifier
        );
        context.lineTo(
          47.038 * scaleFactor + xModifier,
          100 * scaleFactor + yModifier
        );

        context.lineTo(
          33.011 * scaleFactor + xModifier,
          46.622 * scaleFactor + yModifier
        );
        context.bezierCurveTo(
          41.971 * scaleFactor + xModifier,
          43.089 * scaleFactor + yModifier,
          48.318 * scaleFactor + xModifier,
          34.374 * scaleFactor + yModifier,
          48.318 * scaleFactor + xModifier,
          24.159 * scaleFactor + yModifier
        );

        context.closePath();

        context.fill();
      } else if (file.previewType === FileFilter.blur) {
        if (
          file.filterIntensity &&
          file.filterIntensity > 5 &&
          canvas.width > 0 &&
          canvas.height > 0
        ) {
          const heightFactor = canvas.height / 380;
          const pixelSize = Math.round(
            ((file.filterIntensity ?? 100) / 5) * heightFactor
          );

          const imageData = context.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
          const data = imageData.data;

          for (let y = 0; y < canvas.height; y += pixelSize) {
            for (let x = 0; x < canvas.width; x += pixelSize) {
              // Calculate average color for the block
              let totalR = 0,
                totalG = 0,
                totalB = 0,
                count = 0;
              for (
                let blockY = y;
                blockY < y + pixelSize && blockY < canvas.height;
                blockY++
              ) {
                for (
                  let blockX = x;
                  blockX < x + pixelSize && blockX < canvas.width;
                  blockX++
                ) {
                  const blockIndex = (blockY * canvas.width + blockX) * 4;
                  totalR += data[blockIndex];
                  totalG += data[blockIndex + 1];
                  totalB += data[blockIndex + 2];
                  count++;
                }
              }

              // Calculate average color
              const avgR = totalR / count;
              const avgG = totalG / count;
              const avgB = totalB / count;

              // Apply the average color to the entire block of pixels
              for (
                let blockY = y;
                blockY < y + pixelSize && blockY < canvas.height;
                blockY++
              ) {
                for (
                  let blockX = x;
                  blockX < x + pixelSize && blockX < canvas.width;
                  blockX++
                ) {
                  const blockIndex = (blockY * canvas.width + blockX) * 4;
                  data[blockIndex] = avgR;
                  data[blockIndex + 1] = avgG;
                  data[blockIndex + 2] = avgB;
                }
              }
            }
          }

          // Put the modified image data back onto the canvas
          context.putImageData(imageData, 0, 0);
        }
      } else if (file.previewType === FileFilter.locked) {
        context.restore();
        context.fillStyle = 'black';
        context.fillRect(0, 0, canvas.width, canvas.height);
      }
    }
  }
}
