import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface InterfaceProps {
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  name: string;
  showMonthDropdown?: boolean;
  showTimeSelect?: boolean;
  showYearDropdown?: boolean;
}

const DateFieldView: React.FC<InterfaceProps> = (props) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val): void => {
        setFieldValue(field.name, val);
      }}
      className="react-datepicker-input__restyled"
      popperClassName="react-datepicker-popper__restyled"
      calendarClassName="react-datepicker-calendar__restyled"
    />
  );
};

export default DateFieldView;
