import React from 'react';
import AppView from 'src/components/app/app-view';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createLink } from 'apollo-absinthe-upload-link';
import {
  offsetBasedPaginatedEntries,
  userByUsername,
} from 'src/components/app/cache-resolvers';
import { getItem } from 'src/utils/storage';
import schema from 'src/queries/schema';
import config from 'src/config';

const httpLink = createLink({
  uri: config.graphqlUrl,
});

const authLink = setContext((_, { headers }) => {
  const token = getItem('auth:token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        keyFields: ['username'],
      },
      Query: {
        fields: {
          offsetBasedPaginatedEntries: {
            read: offsetBasedPaginatedEntries,
          },
          userByUsername: {
            read: userByUsername,
          },
        },
      },
    },
  }),
  typeDefs: schema,
  // eslint-disable-next-line
  // @ts-ignore
  link: authLink.concat(httpLink),
});

const ApolloWrapper: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <AppView />
    </ApolloProvider>
  );
};

export default ApolloWrapper;
