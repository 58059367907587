import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { GlobalStyleVariables } from 'src/styles';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';

const MAX_DOTS = 6;

interface ContainerInterfaceProps {
  alwaysShow?: boolean;
}

const Container = styled.div.attrs((props: ContainerInterfaceProps) => ({
  alwaysShow: props.alwaysShow,
}))`
  ${(props): string => (props.alwaysShow ? 'display: flex;' : 'display: none;')}
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    display: flex;
  }
`;

const Dot = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin: 0 ${fibonacci(1) * Math.pow(goldenRatioInverse, 3)}rem;
  color: rgba(
    ${(props): string => props.theme.lowMutedColor},
    ${goldenRatioInverse}
  );
`;

const CurrentDot = styled(Dot)`
  color: rgb(${(props): string => props.theme.primaryColor});
`;

const HiddenDot = styled(Dot)`
  display: none;
`;

const LittleDot = styled(Dot)`
  font-size: ${fibonacci(1) * goldenRatioInverse}rem;
`;

interface InterfaceProps {
  alwaysShow?: boolean;
  className?: string;
  index: number;
  length: number;
}

const CarouselIndexerView: React.FC<InterfaceProps> = ({
  alwaysShow,
  className,
  index,
  length,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const [dots, setDots] = useState([...Array(length)]);

  useEffect(() => {
    if (index - startIndex > MAX_DOTS - 1) {
      const newStartIndex = Math.max(
        0,
        Math.min(index - (MAX_DOTS - 1), length - (MAX_DOTS + 1))
      );
      if (startIndex !== newStartIndex) setStartIndex(newStartIndex);
    } else if (index === startIndex && startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  }, [index, length]);

  useEffect(() => {
    setDots([...Array(length)]);
  }, [length]);

  if (length < 2) {
    return <></>;
  }

  return (
    <Container className={className} alwaysShow={alwaysShow}>
      {dots.map((_, i) => {
        if (i < startIndex || i > startIndex + MAX_DOTS) {
          return <HiddenDot icon="circle" key={i}></HiddenDot>;
        } else if (
          (i === startIndex && i > 0) ||
          (i === startIndex + MAX_DOTS && i < length - 1)
        ) {
          return <LittleDot icon="circle" key={i} />;
        } else if (i === index) {
          return <CurrentDot icon="circle" key={i} />;
        } else {
          return <Dot icon="circle" key={i} />;
        }
      })}
    </Container>
  );
};

export default CarouselIndexerView;
