import React from 'react';
import { FeedItem } from 'src/types';
import FeedItemPostItem from 'src/components/feed-item-list/types/post-item';
import FeedItemPreamble from 'src/components/feed-item-list/types//preamble';
import { useTranslation } from 'react-i18next';
import { Ellipsis, MutedLink } from 'src/styles';
import FeedItemContainer from 'src/components/feed-item-list/types/container';

interface InterfaceProps {
  feedItem: FeedItem;
}

const FeedItemPostCurated: React.FC<InterfaceProps> = ({ feedItem }) => {
  const { t } = useTranslation('general');

  return (
    <FeedItemContainer
      content={<FeedItemPostItem feedItem={feedItem} />}
      preamble={
        <FeedItemPreamble>
          <MutedLink to={`/lists/${feedItem.curatedList.id}`}>
            <Ellipsis>
              {t('feedItemPostCurated', { name: feedItem.curatedList.name })}
            </Ellipsis>
          </MutedLink>
        </FeedItemPreamble>
      }
    />
  );
};

export default FeedItemPostCurated;
