import React from 'react';
import styled from 'styled-components';
import { FileUpload, SimpleFile } from 'src/types';

const StyledVideo = styled.video``;

interface InterfaceProps {
  className?: string;
  file?: FileUpload;
  mode?: string;
  onClick?: () => void;
  originalFile?: SimpleFile;
}

const FileUploaderVideo: React.FC<InterfaceProps> = ({
  className,
  file,
  mode,
  onClick,
  originalFile,
}) => {
  return (
    <StyledVideo
      className={className}
      src={file?.result || originalFile?.src}
      onClick={onClick}
      autoPlay
      loop
      muted
    />
  );
};

export default FileUploaderVideo;
