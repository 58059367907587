import { useTranslation } from 'react-i18next';
import { GraphQLError } from 'graphql';

type Callback = (message: string) => void;
export interface Error {
  graphQLErrors: GraphQLError[];
}

export function useGraphQLErrors(): (error: Error, cb: Callback) => void {
  const { t } = useTranslation('general');

  return function handleGraphQLErrors(error: Error, cb: Callback): void {
    if (!error || !error.graphQLErrors) return;

    error.graphQLErrors.forEach((error: { message: string }) => {
      if (t(error.message) !== error.message) {
        cb(t(error.message));
      }
    });
  };
}
