import React from 'react';
import styled from 'styled-components';
import { CuratedList } from 'src/types';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles';
import { useTranslation } from 'react-i18next';

const StatsContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
`;

const Stat = styled.div`
  margin-top: ${fibonacci(1) * goldenRatioInverse}rem;
  padding: ${fibonacci(1) * goldenRatioInverse}rem 1rem;
  background: rgba(${(props): string => props.theme.primaryColor}, 1);
  color: rgba(${(props): string => props.theme.backgroundColor}, 1);
`;

interface InterfaceProps {
  curatedList: CuratedList;
}

const CuratedListCardStats: React.FC<InterfaceProps> = ({ curatedList }) => {
  const { t } = useTranslation('general');

  return (
    <StatsContainer>
      {curatedList.isFeatured && <Stat>{t('curatedListsIsFeatured')}</Stat>}
      {curatedList.curatedPostCount > 0 && (
        <Stat>
          {t('curatedListsPostsWithCount', {
            count: curatedList.curatedPostCount,
          })}
        </Stat>
      )}

      {curatedList.userFollowerCount > 0 && (
        <Stat>
          {t('followersWithCount', {
            count: curatedList.userFollowerCount,
          })}
        </Stat>
      )}
    </StatsContainer>
  );
};

export default CuratedListCardStats;
