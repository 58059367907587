import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'ts-debounce';
import { goldenRatioInverse } from 'src/utils/math';
import CarouselNavButton from 'src/components/carousel/carousel-nav-button';
import CarouselIndexer from 'src/components/carousel/carousel-indexer-view';
import { GlobalStyleVariables } from 'src/styles';

const Container = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 2px;
  height: 100%;
  user-select: none;

  &:hover {
    > button {
      opacity: ${goldenRatioInverse};
    }
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    display: flex;
    padding: 0;
  }
`;

const Carousel = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  & > div {
    scroll-snap-align: start;
  }

  scrollbar-width: none;

  ::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }
`;

function getElements(scroller: HTMLDivElement): HTMLElement[] {
  return [...(scroller.children as unknown as HTMLElement[])];
}

function scrollToInitial(
  scroller: HTMLDivElement,
  initialIndex?: number
): number {
  return requestAnimationFrame(() => {
    if (initialIndex && scroller) {
      const elements = getElements(scroller);
      const element = elements[initialIndex];

      if (element) {
        scroller.scrollLeft = element.offsetLeft;
      } else {
        scroller.scrollLeft = scroller.scrollWidth;
        scrollToInitial(scroller, initialIndex);
      }
    }
  });
}

interface InterfaceProps {
  alwaysShowIndexer?: boolean;
  className?: string;
  children: (
    setCarouselLength: (carouselLength: number) => void
  ) => ReactElement;
  hideIfEmpty?: boolean;
  initialIndex?: number;
  scrollElementRef?: HTMLDivElement | null;
  setScrollElementRef?: (element: HTMLDivElement | null) => void;
  updateIndex?: (index: number) => void;
}

const CarouselView: React.FC<InterfaceProps> = ({
  alwaysShowIndexer,
  children,
  className,
  hideIfEmpty,
  initialIndex,
  scrollElementRef,
  setScrollElementRef,
  updateIndex,
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselLength, setCarouselLength] = useState(0);
  const [hasScrolledToInitial, setHasScrolledToInitial] = useState(false);

  if (!hasScrolledToInitial && scrollElementRef) {
    scrollToInitial(scrollElementRef, initialIndex);
    setHasScrolledToInitial(true);
  }

  useEffect(() => {
    let canceled = false;

    const debouncedUpdateIndex = debounce((index: number) => {
      if (updateIndex && carouselLength > 0 && !canceled) {
        updateIndex(index);
      }
    }, 100);
    if (scrollElementRef !== null) {
      const getCurrentIndex = (): void => {
        if (scrollElementRef) {
          const elements = getElements(scrollElementRef as HTMLDivElement);

          const index =
            elements.findIndex((element) => {
              return element.getBoundingClientRect().left >= -5;
            }) || 0;

          setCarouselIndex(index);

          debouncedUpdateIndex(index);
        }
      };

      scrollElementRef?.addEventListener('scroll', getCurrentIndex);

      return () => {
        scrollElementRef?.removeEventListener('scroll', getCurrentIndex);
        canceled = true;
      };
    }
  }, [updateIndex, carouselLength, scrollElementRef]);

  return hideIfEmpty && carouselLength === 0 ? (
    <>{children(setCarouselLength)}</>
  ) : (
    <>
      <Container>
        <CarouselNavButton
          direction="left"
          scrollElementRef={scrollElementRef}
        />

        <Carousel ref={setScrollElementRef} className={className}>
          {children(setCarouselLength)}
        </Carousel>

        <CarouselNavButton
          direction="right"
          scrollElementRef={scrollElementRef}
        />
      </Container>

      <CarouselIndexer
        alwaysShow={alwaysShowIndexer}
        index={carouselIndex}
        length={carouselLength}
      />
    </>
  );
};

export default CarouselView;
