import React from 'react';
import BuyersList from 'src/routes/post/likers/buyers/buyers-list';
import usePost from '../../use-post';

const PostBuyersRouteView: React.FC = () => {
  const { post } = usePost();

  return <BuyersList purchasedPostId={post.id} />;
};

export default PostBuyersRouteView;
