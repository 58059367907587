import React from 'react';
import { ModerationRequest, Citizenship } from 'src/types';
import { useTranslation } from 'react-i18next';
import ModerationRequestItemWrapper from 'src/components/moderation-request-item/moderation-request-item-wrapper';
import { HeadingTwo } from 'src/styles';
import UsernameView from 'src/components/username';
import styled from 'styled-components';
import moment from 'moment';

const UsernameContainer = styled.div`
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
`;

interface InterfaceProps {
  moderationRequest: ModerationRequest;
}

const ModerationRequestItemCreatorApplication: React.FC<InterfaceProps> = ({
  moderationRequest,
}) => {
  const { t } = useTranslation('general');
  const creatorApplication = moderationRequest.creatorApplication;
  const birthday = moment(creatorApplication.birthday)
    .utc()
    .format('MM/DD/YYYY');

  return (
    <ModerationRequestItemWrapper moderationRequest={moderationRequest}>
      <HeadingTwo>{t('moderationCreatorApplication')}</HeadingTwo>
      <UsernameContainer>
        <UsernameView user={creatorApplication.user} />
        <div>{creatorApplication.legalName}</div>
        <div>{birthday}</div>
        <div>
          {t('citizenshipWhere', {
            where: t(Citizenship[creatorApplication.citizenship]),
          })}
        </div>
      </UsernameContainer>
    </ModerationRequestItemWrapper>
  );
};

export default ModerationRequestItemCreatorApplication;
