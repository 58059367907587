import React from 'react';
import { Notification } from 'src/types';
import { useTranslation } from 'react-i18next';
import { postTitle } from 'src/utils/formatting';

interface InterfaceProps {
  notification: Notification;
  type: string;
}

const NotificationItemPostInteraction: React.FC<InterfaceProps> = ({
  notification,
  type,
}) => {
  const { t } = useTranslation('general');

  if (!notification.post) {
    return t('notificationsDeletedContent');
  } else if (notification.latestUser) {
    const translation =
      notification.count > 1
        ? `notificationsPost${type}WithUserAndMany`
        : `notificationsPost${type}WithUser`;
    return t(translation, {
      post: postTitle(notification.post),
      count: notification.count - 1,
      user: `@${notification.latestUser.username}`,
    });
  } else {
    return t(`notificationsPost${type}`, {
      post: postTitle(notification.post),
      count: notification.count,
    });
  }
};

export default NotificationItemPostInteraction;
