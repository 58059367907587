import React from 'react';
import UserList from 'src/components/user-list/index';
import NoContent from 'src/components/no-content';
import { useTranslation } from 'react-i18next';
import useUser from '../../use-user';

const UserFollowingUsersRouteView: React.FC = () => {
  const { t } = useTranslation('general');
  const { user } = useUser();

  if (user.userFollowingCount === 0) {
    return <NoContent>{t('noFollowing')}</NoContent>;
  } else {
    return (
      <UserList
        paginationArgs={{
          userFollowerId: user.id,
        }}
        useLineItemLayout={true}
      />
    );
  }
};

export default UserFollowingUsersRouteView;
