import React, { ReactElement } from 'react';
import { CURATED_LIST_QUERY } from 'src/queries';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import GraphqlResult from 'src/components/graphql-result';
import { MainContainer, GlobalStyleVariables } from 'src/styles';
import ListRouteNav from 'src/routes/list/list-route-nav';
import ListRouteContent from 'src/routes/list/list-route-content';
import StickyBorder from 'src/components/sticky-border';
import ListRouteRouter from 'src/routes/list/list-route-router';

const ListRouteView: React.FC = () => {
  const { curatedListId } = useParams<{ curatedListId?: string }>();
  const response = useQuery(CURATED_LIST_QUERY, {
    variables: {
      curatedListId,
    },
  });

  return (
    <MainContainer>
      <GraphqlResult response={response}>
        {({ curatedList }): ReactElement => (
          <>
            <ListRouteContent curatedList={curatedList} />

            <ListRouteNav curatedList={curatedList} />

            <StickyBorder top={GlobalStyleVariables.headerHeight * 2} />

            <ListRouteRouter curatedList={curatedList} />
          </>
        )}
      </GraphqlResult>
    </MainContainer>
  );
};

export default ListRouteView;
