/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { useMutation } from '@apollo/client';
import { COLLECT_ALBUM_MUTATION } from 'src/queries/membership';
import { Post, User } from 'src/types';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { updateCurrentUser, updatePaginatedPosts } from 'src/utils/data';
import { postTitle } from 'src/utils/formatting';

export function useLikePost(
  post: Post
): (_: any, options?: any) => Promise<void> {
  const { t } = useTranslation('general');
  const ensureUser = useEnsureUser();
  const [likePostMutation] = useMutation(COLLECT_ALBUM_MUTATION, {
    update(cache, { data: { likePost } }) {
      updateCurrentUser(cache, (currentUser: User) => {
        return {
          ...currentUser,
          ...likePost.user,
        };
      });

      updatePaginatedPosts(
        cache,
        {
          likerId: likePost.user.id,
        },
        (posts) => {
          return [likePost.post, ...posts];
        }
      );
    },
  });
  const handleGraphQLErrors = useGraphQLErrors();

  return async (_: any, options?: any): Promise<void> => {
    try {
      if (!likePostMutation) return;

      await ensureUser();
      await likePostMutation({
        variables: {
          postId: post.id,
        },
      });

      toast(
        t('likeAdded', {
          title: postTitle(post),
        })
      );
    } catch (e) {
      handleGraphQLErrors(e as Error, (message) => {
        toast(message, { type: 'error' });
      });
    } finally {
      if (options && options.setSubmitting) options.setSubmitting(false);
    }
  };
}
