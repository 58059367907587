import { DefaultTheme } from 'styled-components';

const HoneyTheme: DefaultTheme = {
  honeycombSrc: '/honeycomb-honey.png',
  backgroundColor: '0, 0, 0',
  primaryColor: '255, 195, 11',
  highMutedColor: '203, 153, 0',
  lowMutedColor: '160, 121, 0',
  errorColor: '251, 11, 23',
  linkColor: '102, 100, 201',
  successColor: '28, 209, 9',
};

export default HoneyTheme;
