import React from 'react';
import { PaginatableType, FeedItem } from 'src/types';
import InfiniteScroll from 'src/components/infinite-scroll';
import NoContent from 'src/components/no-content';
import FeedItemPostPublished from 'src/components/feed-item-list/types/post-published';
import FeedItemPostCurated from 'src/components/feed-item-list/types/post-curated';
import { useTranslation } from 'react-i18next';
import { CardVerticalLayout } from 'src/styles';

interface InterfaceProps {
  entries: PaginatableType[];
  onLoadMore: () => Promise<void>;
}

const FeedItemListView: React.FC<InterfaceProps> = ({
  entries,
  onLoadMore,
}) => {
  const { t } = useTranslation('general');

  if (!entries || entries.length === 0) {
    return <NoContent>{t('feedItemNoContent')}</NoContent>;
  }

  // TODO: aggregate these with a graphdb query;
  // you know, once we're using graphdb :)
  const usedPostIds = [] as string[];
  entries = entries.filter((feedItem) => {
    feedItem = feedItem as FeedItem;
    const canDisplay = usedPostIds.indexOf(feedItem.post.id) === -1;
    usedPostIds.push(feedItem.post.id);
    return canDisplay;
  });

  return (
    <InfiniteScroll onApproachingInfinity={onLoadMore}>
      <CardVerticalLayout>
        {entries.map((feedItem) => {
          feedItem = feedItem as FeedItem;

          switch (feedItem.type) {
            case 0:
              return (
                <FeedItemPostPublished feedItem={feedItem} key={feedItem.id} />
              );
            case 1:
              return (
                <FeedItemPostCurated feedItem={feedItem} key={feedItem.id} />
              );
            default:
              return <div key={feedItem.id}></div>;
          }
        })}
      </CardVerticalLayout>
    </InfiniteScroll>
  );
};

export default FeedItemListView;
