import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import {
  HeadingTwo,
  Paragraph,
  DefaultLink,
  GlobalStyleVariables,
} from 'src/styles';
import HoneycombLogo from 'src/components/honeycomb-logo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${fibonacci(3)}rem;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: ${fibonacci(8)}rem;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    display: none;
  }
`;

const Logo = styled(HoneycombLogo)`
  height: ${fibonacci(7)}rem;
`;

interface InterfaceProps {
  goToLoginForm: () => void;
}

const RegistrationFormAbout: React.FC<InterfaceProps> = ({ goToLoginForm }) => {
  const { t } = useTranslation('general');

  return (
    <Container data-testid="registration-form-about">
      <HeadingTwo>{t('register')}</HeadingTwo>
      <Paragraph>{t('registerAbout')}</Paragraph>
      <ul>
        <li>{t('registerListAds')}</li>
        <li>{t('registerListResolution')}</li>
        <li>{t('registerListPayouts')}</li>
        <li>{t('registerListGovernance')}</li>
      </ul>
      <Paragraph>
        {t('registerLoginInstead')}{' '}
        <DefaultLink
          data-testid="registration-form-about__login"
          onClick={goToLoginForm}
        >
          {t('registerLoginInsteadLink')}
        </DefaultLink>
      </Paragraph>
      <Icon>
        <Logo />
      </Icon>
    </Container>
  );
};

export default RegistrationFormAbout;
