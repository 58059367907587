import React from 'react';
import { ButtonSet, InvertedButton, StyledButton } from 'src/styles';
import { FieldProps } from 'formik';
import _ from 'lodash';
import { GenericOptionItem } from 'src/types';
import { useForceUpdate } from 'src/hooks/use-force-update';

interface InterfaceProps {
  canDeselect?: boolean;
  className?: string;
  disabled?: boolean;
  optionName: string;
  options: GenericOptionItem[];
  onSelect?: (option?: GenericOptionItem) => void;
}

const DeconstructedSelectView: React.FC<InterfaceProps & FieldProps> = ({
  canDeselect = true,
  className,
  disabled,
  options,
  optionName,
  form,
  onSelect,
}) => {
  const forceUpdate = useForceUpdate();

  const selectOption = (option?: GenericOptionItem): void => {
    if (option || canDeselect) {
      _.set(form.values, optionName, option?.value);
      forceUpdate();
      if (onSelect) onSelect(option);
    }
  };

  return (
    <ButtonSet className={className}>
      {options.map((option) => {
        const isSelected = _.get(form.values, optionName) === option.value;

        if (isSelected) {
          return (
            <InvertedButton
              disabled={disabled}
              key={option.value}
              onClick={() => selectOption()}
              type="button"
            >
              {option.label}
            </InvertedButton>
          );
        } else {
          return (
            <StyledButton
              disabled={disabled}
              key={option.value}
              onClick={() => selectOption(option)}
              type="button"
            >
              {option.label}
            </StyledButton>
          );
        }
      })}
    </ButtonSet>
  );
};

export default DeconstructedSelectView;
