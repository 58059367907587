import React, { ReactElement } from 'react';
import DropdownButton from 'src/components/dropdown-button';
import { ArrowNavList } from 'src/components/arrow-nav-list';
import {
  GenericOptionItem,
  GenericOptionItemValue,
  GenericOptionItemLabel,
} from 'src/types';

interface InterfaceProps {
  className?: string;
  button: GenericOptionItemLabel;
  initialIndex?: number;
  onChange: (value: GenericOptionItemValue) => void;
  options: GenericOptionItem[];
}

const SelectView: React.FC<InterfaceProps> = ({
  className,
  button,
  initialIndex = 0,
  onChange,
  options,
}) => {
  return (
    <DropdownButton
      className={className}
      button={button}
      menu={(closeDropdown): ReactElement => {
        function select(value: GenericOptionItemValue): void {
          closeDropdown();
          onChange(value);
        }
        return (
          <ArrowNavList
            onChange={select}
            initialIndex={initialIndex}
            items={options}
          />
        );
      }}
    />
  );
};

export default SelectView;
