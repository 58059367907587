import { GlobalStyleVariables } from 'src/styles/global';
import styled, { css } from 'styled-components';
import { fibonacci } from 'src/utils/math';

export const HeadingMixin = css`
  margin: 0;
  padding: 0;
`;

export const HeadingOne = styled.h1`
  ${HeadingMixin}
  font-size: ${GlobalStyleVariables.fontSizeThreeUp}rem;
`;

export const HeadingTwoMixin = css`
  ${HeadingMixin}
  font-size: ${GlobalStyleVariables.fontSizeTwoUp}rem;
`;

export const HeadingTwo = styled.h2`
  ${HeadingTwoMixin}
`;

export const Paragraph = styled.p`
  margin: ${fibonacci(3)}rem 0 0;
`;
