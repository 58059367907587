import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { User } from 'src/types';
import NavBar from 'src/components/nav-bar';
import { GlobalStyleVariables } from 'src/styles';

const StyledNavBar = styled(NavBar)`
  @media ${GlobalStyleVariables.phoneMediaQuery} {
    a {
      font-size: ${GlobalStyleVariables.fontSizeTwoDown}rem;
    }
  }
`;

interface InterfaceProps {
  user: User;
}

const UserRouteNav: React.FC<InterfaceProps> = ({ user }) => {
  const { t } = useTranslation('general');
  const content = [];

  if (user?.postCount > 0) {
    content.push({
      to: '',
      end: true,
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('uploadsWithCount', { count: user.postCount }),
    });
  }

  if (user.curatedListCount > 0) {
    content.push({
      to: 'likes/lists',
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('listsWithCount', { count: user.curatedListCount }),
    });
  } else {
    content.push({
      to: 'likes',
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('likeWithCount', { count: user.likeCount }),
    });
  }

  content.push({
    to: 'following',
    preventScrollReset: true,
    scrollToNavOnClick: true,
    content: t('followingWithCount', {
      count: user.userFollowingCount + user.curatedListFollowingCount,
    }),
  });

  content.push({
    to: 'followers',
    preventScrollReset: true,
    scrollToNavOnClick: true,
    content: t('followersWithCount', {
      count: user.userFollowerCount,
    }),
  });

  return <StyledNavBar>{content}</StyledNavBar>;
};

export default UserRouteNav;
