import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import FileUploaderImage from 'src/components/file-uploader/file-uploader-image';
import FileUploaderVideo from 'src/components/file-uploader/file-uploader-video';
import { FileUpload, SimpleFile } from 'src/types';
import { fibonacci } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles';

const Container = styled.div`
  position: relative;
  z-index: 5;
`;

const NoPointerEventImage = styled(FileUploaderImage)`
  pointer-events: none;
`;

const NoPointerEventVideo = styled(FileUploaderVideo)`
  pointer-events: none;
`;

const DeleteButton = styled(FontAwesomeIcon)`
  position: absolute;
  top: ${fibonacci(3)}rem;
  right: ${fibonacci(3)}rem;
  font-size: ${GlobalStyleVariables.fontSizeTwoUp}rem;
  z-index: 5;
  cursor: pointer;
`;

interface BaseInterfaceProps {
  className?: string;
  file?: FileUpload;
  openEditor: () => void;
  originalFile?: SimpleFile;
}

interface InterfaceProps extends BaseInterfaceProps {
  removeFile: () => void;
}

const MediaElement: React.FC<BaseInterfaceProps> = ({
  className,
  file,
  openEditor,
  originalFile,
}) => {
  if (file) {
    if (file.type === 'image') {
      return (
        <FileUploaderImage
          className={className}
          file={file}
          onClick={openEditor}
        />
      );
    } else if (file.type === 'video') {
      return (
        <FileUploaderVideo
          className={className}
          file={file}
          onClick={openEditor}
        />
      );
    }
  }

  if (originalFile) {
    if (originalFile.type === 'image') {
      return (
        <NoPointerEventImage
          className={className}
          originalFile={originalFile}
          onClick={openEditor}
        />
      );
    } else if (originalFile.type === 'video') {
      return (
        <NoPointerEventVideo
          className={className}
          originalFile={originalFile}
          onClick={openEditor}
        />
      );
    }
  }

  return <></>;
};

const FileUploaderSingltonItem: React.FC<InterfaceProps> = ({
  className,
  file,
  openEditor,
  originalFile,
  removeFile,
}) => {
  if ((file && !file.delete) || originalFile) {
    return (
      <Container>
        <MediaElement
          className={className}
          file={file}
          openEditor={openEditor}
          originalFile={originalFile}
        />
        <DeleteButton icon="circle-xmark" onClick={removeFile} />
      </Container>
    );
  } else {
    return <></>;
  }
};

export default FileUploaderSingltonItem;
