const dev = {
  graphqlUrl: 'http://192.168.50.48:4000/graphql',
};

const staging = {
  graphqlUrl: '/graphql',
};

const config = process.env.REACT_APP_ENV === 'dev' ? dev : staging;

export default {
  ...config,
};
