import marked from 'marked';
import purify from 'dompurify';

purify.addHook('afterSanitizeAttributes', function (node) {
  if (node.tagName === 'A') {
    node.setAttribute('target', '_blank');
  }

  if (
    !node.hasAttribute('target') &&
    (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
  ) {
    node.setAttribute('xlink:show', 'new');
  }
});

// https://medium.com/javascript-security/avoiding-xss-in-react-is-still-hard-d2b5c7ad9412
export function isSafeUrl(dangerousURL: string): boolean {
  const url = new URL(dangerousURL);
  if (url.protocol === 'http:') return true;
  if (url.protocol === 'https:') return true;

  return false;
}

function atSignRenderer(match: string): string {
  return `<a href="/${match.substring(1)}">${match}</a>`;
}

function hashTagRenderer(match: string): string {
  return `<a href="/search/?queryType=everything&query=${encodeURIComponent(
    match
  )}">${match}</a>`;
}

function purifyAndAugmentHtml(text: string): string {
  return purify
    .sanitize(
      marked(text)
        .replace(/@([a-zA-Z0-9_]+)/g, atSignRenderer)
        .replace(/\w*(?<!&)#([a-zA-Z0-9_]+)/g, hashTagRenderer)
    )
    .replaceAll('target="_blank', '');
}

export function parseMarkdown(text?: string): { __html: string } {
  return {
    __html: text ? purifyAndAugmentHtml(text) : '',
  };
}
