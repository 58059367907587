/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { useMutation } from '@apollo/client';
import { FOLLOW_CURATED_LIST_MUTATION } from 'src/queries/membership';
import { CuratedList, User } from 'src/types';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { toast } from 'react-toastify';
import { updateCurrentUser } from 'src/utils/data';
import { useTranslation } from 'react-i18next';

export function useFollowCuratedList(
  followed: CuratedList
): (_: any, options?: any) => Promise<void> {
  const ensureUser = useEnsureUser();
  const { t } = useTranslation('general');
  const [followCuratedListMutation] = useMutation(
    FOLLOW_CURATED_LIST_MUTATION,
    {
      update(cache, { data: { followCuratedList } }) {
        updateCurrentUser(cache, (currentUser: User) => {
          return {
            ...currentUser,
            followerCuratedListFollowingCount:
              followCuratedList.curatedListFollowingCount,
            followedCuratedLists: [
              ...currentUser.followedCuratedLists,
              followed,
            ],
          };
        });
      },
    }
  );
  const handleGraphQLErrors = useGraphQLErrors();

  return async (_: any, options?: any): Promise<void> => {
    try {
      if (!followCuratedListMutation) return;

      await ensureUser();

      await followCuratedListMutation({
        variables: {
          followedId: followed.id,
        },
      });
      toast(
        t('followFollowed', {
          username: followed.name ? `'${followed.name}'` : 'list',
        })
      );
    } catch (e) {
      handleGraphQLErrors(e as Error, (message) => {
        toast(message, { type: 'error' });
      });
    } finally {
      if (options && options.setSubmitting) options.setSubmitting(false);
    }
  };
}
