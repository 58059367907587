import React from 'react';
import { StyledLabel, StyledFieldError, StyledLabelName } from 'src/styles';
import { Field } from 'formik';
import { AutocompleteField } from 'src/components/autocomplete-field';
import { DocumentNode } from 'graphql';
import { GenericOptionItem } from 'src/types';

interface InterfaceProps {
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  label?: string;
  name: string;
  query: DocumentNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serializeEntries: (data: any) => GenericOptionItem[];
  type?: string;
}

const AutocompleteView: React.FC<InterfaceProps> = ({
  autoComplete,
  autoFocus,
  disabled,
  label,
  name,
  query,
  serializeEntries,
  type,
}) => {
  return (
    <StyledLabel disabled={disabled}>
      <StyledLabelName>{label}</StyledLabelName>
      <StyledFieldError name={name} component="div" />
      <Field
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        component={AutocompleteField}
        name={name}
        query={query}
        serializeEntries={serializeEntries}
        type={type}
      />
    </StyledLabel>
  );
};

export default AutocompleteView;
