import React, { useEffect } from 'react';
import { StyledButton, ButtonSet, DeemphasizedButton } from 'src/styles';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { Post, PostAccessType } from 'src/types';
import { SimpleInput, SimpleTextarea } from 'src/components/simple-form';
import { useBlocker } from 'react-router-dom';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';
import MediaCollectionUploader from '../media-collection-uploader';

interface InterfaceProps {
  className?: string;
  dirty: boolean;
  post: Post;
  isSubmitting: boolean;
  isValid: boolean;
  cancel: () => void;
}

const PostEditInnerForm: React.FC<InterfaceProps> = ({
  className,
  dirty,
  post,
  isSubmitting,
  isValid,
  cancel,
}) => {
  const { t } = useTranslation('general');
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return currentLocation.pathname !== nextLocation.pathname && dirty;
  });
  const confirm = useConfirmationModal();
  const cancelWrapper = async () => {
    if (!dirty || (await confirm(t('formCancel')))) {
      cancel();
    }
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm(t('formCancel'), blocker);
    }
  }, [blocker.state]);

  return (
    <Form className={className}>
      <Field
        component={MediaCollectionUploader}
        mode="filter"
        name="mediaCollection"
      />
      <SimpleInput label={t('postsTitle')} name="title" />
      {post.accessType === PostAccessType['post-access-type-pay'] ? (
        <SimpleInput
          label={t('postsPrice')}
          min={1}
          name="price"
          step="0.01"
          type="number"
        />
      ) : null}
      <SimpleTextarea
        label={t('postsDescription')}
        name="description"
        placeholder={t('postsDescriptionDetail')}
      />
      <ButtonSet>
        <DeemphasizedButton type="button" onClick={cancelWrapper}>
          {t('cancel')}
        </DeemphasizedButton>
        <StyledButton type="submit" disabled={isSubmitting || !isValid}>
          {t('update')}
        </StyledButton>
      </ButtonSet>
    </Form>
  );
};

export default PostEditInnerForm;
