import React from 'react';
import { CuratedList } from 'src/types';
import ReportForm from 'src/components/report-form';

interface InterfaceProps {
  curatedList: CuratedList;
  onReject: () => void;
  onResolve: () => void;
}

const ReportCuratedListFormView: React.FC<InterfaceProps> = ({
  curatedList,
  onReject,
  onResolve,
}) => {
  return (
    <ReportForm
      initialValues={{ curatedListId: curatedList.id, comment: '' }}
      onReject={onReject}
      onResolve={onResolve}
    />
  );
};

export default ReportCuratedListFormView;
