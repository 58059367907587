import React from 'react';
import { Notification } from 'src/types';
import { useTranslation } from 'react-i18next';
import NotificationItemWrapper from 'src/components/notification-item/notification-item-wrapper';

interface InterfaceProps {
  notification: Notification;
  onClick: () => void;
}

const NotificationItemBanned: React.FC<InterfaceProps> = ({
  notification,
  onClick,
}) => {
  const { t } = useTranslation('general');
  const until = notification.userRestriction?.activeUntil;
  const restrictions = [];

  if (notification.userRestriction?.cannotModerate) {
    restrictions.push(t('userRestrictionCannotModerate'));
  }

  if (notification.userRestriction?.cannotPostAnything) {
    restrictions.push(t('userRestrictionCannotPostAnything'));
  }

  if (notification.userRestriction?.cannotPostComments) {
    restrictions.push(t('userRestrictionCannotPostComments'));
  }

  if (notification.userRestriction?.cannotPostContent) {
    restrictions.push(t('userRestrictionCannotPostContent'));
  }

  if (restrictions.length === 0) {
    return (
      <NotificationItemWrapper notification={notification} onClick={onClick}>
        {t('notificationsUserRestrictionsRemoved')}
      </NotificationItemWrapper>
    );
  } else {
    return (
      <NotificationItemWrapper notification={notification} onClick={onClick}>
        {until
          ? t('notificationsUserRestrictionsAddedListUntil', {
              restrictions,
              until: until,
            })
          : t('notificationsUserRestrictionsAddedList', { restrictions })}
      </NotificationItemWrapper>
    );
  }
};

export default NotificationItemBanned;
