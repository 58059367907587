/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { ProfileInput, User } from 'src/types';
import * as yup from 'yup';
import { StyledButton, ButtonSet, DeemphasizedButton } from 'src/styles';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/client';
import { UPDATE_PROFILE_MUTATION, USER_BY_USERNAME_QUERY } from 'src/queries';
import { SimpleInput, SimpleTextarea } from 'src/components/simple-form';
import UserSummaryAvatarUploader from 'src/components/user-summary/user-summary-avatar-uploader-view';
import { getExtension, uploadFile } from 'src/utils/uploads';
import { useBlocker } from 'react-router-dom';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';

interface InterfaceProps {
  user: User;
  toggleForm: () => void;
}

const UserSummaryForm: React.FC<InterfaceProps> = ({ user, toggleForm }) => {
  const { t } = useTranslation('general');
  const [updateProfileMutation] = useMutation(UPDATE_PROFILE_MUTATION, {
    refetchQueries: [
      {
        query: USER_BY_USERNAME_QUERY,
        variables: {
          username: user.username,
        },
      },
    ],
  });
  const [isDirty, setIsDirty] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return currentLocation.pathname !== nextLocation.pathname && isDirty;
  });
  const confirm = useConfirmationModal();
  const cancelWrapper = async () => {
    if (!isDirty || (await confirm(t('formCancel')))) {
      toggleForm();
    }
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm(t('formCancel'), blocker);
    }
  }, [blocker.state]);

  const profile = user.profile;

  const profileSchema = yup.object().shape({
    description: yup.string().max(666, t('errorTooLong', { length: 666 })),
    displayName: yup.string().max(69, t('errorTooLong', { length: 69 })),
    pronouns: yup.string().max(69, t('errorTooLong', { length: 69 })),
    website: yup.string().max(69, t('errorTooLong', { length: 69 })),
  });

  const onSubmit = async (
    variables: ProfileInput,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { setSubmitting }: any
  ): Promise<void> => {
    try {
      let avatar;
      if (variables.avatar?.file) {
        avatar = variables.avatar;
        variables = {
          ...variables,
          avatar: {
            extension: getExtension(variables.avatar.file.name),
          },
        };
      }
      const result = (await updateProfileMutation({
        variables,
      })) as any;

      if (
        result.data?.updateProfile?.avatar?.uploadUrl &&
        avatar?.modifiedFile
      ) {
        await uploadFile(
          result.data.updateProfile.avatar.uploadUrl,
          avatar.modifiedFile
        );
      }

      toggleForm();
    } catch (e) {
      console.log('error', e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        id: profile.id,
        description: profile.description || '',
        displayName: user.displayName || '',
        pronouns: user.pronouns || '',
        website: profile.website || '',
      }}
      onSubmit={onSubmit}
      validationSchema={profileSchema}
    >
      {({ dirty, isSubmitting }): ReactElement => {
        setIsDirty(dirty);

        return (
          <Form>
            <Field
              component={UserSummaryAvatarUploader}
              name="avatar"
              originalAvatar={user.avatar}
            />
            <SimpleInput
              autoFocus={true}
              label={t('usersDisplayName')}
              name="displayName"
            />
            <SimpleInput label={t('profilesPronouns')} name="pronouns" />
            <SimpleInput label={t('profilesWebsite')} name="website" />
            <SimpleTextarea
              label={t('profilesDescription')}
              name="description"
              placeholder={t('profilesDescriptionDetail')}
            />
            <ButtonSet>
              <DeemphasizedButton type="button" onClick={cancelWrapper}>
                {t('cancel')}
              </DeemphasizedButton>
              <StyledButton type="submit" disabled={isSubmitting}>
                {t('submit')}
              </StyledButton>
            </ButtonSet>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserSummaryForm;
