import React, { useState } from 'react';
import { ModerationRequest } from 'src/types';
import styled from 'styled-components';
import { PrimaryColorVanillaLink } from 'src/styles';
import { useTranslation } from 'react-i18next';
import ModerationRequestItemResponsesEntries from 'src/components/moderation-request-item/moderation-request-item-responses-entries';

const ResponsesContainer = styled.div`
  padding: 1rem;
`;

interface InterfaceProps {
  isDisciplinary?: boolean;
  moderationRequest: ModerationRequest;
}

const ModerationRequestItemResponses: React.FC<InterfaceProps> = ({
  isDisciplinary,
  moderationRequest,
}) => {
  const { t } = useTranslation('general');
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleResponses = (): void => {
    setIsExpanded(!isExpanded);
  };

  if (moderationRequest.moderationRequestResponseCount > 0) {
    return (
      <ResponsesContainer>
        <PrimaryColorVanillaLink onClick={toggleResponses}>
          {t('moderationResponses', {
            count: moderationRequest.moderationRequestResponseCount,
          })}
        </PrimaryColorVanillaLink>
        {isExpanded && (
          <ModerationRequestItemResponsesEntries
            moderationRequestResponses={
              moderationRequest.moderationRequestResponses
            }
            isDisciplinary={isDisciplinary}
          />
        )}
      </ResponsesContainer>
    );
  } else {
    return <></>;
  }
};

export default ModerationRequestItemResponses;
