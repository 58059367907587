import React from 'react';
import styled from 'styled-components';
import { Post, PostAccessType } from 'src/types';
import { useTranslation } from 'react-i18next';
import MediaItemCarousel from 'src/components/media-item-carousel';
import { usePurchasePost } from 'src/hooks/use-purchase-post';
import { useSubscribe } from 'src/hooks/use-subscribe';
import { useReloadAndViewPost } from 'src/hooks/use-reload-and-view-post';

const StyledMediaItemCarousel = styled(MediaItemCarousel)`
  align-items: center;

  > div {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 100%;

    img,
    video {
      flex-shrink: 0;
      display: block;
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: contain;
    }
  }
`;

interface InterfaceProps {
  post: Post;
  shouldMute?: boolean;
  shouldPlayOnView?: boolean;
}

const PostCardCarousel: React.FC<InterfaceProps> = ({
  post,
  shouldMute = false,
  shouldPlayOnView,
}) => {
  const { t } = useTranslation('general');
  const onPurchase = usePurchasePost(post);
  const onSubscribe = useSubscribe(post.credits[0].user);
  const onReloadCurrentPost = useReloadAndViewPost(post);

  const subscribe = async () => {
    await onSubscribe(null, {});
    await onReloadCurrentPost();
  };

  return (
    <StyledMediaItemCarousel
      alwaysShowIndexer={true}
      shouldMute={shouldMute}
      shouldPlayOnView={shouldPlayOnView}
      paginationArgs={{
        mediaCollectionId: post.mediaCollection.id,
      }}
      noContentText={
        post.accessType === PostAccessType['post-access-type-pay']
          ? t('postsToViewPay')
          : post.accessType === PostAccessType['post-access-type-subscription']
          ? t('postsToViewSubscribe')
          : undefined
      }
      noContentClickCb={
        post.accessType === PostAccessType['post-access-type-pay']
          ? onPurchase
          : post.accessType === PostAccessType['post-access-type-subscription']
          ? subscribe
          : undefined
      }
    />
  );
};

export default PostCardCarousel;
