import React from 'react';
import { PaginatableType, ModerationRequestReport } from 'src/types';
import NoContent from 'src/components/no-content';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import styled from 'styled-components';
import UsernameView from 'src/components/username';
import { useTranslation } from 'react-i18next';
import { PrimaryColorVanillaLink } from 'src/styles/components/link';

const LoadMoreLink = styled(PrimaryColorVanillaLink)`
  display: block;
  margin: ${fibonacci(1) * goldenRatioInverse}rem;
`;

interface ReportProps {
  isResolved: boolean;
}

const Report = styled.div.attrs((props: ReportProps) => ({
  isResolved: props.isResolved,
}))`
  margin-top: 1rem;
  ${(props): string | undefined => {
    if (props.isResolved) {
      return `
        opacity: ${goldenRatioInverse};
      `;
    }
  }}
`;

const ReportComment = styled.div`
  margin-top: ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem;
`;

interface InterfaceProps {
  entries: PaginatableType[];
  onLoadMore: () => Promise<void>;
  reportCount: number;
}

const ModerationRequestItemReportsEntries: React.FC<InterfaceProps> = ({
  entries,
  onLoadMore,
  reportCount,
}) => {
  const { t } = useTranslation('general');

  if (!entries || entries.length === 0) {
    return <NoContent></NoContent>;
  }

  return (
    <div>
      {reportCount > entries.length && (
        <LoadMoreLink onClick={onLoadMore}>
          {t('moderationReportLoadMore')}
        </LoadMoreLink>
      )}
      {entries
        .slice(0)
        .reverse()
        .map((moderationRequestReport) => {
          moderationRequestReport =
            moderationRequestReport as ModerationRequestReport;
          return (
            <Report
              key={moderationRequestReport.id}
              isResolved={moderationRequestReport.isResolved}
            >
              <UsernameView user={moderationRequestReport.user} />
              <ReportComment>{moderationRequestReport.comment}</ReportComment>
            </Report>
          );
        })}
    </div>
  );
};

export default ModerationRequestItemReportsEntries;
