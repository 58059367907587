import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  MinorMajorButtonSet,
  StyledButton,
  DeemphasizedButton,
} from 'src/styles';
import { aggregateGraphqlErrors } from 'src/utils/error';
import { useAuth } from 'src/hooks/use-auth';
import { User } from 'src/types';
import { SimpleInput } from 'src/components/simple-form';

const StyledForm = styled(Form)`
  padding: ${fibonacci(3)}rem;
`;

const StyledButtonSet = styled(MinorMajorButtonSet)`
  margin-top: ${fibonacci(3)}rem;
`;

interface RegistrationValues {
  email: string;
  password: string;
  username: string;
}

interface InterfaceProps {
  onReject: () => void;
  onResolve: (user: User) => void;
}

const RegistrationFormView: React.FC<InterfaceProps> = ({
  onReject,
  onResolve,
}) => {
  const { t } = useTranslation('general');
  const { register } = useAuth();

  const userSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('errorFormatEmail'))
      .required(t('errorRequired'))
      .max(1000, t('errorTooLong', { length: 1000 })),
    password: yup
      .string()
      .required(t('errorRequired'))
      .max(69, t('errorTooLong', { length: 69 }))
      .min(6, t('errorTooShort', { length: 6 })),
    username: yup
      .string()
      .required(t('errorRequired'))
      .max(15, t('errorTooLong', { length: 15 }))
      .matches(/^[a-zA-Z0-9_]*$/, t('errorFormatAlphanumericUnderscore'))
      .notOneOf(
        [
          'post',
          'posts',
          'list',
          'lists',
          'notification',
          'notifications',
          'publish',
          'search',
          'user',
          'users',
        ],
        t('errorReserved')
      ),
  });

  const onSubmit = async (
    values: RegistrationValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { setErrors, setSubmitting }: any
  ): Promise<void> => {
    try {
      if (!register) return;

      const user = await register(values);

      onResolve(user);
    } catch (e) {
      setErrors(aggregateGraphqlErrors(e, t));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: '', password: '', username: '' }}
      validationSchema={userSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }): ReactElement => (
        <StyledForm data-testid="registration-form">
          <SimpleInput
            data-testid="registration-form__username"
            autoComplete="username"
            autoFocus={true}
            label={t('username')}
            name="username"
          />
          <SimpleInput
            data-testid="registration-form__email"
            autoComplete="email"
            label={t('emailAddress')}
            name="email"
            type="email"
          />
          <SimpleInput
            data-testid="registration-form__password"
            autoComplete="new-password"
            label={t('password')}
            name="password"
            type="password"
          />
          <StyledButtonSet>
            <DeemphasizedButton
              data-testid="registration-form__cancel"
              type="button"
              onClick={onReject}
            >
              {t('cancel')}
            </DeemphasizedButton>
            <StyledButton
              data-testid="registration-form__submit"
              type="submit"
              disabled={isSubmitting}
            >
              {t('submit')}
            </StyledButton>
          </StyledButtonSet>
        </StyledForm>
      )}
    </Formik>
  );
};

export default RegistrationFormView;
