import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  max-width: 70vw;
  overflow: hidden;

  * {
    display: block;
    margin-right: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
