import React from 'react';
import styled from 'styled-components';
import { CuratedList } from 'src/types';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { useNavigate } from 'react-router';
import { Ellipsis, PrimaryColorLink } from 'src/styles';
import { GlobalStyleVariables } from 'src/styles/global';
import CuratedListCardFollowButton from 'src/components/curated-list-card/curated-list-card-follow-button';
import CuratedListCardCurators from 'src/components/curated-list-card/curated-list-card-curators';
import { useTranslation } from 'react-i18next';
import CuratedListCardStats from 'src/components/curated-list-card/curated-list-card-stats';
import { useDoubleTap } from 'use-double-tap';
import { parseMarkdown } from 'src/utils/security';
import PostPreview from 'src/components/post-preview';

const Container = styled.div`
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 3)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    width: 100%;
    border: 0;
  }
`;

const MediaContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledPostPreview = styled(PostPreview)`
  height: 50%;
  width: 50%;
  object-fit: cover;
  object-position: center;
  min-height: initial;
`;

const InfoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${(1 - goldenRatioInverse) * (100 / 2.5)}rem;
`;

const InfoContainerContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${fibonacci(1) * goldenRatioInverse}rem;
  background: rgba(${(props): string => props.theme.backgroundColor}, 0.8);
  overflow: hidden;
`;

const Title = styled(PrimaryColorLink)`
  display: block;
  flex-grow: 1;
  padding: 1rem 0;
  font-size: ${GlobalStyleVariables.fontSizeOneUp}rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled(Ellipsis)`
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

interface InterfaceProps {
  curatedList: CuratedList;
  usesLargeFormat?: boolean;
  usesSmallFormat?: boolean;
}

const CuratedListCardView: React.FC<InterfaceProps> = ({ curatedList }) => {
  const { t } = useTranslation('general');
  const navigate = useNavigate();
  const onNav = useDoubleTap(() => {
    navigate({
      pathname: `/lists/${curatedList.id}`,
    });
  });

  return (
    <Container {...onNav}>
      <PrimaryColorLink to={`/lists/${curatedList.id}`}>
        <MediaContainer>
          {curatedList.curatedPostListings.map((cal) => {
            return (
              <StyledPostPreview
                post={cal.post}
                shouldMute={true}
                shouldPlayOnView={true}
                uncontrollable={true}
                key={cal.post.id}
              />
            );
          })}
        </MediaContainer>

        <CuratedListCardStats curatedList={curatedList} />
      </PrimaryColorLink>

      <InfoContainer>
        <InfoContainerContent>
          <Title to={`/lists/${curatedList.id}`}>
            <Ellipsis>{curatedList.name || t('curatedListsUntitled')}</Ellipsis>
          </Title>
          <CuratedListCardCurators
            curatorialMemberships={curatedList.curatorialMemberships}
          />
          <Description
            dangerouslySetInnerHTML={parseMarkdown(curatedList.description)}
          />
        </InfoContainerContent>
      </InfoContainer>
      <ButtonContainer>
        <CuratedListCardFollowButton curatedList={curatedList} />
      </ButtonContainer>
    </Container>
  );
};

export default CuratedListCardView;
