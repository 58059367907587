// taken heavily from:
// https://usehooks.com/useAuth/

import React, { useState, useContext, createContext } from 'react';
import { User } from 'src/types';
import { useMutation } from '@apollo/client';
import {
  LOGIN_MUTATION,
  CREATE_USER_MUTATION,
  CURRENT_USER_QUERY,
} from 'src/queries';
import { setItem, removeItem, getItem } from 'src/utils/storage';
import { useQuery } from '@apollo/client';

interface ContextProps {
  startedWithAuthToken: boolean;
  login: (variables: LoginProps) => Promise<User>;
  logout: () => void;
  register: (variables: RegisterProps) => Promise<User>;
  user?: User;
}

interface LoginProps {
  identity: string;
  password: string;
}

interface RegisterProps {
  email: string;
  password: string;
  username: string;
}

const AuthContext = createContext<Partial<ContextProps>>({
  startedWithAuthToken: getItem('auth:token'),
});

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): Partial<ContextProps> => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
function useAuthProvider(): ContextProps {
  const [user, setUser] = useState<User>();
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const [createUserMutation] = useMutation(CREATE_USER_MUTATION);
  const { data } = useQuery(CURRENT_USER_QUERY, {
    skip: !getItem('auth:token'),
  });

  if (data && data.currentUser && data.currentUser !== user) {
    setUser(data.currentUser);
  }

  const login = async (variables: LoginProps): Promise<User> => {
    const {
      data: {
        login: { token, user },
      },
    } = await loginMutation({
      variables,
    });
    setItem('auth:token', token);
    setUser(user);

    return user;
  };

  const register = async (variables: RegisterProps): Promise<User> => {
    const {
      data: {
        createUser: { token, user },
      },
    } = await createUserMutation({
      variables,
    });
    setItem('auth:token', token);
    setUser(user);

    return user;
  };

  const logout = (): void => {
    removeItem('auth:token');
    setUser(undefined);
    window.location.assign(window.location.origin);
  };

  // Return the user object and auth methods
  return {
    startedWithAuthToken: getItem('auth:token'),
    login,
    logout,
    register,
    user,
  };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuthProvider();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
