import React from 'react';
import {
  DashDotDotButtonSet,
  Ellipsis,
  GlobalStyleVariables,
  PrimaryColorLink,
} from 'src/styles';
import { Post } from 'src/types';
import Summary from 'src/components/summary';
import ExpandingList from 'src/components/expanding-list';
import CreditByline from 'src/components/credit-byline';
import ShareButton from 'src/components/share-button';
import styled from 'styled-components';
import { goldenRatioInverse, fibonacci } from 'src/utils/math';
import PostSummarySumplementaryInfo from 'src/components/post-summary/post-summary-supplementary-info';
import PostSummaryPrimaryButton from 'src/components/post-summary/post-summary-primary-button';
import PostSummaryMenu from 'src/components/post-summary/post-summary-menu';
import PostSummaryCreditAcceptanceInterface from 'src/components/post-summary/post-summary-credit-acceptance-interface';
import { parseMarkdown } from 'src/utils/security';

interface PostSummaryProps {
  isConstrained?: boolean;
}

const PostSummary = styled(Summary).attrs((props: PostSummaryProps) => ({
  isConstrained: props.isConstrained,
}))`
  ${(props): string | undefined => {
    if (!props.isConstrained) {
      return `
        margin-top: 0;
        border-top: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 3)}rem
          rgba(${props.theme.primaryColor}, ${goldenRatioInverse});
      `;
    }
  }}
`;

export const ExpandingCreditsList = styled(ExpandingList)`
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  font-weight: bold;

  li:not(:first-child),
  .expanding-list__more {
    display: block;
    margin-top: ${fibonacci(1) * Math.pow(goldenRatioInverse, 3)}rem;
  }

  a {
    color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
  }
`;

interface InterfaceProps {
  post: Post;
  className?: string;
  disableHeaderLink?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
}

const PostSummaryView: React.FC<InterfaceProps> = ({
  post,
  className,
  disableHeaderLink,
  setIsEditing,
}) => {
  const { credits, description, id, title } = post;

  return (
    <PostSummary className={className}>
      <section className="summary__lead">
        <h1 className="summary__header">
          {disableHeaderLink ? (
            <Ellipsis>{title}</Ellipsis>
          ) : (
            <PrimaryColorLink to={`/posts/${id}`}>
              <Ellipsis>{title}</Ellipsis>
            </PrimaryColorLink>
          )}
        </h1>
        <div className="summary__credits">
          <ExpandingCreditsList contractedLength={1}>
            {[...credits]
              .sort((a, b) => a.role - b.role)
              .map((credit) => {
                return <CreditByline credit={credit} key={credit.id} />;
              })}
          </ExpandingCreditsList>
        </div>
        <div className="summary__buttons">
          <DashDotDotButtonSet>
            <PostSummaryPrimaryButton post={post} />
            <ShareButton url={window.location.href} text={title} />
            <PostSummaryMenu post={post} setIsEditing={setIsEditing} />
          </DashDotDotButtonSet>
          <PostSummaryCreditAcceptanceInterface post={post} />
        </div>
      </section>
      <section className="summary__primary">
        <Ellipsis className="summary__supplementary-list">
          <PostSummarySumplementaryInfo post={post} />
        </Ellipsis>
        <Ellipsis
          className="summary__main"
          dangerouslySetInnerHTML={parseMarkdown(description)}
        />
      </section>
    </PostSummary>
  );
};

export default PostSummaryView;
