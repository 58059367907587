import React from 'react';
import ModerationRequestList from 'src/components/moderation-request-list';
import { MainContainer } from 'src/styles';

const ModerationRouteView: React.FC = () => {
  return (
    <MainContainer>
      <ModerationRequestList />
    </MainContainer>
  );
};

export default ModerationRouteView;
