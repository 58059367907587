import React, { Children, useState, Fragment } from 'react';
import { UnstyledList, UnstyledListItem } from 'src/styles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Link = styled.a`
  display: block;
  cursor: pointer;
`;

function removeOverflowingChildren(
  children: React.ReactNode[],
  contractedLength: number,
  isExpanded: boolean
): React.ReactNode[] {
  if (!isExpanded && children.length > contractedLength + 1) {
    return children.slice(0, contractedLength);
  } else {
    return children;
  }
}

interface InterfaceProps {
  className?: string;
  contractedLength: number;
}

const ExpandingListView: React.FC<InterfaceProps> = ({
  className,
  children,
  contractedLength,
}) => {
  const { t } = useTranslation('general');
  const [isExpanded, setIsExpanded] = useState(false);
  const childrenArray = Children.toArray(children);
  const remainingChildren = removeOverflowingChildren(
    childrenArray,
    contractedLength,
    isExpanded
  );
  const overflowAmount = childrenArray.length - remainingChildren.length;

  return (
    <div className={className}>
      <UnstyledList>
        {remainingChildren.map((child, index) => {
          return <UnstyledListItem key={index}>{child}</UnstyledListItem>;
        })}
      </UnstyledList>

      {overflowAmount > 0 && (
        <Link
          className="expanding-list__more"
          onClick={(): void => setIsExpanded(!isExpanded)}
        >
          <Fragment>{t('more', { amount: overflowAmount })}</Fragment>
        </Link>
      )}
    </div>
  );
};

export default ExpandingListView;
