import React from 'react';
import { Credit } from 'src/types';
import { useTranslation } from 'react-i18next';
import UsernameView from 'src/components/username';
import ReactTooltip from 'react-tooltip';
import { Ellipsis } from 'src/styles';

interface InterfaceProps {
  credit: Credit;
}

const ExpandingCreditsByline: React.FC<InterfaceProps> = ({ credit }) => {
  const { t } = useTranslation('general');
  const { user } = credit;

  return (
    <UsernameView user={user}>
      {credit.title && credit.title !== '' && (
        <Ellipsis> ({credit.title}) </Ellipsis>
      )}
      {!credit.isVerified && (
        <>
          <span data-tip={t('creditsTooltipUnverified')}>[?]</span>
          <ReactTooltip effect="solid" place="right" />
        </>
      )}
    </UsernameView>
  );
};

export default ExpandingCreditsByline;
