import gql from 'graphql-tag';
import { ESSENTIAL_USER_FRAGMENT } from '.';

export const SEARCH_SUGGESTIONS_QUERY = gql`
  query SearchSuggestion($query: String, $metadata: PaginationMetadataInput) {
    userSuggestions(query: $query, metadata: $metadata) {
      ...essentialUser
      displayName
      avatar {
        id
        src
      }
    }
    tagSuggestions(query: $query, metadata: $metadata) {
      id
      discoverableCount
      name
    }
  }
  ${ESSENTIAL_USER_FRAGMENT}
`;
