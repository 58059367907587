import React, { ReactElement, useState } from 'react';
import { ModerationRequest, PaginationQueryVariables } from 'src/types';
import styled from 'styled-components';
import { PrimaryColorVanillaLink } from 'src/styles';
import { PAGINATED_MODERATION_REQUEST_REPORT_QUERY } from 'src/queries';
import { useTranslation } from 'react-i18next';
import { useOffsetBasedPagination } from 'src/hooks';
import GraphqlResult from 'src/components/graphql-result';
import ModerationRequestItemReportsEntries from 'src/components/moderation-request-item/moderation-request-item-reports-entries';

const ReportsContainer = styled.div`
  padding: 1rem;
`;

const variables: PaginationQueryVariables = {
  metadata: {
    limit: 3,
    offset: 0,
  },
  type: 'ModerationRequestReport',
};

interface InterfaceProps {
  moderationRequest: ModerationRequest;
}

const ModerationRequestItemReports: React.FC<InterfaceProps> = ({
  moderationRequest,
}) => {
  const { t } = useTranslation('general');
  const [isExpanded, setIsExpanded] = useState(true);
  const response = useOffsetBasedPagination(
    PAGINATED_MODERATION_REQUEST_REPORT_QUERY,
    {
      ...variables,
      paginationArgs: {
        moderationRequestId: moderationRequest.id,
      },
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  const toggleReports = (): void => {
    setIsExpanded(!isExpanded);
  };

  return (
    <ReportsContainer>
      <PrimaryColorVanillaLink onClick={toggleReports}>
        {t('moderationReports', {
          count: moderationRequest.moderationRequestReportCount,
        })}
      </PrimaryColorVanillaLink>
      {isExpanded && (
        <GraphqlResult response={response}>
          {(data): ReactElement => {
            return (
              <ModerationRequestItemReportsEntries
                {...data}
                reportCount={moderationRequest.moderationRequestReportCount}
              />
            );
          }}
        </GraphqlResult>
      )}
    </ReportsContainer>
  );
};

export default ModerationRequestItemReports;
