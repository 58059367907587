import React from 'react';
import { User } from 'src/types';
import { PrimaryColorLink } from 'src/styles';

interface InterfaceProps {
  className?: string;
  user: User;
}

const UsernameView: React.FC<InterfaceProps> = ({
  children,
  className,
  user,
}) => {
  return (
    <PrimaryColorLink
      to={`/${user.username}`}
      className={className}
      data-testid="username"
    >
      @{user.username} {children}
    </PrimaryColorLink>
  );
};

export default UsernameView;
