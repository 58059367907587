/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useState, useEffect } from 'react';
import { VanillaStyledInput } from 'src/styles';
import { DocumentNode } from 'graphql';
import { useLazyQuery } from '@apollo/client';
import { FieldProps } from 'formik';
import { ArrowNavList } from 'src/components/arrow-nav-list';
import { GenericOptionItem } from 'src/types';
import styled from 'styled-components';
import { goldenRatioInverse, fibonacci } from 'src/utils/math';
import StyledScrollbar from 'src/components/styled-scrollbar';
import { isMobile } from 'src/utils/detection';

const Container = styled.div`
  position: relative;
`;

const ReStyledScrollbar = styled(StyledScrollbar)`
  position: absolute;
  top: -${fibonacci(1) * Math.pow(goldenRatioInverse, 5)}rem;
  max-height: ${Math.pow(goldenRatioInverse, 2) * 100}rem;
  overflow: auto;
  z-index: 100;
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  border: solid ${fibonacci(1) * Math.pow(goldenRatioInverse, 4)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);

  ::-webkit-scrollbar {
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
`;

interface InterfaceProps {
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  initialIndex?: number;
  limit?: number;
  min?: number;
  max?: number;
  placeholder?: string;
  query: DocumentNode;
  serializeEntries: (data: any, close: () => void) => GenericOptionItem[];
  type?: string;
  value?: string;
}

const AutocompleteFieldView: React.FC<InterfaceProps & FieldProps> = ({
  autoComplete,
  autoFocus,
  disabled,
  field,
  form,
  initialIndex = -1,
  limit = 5,
  min,
  max,
  placeholder,
  query,
  serializeEntries,
  type,
  ...props
}) => {
  const [autocompleteIsOpen, setAutocompleteIsOpen] = useState(false);
  const [performQuery, { data }] = useLazyQuery(query);

  const close = (): void => {
    setAutocompleteIsOpen(false);
  };

  const open = (): void => {
    setAutocompleteIsOpen(true);
  };

  const onBlur = (): void => {
    close();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    form.setFieldValue(field.name, value);
    performQuery({
      variables: {
        query: value,
        metadata: { limit },
        mediaItemsMetadata: { limit: 1, offset: 0 },
      },
    });
    open();
  };

  const onClick = (value: string): void => {
    form.setFieldValue(field.name, value);
    close();
  };

  const onFocus = (): void => {
    open();
  };

  const items = serializeEntries(data, close);

  function onKeyDown(event: KeyboardEvent): void {
    if (
      event.key === 'Escape' ||
      event.key === 'Enter' ||
      event.key === 'NumpadEnter'
    ) {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
      close();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  if (isMobile()) autoFocus = false;

  return (
    <div>
      <VanillaStyledInput
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        min={min}
        max={max}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        type={type}
        value={field.value}
        {...props}
      />
      {autocompleteIsOpen && items.length > 0 && (
        <Container>
          <ReStyledScrollbar>
            <ArrowNavList
              initialIndex={initialIndex}
              items={items}
              onChange={onClick}
            />
          </ReStyledScrollbar>
        </Container>
      )}
    </div>
  );
};

export default AutocompleteFieldView;
