import React from 'react';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import styled from 'styled-components';

interface BorderProps {
  top: number;
}

const Border = styled.hr.attrs((props: BorderProps) => ({
  top: props.top,
}))`
  position: sticky;
  top: ${(props): number => props.top}rem;
  z-index: 9;
  margin: 0;
  border: 0;
  border-bottom: dashed ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem
    rgba(${(props): string => props.theme.primaryColor}, 1);
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
`;

interface InterfaceProps {
  top: number;
}

const StickyBorderView: React.FC<InterfaceProps> = ({ top }) => {
  return <Border top={top} />;
};

export default StickyBorderView;
