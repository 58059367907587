/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client';
import {
  CURRENT_USER_QUERY,
  MANAGE_SUBSCRIPTION_PROMOTION_MUTATION,
  SUBSCRIPTION_DISCOUNTS_QUERY,
} from 'src/queries';
import {
  CuratedList,
  SubscriptionDiscountType,
  SubscriptionPromotionInput,
  User,
} from 'src/types';
import { useEnsureUser } from 'src/hooks/use-ensure-user';

export function useManageSubscriptionPromotion(): (
  subscriptionPromotionInput: SubscriptionPromotionInput
) => Promise<CuratedList> {
  const ensureUser = useEnsureUser();
  const [manageSubscriptionPromotionMutation] = useMutation(
    MANAGE_SUBSCRIPTION_PROMOTION_MUTATION,
    {
      update(cache, { data: { manageSubscriptionPromotion } }) {
        const promotion = manageSubscriptionPromotion.subscriptionDiscount;

        const { currentUser } = cache.readQuery({
          query: CURRENT_USER_QUERY,
        }) as { currentUser: User };

        cache.writeQuery({
          query: SUBSCRIPTION_DISCOUNTS_QUERY,
          variables: {
            isActive: true,
            type: SubscriptionDiscountType.initialSubscription,
            userId: currentUser.id,
          },
          data: {
            subscriptionDiscounts: promotion ? [promotion] : [],
          },
        });

        cache.writeQuery({
          query: CURRENT_USER_QUERY,
          data: {
            currentUser: {
              ...currentUser,
              subscriptionPrice: manageSubscriptionPromotion.subscriptionPrice,
            },
          },
        });
      },
    }
  );

  return async (
    subscriptionPromotionInput: SubscriptionPromotionInput
  ): Promise<CuratedList> => {
    if (!manageSubscriptionPromotionMutation)
      throw new Error("manageSubscriptionPromotionMutation didn't load");

    await ensureUser();

    const {
      data: { manageSubscriptionPromotion },
    } = await manageSubscriptionPromotionMutation({
      variables: {
        subscriptionPromotionInput: {
          ...subscriptionPromotionInput,
          subscriptionPrice:
            subscriptionPromotionInput.subscriptionPrice.toString(),
        },
      },
    });

    return manageSubscriptionPromotion;
  };
}
