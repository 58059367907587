import React, { RefObject, createRef, useEffect, forwardRef } from 'react';

interface InterfaceProps {
  className?: string;
  onClick?: () => void;
  ref?: RefObject<HTMLCanvasElement>;
  render: (canvas: HTMLCanvasElement) => void;
}

const ResponsiveCanvasView = forwardRef<HTMLCanvasElement, InterfaceProps>(
  (props, passedRef) => {
    const ref = (passedRef as RefObject<HTMLCanvasElement>) || createRef();
    useEffect(() => {
      const element = ref.current;

      if (element) {
        const rerender = (): void => {
          requestAnimationFrame(() => props.render(element));
        };

        rerender();

        window.addEventListener('resize', rerender);

        return (): void => {
          window.removeEventListener('resize', rerender);
        };
      }
    });

    return (
      <canvas
        className={props.className}
        ref={ref}
        onClick={props.onClick}
      ></canvas>
    );
  }
);

ResponsiveCanvasView.displayName = 'responsive-canvas-view';

export default ResponsiveCanvasView;
