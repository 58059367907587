import React from 'react';
import { User } from 'src/types';
import { Avatar } from 'src/components/avatar';
import { AutocompleteFieldSuggestion } from '.';
import { fibonacci } from 'src/utils/math';
import styled from 'styled-components';
import { Ellipsis } from 'src/styles';

const StyledAvatar = styled(Avatar)`
  height: ${fibonacci(5)}rem;
  width: ${fibonacci(5)}rem;
`;

interface InterfaceProps {
  user: User;
}

const AutocompleteFieldUserSuggestion: React.FC<InterfaceProps> = ({
  user,
  ...props
}) => {
  return (
    <AutocompleteFieldSuggestion {...props}>
      <StyledAvatar avatar={user.avatar} size={4} />
      <span>@{user.username}</span>
      {user.displayName && (
        <>
          <span>-</span>
          <Ellipsis>{user.displayName}</Ellipsis>
        </>
      )}
    </AutocompleteFieldSuggestion>
  );
};

export default AutocompleteFieldUserSuggestion;
