import React, { ReactElement } from 'react';
import UserListView from 'src/components/user-list/user-list-view';
import { PaginationQueryVariables, PaginationArgs } from 'src/types';
import { PAGINATED_USER_QUERY } from 'src/queries';
import { useOffsetBasedPagination } from 'src/hooks';
import GraphqlResult from 'src/components/graphql-result';
import {
  generateGridPaginationMetadata,
  generateMediaItemsMetadataPreview,
  generatePaginationMetadata,
} from 'src/utils/pagination';

interface InterfaceProps {
  className?: string;
  hideIfEmpty?: boolean;
  paginationArgs?: PaginationArgs;
  scrollElementRef?: HTMLDivElement | null;
  useGridLayout?: boolean;
  useLineItemLayout?: boolean;
  setCarouselLength?: (carouselLength: number) => void;
}

const UserListQuery: React.FC<InterfaceProps> = ({
  className,
  hideIfEmpty,
  paginationArgs = {},
  scrollElementRef,
  useGridLayout,
  useLineItemLayout,
  setCarouselLength,
}) => {
  const variables: PaginationQueryVariables = {
    metadata: useGridLayout
      ? generateGridPaginationMetadata()
      : generatePaginationMetadata(),
    type: 'User',
    mediaItemsMetadata: generateMediaItemsMetadataPreview(),
  };

  const response = useOffsetBasedPagination(PAGINATED_USER_QUERY, {
    ...variables,
    paginationArgs,
  });

  return (
    <GraphqlResult response={response}>
      {(data): ReactElement => {
        return (
          <UserListView
            className={className}
            hideIfEmpty={hideIfEmpty}
            scrollElementRef={scrollElementRef}
            setCarouselLength={setCarouselLength}
            useGridLayout={useGridLayout}
            useLineItemLayout={useLineItemLayout}
            {...data}
          />
        );
      }}
    </GraphqlResult>
  );
};

export default UserListQuery;
